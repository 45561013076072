import { ProductPromo, PromoCampaign, Product, Offer } from '@box-types';
import { getPromoCampaignImage, getPromoText, isProductPromoCampaign } from './promo-campaigns.utils';
import { isNil, omitBy } from 'lodash-es';
import { generateImageSrc } from '../media';
import { sortSuggestionElements } from '../core';

export { promoCampaignToProductPromo, getProductPromos, getOfferProductPromos };

function promoCampaignToProductPromo(promoCampaign: PromoCampaign): ProductPromo {
  if (!promoCampaign) return;
  const { name, priority, multiplier, points, type } = promoCampaign;
  const indicator = generateImageSrc(getPromoCampaignImage(promoCampaign, 'productIndicator'));
  const imageIndicator = generateImageSrc(getPromoCampaignImage(promoCampaign, 'productImageIndicator'));
  const detailsImage = generateImageSrc(getPromoCampaignImage(promoCampaign, 'productDetailsImage'));
  const detailsText = getPromoText(promoCampaign.texts, 'productDetailsHeader');
  return omitBy(
    {
      name,
      priority,
      multiplier,
      points,
      type,
      indicator,
      imageIndicator,
      detailsImage,
      detailsText
    },
    isNil
  );
}

function getProductPromos(product: Product, promoCampaigns: PromoCampaign[]): ProductPromo[] {
  const {
    brand: productBrand,
    promoCampaigns: productPromoCampaignNames,
    drinkId: productDrinkId,
    isInsideOffer: productIsInsideOffer
  } = product;
  if (!productBrand?.promoCampaignId && !productPromoCampaignNames?.length && !productDrinkId) return [];
  if (!promoCampaigns?.length) return [];
  const eligibleProductPromoCampaigns = promoCampaigns.filter((promoCampaign) => {
    if (!isProductPromoCampaign(promoCampaign)) return false;
    const productReferencesCampaign =
      productPromoCampaignNames?.includes(promoCampaign.name) ||
      productBrand?.promoCampaignId === promoCampaign._id ||
      promoCampaign.drinkIds?.includes(productDrinkId);
    const isValid = productIsInsideOffer ? promoCampaign.validForProductsInOffers : true;
    return productReferencesCampaign && isValid;
  });
  const sortedProductPromoCampaigns = sortSuggestionElements(eligibleProductPromoCampaigns);
  return sortedProductPromoCampaigns.map((promoCampaign) => promoCampaignToProductPromo(promoCampaign));
}

function getOfferProductPromos(offer: Offer, promoCampaigns: PromoCampaign[]): ProductPromo[] {
  const { promoCampaigns: offerPromoCampaignNames } = offer;
  if (!offerPromoCampaignNames?.length) return [];
  if (!promoCampaigns?.length) return [];
  const eligibleProductPromoCampaigns = promoCampaigns.filter((promoCampaign) => {
    if (!isProductPromoCampaign(promoCampaign)) return false;
    return offerPromoCampaignNames.includes(promoCampaign.name);
  });
  const sortedProductPromoCampaigns = sortSuggestionElements(eligibleProductPromoCampaigns);
  return sortedProductPromoCampaigns.map((promoCampaign) => promoCampaignToProductPromo(promoCampaign));
}

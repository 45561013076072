import { Order, UserEvent, GDPRConsentReminder } from '@box-types';
import { orderBy } from 'lodash-es';
import dayjs from 'dayjs';
import { getOrderShopStatus } from '../order';

export { getLatestGDPRConsentReminder, getNextGDPRConsentReminder, isGDPRConsentReminderActive };

function getLatestGDPRConsentReminder(
  reminders: GDPRConsentReminder[],
  eventHistory: UserEvent[]
): GDPRConsentReminder {
  if (!reminders.length) return;
  const sortedReminders = orderBy(reminders, 'days', 'desc');
  return sortedReminders.find((reminder) => eventHistory.find((event) => event.key === reminder.key));
}

function getNextGDPRConsentReminder(
  latestReminder: GDPRConsentReminder,
  reminders: GDPRConsentReminder[]
): GDPRConsentReminder {
  if (!reminders.length) return;
  const sortedReminders = orderBy(reminders, 'days', 'desc');
  if (!latestReminder) return sortedReminders[sortedReminders.length - 1];
  const currentReminderIndex = sortedReminders.findIndex((reminder) => reminder.key === latestReminder.key);
  const nextReminderIndex = currentReminderIndex - 1;
  if (nextReminderIndex > -1) return sortedReminders[nextReminderIndex];
}

function isGDPRConsentReminderActive(
  latestOrder: Order,
  nextReminder: GDPRConsentReminder,
  latestEvent: UserEvent
): boolean {
  const acceptedOrderStatus = getOrderShopStatus(latestOrder, 'ACCEPTED');
  if (!acceptedOrderStatus?.changedAt) return false;
  const latestOrderDate = dayjs.unix(acceptedOrderStatus.changedAt);
  const latestEventDate = dayjs.unix(latestEvent.timestamp);
  const currentDate = dayjs();
  if (latestOrderDate.isBefore(latestEventDate)) return false;
  return currentDate.isAfter(latestEventDate.add(nextReminder.days, 'days'));
}

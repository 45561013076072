import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { auditTime, Subscription } from 'rxjs';

@Directive({ selector: '[heroOpacity]' })
export class HeroOpacityDirective implements AfterViewInit, OnDestroy {
  private maxScrollHeight: number;
  private subscription: Subscription;

  constructor(private element: ElementRef<HTMLElement>, private scrollDispatcher: ScrollDispatcher) {}

  ngAfterViewInit(): void {
    this.element.nativeElement.style.transition = 'opacity 50ms cubic-bezier(0.4, 0, 0.2, 1)';
    this.element.nativeElement.style.opacity = '1';
    this.maxScrollHeight = this.getScrollMaxHeight();
    this.setScrollSubscription();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.element.nativeElement.style.removeProperty('transition');
    this.element.nativeElement.style.removeProperty('opacity');
  }

  private getScrollMaxHeight(): number {
    const { offsetHeight } = this.element.nativeElement;
    // We are extracting 10% of the height, to see the whole animation before it disappears
    return offsetHeight - offsetHeight * 0.1;
  }

  private setScrollSubscription(): void {
    this.subscription = this.scrollDispatcher
      .scrolled()
      .pipe(auditTime(16))
      .subscribe((scrollData: CdkScrollable) => {
        const parentScrollTop = scrollData?.getElementRef()?.nativeElement?.scrollTop ?? 0;
        const percent = 1 - Math.min(parentScrollTop, this.maxScrollHeight) / this.maxScrollHeight;
        this.element.nativeElement.style.opacity = String(percent);
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'checkout-payment-badge',
  templateUrl: './checkout-payment-badge.component.html',
  styleUrls: ['./checkout-payment-badge.component.scss']
})
export class CheckoutPaymentBadgeComponent implements OnInit {
  @Input() public images: string[];
  @Input() private multiplier: number;
  @Input() private points: number;

  public multiplierView: string;
  public pointsView: string;

  ngOnInit(): void {
    this.multiplierView = this.multiplier > 1 ? `x${this.multiplier}` : '';
    this.pointsView = this.points > 0 ? `+${this.points}` : '';
  }
}

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Injectable, TemplateRef } from '@angular/core';
import { ConfirmDialogData, ConfirmDialogResponse, InfoDialogData, APIError, InfoDialogResponse } from '@box-types';
import { ComponentType } from '@angular/cdk/portal';
import { BoxConfirmDialogComponent, BoxInfoDialogComponent } from '@box-shared/components';
import { translate } from '@box-core/services/language.service';

@Injectable({ providedIn: 'any' })
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public openDialog<T, R = any>(
    componentType: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig
  ): MatDialogRef<T, R> {
    if (!componentType) return;
    return this.dialog.open(componentType, config);
  }

  public openInfoDialog(
    data: InfoDialogData,
    config?: MatDialogConfig
  ): MatDialogRef<BoxInfoDialogComponent, InfoDialogResponse> {
    if (!data) return;
    const dialogConfig: MatDialogConfig = { ...config, data };
    return this.dialog.open(BoxInfoDialogComponent, dialogConfig);
  }

  public openConfirmDialog(
    data: ConfirmDialogData,
    config?: MatDialogConfig
  ): MatDialogRef<BoxConfirmDialogComponent, ConfirmDialogResponse> {
    if (!data) return;
    const dialogConfig: MatDialogConfig = { ...config, data };
    return this.dialog.open(BoxConfirmDialogComponent, dialogConfig);
  }

  public openErrorDialog(
    error: Error | APIError,
    config?: MatDialogConfig
  ): MatDialogRef<BoxInfoDialogComponent, Error | APIError> {
    if (!error) return;
    const title = (error as APIError).userTitle ?? translate('something_went_wrong');
    const message = (error as APIError).userMessage ?? translate('try_again_in_a_bit');
    const dialogConfig = {
      ...config,
      data: {
        title,
        messages: [message]
      }
    };
    return this.dialog.open(BoxInfoDialogComponent, dialogConfig);
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AddressesService } from '@box-core/services';

@Injectable({ providedIn: 'root' })
export class PromoRedirectGuard implements CanActivate {
  /*
  This is a redirection guard to handle the urls like "promos?promo=explore-x6-points"
  When there is not any address must redirect to landing page.
  */
  constructor(private router: Router, private addressesService: AddressesService) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    const address = this.addressesService.getAddress();
    const queryParams = activatedRouteSnapshot.queryParams;
    const promoParam = queryParams?.promo;

    if (promoParam && !address) return void this.router.navigate(['/'], { queryParams: { promo: promoParam } });
    return true;
  }
}

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'component-loader',
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentLoaderComponent {
  @HostBinding('class') public hostClass = 'component-loader';
}

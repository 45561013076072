import { Injectable } from '@angular/core';
import { ConfigurationService, CoreService, PromoCampaignsService } from '@box-core/services';
import { Shop, Cuisine, Brand } from '@box-types';
import {
  decorateBrandWithBenefits,
  filterShopsByBrands,
  filterSortAndSliceBrands,
  getBrandsForCuisine
} from '@box/utils';

@Injectable()
export class BrandsService {
  constructor(
    private coreService: CoreService,
    private configService: ConfigurationService,
    private promoCampaignsService: PromoCampaignsService
  ) {}

  public getBrandsForCuisine(cuisine?: Cuisine): Brand[] {
    const brands = this.coreService.brands.getValue();
    return getBrandsForCuisine(cuisine, brands);
  }

  public decorateFilterAndSortBrands(brands: Brand[], shops: Shop[]): Brand[] {
    const maxBrands = this.configService.getConfiguration().maxBrandsNo;
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const decoratedBrands = brands.map((brand) => decorateBrandWithBenefits(brand, promoCampaigns));
    return filterSortAndSliceBrands(decoratedBrands, shops, maxBrands);
  }

  public getFilteredShopsByBrand(brand: Brand, shops: Shop[]): Shop[] {
    const activeBrands = brand ? [brand] : [];
    return filterShopsByBrands(shops, activeBrands);
  }
}

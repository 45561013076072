import { Cuisine, CollapsibleTile } from '@box-types';
import { removeAccents } from '../string.utils';
import { generateImageSrc } from '../media';

export { cuisineToCollapsibleTile };

function cuisineToCollapsibleTile(cuisine: Cuisine, shopsCount?: number): CollapsibleTile<Cuisine> {
  return {
    key: cuisine.key,
    collapsedTitle: removeAccents(cuisine.name),
    collapsedImage: generateImageSrc(cuisine.collapsedImageWeb),
    backGroundColor: cuisine.backGroundColor,
    title: removeAccents(cuisine.name),
    disabled: !shopsCount,
    image: generateImageSrc(cuisine.tileImageWeb),
    item: cuisine
  };
}

<ng-container *ngIf="mapsAPILoaded; else emptyState">
  <google-map #map class="live-tracking-map" width="100%" height="100%" [options]="mapOptions">
    <map-marker [options]="shopMarkerOptions"></map-marker>
    <map-marker [options]="addressMarkerOptions"></map-marker>
    <map-marker [options]="deliveryMarkerOptions"></map-marker>
  </google-map>
</ng-container>
<ng-template #emptyState>
  <div [style.background-image]="emptyStateMapUrl" class="live-tracking-map-empty-state"></div>
</ng-template>

const PRERENDER_USER_AGENT_TEXT = 'Prerender (+https://github.com/prerender/prerender)';
// This is from the Official Prerender io Documentation https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
// https://docs.prerender.io/docs/11-best-practices

const PRERENDER_STATUS_CODE_NAME = 'prerender-status-code';
const PRERENDER_HEADER_NAME = 'prerender-header';

function isPrerenderBrowser(window: Window): boolean {
  const userAgent = window?.navigator?.userAgent;
  if (!userAgent) return false;
  return userAgent.includes(PRERENDER_USER_AGENT_TEXT);
}

function createPrerenderStatusCodeMetaElement(code: string): HTMLMetaElement {
  if (!code) return;
  const metaElementCode = document.createElement('meta');
  metaElementCode.setAttribute('name', PRERENDER_STATUS_CODE_NAME);
  metaElementCode.setAttribute('content', code);
  return metaElementCode;
}

function createPrerenderHeaderMetaElement(url: string): HTMLMetaElement {
  if (!url) return;
  const metaElementHeader = document.createElement('meta');
  metaElementHeader.setAttribute('name', PRERENDER_HEADER_NAME);
  metaElementHeader.setAttribute('content', `Location: ${url}`);
  return metaElementHeader;
}

function clearExistingRedirectPrerenderMetaElements(): void {
  const codeMetaElement = document.head.querySelector(`meta[name=${PRERENDER_STATUS_CODE_NAME}]`);
  if (codeMetaElement) codeMetaElement.remove();
  const headerMetaElement = document.head.querySelector(`meta[name=${PRERENDER_HEADER_NAME}]`);
  if (headerMetaElement) headerMetaElement.remove();
}

function setRedirectPrerenderMetaElements(url: string): void {
  if (!url) return;
  const codeMetaElement = createPrerenderStatusCodeMetaElement('301');
  if (!codeMetaElement) return;
  const headerMetaElement = createPrerenderHeaderMetaElement(url);
  if (!headerMetaElement) return;
  document.head.appendChild(codeMetaElement);
  document.head.appendChild(headerMetaElement);
}

function setPrerenderReady(ready: boolean): void {
  if (!window) return;
  window['prerenderReady'] = ready;
}

export {
  PRERENDER_USER_AGENT_TEXT,
  isPrerenderBrowser,
  setRedirectPrerenderMetaElements,
  clearExistingRedirectPrerenderMetaElements,
  createPrerenderStatusCodeMetaElement,
  createPrerenderHeaderMetaElement,
  setPrerenderReady
};

import { ComponentType } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { NotificationComponent } from '@box-core/components';
import { NotificationConfig } from '@box-core/components/notification/notification.config';
import { NotificationInjector } from '@box-core/components/notification/notification.injector';
import { NotificationRef } from '@box-core/components/notification/notification.ref';
import { BehaviorSubject, Subject } from 'rxjs';
import { v1 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private readonly notificationsSource = new BehaviorSubject<ComponentRef<NotificationComponent>[]>([]);
  public readonly notifications$ = this.notificationsSource.asObservable();

  public readonly afterOpened = new Subject<ComponentRef<NotificationComponent>[]>();

  constructor(
    private injector: Injector,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  public addNotification<T>(componentType: ComponentType<T>, config?: NotificationConfig): NotificationRef {
    const map = new WeakMap();
    const notificationConfig = this.generateNotificationConfig(config);
    map.set(NotificationConfig, notificationConfig);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationComponent);
    const componentRef = componentFactory.create(new NotificationInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);
    componentRef.instance.childComponentType = componentType;
    const notificationRef = new NotificationRef();
    map.set(NotificationRef, notificationRef);
    notificationRef.containerId = notificationConfig.id;
    notificationRef.afterDismissed.subscribe(() => this.removeNotification(notificationRef));
    notificationRef.afterDismissedWithAction.subscribe(() => this.removeNotification(notificationRef));
    const currentNotifications = this.notificationsSource.getValue();
    const updatedNotifications = [componentRef, ...currentNotifications];
    this.notificationsSource.next(updatedNotifications);
    this.afterOpened.next(updatedNotifications);
    return notificationRef;
  }

  public removeNotification(notificationRef: NotificationRef): void {
    const currentNotifications = this.notificationsSource.getValue();
    const currentNotificationIndex = currentNotifications.findIndex(
      (n) => n.instance.id === notificationRef.containerId
    );
    const currentNotification = currentNotifications[currentNotificationIndex];
    if (!currentNotification) return;
    const updatedNotifications = [
      ...currentNotifications.slice(0, currentNotificationIndex),
      ...currentNotifications.slice(currentNotificationIndex + 1)
    ];
    this.notificationsSource.next(updatedNotifications);
    this.appRef.detachView(currentNotification.hostView);
    /*
    We do not call currentNotification.destroy() because the Notifications Component works
    with a loop. Thus when the ComponentRef is getting removed from the notificationsSource
    the Template Engine removes the element from the View. Therefor, calling destroy() here,
    will cause issues with the Notification Animation * => void transition
    */
  }

  public removeAllNotifications(): void {
    const currentNotifications = this.notificationsSource.getValue();
    if (!currentNotifications?.length) return;
    currentNotifications.forEach((notification) => this.appRef.detachView(notification.hostView));
    this.notificationsSource.next([]);
  }

  private generateNotificationConfig(config: NotificationConfig): NotificationConfig {
    const id = v1();
    return { id, ...config };
  }
}

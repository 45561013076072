import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ShopMenuNavItemChange, ShopMenuNav, ShopMenuNavItem } from '@box-types';
import { Subscription } from 'rxjs';
import { ShopMenuNavService } from '../../services';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { ShopMenuNavBottomSheetComponent } from '../shop-menu-nav-bottom-sheet/shop-menu-nav-bottom-sheet.component';
import { Swiper, SwiperOptions } from 'swiper/types';
import { Mousewheel } from 'swiper';

@Component({
  selector: 'shop-menu-nav-mobile',
  templateUrl: './shop-menu-nav-mobile.component.html',
  styleUrls: ['./shop-menu-nav-mobile.component.scss']
})
export class ShopMenuNavMobileComponent implements OnInit, OnDestroy {
  @ViewChild('mainSwiper') private mainSwiper: ElementRef<HTMLDivElement & { swiper: Swiper }>;
  @ViewChild('secondarySwiper') private secondarySwiper: ElementRef<HTMLDivElement & { swiper: Swiper }>;
  @Output() private itemChange = new EventEmitter<ShopMenuNavItemChange>();

  public menuNav: ShopMenuNav;
  public activeItem: ShopMenuNavItem;
  public swiperOptions: SwiperOptions;

  private menuSubscription: Subscription;

  constructor(private shopMenuNavService: ShopMenuNavService, private bottomSheet: MatBottomSheet) {}

  ngOnInit(): void {
    this.setMenuSubscription();
    this.setSwiperOptions();
  }

  ngOnDestroy(): void {
    if (this.menuSubscription) this.menuSubscription.unsubscribe();
  }

  public onItemClick(item: ShopMenuNavItem): void {
    this.itemChange.emit({ item });
  }

  public onSecondaryItemClick(subItem: ShopMenuNavItem): void {
    this.itemChange.emit({ item: this.activeItem, subItem });
  }

  public onMenuButtonClick(): void {
    this.openBottomSheetMenu();
  }

  public trackByActive(index: number, item: ShopMenuNavItem): boolean {
    return item.active;
  }

  private setMenuSubscription(): void {
    this.menuSubscription = this.shopMenuNavService.menuNav.subscribe((menu) => {
      if (!menu) return undefined;
      this.menuNav = menu;
      const activeIndex = this.menuNav.items.findIndex((item) => item.active);
      this.activeItem = this.menuNav.items[activeIndex];
      if (this.mainSwiper) this.mainSwiper.nativeElement.swiper.slideTo(activeIndex);
      if (!this.activeItem || !this.activeItem.subItems) return;
      const activeSecondaryIndex = this.activeItem.subItems.findIndex((item) => item.active);
      if (this.secondarySwiper) this.secondarySwiper.nativeElement.swiper.slideTo(activeSecondaryIndex);
    });
  }

  private setSwiperOptions(): void {
    this.swiperOptions = {
      modules: [Mousewheel],
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true
    };
  }

  private openBottomSheetMenu(): void {
    const config: MatBottomSheetConfig = {
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      panelClass: 'box-bottom-sheet',
      data: { menuNav: this.menuNav }
    };
    this.bottomSheet
      .open(ShopMenuNavBottomSheetComponent, config)
      .afterDismissed()
      .subscribe((data: ShopMenuNavItemChange) => {
        if (!data) return;
        this.itemChange.emit(data);
      });
  }
}

<ng-container *ngIf="desktopView && !config.shortViewOnly; else mobileView">
  <div class="banner-wrapper banner-wrapper-desktop" data-mode="light">
    <figure class="image cover-image banner-image" *ngIf="imageUrl">
      <img [src]="imageUrl" [alt]="config.title" />
    </figure>
    <div class="texts">
      <span class="texts-header">{{ config.title }}</span>
      <span class="texts-description ods-typography-body-m-regular">{{ config.description }}</span>
    </div>
    <button *ngIf="showCta" box-button class="cta" (click)="onClickCta()">
      {{ config.cta }}
    </button>
    <button *ngIf="showCloseButton" mat-icon-button class="close-button" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-container>
<ng-template #mobileView>
  <div class="banner-wrapper banner-wrapper-mobile" data-mode="light">
    <div class="texts" (click)="onClickCta()">
      <span class="texts-header" [style.color]="config.titleColor">{{ config.title }}</span>
      <span class="texts-description ods-typography-body-m-regular" [style.color]="config.descriptionColor">{{
        config.description
      }}</span>
    </div>
    <figure class="image cover-image banner-image" *ngIf="imageUrl">
      <img [src]="imageUrl" [alt]="config.title" />
    </figure>
    <button *ngIf="showCloseButton" mat-icon-button class="close-button" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-template>

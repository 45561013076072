import { isCouponSelectable, isOrderCoupon } from './coupon.utils';
import { Coupon } from '@box-types';

/* Those are the coupons that need to go on the order object */
function isCouponCheckoutPreselected(coupon: Coupon): boolean {
  if (!coupon.info?.checkoutPreselected) return false;
  if (!isCouponSelectable(coupon)) return false;
  if (!isOrderCoupon(coupon)) return false;
  return true;
}

export { isCouponCheckoutPreselected };

import { BOXDynamicText, BOXDynamicTextElement, BOXColor } from '@box-types';

function getBOXDynamicTextColor(color: BOXColor): string {
  if (!color) return undefined;
  return `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`;
}

function getBOXDynamicTextFontWeight(fontStyle: string): number {
  switch (fontStyle) {
    case 'light':
    case 'lightItalic':
      return 300;
    case 'medium':
    case 'mediumItalic':
      return 500;
    case 'bold':
    case 'boldItalic':
      return 600;
    case 'extraBlack':
    case 'extraBlackItalic':
      return 900;
    default:
      return 400;
  }
}

function getBOXDynamicTextFontStyle(fontStyle: string): string {
  switch (fontStyle) {
    case 'lightItalic':
    case 'italic':
    case 'mediumItalic':
    case 'boldItalic':
    case 'extraBlackItalic':
      return 'italic';
    default:
      return 'normal';
  }
}

function boxDynamicTextToTextElement(promoText: BOXDynamicText): BOXDynamicTextElement {
  const color = getBOXDynamicTextColor(promoText?.color);
  const promoTextSize = promoText.size ?? 16;
  const fontSize = `${promoTextSize / 16}rem`;
  const lineHeight = `${(promoTextSize + 2) / 16}rem`;
  const fontWeight = getBOXDynamicTextFontWeight(promoText.font);
  const fontStyle = getBOXDynamicTextFontStyle(promoText.font);
  const textAlign = promoText.alignment ?? 'center';
  const text = promoText.text;
  return { text, style: { color, fontSize, lineHeight, fontWeight, fontStyle, textAlign } };
}

export { getBOXDynamicTextColor, getBOXDynamicTextFontWeight, getBOXDynamicTextFontStyle, boxDynamicTextToTextElement };

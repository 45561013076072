<div boxNav [options]="navOptions">
  <div
    *ngFor="let block of blocks"
    boxNavItem
    [title]="block.title"
    [class.box-nav-item-active]="deliveryMethod === block.deliveryMethod"
    (click)="onDeliveryMethodClick(block.deliveryMethod)"
  >
    <div class="shop-page-delivery-method-block">
      <div class="shop-page-delivery-method-block-title">{{ block.title }}</div>
      <div class="shop-page-delivery-method-block-content">
        <div class="shop-page-delivery-method-block-content-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path
              fill="inherit"
              stroke="inherit"
              d="M15.975 6.028C15.82 4.236 14.11 2.85 12.092 2.85c-2.017 0-3.746 1.385-3.884 3.178v.001L8.09 7.432H6.352c-.83 0-1.542.574-1.616 1.335v.002l-.88 10.833h-.001c-.037.408.13.809.439 1.094a1.75 1.75 0 0 0 1.178.454h13.256c.44 0 .87-.154 1.18-.455.307-.286.474-.686.437-1.093l-.88-10.733v-.001c-.074-.82-.839-1.422-1.732-1.422h-1.638l-.12-1.418Zm-6.49.083v-.002c.091-1.161 1.216-2.103 2.607-2.103 1.39 0 2.515.942 2.606 2.103l.102 1.323H9.37l.116-1.321Zm6.784 3.457-.084-.98h1.548c.244 0 .43.166.454.365l.88 10.732.002.009c.007.053-.012.121-.085.198a.408.408 0 0 1-.256.102H5.472a.344.344 0 0 1-.252-.098c-.077-.08-.097-.136-.088-.205v-.006l.88-10.835V8.85c.01-.133.14-.26.34-.26H8l-.084.979v.002c-.023.342.254.6.598.617h.041c.32 0 .615-.214.638-.538l.087-1.06h5.626l.086 1.06c.024.344.343.555.68.538.344-.018.62-.275.598-.617v-.002Z"
            />
          </svg>
        </div>
        <div class="shop-page-delivery-method-block-content-text minimum-price-text">
          {{ block.minimumOrderPriceText }}
        </div>

        <div class="shop-page-delivery-method-block-content-icon">
          <svg width="16" height="16" viewBox="0 0 24 24">
            <path
              fill="inherit"
              stroke="inherit"
              stroke-width=".3"
              d="M11.212 10.934a.754.754 0 0 1-.272-.567.724.724 0 0 1 .214-.541l1.553-1.207a.8.8 0 0 1 1.11.051l2.058 1.855a.812.812 0 0 0 .544.208h2.414a.811.811 0 0 0 .567-.229.773.773 0 0 0 0-1.107.811.811 0 0 0-.567-.23h-2.102l-1.799-1.621a2.39 2.39 0 0 0-1.607-.698 2.399 2.399 0 0 0-1.656.58l-1.555 1.205a2.33 2.33 0 0 0-.582.796 2.28 2.28 0 0 0 .03 1.912c.143.298.35.563.606.778l1.53 1.286v2.731c0 .208.085.407.235.554a.812.812 0 0 0 1.134 0 .774.774 0 0 0 .235-.554v-3.09a.768.768 0 0 0-.28-.594l-1.81-1.518Zm-6.458 8.423a4.038 4.038 0 0 0 5.009-.483 3.832 3.832 0 0 0 1.164-2.738c0-.766-.233-1.515-.67-2.152a3.949 3.949 0 0 0-1.78-1.426 4.059 4.059 0 0 0-2.293-.22 4 4 0 0 0-2.032 1.06 3.846 3.846 0 0 0-1.086 1.983 3.788 3.788 0 0 0 .226 2.238c.3.708.81 1.313 1.462 1.738Zm.89-5.14a2.407 2.407 0 0 1 2.987.287 2.269 2.269 0 0 1 .294 2.914c-.26.38-.63.676-1.062.85a2.42 2.42 0 0 1-1.366.132 2.384 2.384 0 0 1-1.211-.632 2.291 2.291 0 0 1-.647-1.181 2.257 2.257 0 0 1 .134-1.334c.18-.422.482-.782.871-1.036Zm10.193 5.14a4.038 4.038 0 0 0 5.01-.483 3.832 3.832 0 0 0 1.163-2.738c0-.766-.233-1.515-.669-2.152a3.95 3.95 0 0 0-1.78-1.426 4.059 4.059 0 0 0-2.294-.22 4 4 0 0 0-2.031 1.06 3.845 3.845 0 0 0-1.086 1.983 3.789 3.789 0 0 0 .225 2.238c.3.708.81 1.313 1.462 1.738Zm.89-5.14a2.407 2.407 0 0 1 2.987.287 2.269 2.269 0 0 1 .294 2.914c-.26.38-.629.676-1.061.85a2.42 2.42 0 0 1-1.367.132 2.384 2.384 0 0 1-1.21-.632 2.29 2.29 0 0 1-.648-1.181 2.257 2.257 0 0 1 .135-1.334 2.32 2.32 0 0 1 .87-1.036ZM16.063 2.99c-.393 0-.777.114-1.105.327a1.953 1.953 0 0 0-.732.872c-.15.354-.19.745-.113 1.122.076.376.266.722.544.994s.633.456 1.018.531c.386.075.786.037 1.15-.11a1.98 1.98 0 0 0 .892-.715 1.91 1.91 0 0 0-.247-2.452 2.014 2.014 0 0 0-1.407-.569Z"
            />
            <path
              fill="inherit"
              stroke="inherit"
              d="M10.6 5.781c0 .389-1.901 2.413-2.375 2.413s-2.362-1.636-.467-2.8c1.895-1.165 2.842 0 2.842.387Z"
            />
          </svg>
        </div>
        <div class="shop-page-delivery-method-block-content-text">{{ block.deliveryFeeText }}</div>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'order-sm-points',
  templateUrl: './order-sm-points.component.html',
  styleUrls: ['./order-sm-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSMPointsComponent {
  @Input() public imageSrc: string;
  @Input() public points: number;

  @HostBinding('class') public hostClass = 'order-sm-points';
}

import { GetTextByKeyType, Shop, ChainShopListItem, DeliveryAnchor } from '@box-types';

export { generateSEODescriptionHTML, generateShopDishesText, generateShopRatingText, generateShopsWithChainAnchors };

function generateShopDishesText(name: string, dishes: string[], translateFn: GetTextByKeyType): string {
  if (!name || !dishes?.length) return '';
  return translateFn('the_options_we_recommend_from_the_shop', {
    _SHOP_NAME: name,
    _DISHES: dishes.join(', ')
  });
}

function generateShopsWithChainAnchors(shop: Shop, shopsWithChain: ChainShopListItem[]): DeliveryAnchor[] {
  if (!shop || !shopsWithChain?.length) return [];
  return shopsWithChain.map((item) => ({
    text: `${shop.name} ${item.locationName}`,
    link: `/delivery/${item.locationKey}/${item.vanityUrl}`
  }));
}

function generateShopRatingText(name: string, location: string, rating: number, translateFn: GetTextByKeyType): string {
  if (!name || !location || !rating) return '';
  return translateFn('shop_rating_in_location', {
    _SHOP_NAME: name,
    _LOCATION: location,
    _RATING: String(rating)
  });
}

function generateSEODescriptionHTML(shop: Shop, template: string, translateFn: GetTextByKeyType): string {
  if (!shop || !template) return;
  const { locationName, deliveryLocations, name, address, ratingOverall, popularDishes } = shop;
  return template
    .replace(/__SHOP_LOCATION__/gi, locationName)
    .replace(/__SHOP_DELIVERY_LOCATIONS__/gi, deliveryLocations?.join(', ') ?? '')
    .replace(/__SHOP_NAME__/gi, name)
    .replace(/__SHOP_ADDRESS__/gi, `${address?.street} ${address?.streetNo}`)
    .replace(/__SHOP_RATING_TEXT__/gi, generateShopRatingText(name, locationName, ratingOverall, translateFn))
    .replace(/__SHOP_DISHES_TEXT__/gi, generateShopDishesText(name, popularDishes, translateFn));
}

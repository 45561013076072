import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[debounceInput]'
})
export class DebounceInputDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;
  @Output() debounceInput: EventEmitter<InputEvent> = new EventEmitter<InputEvent>();
  private inputs = new Subject();
  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.inputs
      .pipe(debounceTime(this.debounceTime))
      .subscribe((event: InputEvent) => this.debounceInput.emit(event));
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  @HostListener('input', ['$event']) inputEvent(event: InputEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.inputs.next(event);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Product, OfferInstance, OfferGroup } from '@box-types';
import { getOfferInstanceGroupIndexToExpand } from '@box/utils';
import { OfferWizardGroupChangeEvent } from './offer-wizard-groups.types';

@Component({
  selector: 'offer-wizard-groups',
  templateUrl: './offer-wizard-groups.component.html',
  styleUrls: ['./offer-wizard-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferWizardGroupsComponent implements OnChanges {
  @Input() public offerInstance: OfferInstance;
  @Output() private productChange = new EventEmitter<OfferWizardGroupChangeEvent>();
  @Output() private productMYO = new EventEmitter();
  @Output() private afterExpand = new EventEmitter();
  @Output() private afterCollapse = new EventEmitter();

  public groupIndexToBeExpanded: number;

  @HostBinding('class') public hostClass = 'offer-wizard-groups';

  ngOnChanges(changes: SimpleChanges): void {
    this.offerInstance = changes.offerInstance.currentValue as OfferInstance;
    this.groupIndexToBeExpanded = getOfferInstanceGroupIndexToExpand(this.offerInstance);
  }

  public onProductChange(group: OfferGroup, product: Product): void {
    this.productChange.emit({ group, product });
    this.productMYO.emit({ group, product });
  }

  public onProductMYO(group: OfferGroup, product: Product): void {
    this.productMYO.emit({ group, product });
  }

  public onAfterCollapse(): void {
    this.afterCollapse.emit();
  }

  public onAfterExpand(): void {
    this.afterExpand.emit();
  }
}

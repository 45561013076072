import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemingService } from '@box-core/services/theming.service';
import { Subscription } from 'rxjs';
import { RewardsCardDialogData } from './rewards-card-dialog.types';

@Component({
  selector: 'rewards-card-dialog',
  templateUrl: './rewards-card-dialog.component.html',
  styleUrls: ['./rewards-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsCardDialogComponent implements OnInit, OnDestroy {
  public title: string;
  public imageSrc: string;
  public contentSrc: string;

  private themeSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themingService: ThemingService,
    private dialogRef: MatDialogRef<RewardsCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RewardsCardDialogData
  ) {}

  ngOnInit(): void {
    this.title = this.data.rewardsCard.action.tutorial.title;
    this.imageSrc = this.data.rewardsCard.dialogImage;
    this.setThemeSubscription();
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.pipe().subscribe((theme) => {
      const tutorialContentSrc = this.data.rewardsCard.action.tutorial.contentSrc;
      this.contentSrc = theme === 'dark' ? tutorialContentSrc.dark : tutorialContentSrc.light;
      this.changeDetectorRef.detectChanges();
    });
  }
}

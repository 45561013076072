import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const ProductMYOAnimation = trigger('productMYOAnimation', [
  state('void', style({ transform: 'translateX(100%)' })),
  transition('void => *', [group([animate('200ms cubic-bezier(0, 0, 0.2, 1)'), style({ transform: 'translateX(0)' })])])
]);

export const OfferWizardAnimation = trigger('offerWizardAnimation', [
  state('void', style({ transform: 'translateX(-100%)' })),
  transition('void => *', [group([animate('200ms cubic-bezier(0, 0, 0.2, 1)'), style({ transform: 'translateX(0)' })])])
]);

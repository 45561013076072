import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { PromoCampaign, PromoCampaignBanner, Payment } from '@box-types';
import { PaymentSynergyBannerService } from '@box-checkout/components/payment-synergy-banner/payment-synergy-banner.service';
import { PromoCampaignsService } from '@box-core/services';
import { doPaymentBannersHaveRequiredAssetsForMultiView, getBannersWithMinimumRequiredAssets } from '@box/utils';

@Component({
  selector: 'payment-synergy-banner',
  templateUrl: './payment-synergy-banner.component.html',
  styleUrls: ['./payment-synergy-banner.component.scss'],
  providers: [PaymentSynergyBannerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSynergyBannerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public selectedPaymentType: Payment;

  public paymentCampaignBanners: PromoCampaignBanner[];
  public showPaymentBanner: boolean;
  public multiView: boolean;

  private topPaymentCampaignsWithAssets: PromoCampaign[];

  constructor(
    private paymentSynergyBannerService: PaymentSynergyBannerService,
    private promoCampaignsService: PromoCampaignsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.topPaymentCampaignsWithAssets = this.paymentSynergyBannerService.getTopPaymentCampaigns();
    this.paymentCampaignBanners = this.getPaymentCampaignBanners(this.topPaymentCampaignsWithAssets);
    this.multiView = doPaymentBannersHaveRequiredAssetsForMultiView(this.paymentCampaignBanners);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPaymentType) {
      this.selectedPaymentType = changes.selectedPaymentType.currentValue as Payment;
      this.shouldShowBanner();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  private getPaymentCampaignBanners(promoCampaigns: PromoCampaign[]): PromoCampaignBanner[] {
    if (!promoCampaigns?.length) return;
    const campaignBanners = promoCampaigns.map((campaign) =>
      this.promoCampaignsService.campaignToCampaignBanner(campaign)
    );
    return getBannersWithMinimumRequiredAssets(campaignBanners);
  }

  private shouldShowBanner(): void {
    this.showPaymentBanner = !this.paymentSynergyBannerService.isSelectedPaymentTypeCompatible(
      this.topPaymentCampaignsWithAssets
    );
  }
}

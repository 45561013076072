import { Component, HostBinding, OnInit } from '@angular/core';
import { appConfig } from '@box-configs/app.config';
import { usefulLinks } from '@box-configs/useful-links.options';
import { UsefulLink } from '@box-types';

@Component({
  selector: 'box-footer',
  templateUrl: './box-footer.component.html',
  styleUrls: ['./box-footer.component.scss']
})
export class BOXFooterComponent implements OnInit {
  public currentYear: number;
  public facebookUrl: string;
  public instagramUrl: string;
  public youtubeUrl: string;
  public zohoUrl: string;
  public usefulLinks: UsefulLink[];
  public supportPhoneNumber: string;
  public supportEmail: string;

  @HostBinding('class') public hostClass = 'box-footer';

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.facebookUrl = appConfig.social.FACEBOOK;
    this.instagramUrl = appConfig.social.INSTAGRAM;
    this.youtubeUrl = appConfig.social.YOUTUBE;
    this.zohoUrl = appConfig.ZOHO_URL;
    this.usefulLinks = usefulLinks;
    this.supportPhoneNumber = appConfig.support.PHONE_NUMBER;
    this.supportEmail = appConfig.support.EMAIL;
  }
}

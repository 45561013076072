<div class="checkout-points-value" [class.checkout-points-value--active]="pointsToRedeem > 0 && checked">
  <div class="checkout-points-value-background">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 64">
      <g fill-rule="evenodd">
        <path d="M0 0h76L64 64H0z" />
        <path opacity=".597" d="M0 64h76L64 0H0z" />
      </g>
    </svg>
  </div>
  <span class="checkout-points-value-content">{{ bannerEuros }}</span>
</div>
<div class="checkout-points-text ods-typography-body-s-bold">{{ bannerText }}</div>
<div class="checkout-points-slide-container">
  <mat-slide-toggle
    color="primary"
    class="checkout-points-slide"
    [checked]="checked"
    (change)="onSlideToggleChange($event)"
  ></mat-slide-toggle>
</div>

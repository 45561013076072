import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ShopMenuNav, ShopMenuNavItemChange } from '@box-types';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ShopMenuNavBottomSheetData } from './shop-menu-nav-bottom-sheet.types';
import { BodyScrollEvent } from '@box-shared/directives/body-scroll-event.types';

@Component({
  selector: 'shop-menu-nav-bottom-sheet',
  templateUrl: './shop-menu-nav-bottom-sheet.component.html',
  styleUrls: ['./shop-menu-nav-bottom-sheet.component.scss']
})
export class ShopMenuNavBottomSheetComponent implements OnInit {
  @ViewChild('bottomSheetHeader') private bottomSheetHeader: ElementRef<HTMLElement>;

  public menuNav: ShopMenuNav;

  constructor(
    private renderer: Renderer2,
    private bottomSheetRef: MatBottomSheetRef<ShopMenuNavBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: ShopMenuNavBottomSheetData
  ) {}

  ngOnInit(): void {
    this.menuNav = this.data.menuNav;
  }

  public onBodyScroll(event: BodyScrollEvent) {
    this.headerScrollCallback(event);
  }

  public onItemClick(data: ShopMenuNavItemChange): void {
    this.dismiss(data);
  }

  private headerScrollCallback(event: BodyScrollEvent): void {
    if (event.scrolled) this.renderer.addClass(this.bottomSheetHeader.nativeElement, 'has-shadow');
    if (!event.scrolled) this.renderer.removeClass(this.bottomSheetHeader.nativeElement, 'has-shadow');
  }

  public dismiss(data?: ShopMenuNavItemChange): void {
    this.bottomSheetRef.dismiss(data);
  }
}

import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order, OrdersDayGroup, Shop, GAItemRatingConfig } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import {
  getOrdersDayGroupsByStatus,
  getOrderDeliveryStatus,
  getCollectionShopOrderImpressionGAConfig
} from '@box/utils';
import { ShopOrdersDialogResponse } from './shop-orders-dialog.types';
import {
  ReviewDialogResponse,
  ReviewDialogData
} from '@box-rating-widget/components/review-dialog/review-dialog.types';
import { Subscription } from 'rxjs';
import { AnalyticsService, DialogService, ShopService } from '@box-core/services';
import { ReviewDialogComponent } from '@box-rating-widget/components';

@Component({
  selector: 'shop-orders-dialog',
  templateUrl: './shop-orders-dialog.component.html',
  styleUrls: ['./shop-orders-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopOrdersDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  private orders: Order[];
  private shop: Shop;
  public scheduledOrdersDayGroups: OrdersDayGroup[];
  public inProgressOrdersDayGroups: OrdersDayGroup[];
  public completedOrdersDayGroups: OrdersDayGroup[];
  private ordersSubscription: Subscription;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<ShopOrdersDialogComponent>,
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private shopService: ShopService,
    @Inject(MAT_DIALOG_DATA) private data: { orders: Order[]; shop: Shop }
  ) {
    super(renderer);
    this.orders = this.data.orders;
    this.shop = this.data.shop;
  }

  ngOnInit(): void {
    this.setOrdersSubscription();
  }

  ngOnDestroy(): void {
    this.ordersSubscription?.unsubscribe();
  }

  public onOrderClick(order: Order): void {
    const status = getOrderDeliveryStatus(order);
    this.triggerAnalyticsEvent('select_promotion', order);
    if (status === 'SCHEDULED') this.closeDialog({ goToOrder: true, order });
    if (status === 'INPROGRESS') this.closeDialog({ goToOrder: true, order });
    if (status === 'COMPLETED') this.closeDialog({ addToCart: true, order });
  }

  public closeDialog(data?: ShopOrdersDialogResponse): void {
    this.dialogRef.close(data);
  }

  public onOrderRate(order: Order, rating: number): void {
    this.triggerAnalyticsRatingEvent(rating);
    this.openReviewDialog(order, rating)
      .afterClosed()
      .subscribe((data: ReviewDialogResponse) => {
        if (!data?.review) return;
        this.updateOrderHistoryAfterRate(order, data.review.rating);
      });
  }

  private setOrdersSubscription(): void {
    this.ordersSubscription = this.shopService.orders.subscribe((orders) => {
      this.orders = orders;
      this.scheduledOrdersDayGroups = getOrdersDayGroupsByStatus(orders, 'SCHEDULED');
      this.inProgressOrdersDayGroups = getOrdersDayGroupsByStatus(orders, 'INPROGRESS');
      this.completedOrdersDayGroups = getOrdersDayGroupsByStatus(orders, 'COMPLETED');
      this.changeDetectorRef.detectChanges();
    });
  }

  private openReviewDialog(order: Order, rating: number): MatDialogRef<ReviewDialogComponent> {
    const dialogConfig: MatDialogConfig<ReviewDialogData> = {
      panelClass: 'box-dialog',
      autoFocus: false,
      restoreFocus: false,
      data: { order, rating, source: 'recent_orders_modal' }
    };
    return this.dialogService.openDialog(ReviewDialogComponent, dialogConfig);
  }

  private updateOrderHistoryAfterRate(order: Order, rating: number): void {
    const currentOrders = this.shopService.getOrders();
    const currentOrder = currentOrders.find((currentOrder) => currentOrder.friendlyId === order.friendlyId);
    const orders = currentOrders.filter((currentOrder) => currentOrder.friendlyId !== order.friendlyId);
    currentOrder.rating = rating;
    this.shopService.setOrders([...orders, currentOrder]);
  }

  private triggerAnalyticsRatingEvent(rating: number): void {
    const gaConfig = {
      rating: String(rating),
      source: 'recent_orders_modal'
    } as GAItemRatingConfig;
    this.analyticsService.addGACustomEvent('item_rating', gaConfig);
  }

  private triggerAnalyticsEvent(eventName: string, order: Order): void {
    const options = {
      creativeName: '',
      creativeSlot: 'recent_orders_modal',
      promotionName: 'recent_orders_modal',
      itemListName: 'recent_orders_modal',
      orders: this.orders,
      order: order,
      shop: this.shop
    };
    const gaConfig = getCollectionShopOrderImpressionGAConfig(options);
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}

import { Params } from '@angular/router';

function urlSearchParamsToParams(searchParams: URLSearchParams): Params {
  if (!searchParams?.toString()?.length) return;
  const params: Params = {};
  searchParams.forEach((value, key) => (params[key] = value));
  return params;
}

export { urlSearchParamsToParams };

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  HostBinding,
  HostListener
} from '@angular/core';
import { Product, PromoVisualOptions } from '@box-types';
import { generateImageSrc, createProductInstance } from '@box/utils';

@Component({
  selector: 'food-product',
  templateUrl: './food-product.component.html',
  styleUrls: ['./food-product.component.scss']
})
export class FoodProductComponent implements OnChanges {
  @Input() public subtraction = false;
  @Input() public product: Product;
  @Output() public view: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() public add: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() public remove: EventEmitter<Product> = new EventEmitter<Product>();
  public name: string;
  public description: string;
  public price: number;
  public thumbnailUrl: string;
  public favouriteDrink: boolean;
  public badgeOptions: PromoVisualOptions;

  private available: boolean;

  @HostBinding('class') public hostClass = 'food-product';
  @HostBinding('class.unavailable') public unavailableClass: boolean;

  @HostListener('click', ['$event'])
  public onClick(): void {
    this.onView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.product = changes.product.currentValue as Product;
    this.name = this.product.name;
    this.description = this.product.info && this.product.info.el ? this.product.info.el : '';
    this.price = createProductInstance(this.product).price;
    this.thumbnailUrl = generateImageSrc(this.product.mobileImage, '@1x');
    this.favouriteDrink = this.product.favouriteDrink;
    this.available = this.product.available;
    this.unavailableClass = !this.available;
    this.badgeOptions = this.product.badgeOptions;
  }

  public onView(): void {
    if (!this.available) return;
    this.view.emit(this.product);
  }

  public onAdd(): void {
    if (!this.available) return;
    this.add.emit(this.product);
  }

  public onRemove(): void {
    if (!this.available) return;
    this.remove.emit(this.product);
  }
}

import { Injectable } from '@angular/core';
import { OrderStatusLogoState } from './order-status-logo.types';
import { Order } from '@box-types';
import { isOrderAccepted, isOrderRejected, generateImageSrc } from '@box/utils';

@Injectable()
export class OrderStatusLogoService {
  public generateOrderStatusLogoState(order: Order): OrderStatusLogoState {
    if (isOrderAccepted(order)) return 'ACCEPTED';
    if (isOrderRejected(order)) return 'REJECTED';
  }

  public generateShopImageSrc(order: Order): string {
    const shopLogo = order.shop.logo;
    return generateImageSrc(shopLogo);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OtpService } from '@box-core/services/otp.service';
import { GenerateOTPResponse, VerifyOTPResponse } from '@box-types';

@Injectable()
export class ProfileDeletionDialogService {
  constructor(private otpService: OtpService) {}

  public generateOTP(): Observable<GenerateOTPResponse> {
    return this.otpService.generateOTP({
      ...this.otpService.generateOtpMethodObject(),
      reason: 'profile_deletion'
    });
  }

  public verifyOTP(otp: string): Observable<VerifyOTPResponse> {
    return this.otpService.verifyOTP({
      ...this.otpService.generateOtpMethodObject(),
      otp
    });
  }

  public getMobilePhone(): string {
    const otpMethod = this.otpService.generateOtpMethodObject();
    return otpMethod?.msisdn;
  }

  public getEmail(): string {
    const otpMethod = this.otpService.generateOtpMethodObject();
    return otpMethod?.email;
  }
}

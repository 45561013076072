import {
  Component,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ShopSuggestionBanner, Coupon } from '@box-types';
import { SwiperOptions } from 'swiper/types';
import { ShopMenuDialogService } from '../../services';
import { MatButton } from '@angular/material/button';
import { SwiperDirective } from '@box-shared/directives';
import { DialogService, ShopService } from '@box-core/services';
import { MatDialogConfig } from '@angular/material/dialog';
import { CouponDetailsDialogData } from '@box-coupon-widget/components/coupon-details-dialog/coupon-details-dialog.types';
import { CouponDetailsDialogComponent } from '@box-coupon-widget/components';

@Component({
  selector: 'shop-reminders',
  templateUrl: './shop-reminders.component.html',
  styleUrls: ['./shop-reminders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopRemindersComponent implements AfterViewInit {
  @ViewChild('nextBtn', { static: false }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: false }) private prevBtn: MatButton;
  @ViewChild(SwiperDirective) private swiperDirective: SwiperDirective;

  @Input() public title: string;

  public remindersLength: number;
  public suggestionBanners: ShopSuggestionBanner[];
  public coupons: Coupon[];
  public items: (ShopSuggestionBanner | Coupon)[];
  public swiperOptions: SwiperOptions = { slidesPerView: 'auto', grabCursor: true };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private shopMenuDialogService: ShopMenuDialogService,
    private shopService: ShopService,
    private dialogService: DialogService
  ) {}

  ngAfterViewInit(): void {
    this.suggestionBanners = this.shopService.getShopSuggestionBanners();
    this.coupons = this.shopService.getCoupons();
    this.remindersLength = (this.coupons?.length ?? 0) + (this.suggestionBanners?.length ?? 0);
    this.changeDetectorRef.detectChanges();
    /* The reason we use setTimeout,
          is the because we want the swiper elements to render,
          before we access them,
          on the next event loop, */
    setTimeout(() => {
      if (this.remindersLength <= 1) return;
      this.updateSwiperButtonsState();
      this.swiperDirective.getSwiper().on('slideChange', () => this.updateSwiperButtonsState());
    });
  }

  public onSuggestionAction(suggestionBanner: ShopSuggestionBanner): void {
    this.shopMenuDialogService.openSuggestionDialog(suggestionBanner);
  }

  public onNextSlideClick(): void {
    this.swiperDirective.getSwiper().slideNext();
    this.updateSwiperButtonsState();
  }

  public onPreviousSlideClick(): void {
    this.swiperDirective.getSwiper().slidePrev();
    this.updateSwiperButtonsState();
  }

  public updateSwiperButtonsState(): void {
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    if (this.swiperDirective.getSwiper().isBeginning) {
      prevEl.classList.add('swiper-button-disabled');
    } else {
      prevEl.classList.remove('swiper-button-disabled');
    }
    if (this.swiperDirective.getSwiper().isEnd) {
      nextEl.classList.add('swiper-button-disabled');
    } else {
      nextEl.classList.remove('swiper-button-disabled');
    }
    this.changeDetectorRef.detectChanges();
  }

  public onCouponClick(coupon: Coupon): void {
    if (!coupon.info?.detailsDescription) return;
    this.openCouponDetails(coupon);
  }

  public openCouponDetails(coupon: Coupon): void {
    const dialogConfig: MatDialogConfig<CouponDetailsDialogData> = {
      panelClass: 'box-dialog-fit-content',
      data: { coupon, showCta: false }
    };
    this.dialogService.openDialog(CouponDetailsDialogComponent, dialogConfig);
  }
}

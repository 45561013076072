import { Component, ChangeDetectionStrategy, HostBinding, Input, Output, EventEmitter } from '@angular/core';

type InformationMessageIconPosition = 'LEFT' | 'RIGHT';

@Component({
  selector: 'informational-message',
  templateUrl: './informational-message.component.html',
  styleUrls: ['./informational-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationalMessageComponent {
  @Input() public iconPosition: InformationMessageIconPosition = 'RIGHT';
  @Output() private showInfo = new EventEmitter();
  @HostBinding('class') public hostClass = 'informational-message';
  public onShowInfo(): void {
    this.showInfo.emit();
  }
}

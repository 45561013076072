import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

declare type BoxDialogHeaderMode = 'normal' | 'check' | 'switch';
declare type BoxDialogHeaderAlignment = 'center' | 'left' | 'right';

@Component({
  selector: 'box-dialog-header',
  templateUrl: './box-dialog-header.component.html',
  styleUrls: ['./box-dialog-header.component.scss']
})
export class BoxDialogHeaderComponent implements OnChanges {
  @Input() public title: string;
  @Input() public canBack = false;
  @Input() public canClose = true;
  @Input() public alternativeTheme = false;
  @Input() public buttonBackground = false;
  @Input() public mode: BoxDialogHeaderMode = 'normal';
  @Input() public alignment: BoxDialogHeaderAlignment = 'center';

  @Output() private back: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() private closeDialog: EventEmitter<Event> = new EventEmitter<Event>();

  public headerClasses: string[];
  public modeClass: string;
  public alignmentClass: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.setClasses();
    if (changes.alternativeTheme) {
      this.alternativeTheme = changes.alternativeTheme.currentValue as boolean;
    }
    if (changes.buttonBackground) {
      this.buttonBackground = changes.buttonBackground.currentValue as boolean;
    }
  }

  private setClasses(): void {
    this.modeClass = this.mode + '-mode';
    this.alignmentClass = this.alignment + '-alignment';
    const headerClasses: string[] = [this.modeClass, this.alignmentClass];
    if (this.alternativeTheme) headerClasses.push('alternative-theme');
    if (this.buttonBackground) headerClasses.push('button-with-bg');
    this.headerClasses = headerClasses;
  }

  public onBack(event: Event): void {
    this.back.emit(event);
  }

  public onClose(event: Event): void {
    this.closeDialog.emit(event);
  }
}

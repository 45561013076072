import { Component, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { Payment } from '@box-types';
import { sortPaymentCampaigns, getPromoCampaignImage, generateImageSrc } from '@box/utils';

@Component({
  selector: 'payment-type',
  templateUrl: './payment-type.component.html',
  styleUrls: ['./payment-type.component.scss']
})
export class PaymentTypeComponent implements OnChanges {
  @HostBinding('class.disabled') public disabled: boolean;

  @Input() public paymentType: Payment;
  @Input() public alternativeIconView = false;
  @Input() public showCampaign = true;

  public paymentTypeImage: string;
  public hasCampaign: boolean;
  public campaignPoints: number;
  public campaignMultiplier: number;
  public campaignImages: string[];
  public dynamicColor: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    // needed for selectedPaymentType changing dynamically
    if (changes.paymentType) {
      this.paymentType = changes.paymentType.currentValue as Payment;
      this.initializePaymentType(this.paymentType);
    }
  }

  @HostBinding('class') public hostClass = 'payment-type';

  private initializePaymentType(paymentType: Payment): void {
    this.disabled = paymentType.disabled;
    this.campaignImages = sortPaymentCampaigns(paymentType.paymentCampaigns)
      ?.map((campaign) => generateImageSrc(getPromoCampaignImage(campaign, 'checkoutPaymentBannerLogo')))
      .filter(Boolean);
    this.campaignPoints =
      paymentType.paymentCampaigns?.reduce((acc, campaign) => acc + (campaign?.points ?? 0), 0) +
      paymentType.firstOrderCardPoints;
    this.campaignMultiplier = paymentType.paymentCampaigns?.reduce(
      (acc, campaign) => acc + (campaign?.multiplier ?? 0),
      0
    );
    this.hasCampaign = this.hasActiveCampaign();
    this.paymentTypeImage = this.getImageUrl();
    this.dynamicColor = this.hasDynamicColor();
  }

  private hasActiveCampaign(): boolean {
    return Boolean(this.campaignMultiplier || this.campaignPoints);
  }

  private getImageUrl(): string {
    const showAlternativeView = Boolean(this.alternativeIconView && this.paymentType.alternativeIcon);
    if (showAlternativeView) return '/assets/images/payments/' + this.paymentType.alternativeIcon + '.svg';
    if (this.paymentType.icon) return '/assets/images/payments/' + this.paymentType.icon + '.svg';
  }

  private hasDynamicColor(): boolean {
    if (this.paymentType.icon === 'card' || this.paymentType.icon === 'cash') return true;
    return false;
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  HostBinding,
  AfterViewInit
} from '@angular/core';
import { PromoBanner } from '@box-types';
import { MatButton } from '@angular/material/button';
import { SwiperOptions } from 'swiper/types';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import { getCollectionCarouselImpressionGAConfig, HomeSection } from '@box/utils';
import { AnalyticsService, PromoBannersService } from '@box-core/services';

@Component({
  selector: 'promo-banners',
  templateUrl: './promo-banners.component.html',
  styleUrls: ['./promo-banners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoBannersComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @Input() private homeSection: HomeSection;

  public promoBanners: PromoBanner[];
  public swiperOptions: SwiperOptions;
  public intersectionThresholds: number;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(private analyticsService: AnalyticsService, private promoBannersService: PromoBannersService) {}

  @HostBinding('class') public hostClass = 'promo-banners';

  ngOnInit(): void {
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      nextEl.setAttribute('hidden', 'true');
      prevEl.setAttribute('hidden', 'true');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.homeSection) {
      this.homeSection = changes.homeSection.currentValue as HomeSection;
      this.promoBanners = this.homeSection.banners;
    }
  }

  public onPromoBannerClick(promoBanner: PromoBanner): void {
    this.promoBannersService.openPromoBannerDetailsDialog(promoBanner, this.promoBanners);
    this.triggerAnalyticsEvent('select_promotion', promoBanner);
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel, Pagination],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      pagination: { el: '.swiper-pagination', type: 'bullets', clickable: true },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  public onPromoBannerEnteredViewport(promoBanner: PromoBanner): void {
    this.triggerAnalyticsEvent('view_promotion', promoBanner);
  }

  private triggerAnalyticsEvent(eventName: string, promoBanner: PromoBanner): void {
    const gaConfig = getCollectionCarouselImpressionGAConfig(
      'discover_home',
      'carousel',
      this.promoBanners,
      promoBanner
    );
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}

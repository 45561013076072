import { ProductPromo, Product } from '@box-types';
import { getBestProductPromo } from '../shops/shop.utils';

export { getProductBestPromo, getProductDescription, getProductBeginPrice };

function getProductBestPromo(product: Product): ProductPromo {
  if (!product.promos?.length) return;
  return getBestProductPromo(product.promos);
}

function getProductDescription(product: Product, isSuperMarket: boolean): string {
  if (isSuperMarket) return product.unitOfMeasurementDescription;
  return product.info?.el;
}

function getProductBeginPrice(product: Product): number {
  if (!product.beginPrice) return 0;
  if (product.beginPrice > product.finalPrice) return product.beginPrice;
}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CartGroupChange, Product, ProductInstance, PromoVisualOptions, Offer, OfferInstance } from '@box-types';
import { getOfferPromoVisuals, generateImageSrc } from '@box/utils';

@Component({
  selector: 'market-cart-item',
  templateUrl: './market-cart-item.component.html',
  styleUrls: ['./market-cart-item.component.scss']
})
export class MarketCartItemComponent implements OnChanges {
  @Input() public item: Product | Offer;
  @Input() public disabled = false;

  @Output() private add = new EventEmitter<CartGroupChange<Product | Offer, ProductInstance | OfferInstance>>();
  @Output() private remove = new EventEmitter<CartGroupChange<Product | Offer, ProductInstance | OfferInstance>>();
  @Output() private edit = new EventEmitter<CartGroupChange<Product | Offer, ProductInstance | OfferInstance>>();

  public name: string;
  public description: string;
  public thumbnail: string;
  public beginPrice: number;
  public finalPrice: number;
  public showBeginPrice: boolean;
  public itemInstance: OfferInstance | ProductInstance;
  public stepToUomDescription: string;
  public tagOptions: PromoVisualOptions;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.item = changes.item.currentValue as Offer | Product;
      this.setContent(this.item);
    }
  }

  public onAdd(itemInstance: OfferInstance | ProductInstance): void {
    this.add.emit({ item: this.item, instance: itemInstance });
  }

  public onRemove(itemInstance: OfferInstance | ProductInstance): void {
    this.remove.emit({ item: this.item, instance: itemInstance });
  }

  public onEdit(itemInstance: OfferInstance | ProductInstance): void {
    if (!this.disabled) this.edit.emit({ item: this.item, instance: itemInstance });
  }

  private setContent(item: Offer | Product): void {
    this.name = item.name;
    this.description = item.unitOfMeasurementDescription;
    this.thumbnail = generateImageSrc(this.item.thumbnail);
    this.showBeginPrice = this.beginPrice > this.finalPrice;
    this.itemInstance = item.cartInstances[0];
    this.stepToUomDescription = item.stepToUomDescription;
    if ((item as Offer).groups?.length) {
      this.beginPrice = (item as Offer).preOfferPrice;
      this.finalPrice = (item as Offer).price;
      this.tagOptions = this.getTagOptions();
    } else {
      this.beginPrice = (item as Product).beginPrice;
      this.tagOptions = (item as Product).tagOptions;
      this.finalPrice = (item as Product).finalPrice;
    }
  }

  private getTagOptions(): PromoVisualOptions {
    if (this.item.discountSticker) return { ...getOfferPromoVisuals(), text: this.item.discountSticker };
    return undefined;
  }
}

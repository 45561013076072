import { Product } from '@box-types';
import { Cart } from './cart.types';

export { syncProductCartQuantityWithCart, getCartProductBeginPrice, getCartProductFinalPrice };

function syncProductCartQuantityWithCart(product: Product, cart: Cart): Product {
  const cartProducts = cart.products;
  const cartProduct = cartProducts.find((pr) => pr._id === product._id);
  if (!cartProduct) return { ...product, cartQuantity: 0 };
  return { ...product, cartQuantity: cartProduct.cartQuantity };
}

function getCartProductBeginPrice(product: Product): number {
  const instances = product.cartInstances;
  if (!instances?.length) return 0;
  return instances.reduce((price, instance) => price + instance.quantity * instance.beginPrice, 0);
}

function getCartProductFinalPrice(product: Product): number {
  const instances = product.cartInstances;
  if (!instances?.length) return 0;
  return instances.reduce((price, instance) => price + instance.quantity * instance.price, 0);
}

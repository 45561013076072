import { ContentSrc, RewardsConfiguration, RewardsTutorialGifKey } from '@box-types';
import { generateImageSrc } from '../media';

function generateRewardsTutorialSrc(config: RewardsConfiguration, key: RewardsTutorialGifKey): string {
  if (!config.tutorialGifs) return;
  const mediaObject = config.tutorialGifs[key];
  if (!mediaObject) return;
  return generateImageSrc(mediaObject);
}

function generateRewardsOrderTutorialSrc(config: RewardsConfiguration): ContentSrc {
  return {
    light: generateRewardsTutorialSrc(config, 'orderProcess'),
    dark: generateRewardsTutorialSrc(config, 'orderProcessDark')
  };
}

function generateRewardsRedemptionTutorialSrc(config: RewardsConfiguration): ContentSrc {
  return {
    light: generateRewardsTutorialSrc(config, 'pointsRedemption'),
    dark: generateRewardsTutorialSrc(config, 'pointsRedemptionDark')
  };
}

function generateRewardsGBTutorialSrc(config: RewardsConfiguration): ContentSrc {
  return {
    light: generateRewardsTutorialSrc(config, 'gbRewardClaim'),
    dark: generateRewardsTutorialSrc(config, 'gbRewardClaimDark')
  };
}

function generateRewardsCardTutorialSrc(config: RewardsConfiguration): ContentSrc {
  return {
    light: generateRewardsTutorialSrc(config, 'firstCardPayment'),
    dark: generateRewardsTutorialSrc(config, 'firstCardPaymentDark')
  };
}

export {
  generateRewardsTutorialSrc,
  generateRewardsOrderTutorialSrc,
  generateRewardsRedemptionTutorialSrc,
  generateRewardsGBTutorialSrc,
  generateRewardsCardTutorialSrc
};

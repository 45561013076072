import { Renderer2, Inject, OnInit, AfterViewInit, ViewChild, ElementRef, Component } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService, PaymentGatewayService } from '@box-core/services';
import {
  BankPointsRedeemVerificationDialogData,
  BankPointsRedeemVerificationDialogResponse
} from '@box-checkout/components/bank-points-redeem-verification-dialog/bank-points-redeem-verification-dialog.interface';
import { finalize } from 'rxjs/operators';
import { APIError } from '@box-types';
import { FormGroup, Validators, FormControl } from '@angular/forms';

const KEYS_TO_IGNORE = ['ArrowLeft', 'ArrowRight', 'Tab', 'Shift'];
@Component({
  selector: 'bank-points-redeem-verification-dialog',
  templateUrl: './bank-points-redeem-verification-dialog.component.html',
  styleUrls: ['./bank-points-redeem-verification-dialog.component.scss']
})
export class BankPointsRedeemVerificationDialogComponent
  extends BoxDialogWrapperComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('panInput2') pan2InputElement: ElementRef<HTMLInputElement>;
  @ViewChild('panInput3') pan3InputElement: ElementRef<HTMLInputElement>;
  @ViewChild('birthDateInput') birthDateInputElement: ElementRef<HTMLInputElement>;
  @ViewChild('expirationDateInput') expirationDateInputElement: ElementRef<HTMLInputElement>;

  public readonly BIRTH_DATE = {
    label: 'HH/MM/YYYY',
    format: 'DD/MM/YYYY'
  };
  public readonly EXPIRATION_DATE = {
    label: 'MM/YY',
    format: 'MM/YY'
  };
  public readonly CARD_PIN_LENGTH = 4;

  public loading = false;
  public pan1: string;
  public pan2: string;
  public pan3: string;
  public pan4: string;
  public birthDate: string;
  public expirationDate: string;
  public cardForm: FormGroup;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<BankPointsRedeemVerificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: BankPointsRedeemVerificationDialogData,
    private dialogService: DialogService,
    private paymentGatewayService: PaymentGatewayService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.preFillData();
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.pan2InputElement.nativeElement.focus();
  }

  private preFillData(): void {
    this.pan1 = this.data.card.pan.slice(0, this.CARD_PIN_LENGTH);
    this.pan4 = this.data.card.pan.slice(-this.CARD_PIN_LENGTH);
  }

  private initializeForm(): void {
    this.cardForm = new FormGroup({
      pan1: new FormControl(
        {
          value: this.pan1,
          disabled: true
        },
        [Validators.required, Validators.minLength(this.CARD_PIN_LENGTH), Validators.maxLength(this.CARD_PIN_LENGTH)] // eslint-disable-line @typescript-eslint/unbound-method
      ),
      pan2: new FormControl(
        {
          value: this.pan2,
          disabled: false
        },
        [Validators.required, Validators.minLength(this.CARD_PIN_LENGTH), Validators.maxLength(this.CARD_PIN_LENGTH)] // eslint-disable-line @typescript-eslint/unbound-method
      ),
      pan3: new FormControl(
        {
          value: this.pan3,
          disabled: false
        },
        [Validators.required, Validators.minLength(this.CARD_PIN_LENGTH), Validators.maxLength(this.CARD_PIN_LENGTH)] // eslint-disable-line @typescript-eslint/unbound-method
      ),
      pan4: new FormControl(
        {
          value: this.pan4,
          disabled: true
        },
        [Validators.required, Validators.minLength(this.CARD_PIN_LENGTH), Validators.maxLength(this.CARD_PIN_LENGTH)] // eslint-disable-line @typescript-eslint/unbound-method
      ),
      birthDate: new FormControl(this.birthDate, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(this.BIRTH_DATE.format.length),
        Validators.maxLength(this.BIRTH_DATE.format.length)
      ]),
      expirationDate: new FormControl(this.expirationDate, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(this.EXPIRATION_DATE.format.length),
        Validators.maxLength(this.EXPIRATION_DATE.format.length)
      ])
    });
  }

  public onInputPan2(event: KeyboardEvent): void {
    if (KEYS_TO_IGNORE.includes(event?.key)) return;
    if (this.pan2?.length !== this.CARD_PIN_LENGTH) return;
    this.pan3InputElement.nativeElement.focus();
  }

  public onInputPan3(event: KeyboardEvent): void {
    if (KEYS_TO_IGNORE.includes(event?.key)) return;
    if (this.pan3?.length !== this.CARD_PIN_LENGTH) return;
    this.birthDateInputElement.nativeElement.focus();
  }

  public onBackSpacePan3(): void {
    if (this.pan3?.length) return;
    this.pan2InputElement.nativeElement.focus();
  }

  public onInputBirthDate(event: KeyboardEvent): void {
    if (KEYS_TO_IGNORE.includes(event?.key)) return;
    if (this.birthDate?.length !== this.BIRTH_DATE.format.length) return;
    this.expirationDateInputElement.nativeElement.focus();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.loading = true;
    this.paymentGatewayService
      .verifyPaymentCard({
        maskedPan: this.data.card.pan,
        pan: this.pan1 + this.pan2 + this.pan3 + this.pan4,
        birthDate: this.birthDate,
        expirationDate: this.expirationDate,
        bank: this.data.card.cardDetailsAddedByBox?.bank,
        productType: this.data.card.cardDetailsAddedByBox?.productType
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (response) => {
          if (!response?.length) return this.dialogRef.close();
          this.dialogRef.close({ cardBoxId: response } as BankPointsRedeemVerificationDialogResponse);
        },
        error: (error: APIError) => {
          this.dialogService.openErrorDialog(error);
        }
      });
  }
}

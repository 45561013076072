import { animate, state, style, transition, trigger, group, query, animateChild } from '@angular/animations';

export const ShopBannerSlide = trigger('shopBannerSlide', [
  state('void', style({ top: '0.5rem', opacity: 0 })),
  transition('void => *', [animate('75ms cubic-bezier(0.4, 0, 1, 1)', style({ top: 0, opacity: 1 }))]),
  transition('* => void', [animate('75ms 200ms cubic-bezier(0, 0, 0.2, 1)', style({ top: '0.5rem', opacity: 0 }))])
]);

export const ShopBannerText = trigger('shopBannerText', [
  state('void', style({ opacity: 0 })),
  transition('void => *', [animate('50ms 300ms cubic-bezier(0.4, 0, 1, 1)', style({ opacity: 1 }))]),
  transition('* => void', [animate('50ms cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 0 }))])
]);

export const ShopBannerUnfold = trigger('shopBannerUnfold', [
  state(
    'void',
    style({ opacity: 0, height: '0.5rem', 'transform-origin': '50% 0', transform: 'perspective(80px) rotateX(90deg)' })
  ),
  transition('void => *', [
    group([
      animate('250ms cubic-bezier(0.4, 0, 1, 1)', style({ opacity: 1, height: '1.5rem', transform: 'rotateX(0deg)' })),
      query('@shopBannerText', [animateChild()])
    ])
  ]),
  transition('* => void', [
    group([
      query('@shopBannerText', [animateChild()]),
      animate('250ms cubic-bezier(0.4, 0, 1, 1)', style({ opacity: 0, height: '0.5rem', transform: 'rotateX(90deg)' }))
    ])
  ])
]);

export const ShopTagSlide = trigger('shopTagSlide', [
  transition(':enter', [
    style({ top: '-26px' }),
    animate('150ms 150ms cubic-bezier(0.4, 0, 1, 1)', style({ top: '0' }))
  ]),
  transition(':leave', [style({ top: '0' }), animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ top: '-26px' }))])
]);

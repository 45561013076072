import { Injectable } from '@angular/core';
import { ShopMenuNav, Category, ShopMenuNavItem } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import { appConfig } from '@box-configs/app.config';
import { ScrollSpyService, ShopService } from '@box-core/services';
import { getSelectedCategoryById, getPathWithoutQueryParams } from '@box/utils';
import { Router, NavigationExtras } from '@angular/router';

@Injectable()
export class ShopMenuNavService {
  public readonly menuNav = new BehaviorSubject<ShopMenuNav>(undefined);
  public readonly selectedParentCategory = new BehaviorSubject<Category>(undefined);
  public readonly selectedSubCategory = new BehaviorSubject<Category>(undefined);

  constructor(private router: Router, private shopService: ShopService, private scrollSpyService: ScrollSpyService) {}

  public clearState(): void {
    this.setMenu(null);
    this.setSelectedParentCategory(null);
    this.setSelectedSubCategory(null);
  }

  public getScrollOffset(deviceTarget: string): number {
    if (deviceTarget === 'desktop') return appConfig.shops.menu.DESKTOP_SCROLL_OFFSET;
    if (deviceTarget === 'mobile') return appConfig.shops.menu.MOBILE_SCROLL_OFFSET;
    return appConfig.shops.menu.DESKTOP_SCROLL_OFFSET;
  }

  private collapseMenu(): void {
    const menuNav = this.menuNav.getValue();
    if (!menuNav?.items?.length) return;
    const collapsedMenu = {
      ...menuNav,
      items: menuNav.items.map((item) => ({ ...item, active: false }))
    };
    this.menuNav.next(collapsedMenu);
  }

  public setMenu(menuNav: ShopMenuNav): void {
    this.menuNav.next(menuNav);
  }

  public goToSideParentCategory(categories: Category[], direction: 'left' | 'right'): void {
    const currentMenuNavItemIndex = this.menuNav.getValue().items?.findIndex((item) => item.active);
    const sideIndex = direction === 'left' ? currentMenuNavItemIndex - 1 : currentMenuNavItemIndex + 1;
    if (sideIndex < 0 || sideIndex > this.menuNav.getValue().items?.length - 1) return;
    const previousMenuNavItem = this.menuNav.getValue()?.items[sideIndex];
    if (!previousMenuNavItem) return;
    this.setMenuItem(previousMenuNavItem?._id);
    const selectedCategories = getSelectedCategoryById(categories, previousMenuNavItem._id);
    this.setSelectedParentCategory(selectedCategories?.parentCategory);
  }

  public setSelectedParentCategory(selectedCategory: Category): void {
    const previousSelectedParentCategory = this.selectedParentCategory.getValue();
    if (previousSelectedParentCategory?._id === selectedCategory?._id) return;
    this.selectedParentCategory.next(selectedCategory);
    if (!selectedCategory) this.collapseMenu();
    this.setCategoryIdQueryParam(selectedCategory?._id);
  }

  public setSelectedSubCategory(selectedCategory: Category): void {
    const previousSelectedSubCategory = this.selectedSubCategory.getValue();
    if (previousSelectedSubCategory?._id === selectedCategory?._id) return;
    this.selectedSubCategory.next(selectedCategory);
  }

  public getShopMenuNavItemById(categoryId: string): ShopMenuNavItem {
    const menuNav = this.menuNav.getValue();
    if (!menuNav?.items?.length) return;
    return menuNav.items.find((item) => item._id === categoryId);
  }

  public getActiveCategoryIdByMenuNav(menu: ShopMenuNav): string {
    const activeCategory = menu.items?.find((item) => item.active);
    if (!activeCategory) return;
    const activeSubCategory = activeCategory.subItems?.find((item) => item.active);
    if (!activeSubCategory) return activeCategory._id;
    return activeSubCategory._id;
  }

  // handles offers category selected parent missing children bug
  public updateSelectedParentCategory(categories: Category[]): void {
    const selectedParentCategory = this.selectedParentCategory.getValue();
    if (!selectedParentCategory) return;
    const decoratedCategory = categories.find((cat) => cat._id === selectedParentCategory._id);
    if (!decoratedCategory) return;
    this.selectedParentCategory.next(decoratedCategory);
  }

  public initializeMenu(items: ShopMenuNavItem[], fixedItems: ShopMenuNavItem[]): void {
    const allItems = [...fixedItems, ...items].filter(Boolean).map((item) => {
      item.active = false;
      return item;
    });
    const hasSubItems = allItems.some((item) => item.subItems?.length > 0);
    this.setMenu({ items: allItems, hasSubItems });
  }

  public categoriesToShopMenuNavItems(categories: Category[]): ShopMenuNavItem[] {
    return [
      ...categories.map((category) => ({
        _id: category._id,
        name: category.name,
        active: false,
        subItems: category.children
          ? category.children.map((childCategory) => ({
              _id: childCategory._id,
              name: childCategory.name,
              active: false
            }))
          : []
      }))
    ];
  }

  public setMenuItem(itemId: string, subItemId?: string): void {
    if (!itemId) return;
    const currentMenuNav = this.menuNav.getValue();
    if (!currentMenuNav) return;
    const newMenuNav: ShopMenuNav = {
      items: currentMenuNav.items.map((item) => ({
        ...item,
        active: item._id === itemId,
        subItems: item.subItems
          ? item.subItems.map((subItem) => ({ ...subItem, active: subItem._id === subItemId }))
          : []
      })),
      hasSubItems: currentMenuNav.hasSubItems
    };

    this.setMenu(newMenuNav);
  }

  public addItem(item: ShopMenuNavItem, method = 'push'): void {
    const currentMenuNav = this.menuNav.value;
    if (method === 'push') currentMenuNav.items.push(item);
    if (method === 'unshift') currentMenuNav.items.unshift(item);
    this.setMenu(currentMenuNav);
  }

  public setCategoryIdQueryParam(categoryId: string): void {
    if (!categoryId?.length) return;
    const shop = this.shopService.getShop();
    const options: NavigationExtras = { queryParams: { categoryId } };
    if (shop.chainView) {
      const pathWithoutParams = getPathWithoutQueryParams(this.router.url);
      // delivery-chains/menu/masouths?categoryId=asdgasdg
      return void this.router.navigate([pathWithoutParams], options);
    }
    void this.router.navigate(['/delivery', shop.locationKey, shop.vanity_url], options);
  }

  public scrollTo(fragment: string, scrollOffsetTop?: number): void {
    if (!fragment) return;
    const targetElement = document.getElementById(fragment);
    if (!targetElement) return;
    this.scrollSpyService.parent.nativeElement.scrollTo({
      top: targetElement.offsetTop + scrollOffsetTop,
      behavior: 'smooth'
    });
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RewardsCard } from '@box-types';

@Component({
  selector: 'rewards-card',
  templateUrl: './rewards-card.component.html',
  styleUrls: ['./rewards-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsCardComponent implements OnChanges {
  @Input() public rewardsCard: RewardsCard;
  @HostBinding('class') public hostClass = 'rewards-card';

  ngOnChanges(changes: SimpleChanges): void {
    this.rewardsCard = changes.rewardsCard.currentValue as RewardsCard;
    this.hostClass = this.getHostClasses();
  }

  private getHostClasses(): string {
    if (this.rewardsCard.status === 'IN_PROGRESS') return `rewards-card rewards-card-in-progress`;
    if (this.rewardsCard.status === 'COMPLETED') return `rewards-card rewards-card-completed`;
    return `rewards-card`;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding
} from '@angular/core';
import { PointsAnimationService } from '@box-core/services';
import { RewardsBalanceBadgeState } from '@box-rewards-widget/components/rewards-balance-badge/rewards-balance-badge.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'benefits-banner',
  templateUrl: './benefits-banner.component.html',
  styleUrls: ['./benefits-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitsBannerComponent {
  @Input() public infoText: string;
  @Input() public pointsText: string;
  @Input() public currencyText: string;
  @Output() private navigate = new EventEmitter<void>();
  @HostBinding('class') public hostClass = 'benefits-banner';

  public state: RewardsBalanceBadgeState;

  private stateSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef, private pointsAnimationService: PointsAnimationService) {}

  ngOnInit(): void {
    this.setStateSubscription();
  }

  ngOnDestroy(): void {
    this.stateSubscription?.unsubscribe();
  }

  private setStateSubscription(): void {
    this.stateSubscription = this.pointsAnimationService.state$.subscribe((state) => {
      if (!this.currencyText) {
        this.state = 'POINTS';
        this.stateSubscription?.unsubscribe();
        this.changeDetectorRef.detectChanges();
        return;
      }
      if (!this.pointsText) {
        this.state = 'CURRENCY';
        this.stateSubscription?.unsubscribe();
        this.changeDetectorRef.detectChanges();
        return;
      }
      this.state = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  public onClick(): void {
    this.navigate.emit();
  }
}

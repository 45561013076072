<box-digital-card [flippable]="format?.length">
  <div cardFront class="market-card-front" [style.background-image]="'url(' + imageSrc + ')'">
    <div class="market-card-front-content">
      <span class="market-card-front-points">{{ pointsText }}</span>
      <span class="market-card-front-number">{{ numberText }}</span>
    </div>
  </div>
  <box-barcode
    *ngIf="format"
    cardBack
    [value]="code"
    [format]="format"
    width="2"
    textMargin="12"
    fontSize="12"
    fontOptions="bold"
  ></box-barcode>
</box-digital-card>

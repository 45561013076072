import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Shop } from '@box-types';
import { Observable } from 'rxjs';
import { CoreService } from '@box-core/services';
import { setRedirectPrerenderMetaElements } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class ClosestChainShopResolver implements Resolve<Shop> {
  constructor(private coreService: CoreService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Shop> {
    const chainKey = route.params.chainKey as string;
    if (!chainKey?.length) return void this.navigateTo404();
    const coreChains = this.coreService.chains.getValue();
    const chainSlug = coreChains.find((chain) => chain.chainKey === chainKey)?.slug;
    this.navigateToChainPage(chainSlug);
  }

  private navigateToChainPage(slug: string): void {
    if (!slug) return this.navigateTo404();
    setRedirectPrerenderMetaElements(window.location.origin + '/delivery-chains/menu/' + slug);
    void this.router.navigate(['delivery-chains', 'menu', slug]);
  }

  private navigateTo404(): void {
    void this.router.navigate(['/404']);
  }
}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { CartGroupChange, Offer, OfferInstance } from '@box-types';

@Component({
  selector: 'cart-offer',
  templateUrl: './cart-offer.component.html',
  styleUrls: ['./cart-offer.component.scss']
})
export class CartOfferComponent implements OnChanges {
  /* The disabled property is used for the Order Revie won Checkout. This needs
  to be removed in the future with a Checkout specific component.
  The inactive property is used for the Cart Offers that do not meet the
  minimumOrderItemsPrice requirement. */
  @Input() public offer: Offer;
  @Input() public disabled = false;

  @Output() private add = new EventEmitter<CartGroupChange<Offer, OfferInstance>>();
  @Output() private remove = new EventEmitter<CartGroupChange<Offer, OfferInstance>>();
  @Output() private edit = new EventEmitter<CartGroupChange<Offer, OfferInstance>>();

  public showSticker: boolean;
  public showDFYBadge: boolean;
  public sticker: string;
  public inactiveText: string;

  @HostBinding('class.inactive') public inactive: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offer) {
      this.showSticker = this.offer.webSticker && !(this.offer.needsCouponToRedeem && this.offer.couponType === 'dfu');
      this.sticker = this.offer.webSticker;
      this.showDFYBadge = this.offer.needsCouponToRedeem && this.offer.couponType === 'dfu';
      this.inactiveText = this.offer.inactiveText;
      this.inactive = Boolean(this.inactiveText);
    }
    if (changes.disabled) this.disabled = changes.disabled.currentValue as boolean;
  }

  public onAdd(itemInstance: OfferInstance): void {
    this.add.emit({ item: this.offer, instance: itemInstance });
  }

  public onRemove(itemInstance: OfferInstance): void {
    this.remove.emit({ item: this.offer, instance: itemInstance });
  }

  public onEdit(itemInstance: OfferInstance): void {
    if (!this.disabled) this.edit.emit({ item: this.offer, instance: itemInstance });
  }
}

import { Component, Input, EventEmitter, Output, OnInit, HostBinding } from '@angular/core';
import { Order, OrderPaymentIndicator } from '@box-types';
import { OrderService } from './order.service';
import { generateOrderPaymentIndicators } from '@box/utils';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [OrderService]
})
export class OrderComponent implements OnInit {
  @Input() public order: Order;
  @Output() private orderRate = new EventEmitter<number>();

  public logoUrl: string;
  public orderPoints: number;
  public totalDiscountText: string;
  public smLogoSrc: string;
  public indicators: OrderPaymentIndicator[];
  public orderItemsToShow: { name: string; quantity: number }[];
  public hiddenOrderItemsQuantity: number;
  public totalPrice: number;
  public smCardPoints: number;
  public reviewDisabled: boolean;
  public reviewEnabled: boolean;

  constructor(private orderService: OrderService) {}

  @HostBinding('class') public hostClass = 'order';

  ngOnInit(): void {
    this.indicators = generateOrderPaymentIndicators(this.order);
    this.orderPoints = this.order?.marketPlacePoints?.collected;
    this.totalDiscountText = this.orderService.getTotalDiscountText(this.order);
    const orderItems = this.orderService.getOrderItems(this.order);
    this.orderItemsToShow = orderItems.slice(0, 4);
    const orderItemsQuantity = orderItems.reduce((acc, cur) => acc + cur.quantity, 0);
    const shownOrderItemsQuantity = this.orderItemsToShow.reduce((acc, cur) => acc + cur.quantity, 0);
    this.hiddenOrderItemsQuantity = orderItemsQuantity - shownOrderItemsQuantity;
    this.totalPrice = this.order.totalPrice + (this.order?.donation ?? 0);
    this.logoUrl = this.orderService.getOrderShopLogoSrc(this.order);
    this.smLogoSrc = this.orderService.getOrderSMLogo(this.order);
    this.smCardPoints = this.order?.integratorLoyalty?.pointsFromOrder ?? 0;
    this.reviewDisabled = Boolean(this.order.rating);
    this.reviewEnabled = this.order.status === 'COMPLETED';
  }

  public onChangeOrderRating(orderRating: number): void {
    this.orderRate.emit(orderRating);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'checkout-market-banner',
  templateUrl: './checkout-market-banner.component.html',
  styleUrls: ['./checkout-market-banner.component.scss']
})
export class CheckoutMarketBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Shop, PromoVisualOptions, ShopPromo, PromoColorsOptions, ColorContent, TextContent, Coupon } from '@box-types';
import {
  getPromoText,
  getPromoColor,
  getOfferPromoVisuals,
  getPointsPromoVisuals,
  getBestShopOfferType,
  formatPoints,
  couponSpecificPromoOptions
} from '@box/utils';
import { ConfigurationService } from '@box-core/services/index';
import { Injectable, Injector } from '@angular/core';
import { LanguageService } from '@box-core/services/language.service';

// Template Method Pattern
// https://refactoring.guru/design-patterns/template-method
export abstract class PromoVisualOptionsService {
  private configService: ConfigurationService;

  constructor(injector: Injector) {
    this.configService = injector.get(ConfigurationService);
  }

  /**
   * 1. The low-order probability coupon has always the highest priority.
   * 2. The synergy coupon maybe overrides a promo (promo.overriddenByCouponSynergies)
   *    if coupon is undefined (e.g texts are not specified), we do not return the PromoVisualOptions.
   *    Instead we return the next available (multiplierSum or points).
   *
   * If 1 and 2 not applicable then returns the multiplier or point PromoVisualOptions
   *
   * @returns {PromoVisualOptions}
   */
  public generateFirstGroupOptions(promo: ShopPromo): PromoVisualOptions {
    if (promo?.lowOrderBenefit) return this.generateLowOrderBenefitOptions(promo);
    if (promo?.promoCoupons?.length) {
      const couponPromoVisualOptions = this.getCouponSpecificPromoOptions(promo.promoCoupons);
      if (couponPromoVisualOptions) return couponPromoVisualOptions;
    }
    if (promo?.multiplierSum) return this.generateMultiplierPromoOptions(promo);
    if (promo?.pointsSum) return this.generatePointsPromoOptions(promo);
  }

  public generateSecondGroupOptions(promo: ShopPromo, shop: Shop): PromoVisualOptions {
    if (promo?.bestSpecialCampaign) return this.generateSpecialCampaignOptions(promo);
    return this.generateOfferPromoOptions(shop);
  }

  private generateLowOrderBenefitOptions(promo: ShopPromo): PromoVisualOptions {
    return {
      ...getPointsPromoVisuals(),
      text: getPromoText(promo.lowOrderBenefit.texts, 'lowOrderProbabilityActiveBadge')
    };
  }

  public generateOfferPromoOptions(shop: Shop): PromoVisualOptions {
    if (shop.isSuperMarket) return { text: 'Offer' };
    const offersPriority = this.configService.getConfiguration()?.offersPriority;
    const bestOfferType = getBestShopOfferType(shop, offersPriority);
    if (bestOfferType) {
      return {
        ...getOfferPromoVisuals(),
        text: bestOfferType
      };
    }
  }

  private getSpecialPromoColors(colors: ColorContent[]): PromoColorsOptions {
    return {
      foreground: getPromoColor(colors, 'foregroundTag'),
      background: getPromoColor(colors, 'backgroundTag'),
      triangle: getPromoColor(colors, 'triangleTag'),
      sideTag: getPromoColor(colors, 'sideTag')
    };
  }

  private generateSpecialCampaignOptions(promo: ShopPromo): PromoVisualOptions {
    return {
      text: this.getPromoText(promo?.bestSpecialCampaign?.texts),
      colors: this.getSpecialPromoColors(promo?.bestSpecialCampaign?.colors)
    };
  }

  abstract generatePointsPromoOptions(promo: ShopPromo): PromoVisualOptions;
  abstract generateMultiplierPromoOptions(promo: ShopPromo): PromoVisualOptions;
  abstract getPromoText(texts: TextContent[]): string;
  abstract getCouponSpecificPromoOptions(coupons: Coupon[]): PromoVisualOptions;
}

@Injectable({ providedIn: 'root' })
export class ShortPromoVisualOptionsService extends PromoVisualOptionsService {
  constructor(injector: Injector) {
    super(injector);
  }

  generatePointsPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedPoints = formatPoints(promo.pointsSum);
    return { text: `+${formattedPoints}` };
  }

  generateMultiplierPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedMultiplier = formatPoints(promo.multiplierSum);
    return { ...getPointsPromoVisuals(), text: `x${formattedMultiplier}` };
  }

  getPromoText(texts: TextContent[]): string {
    return getPromoText(texts, 'shortTag');
  }

  getCouponSpecificPromoOptions(coupons: Coupon[]): PromoVisualOptions {
    return couponSpecificPromoOptions(coupons, 'shortTag');
  }
}

@Injectable({ providedIn: 'root' })
export class LongPromoVisualOptionsService extends PromoVisualOptionsService {
  constructor(injector: Injector, private languageService: LanguageService) {
    super(injector);
  }

  generatePointsPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedPoints = formatPoints(promo.pointsSum);
    return { text: `+${formattedPoints} ${this.languageService.getTextByKey('points_')}` };
  }

  generateMultiplierPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedMultiplier = formatPoints(promo.multiplierSum);
    return {
      ...getPointsPromoVisuals(),
      text: `x${formattedMultiplier} ${this.languageService.getTextByKey('points_')}`
    };
  }

  getPromoText(texts: TextContent[]): string {
    return getPromoText(texts, 'longTag');
  }

  getCouponSpecificPromoOptions(coupons: Coupon[]): PromoVisualOptions {
    return couponSpecificPromoOptions(coupons, 'longTag');
  }
}

import { FocusMonitor } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatRipple } from '@angular/material/core';

@Directive({
  selector: '[boxNavItem]',
  providers: [MatRipple]
})
export class BOXNavItemDirective implements OnInit, OnDestroy {
  private rippleListener: () => void;

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private platform: Platform,
    private focusMonitor: FocusMonitor
  ) {}

  @HostBinding('class') public hostClass = 'box-nav-item';

  ngOnInit(): void {
    this.setRippleListener();
    this.setFocusOverlay();
    this.setFocusMonitor();
  }

  ngOnDestroy(): void {
    this.deRegisterRippleListener();
  }

  private setRippleListener(): void {
    const rippleElement = this.renderer.createElement('div') as HTMLDivElement;
    this.renderer.addClass(rippleElement, 'box-nav-item-ripple');
    this.renderer.appendChild(this.element.nativeElement, rippleElement);
    const rippleElementRef = new ElementRef(rippleElement);
    const ripple = new MatRipple(rippleElementRef, this.ngZone, this.platform);
    this.rippleListener = this.renderer.listen(rippleElement, 'mousedown', (event: MouseEvent) => {
      ripple.launch(event.x, event.y);
    });
  }

  private deRegisterRippleListener(): void {
    if (this.rippleListener) this.rippleListener();
  }

  private setFocusOverlay(): void {
    const overlay = this.renderer.createElement('div') as HTMLDivElement;
    this.renderer.addClass(overlay, 'box-nav-item-overlay');
    this.renderer.appendChild(this.element.nativeElement, overlay);
  }

  private setFocusMonitor(): void {
    this.focusMonitor.monitor(this.element);
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding
} from '@angular/core';
import { getCouponImagePath, showCheckoutCouponCustomActiveState, showCheckoutCouponDefaultState } from '@box/utils';
import { Coupon } from '@box-types';

@Component({
  selector: 'checkout-coupon-image',
  templateUrl: './checkout-coupon-image.component.html',
  styleUrls: ['./checkout-coupon-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutCouponImageComponent implements OnInit, OnChanges {
  @Input() public coupon: Coupon;
  public imageSrc: string;
  public showCustomActiveState = false;
  public showDefaultCouponState = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class') public hostClass = 'checkout-coupon-image';

  ngOnInit(): void {
    this.setImageState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coupon) {
      this.coupon = changes.coupon.currentValue as Coupon;
      this.setImageState();
    }
  }

  private setImageState(): void {
    this.imageSrc = getCouponImagePath(this.coupon);
    this.showCustomActiveState = showCheckoutCouponCustomActiveState(this.coupon);
    this.showDefaultCouponState = showCheckoutCouponDefaultState(this.coupon);
    this.changeDetectorRef.detectChanges();
  }
}

import { Injectable } from '@angular/core';
import { DeliveryMethodService, ShopService } from '@box-core/services';
import { Shop, TimetableDayDetails, BoxTheme, ChainShopListItem, Coordinates, DeliveryAnchor } from '@box-types';
import {
  generateGoogleMapsDirectionsUrl,
  getAddressDescription,
  formatPhoneToITU,
  getShopDeliveryFeeText,
  getShopMinimumOrderPriceText,
  getTimetableDetails,
  getStaticMapUrl,
  generateImageSrc,
  filterCoordinatesByDistance
} from '@box/utils';
import { currencyCode } from '@box-core/services/currency.service';

@Injectable()
export class ShopPageDetailsService {
  constructor(private shopService: ShopService, private deliveryMethodService: DeliveryMethodService) {}

  public getShop(): Shop {
    return this.shopService.getShop();
  }

  public getShopLogoUrl(): string {
    const shop = this.shopService.getShop();
    return generateImageSrc(shop.logo);
  }

  public generateMapBackgroundImage(theme: BoxTheme, shopsWithChain?: ChainShopListItem[]): string {
    const shop = this.getShop();
    const centerCoordinates = { latitude: shop.address.latitude, longitude: shop.address.longitude };
    if (!shopsWithChain?.length) return getStaticMapUrl(centerCoordinates, theme);

    const otherCoordinates = shopsWithChain?.map((item) => ({
      latitude: item.address.latitude,
      longitude: item.address.longitude
    })) as Coordinates[];
    const filteredCoordinates = filterCoordinatesByDistance(centerCoordinates, otherCoordinates, 1591264); // 9km
    return getStaticMapUrl(centerCoordinates, theme, filteredCoordinates);
  }

  public generatePaymentIcons(): string[] {
    const shop = this.shopService.getShop();
    const icons: string[] = [];
    if (shop.card) icons.push('/assets/images/shops/payment-methods/card.svg');
    if (shop.cash) icons.push('/assets/images/shops/payment-methods/cash.svg');
    return icons;
  }

  public getShopMinimumOrderPriceText(): string {
    const shop = this.shopService.getShop();
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const canDeliverToAddress = this.shopService.canDeliverToAddress.getValue();
    return getShopMinimumOrderPriceText(shop, deliveryMethod, canDeliverToAddress, currencyCode);
  }

  public getShopDeliveryFeeText(): string {
    const shop = this.shopService.getShop();
    if (!shop.deliveryFees?.length) return;
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    return getShopDeliveryFeeText(shop, deliveryMethod, currencyCode);
  }

  public getAddressText() {
    const shop = this.shopService.getShop();
    return getAddressDescription(shop.address);
  }

  public getAddressAnchor() {
    const shop = this.shopService.getShop();
    return generateGoogleMapsDirectionsUrl(shop.address);
  }

  public getPhoneNumber() {
    const shop = this.shopService.getShop();
    return formatPhoneToITU(shop.phone);
  }

  public getTimetableDayDetails(): TimetableDayDetails[] {
    const shop = this.shopService.getShop();
    const timetableDetails = getTimetableDetails(shop.timetableV2);
    if (!timetableDetails.length) return [];
    return [...timetableDetails.slice(1), timetableDetails[0]]; // Bring sunday last on the list;
  }

  public shopsWithChainToDeliveryAnchors(shopsWithChain: ChainShopListItem[]): DeliveryAnchor[] {
    if (!shopsWithChain?.length) return [];
    return shopsWithChain.map((shop) => {
      const addressDescription = getAddressDescription(shop.address);
      const link = `/delivery/${shop.locationKey}/${shop.vanityUrl}`;
      return { text: addressDescription, link };
    });
  }

  public findMainChainShopDeliveryAnchor(anchors: DeliveryAnchor[]): DeliveryAnchor {
    if (!anchors?.length) return;
    const link = `/delivery/${this.getShop().locationKey}/${this.getShop().vanity_url}`;
    return anchors.find((anchor) => anchor.link === link);
  }
}

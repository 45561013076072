import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@box-shared/shared.module';

import * as Components from './components';

@NgModule({
  declarations: [
    Components.ShopsComponent,
    Components.ShopRatingComponent,
    Components.ShopTileDeliveryInfoComponent,
    Components.ShopTileCompactComponent,
    Components.ShopTileDetailedComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    Components.ShopsComponent,
    Components.ShopRatingComponent,
    Components.ShopTileDeliveryInfoComponent,
    Components.ShopTileCompactComponent,
    Components.ShopTileDetailedComponent
  ]
})
export class ShopsWidgetModule {}

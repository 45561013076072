import { ValidatorFn } from '@angular/forms';
import { regExToValidatorFunction } from '@box-shared/validators/validator.utils';

/* Only for Greek mobile numbers,  10 Digits starting with 69xxxxxxxx, with or without the +30 prefix */

const EXPRESSIONS: string[] = ['(^69\\d{8}$)', '(^\\+3069\\d{8}$)'];

const REG_EXP = new RegExp(EXPRESSIONS.join('|'));

export function mobilePhoneValidator(): ValidatorFn {
  return regExToValidatorFunction(REG_EXP);
}

export function isMobilePhone(input: string): boolean {
  return REG_EXP.test(input);
}

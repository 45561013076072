import { Component, Input, HostBinding, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { PromoBanner } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoBannerComponent implements OnChanges {
  @Input() public promoBanner: PromoBanner;
  public title: string;
  public imageUrl: string;

  @HostBinding('class') public hostClass = 'promo-banner';

  ngOnChanges(): void {
    this.title = this.promoBanner.title;
    this.imageUrl = generateImageSrc(this.promoBanner.webImage);
  }
}

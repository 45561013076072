export { getPlaceResultAddressComponent, isPlaceResultDeliveryCompliant };

function getPlaceResultAddressComponent(
  placeResult: google.maps.places.PlaceResult,
  type: string
): google.maps.GeocoderAddressComponent {
  const addressComponents = placeResult.address_components;
  if (!addressComponents?.length || !type) return;
  const addressComponent = addressComponents.find((component) => component.types?.includes(type));
  return addressComponent as google.maps.GeocoderAddressComponent;
}

function isPlaceResultDeliveryCompliant(placeResult: google.maps.places.PlaceResult): boolean {
  if (!placeResult) return false;
  const city = getPlaceResultAddressComponent(placeResult, 'locality')?.long_name;
  const street = getPlaceResultAddressComponent(placeResult, 'route')?.long_name;
  const postalCodeText = getPlaceResultAddressComponent(placeResult, 'postal_code')?.long_name;
  return Boolean(city) && Boolean(street) && Boolean(postalCodeText);
}

import { Injectable } from '@angular/core';
import { appConfig } from '@box-configs/app.config';
import { AnalyticsService, SEOService } from '@box-core/services';

@Injectable()
export class DownloadService {
  constructor(private seoService: SEOService, private analyticsService: AnalyticsService) {}

  public setMetaTags(): void {
    const title = 'download_box_and_earn';
    const description = 'box_the_only_rewarding_delivery_app';
    const url = '/download';
    const options = { title, description, url };
    this.seoService.setTags(options);
  }

  public getAndroidDownloadLink(): string {
    return appConfig.mobile.ANDROID_APP;
  }

  public getIOSDownloadLink(): string {
    return appConfig.mobile.IOS_APP;
  }

  public getHuaweiDownloadLink(): string {
    return appConfig.mobile.HUAWEI_APP;
  }

  public setDownloadPageAnalayticsEvent(platform: string): void {
    const source = 'Download Page';
    this.analyticsService.addGACustomEvent('click_app_download', { platform, source });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import { BarcodeEncodingType } from '@box-types';
import JsBarcode from 'jsbarcode';

@Component({
  selector: 'box-barcode',
  templateUrl: './box-barcode.component.html',
  styleUrls: ['./box-barcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BOXBarcodeComponent implements AfterViewInit {
  @ViewChild('svg') svgNode: ElementRef<SVGElement>;

  @Input() public value: string;
  @Input() public format: BarcodeEncodingType = 'auto';
  @Input() public width = 2;
  @Input() public height = 100;
  @Input() public displayValue = true;
  @Input() public text: string;
  @Input() public fontOptions = '';
  @Input() public font = 'PF DIN Text Pro';
  @Input() public textAlign = 'center';
  @Input() public textPosition = 'bottom';
  @Input() public textMargin = 2;
  @Input() public fontSize = 20;
  @Input() public background = '#ffffff';
  @Input() public lineColor = '#2a292e';
  @Input() public margin = 10;
  @Input() public marginTop: number;
  @Input() public marginBottom: number;
  @Input() public marginLeft: number;
  @Input() public marginRight: number;

  @Output() private valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class') public hostClass = 'box-barcode';

  ngAfterViewInit(): void {
    JsBarcode(this.svgNode.nativeElement, this.value, {
      format: this.format,
      width: this.width,
      height: this.height,
      displayValue: this.displayValue,
      text: this.text,
      fontOptions: this.fontOptions,
      font: this.font,
      textAlign: this.textAlign,
      textPosition: this.textPosition,
      textMargin: this.textMargin,
      fontSize: this.fontSize,
      background: this.background,
      lineColor: this.lineColor,
      margin: this.margin,
      marginTop: this.marginTop,
      marginBottom: this.marginBottom,
      marginLeft: this.marginLeft,
      marginRight: this.marginRight,
      valid: (valid) => this.valid.emit(valid)
    });
  }
}

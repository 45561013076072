import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'box-chips',
  templateUrl: './box-chips.component.html',
  styleUrls: ['./box-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BOXChipsComponent {
  @HostBinding('class') public hostName = 'box-chips';
}

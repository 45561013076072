import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIResponse } from '@box-types';
import { CheckoutInfo } from '@box/utils';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable()
export class CheckoutInfoService {
  private readonly API_URL = environment.application.API_URL;
  private readonly checkoutInfoSource = new BehaviorSubject<CheckoutInfo>({});
  public readonly checkoutInfo$ = this.checkoutInfoSource.asObservable();

  constructor(private http: HttpClient) {}

  public setCheckoutInfo(info: CheckoutInfo): void {
    this.checkoutInfoSource.next(info);
  }

  public getCheckoutInfo(): CheckoutInfo {
    return this.checkoutInfoSource.getValue();
  }

  public fetchCheckoutInfo(collectionType: number): Observable<CheckoutInfo> {
    const params = new HttpParams().set('collectionType', collectionType);
    return this.http
      .get(`${this.API_URL}/checkout-info`, { params })
      .pipe(map((response: APIResponse<CheckoutInfo>) => response.payload));
  }
}

import { Component, Input } from '@angular/core';
import { PromoCoupon } from '@box-types';
import { PromoCouponClipboardService } from './promo-coupon-clipboard.service';

@Component({
  selector: 'promo-coupon-clipboard',
  templateUrl: './promo-coupon-clipboard.component.html',
  styleUrls: ['./promo-coupon-clipboard.component.scss'],
  providers: [PromoCouponClipboardService]
})
export class PromoCouponClipboardComponent {
  @Input() public coupon: PromoCoupon;

  constructor(private promoCouponClipboardService: PromoCouponClipboardService) {}

  public onCouponCopy(): void {
    this.promoCouponClipboardService.showCopySnackBar();
  }
}

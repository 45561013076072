import { Shop } from '@box-types';
import { ShopRatingStatus } from './shop.types';

export { canShowShopRatings, getShopRating, getFormattedShopRating, getShopRatingStatus };

function canShowShopRatings(ratingCounter: number): boolean {
  return ratingCounter >= 5;
}

function getShopRating(shop: Shop): number {
  const ratingOverall = shop.ratingOverall ?? 0;
  const ratingCounter = shop.ratingCounter ?? 0;
  if (!canShowShopRatings(ratingCounter)) return 0;
  return ratingOverall;
}

function getFormattedShopRating(shop: Shop): string {
  const rating = getShopRating(shop);
  if (rating === 0) return '-';
  return new Intl.NumberFormat('el-GR', { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(rating);
}

function getShopRatingStatus(shop: Shop): ShopRatingStatus {
  const rating = Math.round(getShopRating(shop) * 10) / 10;
  if (rating < 4) return 'OK';
  if (rating >= 4.5) return 'EXCELLENT';
  if (rating >= 4) return 'GOOD';
}

import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ShopMenuNav, ShopMenuNavItem, ShopMenuNavItemChange } from '@box-types';
import { Subscription } from 'rxjs';
import { ShopMenuNavService } from '../../services';

@Component({
  selector: 'shop-menu-nav',
  templateUrl: './shop-menu-nav.component.html',
  styleUrls: ['./shop-menu-nav.component.scss']
})
export class ShopMenuNavComponent implements OnInit, OnDestroy {
  @Output() private itemChange: EventEmitter<ShopMenuNavItemChange> = new EventEmitter<ShopMenuNavItemChange>();

  public menuNav: ShopMenuNav;
  private menuSubscription: Subscription;

  constructor(private shopMenuNavService: ShopMenuNavService) {}

  ngOnInit(): void {
    this.setMenuSubscription();
  }

  ngOnDestroy(): void {
    if (this.menuSubscription) this.menuSubscription.unsubscribe();
  }

  public onItemClick(data: ShopMenuNavItemChange): void {
    this.itemChange.emit(data);
  }

  public trackByActive(index: number, item: ShopMenuNavItem): boolean {
    return item.active;
  }

  private setMenuSubscription(): void {
    this.menuSubscription = this.shopMenuNavService.menuNav.subscribe((menu) => (this.menuNav = menu));
  }
}

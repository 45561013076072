import { Injectable } from '@angular/core';
import { Offer } from '@box-types';
import { generateImageSrc } from '@box/utils';
import orderBy from 'lodash-es/orderBy';

@Injectable()
export class OfferWizardService {
  public getOfferHeroImageSrc(offer: Offer): string {
    return generateImageSrc(offer.webImage);
  }

  public getOfferImages(offer: Offer): string[] {
    if (!offer?.webImages) return [];
    const sortedImages = orderBy(offer.webImages, 'galleryIndex', 'asc');
    return sortedImages.map((image) => generateImageSrc(image));
  }
}

import { of, Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NotificationDismissOptions } from './notification.types';

export class NotificationRef {
  private readonly _afterDismissed = new Subject<void>();
  private readonly _afterDismissedWithAction = new Subject<void>();

  public containerId: string;
  public afterDismissed: Observable<void> = this._afterDismissed.asObservable();
  public afterDismissedWithAction: Observable<void> = this._afterDismissedWithAction.asObservable();

  public dismiss(options?: NotificationDismissOptions): void {
    const _delay = options?.delay ?? 0;
    of(undefined)
      .pipe(delay(_delay))
      .subscribe(() => {
        this._afterDismissed.next();
        this._afterDismissed.complete();
      });
  }

  public dismissWithAction(options?: NotificationDismissOptions): void {
    const _delay = options?.delay ?? 0;
    of(undefined)
      .pipe(delay(_delay))
      .subscribe(() => {
        this._afterDismissedWithAction.next();
        this._afterDismissedWithAction.complete();
      });
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject, of, timer } from 'rxjs';
import {
  LiveTrackingResponse,
  DriverLocation
} from '@box-checkout/components/live-tracking-map/live-tracking-map.types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SentryService, ConfigurationService } from '@box-core/services';
import { switchMap, map, takeUntil, catchError, skipWhile, take } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { APIResponse, APIError, Order } from '@box-types';
import dayjs from 'dayjs';
import { canShowDeliveryMarker, canFocusOnUserAndDelivery } from '@box/utils';
import { OrderStatusService } from '@box-checkout/pages/order-status/order-status.service';
import { uniqWith, isEqual } from 'lodash-es';

@Injectable()
export class LiveTrackingMapService {
  private readonly BOX_API = environment.application.API_URL;
  private pollingStopped = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private orderStatusService: OrderStatusService,
    private sentryService: SentryService,
    private configService: ConfigurationService
  ) {}

  public waitForOrderToBeDeliveryReady(): Observable<Order> {
    return this.orderStatusService.order$.pipe(
      skipWhile((order: Order) => !canShowDeliveryMarker(order)),
      take(1)
    );
  }

  public canFocusOnUserAndDelivery(): boolean {
    const order = this.orderStatusService.getOrder();
    return canFocusOnUserAndDelivery(order);
  }

  public filterLocationsToBeShown(locations: DriverLocation[], lastDateShown: Date): DriverLocation[] {
    if (!lastDateShown) return locations;
    const uniqLocations = uniqWith(locations, (a, b) => isEqual(a, b));
    return uniqLocations.filter((location) => {
      return dayjs(location.capturedAt).isAfter(lastDateShown);
    });
  }

  public liveTrackingPolling(): Observable<LiveTrackingResponse> {
    const pollingRate = this.configService.getConfiguration().liveTrackingTimeInterval ?? 10 * 1000;
    return timer(0, pollingRate).pipe(
      switchMap(() => this.fetchLiveTrackingCoordinates()),
      takeUntil(this.pollingStopped)
    );
  }

  private fetchLiveTrackingCoordinates(): Observable<LiveTrackingResponse> {
    const orderFriendlyId = this.orderStatusService.getOrder().friendlyId;
    const params: HttpParams = new HttpParams().set('friendlyId', orderFriendlyId);
    return this.httpClient.get(`${this.BOX_API}/orders/live-tracking`, { params }).pipe(
      map((response: APIResponse<LiveTrackingResponse>) => response.payload),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Orders',
          domainDetails: 'Live Tracking',
          severity: 'error'
        });
        return of(null);
      })
    );
  }

  public stopPolling(): void {
    this.pollingStopped.next(true);
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  AfterViewInit,
  HostBinding
} from '@angular/core';
import { PromoVisualOptions, Product, Offer } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'market-item',
  templateUrl: './market-item.component.html',
  styleUrls: ['./market-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketItemComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public isOffer = false;
  @Input() public item: Product | Offer;
  @Output() public add = new EventEmitter<Product | Offer>();
  @Output() public remove = new EventEmitter<Product | Offer>();
  public name: string;
  public description: string;
  public thumbnail: string;
  public unitOfMeasurement: string;
  public stepToUomDescription: string;
  public originalPrice: number;
  public price: number;
  public quantity: number;
  public tagOptions: PromoVisualOptions;

  private available: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class') public hostClass = 'market-item';
  @HostBinding('class.unavailable') public unavailableClass: boolean;

  ngOnInit(): void {
    this.setContent(this.item);
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detach();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.item = changes.item.currentValue as Product | Offer;
      this.setContent(this.item);
      this.changeDetectorRef.detectChanges();
    }
  }

  private setContent(item: Offer | Product): void {
    this.name = item.name;
    this.description = item.unitOfMeasurementDescription;
    this.thumbnail = generateImageSrc(item.thumbnail);
    this.unitOfMeasurement = item.unitOfMeasurement;
    this.stepToUomDescription = item.stepToUomDescription;
    this.tagOptions = item.tagOptions;
    this.quantity = item.cartQuantity ?? 0;
    this.available = item.available;
    this.unavailableClass = !item.available;

    if (this.isOffer) {
      this.originalPrice = (item as Offer).preOfferPrice ?? 0;
      this.price = item.price ?? 0;
    } else {
      const beginPrice = item.beginPrice ?? 0;
      const finalPrice = item.finalPrice ?? 0;
      const showBeginPrice = beginPrice > finalPrice;
      this.originalPrice = showBeginPrice ? beginPrice : null;
      this.price = finalPrice;
    }
  }

  public onAdd() {
    if (!this.available) return;
    this.add.emit(this.item);
    this.changeDetectorRef.detectChanges();
  }

  public onRemove() {
    if (!this.available) return;
    this.remove.emit(this.item);
    this.changeDetectorRef.detectChanges();
  }
}

import { Directive, OnInit, Input, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[sectionSlice]'
})
export class SectionSliceDirective implements OnInit {
  @Input() private bottom: string;
  @Input() private top: string;
  @Input() private flip = false;
  @Input() private bgColor: string;
  @Input() private sliceShadow = false;
  @Input() private customSliceUrl: string;
  @Input() private shift: string;
  @Input() private size: string;

  private transformStyle: string;
  private urlStyle: string;
  private sizeStyle: string;
  private shiftStyle: string;

  constructor(private element: ElementRef) {}

  @HostBinding('class') public hostClass = 'section-slice';

  ngOnInit(): void {
    this.initializeSliceStyling();
    this.bindStyleVariables();
  }

  private initializeSliceStyling(): void {
    if (this.flip) this.transformStyle = 'scaleY(-1)';
    if (this.customSliceUrl?.length) {
      this.urlStyle = "url('" + this.customSliceUrl + "')";
    } else {
      this.urlStyle = this.sliceShadow
        ? "url('/assets/images/landing/hero/landing-hero-bottom-slice.svg')"
        : "url('/assets/images/sections/section-slice.svg')";
    }
    if (this.size) this.sizeStyle = `auto ${this.size}`;
    this.shiftStyle = this.shift ? `${this.shift} bottom` : 'bottom';
  }

  private bindStyleVariables(): void {
    const elementStyle = (this.element.nativeElement as HTMLElement)?.style;
    if (!elementStyle) return;
    elementStyle.setProperty('position', 'absolute');
    elementStyle.setProperty('left', '0');
    elementStyle.setProperty('height', '10em');
    elementStyle.setProperty('width', '100%');

    // With these properties you can modify the color of an svg that you only have the path to
    elementStyle.setProperty('mask', this.urlStyle);
    elementStyle.setProperty('background-color', this.bgColor);

    elementStyle.setProperty('mask-repeat', 'repeat-x');
    elementStyle.setProperty('top', this.top);
    elementStyle.setProperty('bottom', this.bottom);
    elementStyle.setProperty('transform', this.transformStyle);
    elementStyle.setProperty('mask-position', this.shiftStyle);
    elementStyle.setProperty('mask-size', this.sizeStyle);
  }
}

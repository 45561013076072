import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { numbersOnly } from '@box/utils';

@Directive({
  selector: 'input[matInputPinFormat]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputPinFormatDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputPinFormatDirective),
      multi: true
    }
  ]
})
export class MatInputPinFormatDirective {
  @Input() private pinLength = 4;

  @Input()
  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    return this._value;
  }

  private _value: string;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  private getFormattedString(input: string): string {
    if (!input?.length) return '';
    const clearInput = numbersOnly(input);
    return clearInput.slice(0, this.pinLength);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const formattedValue = this.getFormattedString(value);
    this.elementRef.nativeElement.value = formattedValue;
    this._value = formattedValue;
    this._onChange(formattedValue); // notify Angular Validators
  }

  // ControlValueAccessor API Start

  // eslint-disable-next-line
  _onChange(value: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    const formattedValue = this.getFormattedString(value as string);
    this._value = formattedValue;
    this.elementRef.nativeElement.value = formattedValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    // eslint-disable-line
    this._onChange = fn;
  }

  registerOnTouched(value: any): void {} // eslint-disable-line

  // ControlValueAccessor API End
}

import { Product } from '@box-types';
import { getGreeklish } from 'greek-utilities';
import { cloneDeep } from 'lodash-es';
import { matchesSearchTerm } from '../core';

export {
  getProductSearchTerms,
  getProductCategorySearchTerms,
  decorateProductWithSearchTerms,
  filterProductsBySearchTerms
};

function getProductSearchTerms(product: Product): string {
  const searchProperties: string[] = [];
  if (product.name) searchProperties.push(product.name);
  if (product.info?.el) searchProperties.push(product.info.el);
  return getGreeklish(searchProperties.join(' ')) as string; // eslint-disable-line
}

function getProductCategorySearchTerms(product: Product): string {
  if (!product.category?.name) return '';
  return getGreeklish(product.category.name) as string; // eslint-disable-line
}

function decorateProductWithSearchTerms(product: Product): Product {
  const productSearchTerms = getProductSearchTerms(product);
  const categorySearchTerms = getProductCategorySearchTerms(product);
  return {
    ...product,
    searchTerms: productSearchTerms,
    category: {
      ...product.category,
      searchTerms: categorySearchTerms
    }
  };
}

function filterProductsBySearchTerms(products: Product[], term: string): Product[] {
  const matchedByCategory: Product[] = [];
  const matchedByProduct: Product[] = [];

  for (const product of cloneDeep(products)) {
    if (matchesSearchTerm(product.category, term)) {
      matchedByCategory.push(product);
    } else if (matchesSearchTerm(product, term)) {
      matchedByProduct.push(product);
    }
  }

  return [...matchedByCategory, ...matchedByProduct];
}

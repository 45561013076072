<div class="product-myo-slider-wrapper box-swiper">
  <div [swiper]="swiperOptions" class="product-myo-slider-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let image of images">
        <figure class="product-myo-slider-image">
          <img [src]="image" />
        </figure>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <div class="product-myo-slider-navigation">
    <button #prevBtn mat-icon-button class="box-swiper-button-prev">
      <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
    </button>
    <button #nextBtn mat-icon-button class="box-swiper-button-next">
      <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
    </button>
  </div>
</div>

<ng-container *ngIf="collapsed; else expandedView">
  <div class="collapsed-tile" [style.color]="textColor" [style.background-color]="backGroundColor">
    <mat-icon [@textFadeIn] fontSet="material-icons-round" class="collapsed-tile-icon">expand_more</mat-icon>
    <div [@textFadeIn] class="collapsed-tile-title ods-typography-subtitle-s">{{ collapsedTitle }}</div>
    <points-tag *ngIf="tagOptions" [points]="points" [multiplier]="multiplier" [simplifiedText]="true"></points-tag>
    <div
      [@textFadeIn]
      class="collapsed-tile-image-background"
      [style.background-image]="collapsedBackgroundImage"
    ></div>
  </div>
</ng-container>
<ng-template #expandedView>
  <div
    class="expanded-tile"
    [class]="expandedTileSizeClass"
    [attr.disabled]="disabledAttribute"
    [style.color]="textColor"
    [style.background-color]="backGroundColor"
  >
    <promo-tag class="expanded-tile-tag" *ngIf="tagOptions" [options]="tagOptions"></promo-tag>
    <div [@textFadeIn] class="expanded-tile-title ods-typography-subtitle-s" [ngClass]="titleSizeClass">
      {{ title }}
    </div>
    <div [@textFadeIn] class="expanded-tile-image-background" [style.background-image]="backgroundImage"></div>
  </div>
</ng-template>

import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { generatePointsTagContent, generateSimplifiedPointsTagContent } from '@box/utils';
import { translate } from '@box-core/services/language.service';

export type PointsTagSign = '+' | '-';

@Component({
  selector: 'points-tag',
  templateUrl: './points-tag.component.html',
  styleUrls: ['./points-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointsTagComponent implements OnInit, OnChanges {
  @Input() public points: number;
  @Input() public multiplier: number;
  /*
   * This value is expected to be a boolean and is passed
   * as true when we do not need the content to be like "x6 points"
   * So the simplified text (tagContent) will be "x6"
   */
  @Input() public simplifiedText: boolean;

  public sign: PointsTagSign;
  public tagContent: string;

  @HostBinding('class') public hostClass = 'points-tag';

  ngOnInit(): void {
    this.sign = this.points ? '+' : null;
    this.generateTagContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // use case: checkout-points-dialog component
    if (changes.points || changes.multiplier) this.generateTagContent();
  }

  private generateTagContent(): void {
    this.tagContent = this.simplifiedText
      ? generateSimplifiedPointsTagContent(this.points, this.multiplier)
      : generatePointsTagContent(this.points, this.multiplier, translate);
  }
}

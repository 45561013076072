import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectionStrategy,
  HostListener,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'box-quantity',
  templateUrl: './box-quantity.component.html',
  styleUrls: ['./box-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxQuantityComponent implements OnChanges {
  @Input() public disabled = false;
  @Input() public dense = false;
  @Input() public large = false;
  @Input() public quantity = 0;
  @Input() public maximum = 99;
  @Input() public subtraction = true;

  @Output() private add: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() private remove: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class') public hostClass = 'box-quantity';
  @HostBinding('class.box-quantity-disabled') public hasDisabledClass: boolean;
  @HostBinding('class.box-quantity-dense') public hasDenseClass: boolean;
  @HostBinding('class.box-quantity-full-button') public hasFullButtonClass: boolean;
  @HostBinding('class.box-quantity-large') public hasLargeClass: boolean;

  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue as boolean;
      this.hasDisabledClass = this.disabled;
    }

    if (changes.dense) {
      this.dense = changes.dense.currentValue as boolean;
      this.hasDenseClass = this.dense;
    }

    if (changes.large) {
      this.large = changes.large.currentValue as boolean;
      this.hasLargeClass = this.large;
    }

    if (changes.maximum) {
      this.maximum = changes.maximum.currentValue as number;
      if (this.quantity > this.maximum) this.quantity = this.maximum;
    }

    if (changes.quantity) {
      this.quantity = (changes.quantity.currentValue ?? 0) as number;
      this.hasFullButtonClass = this.hasLargeClass && this.quantity <= 0;
    }
  }

  public onAdd(): void {
    if (this.quantity < this.maximum) this.add.emit();
  }

  public onRemove(): void {
    if (this.quantity > 0) this.remove.emit();
  }
}

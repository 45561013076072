import { Injectable, ElementRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollSpyService {
  public parent: ElementRef<HTMLElement>;
  public scrollSpyElements: HTMLElement[] = [];

  public setParent(parent: ElementRef<HTMLElement>): void {
    if (!parent) return;
    this.parent = parent;
  }

  public clearParent(): void {
    delete this.parent;
  }

  public setScrollSpyElements(elements: HTMLCollection): void {
    if (!elements?.length) return;
    this.scrollSpyElements = Array.from(elements) as HTMLElement[];
  }

  public addScrollSpyElements(elements: HTMLCollection): void {
    if (!elements?.length) return;
    const elementsToAdd: HTMLElement[] = (Array.from(elements) as HTMLElement[]).filter(
      (el) => !this.scrollSpyElements.includes(el)
    );
    this.scrollSpyElements.push(...elementsToAdd);
  }

  public removeScrollSpyElements(elements: HTMLCollection): void {
    if (!elements) return;
    (Array.from(elements) as HTMLElement[]).forEach((el) => {
      const index = this.scrollSpyElements.indexOf(el);
      if (index !== -1) this.scrollSpyElements.splice(index, 1);
    });
  }

  public clearScrollSpyElements(): void {
    this.scrollSpyElements = [] as HTMLElement[];
  }

  public scrollToElementWithId(id: string): void {
    if (!id?.length) return;
    const element = document.getElementById(id);
    if (!element?.offsetTop || !this.parent?.nativeElement) return;
    this.parent.nativeElement.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
  }
}

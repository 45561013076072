import { Injectable, Optional } from '@angular/core';
import { CouponsService } from '@box-core/services/coupons.service';
import { ShopService } from '@box-core/services/shop.service';
import { CheckoutCouponsService } from '@box-checkout/services';
import { RewardsCouponsService } from '@box-rewards/pages/rewards-coupons/rewards-coupons.service';
import { DialogService } from '@box-core/services/dialog.service';
import { Observable, skipWhile, switchMap, of, throwError } from 'rxjs';
import { CouponCodeDialogComponent, CouponRedemptionDialogComponent } from '@box-coupon-widget/components';
import { CheckoutCouponCodeDialogResponse } from '@box-coupon-widget/components/coupon-code-dialog/coupon-code-dialog.types';
import { map, tap, catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '@box-core/services/loader.service';
import {
  isCouponLoyalty,
  isCouponKnown,
  isOrderCoupon,
  isCouponRedeemamble,
  isCouponSelectable,
  currencyFormat,
  CurrencyFormatOptions
} from '@box/utils';
import { APIError, Coupon, CouponCheckOptions } from '@box-types';
import { UserService } from '@box-core/services/user.service';
import { DFY_TYPE_ERROR_DATA } from '@box-checkout/services/checkout-coupons.service';
import { LanguageService } from './language.service';
import { currencyCode } from '@box-core/services/currency.service';
import { CouponTimerService } from './coupon-timer.service';

@Injectable()
export class AddCouponService {
  constructor(
    @Optional() private checkoutCouponsService: CheckoutCouponsService,
    @Optional() private rewardsCouponsService: RewardsCouponsService,
    @Optional() private shopService: ShopService,
    private couponsService: CouponsService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private userService: UserService,
    private languageService: LanguageService,
    private couponTimerService: CouponTimerService
  ) {}

  public initiateAddCouponFlow(startingPrice?: number): void {
    this.openAddCouponDialogAndGetCode()
      .pipe(
        tap(() => this.loaderService.setState(true)),
        skipWhile((code) => this.isCouponAlreadyInWallet(code)),
        switchMap((code: string) => this.addCouponToWalletAndHandleErrors(code)),
        switchMap((coupon: Coupon) => this.redeemCouponIfNeededAndHandleErrors(coupon)),
        switchMap((coupon: Coupon) => this.updateCouponWallet(coupon)),
        tap(() => {
          const availableCoupons = this.couponsService.getAvailableCoupons();
          this.couponTimerService.initialize(availableCoupons);
        }),
        switchMap((coupon: Coupon) => this.findCouponAfterUpdate(coupon)),
        catchError((error: APIError) => {
          this.dialogService.openErrorDialog(error);
          return of(null);
        }),
        finalize(() => this.loaderService.setState(false))
      )
      .subscribe((coupon: Coupon) => {
        this.handleCouponAfterAddFlow(coupon, startingPrice);
      });
  }

  public isCouponAlreadyInWallet(code: string): boolean {
    const coupons = this.couponsService.getAvailableCoupons();
    if (!coupons) return false;
    const isInWallet = coupons.some((coupon) => coupon.code === code);
    if (isInWallet) {
      this.dialogService.openInfoDialog({
        title: 'something_went_wrong',
        messages: ['its_in_your_list_of_active_coupons']
      });
    }
    return isInWallet;
  }

  private addCouponToWalletAndHandleErrors(code: string): Observable<Coupon> {
    return this.couponsService?.addCouponToWallet(code).pipe(
      map((coupon) => {
        if (isCouponKnown(coupon)) return coupon;
        this.dialogService.openInfoDialog({
          title: 'something_went_wrong',
          messages: ['we_dont_recognize_this_coupon']
        });
        return null;
      }),
      catchError((error: APIError) => {
        // in the checkout view we call check coupon since we need to get the coupon model somehow if the coupon is dfy
        const isDFY = this.checkoutCouponsService && error.code === 'DFY_COUPON_CAN_NOT_BE_ADDED_TO_WALLET';
        if (isDFY) return this.checkCoupon(code);
        // in the rewards page we ask the user to go to checkout
        return throwError(() => error);
      })
    );
  }

  private redeemCouponIfNeededAndHandleErrors(coupon: Coupon): Observable<Coupon> {
    if (!coupon || !isCouponRedeemamble(coupon)) return of(coupon);
    return this.redeemCoupon(coupon?.code).pipe(
      map(() => coupon),
      catchError((error: APIError) => {
        /* if the redemption fails for a loyalty coupon, the coupon is still available,
        therefore we can add the coupon locally to our global wallet
        this will result in the header component being updated */
        this.couponsService.addAvailableCoupon(coupon);
        return throwError(() => error);
      })
    );
  }

  private updateCouponWallet(coupon: Coupon): Observable<Coupon> {
    /* loyalty coupons are not shown in the wallet after successful redemption,
    since they become unavailable instantly,
    DFY coupons are not shown in the wallet so there is no need to update our view */
    if (!coupon || isCouponLoyalty(coupon) || coupon.type === 'DFY') return of(coupon);

    // we can add the coupon locally to our global state without calling any service again
    // since there is no need to validate the coupons again
    this.couponsService.addAvailableCoupon(coupon);
    if (this.rewardsCouponsService) return of(coupon);

    if (this.checkoutCouponsService) {
      // in the checkout page we need to revalidate all the coupons through BE before we show them to the user,
      // since the user has selected a shop, specific items and payment methods
      return this.checkoutCouponsService.updateCheckoutCoupons().pipe(map(() => coupon));
    }
  }

  private findCouponAfterUpdate(coupon: Coupon): Observable<Coupon> {
    // we can only have a selected coupon in checkout page
    if (!coupon || !this.checkoutCouponsService || isCouponLoyalty(coupon) || coupon.type === 'DFY') return of(coupon);
    return of(this.checkoutCouponsService.findCouponAfterUpdate(coupon));
  }

  private handleCouponAfterAddFlow(coupon: Coupon, startingPrice: number): void {
    if (!coupon || !isCouponSelectable(coupon)) return this.checkoutCouponsService?.clearCoupon();

    if (isCouponRedeemamble(coupon)) {
      // no need to select this coupon since,
      // it is immediately redeemed in both checkout and rewards page,
      // not attached to an order,
      // and the user has no access to his used coupons from the checkout view
      return void this.showCouponRedemptionSuccessDialog(coupon).subscribe(() => {
        if (isCouponLoyalty(coupon)) this.userService.addPoints(coupon.loyaltyPoints);
      });
    }

    if (this.checkoutCouponsService) {
      if (this.checkoutCouponsService.invalidDFY()) return void this.dialogService.openInfoDialog(DFY_TYPE_ERROR_DATA);

      if (isOrderCoupon(coupon)) {
        // this is handled by BE (we get a disabledText field) in all cases,
        // except for DFY coupons since they don't belong in the wallet,
        // and we don't call the coupons service.
        // dfy coupons are discount percentage,
        // they don't have a minimumPrice but this is put here for futureproofing
        // if this code is removed the user will still be protected by the createOrder BE checks
        const invalidMinimumPrice = coupon.minimumPrice > startingPrice;
        if (invalidMinimumPrice) return this.showMinimumPriceErrorDialog(coupon);

        this.checkoutCouponsService.setCoupon(coupon);
      }
    }
  }

  public checkCoupon(code: string): Observable<Coupon> {
    if (!code?.length) return;
    return this.couponsService.checkCoupon(code, this.getOptions());
  }

  public getOptions(): CouponCheckOptions {
    const defaultOptions = { shop: this.shopService?.shop?.getValue().collectionType };
    const hasDFY: boolean = this.shopService?.cartHasDFYOffer();
    if (!hasDFY) return defaultOptions;
    return { dfyType: 'UNLOCK_OFFER', ...defaultOptions };
  }

  public redeemCoupon(code: string): Observable<Coupon> {
    if (!code?.length) return;
    return this.couponsService.redeemCoupon(code);
  }

  private showCouponRedemptionSuccessDialog(coupon: Coupon): Observable<unknown> {
    return this.dialogService
      .openDialog(CouponRedemptionDialogComponent, {
        panelClass: 'box-dialog-fit-content',
        data: { coupon }
      })
      .afterClosed();
  }

  private showMinimumPriceErrorDialog(coupon: Coupon): void {
    const formatOptions: CurrencyFormatOptions = { currencyCode: currencyCode, symbolSpace: false };
    const minimumPriceText: string = currencyFormat(coupon.minimumPrice, formatOptions);
    const translatedMessage = this.languageService
      .getTextByKey('the_coupon_is_valid_for_over_minimum_price')
      .replace('minimum_price', minimumPriceText);
    return void this.dialogService.openInfoDialog({
      title: 'dont_forget',
      messages: [translatedMessage]
    });
  }

  public openAddCouponDialogAndGetCode(): Observable<string> {
    return this.dialogService
      .openDialog(CouponCodeDialogComponent, {
        panelClass: 'box-dialog-fit-content'
      })
      .afterClosed()
      .pipe(
        skipWhile((response: CheckoutCouponCodeDialogResponse) => !response?.code?.length),
        map((response: CheckoutCouponCodeDialogResponse) => response.code)
      );
  }
}

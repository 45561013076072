import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HomeSectionInfoDialogData } from './home-section-info-dialog.component.types';
import { getHomeSectionInfoHtml } from '@box/utils';

@Component({
  selector: 'home-section-info-dialog',
  templateUrl: './home-section-info-dialog.component.html',
  styleUrls: ['./home-section-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeSectionInfoDialogComponent implements OnInit {
  public title: string;
  public htmlContent: string;

  constructor(
    private dialogRef: MatDialogRef<HomeSectionInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: HomeSectionInfoDialogData
  ) {}

  ngOnInit(): void {
    const homeSection = this.data.homeSection;
    this.title = homeSection.title;
    this.htmlContent = getHomeSectionInfoHtml(homeSection);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

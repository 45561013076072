<div class="loader-wrapper">
  <div class="loader-content">
    <div class="loader-circle"></div>
    <div class="loader-line-mask">
      <div class="loader-line"></div>
    </div>
    <figure class="image contain-image loader-logo">
      <img src="/assets/images/logo/box-logo.svg" alt="BOX Loader" />
    </figure>
  </div>
</div>

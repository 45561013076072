import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Review } from '@box-types';
import { shouldShowShopReviews } from '@box/utils';

@Component({
  selector: 'shop-page-reviews',
  templateUrl: './shop-page-reviews.component.html',
  styleUrls: ['./shop-page-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopPageReviewsComponent implements OnChanges {
  @Input() public reviews: Review[];

  public showReviews: boolean;

  @HostBinding('class') public hostClass = 'shop-page-reviews';

  ngOnChanges(changes: SimpleChanges): void {
    this.reviews = changes.reviews.currentValue as Review[];
    this.showReviews = shouldShowShopReviews(this.reviews);
  }
}

import { DayOfTheWeek, PromoBanner } from '@box-types';
import dayjs from 'dayjs';
import orderBy from 'lodash-es/orderBy';
import { isCurrentTimeInTimeRange } from '../core';

export const weekDays: DayOfTheWeek[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function normalizePromoBanner(promoBanner: PromoBanner): PromoBanner {
  return {
    ...promoBanner,
    available: promoBanner.available ?? true,
    enabled: promoBanner.enabled ?? true,
    enabledOnWeekDays: promoBanner.enabledOnWeekDays ?? weekDays,
    isPromoted: promoBanner.isPromoted ?? false,
    isLandingPageOnly: promoBanner.isLandingPageOnly ?? false,
    opensAutomatically: promoBanner.opensAutomatically ?? false,
    detailsTitle: promoBanner.detailsTitle ?? '',
    segments: promoBanner.segments ?? [],
    notEligibleForSegments: promoBanner.notEligibleForSegments ?? [],
    areas: promoBanner.areas ?? [],
    shops: promoBanner.shops ?? [],
    ineligibleShops: promoBanner.ineligibleShops ?? [],
    cuisines: promoBanner.cuisines ?? [],
    primaryOrSecondaryCuisines: promoBanner.primaryOrSecondaryCuisines ?? [],
    offerKinds: promoBanner.offerKinds ?? [],
    chains: promoBanner.chains ?? [],
    shopCampaigns: promoBanner.shopCampaigns ?? [],
    visibleOnlyWhenShopsFound: promoBanner.visibleOnlyWhenShopsFound ?? false,
    position: promoBanner.position ?? 0,
    displayDaasShops: promoBanner.displayDaasShops ?? false,
    limitResultsToDaas: promoBanner.limitResultsToDaas ?? false,
    eligibleCouponSynergies: promoBanner.eligibleCouponSynergies ?? [],
    notEligibleCouponSynergies: promoBanner.notEligibleCouponSynergies ?? []
  };
}

function getPromoBannerPosition(promoBanner: PromoBanner): number {
  if (!promoBanner.sortingPerDay?.length) return promoBanner.position;
  const currentDay = dayjs().locale('en').format('dddd');
  const currentSorting = promoBanner.sortingPerDay.find((sorting) => sorting.day === currentDay);
  if (!currentSorting) return promoBanner.position;
  const currentTimeRange = currentSorting.timeRanges.find((timeRange) => isCurrentTimeInTimeRange(timeRange));
  if (!currentTimeRange) return promoBanner.position;
  return currentTimeRange.position;
}

function sortPromoBanners(promoBanners: PromoBanner[]): PromoBanner[] {
  if (!promoBanners?.length) return [];
  return orderBy(promoBanners, (promoBanner) => getPromoBannerPosition(promoBanner), 'asc');
}

export { normalizePromoBanner, getPromoBannerPosition, sortPromoBanners };

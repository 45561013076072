<section *ngIf="templateHtml" class="section">
  <div class="container">
    <!-- We use h2 elements but with the h3 CSS, because in the design we need the h3 styling but the h2 tag is required for SEO purposes -->
    <h2 class="shop-extra-info-title">{{ shop.name }} - {{ 'info_for_shop' | translate }}</h2>
    <div class="shop-extra-info-description" [innerHTML]="templateHtml"></div>
  </div>
</section>
<section *ngIf="shopsWithChainAnchors?.length > 0" class="section">
  <div class="container">
    <delivery-anchors
      [title]="'other_stores' | translate: { _SHOP_NAME: shop.name }"
      [anchors]="shopsWithChainAnchors"
    ></delivery-anchors>
  </div>
</section>
<section *ngIf="extraInfoLocationData?.shopsAnchors?.length > 0" class="section">
  <div class="container">
    <delivery-anchors
      [title]="extraInfoLocationData.cuisine?.name + ' ' + ('delivery_in_area' | translate) + ' ' + shop?.locationName"
      [anchors]="extraInfoLocationData?.shopsAnchors"
    ></delivery-anchors>
  </div>
</section>
<section *ngIf="extraInfoLocationData?.cuisineAnchors?.length > 0" class="section">
  <div class="container">
    <delivery-anchors
      [title]="('looking_for_more_shops_in_the_area' | translate) + ' ' + shop?.locationName"
      [anchors]="extraInfoLocationData?.cuisineAnchors"
    ></delivery-anchors>
  </div>
</section>

<button
  box-button
  size="small"
  theme="insignificant"
  borders="none"
  class="informational-message-button"
  (click)="onShowInfo()"
>
  <div class="informational-message-wrapper">
    <svg
      *ngIf="iconPosition === 'LEFT'"
      class="informational-message-icon informational-message-icon-left"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05065 11.3333C8.1951 11.3333 8.31454 11.2861 8.40898 11.1916C8.50343 11.0972 8.55065 10.9778 8.55065 10.8333V7.81665C8.55065 7.68331 8.50065 7.56942 8.40065 7.47498C8.30065 7.38054 8.18398 7.33331 8.05065 7.33331C7.90621 7.33331 7.78676 7.38054 7.69232 7.47498C7.59787 7.56942 7.55065 7.68887 7.55065 7.83331V10.85C7.55065 10.9833 7.60065 11.0972 7.70065 11.1916C7.80065 11.2861 7.91732 11.3333 8.05065 11.3333ZM8.00065 6.09998C8.15621 6.09998 8.28676 6.04998 8.39232 5.94998C8.49787 5.84998 8.55065 5.7222 8.55065 5.56665C8.55065 5.41109 8.49787 5.27776 8.39232 5.16665C8.28676 5.05554 8.15621 4.99998 8.00065 4.99998C7.8451 4.99998 7.71454 5.05554 7.60898 5.16665C7.50343 5.27776 7.45065 5.41109 7.45065 5.56665C7.45065 5.7222 7.50343 5.84998 7.60898 5.94998C7.71454 6.04998 7.8451 6.09998 8.00065 6.09998ZM8.00065 14.6666C7.05621 14.6666 6.17843 14.4972 5.36732 14.1583C4.55621 13.8194 3.85065 13.35 3.25065 12.75C2.65065 12.15 2.18121 11.4444 1.84232 10.6333C1.50343 9.8222 1.33398 8.94442 1.33398 7.99998C1.33398 7.06665 1.50343 6.19442 1.84232 5.38331C2.18121 4.5722 2.65065 3.86665 3.25065 3.26665C3.85065 2.66665 4.55621 2.19442 5.36732 1.84998C6.17843 1.50554 7.05621 1.33331 8.00065 1.33331C8.93398 1.33331 9.80621 1.50554 10.6173 1.84998C11.4284 2.19442 12.134 2.66665 12.734 3.26665C13.334 3.86665 13.8062 4.5722 14.1507 5.38331C14.4951 6.19442 14.6673 7.06665 14.6673 7.99998C14.6673 8.94442 14.4951 9.8222 14.1507 10.6333C13.8062 11.4444 13.334 12.15 12.734 12.75C12.134 13.35 11.4284 13.8194 10.6173 14.1583C9.80621 14.4972 8.93398 14.6666 8.00065 14.6666ZM8.00065 13.6666C9.55621 13.6666 10.8895 13.1111 12.0007 12C13.1118 10.8889 13.6673 9.55554 13.6673 7.99998C13.6673 6.44442 13.1118 5.11109 12.0007 3.99998C10.8895 2.88887 9.55621 2.33331 8.00065 2.33331C6.4451 2.33331 5.11176 2.88887 4.00065 3.99998C2.88954 5.11109 2.33398 6.44442 2.33398 7.99998C2.33398 9.55554 2.88954 10.8889 4.00065 12C5.11176 13.1111 6.4451 13.6666 8.00065 13.6666Z"
      />
    </svg>
    <span class="informational-message-text ods-typography-body-s-regular">
      <ng-content></ng-content>
    </span>
    <svg
      *ngIf="iconPosition === 'RIGHT'"
      class="informational-message-icon informational-message-icon-right"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05065 11.3333C8.1951 11.3333 8.31454 11.2861 8.40898 11.1916C8.50343 11.0972 8.55065 10.9778 8.55065 10.8333V7.81665C8.55065 7.68331 8.50065 7.56942 8.40065 7.47498C8.30065 7.38054 8.18398 7.33331 8.05065 7.33331C7.90621 7.33331 7.78676 7.38054 7.69232 7.47498C7.59787 7.56942 7.55065 7.68887 7.55065 7.83331V10.85C7.55065 10.9833 7.60065 11.0972 7.70065 11.1916C7.80065 11.2861 7.91732 11.3333 8.05065 11.3333ZM8.00065 6.09998C8.15621 6.09998 8.28676 6.04998 8.39232 5.94998C8.49787 5.84998 8.55065 5.7222 8.55065 5.56665C8.55065 5.41109 8.49787 5.27776 8.39232 5.16665C8.28676 5.05554 8.15621 4.99998 8.00065 4.99998C7.8451 4.99998 7.71454 5.05554 7.60898 5.16665C7.50343 5.27776 7.45065 5.41109 7.45065 5.56665C7.45065 5.7222 7.50343 5.84998 7.60898 5.94998C7.71454 6.04998 7.8451 6.09998 8.00065 6.09998ZM8.00065 14.6666C7.05621 14.6666 6.17843 14.4972 5.36732 14.1583C4.55621 13.8194 3.85065 13.35 3.25065 12.75C2.65065 12.15 2.18121 11.4444 1.84232 10.6333C1.50343 9.8222 1.33398 8.94442 1.33398 7.99998C1.33398 7.06665 1.50343 6.19442 1.84232 5.38331C2.18121 4.5722 2.65065 3.86665 3.25065 3.26665C3.85065 2.66665 4.55621 2.19442 5.36732 1.84998C6.17843 1.50554 7.05621 1.33331 8.00065 1.33331C8.93398 1.33331 9.80621 1.50554 10.6173 1.84998C11.4284 2.19442 12.134 2.66665 12.734 3.26665C13.334 3.86665 13.8062 4.5722 14.1507 5.38331C14.4951 6.19442 14.6673 7.06665 14.6673 7.99998C14.6673 8.94442 14.4951 9.8222 14.1507 10.6333C13.8062 11.4444 13.334 12.15 12.734 12.75C12.134 13.35 11.4284 13.8194 10.6173 14.1583C9.80621 14.4972 8.93398 14.6666 8.00065 14.6666ZM8.00065 13.6666C9.55621 13.6666 10.8895 13.1111 12.0007 12C13.1118 10.8889 13.6673 9.55554 13.6673 7.99998C13.6673 6.44442 13.1118 5.11109 12.0007 3.99998C10.8895 2.88887 9.55621 2.33331 8.00065 2.33331C6.4451 2.33331 5.11176 2.88887 4.00065 3.99998C2.88954 5.11109 2.33398 6.44442 2.33398 7.99998C2.33398 9.55554 2.88954 10.8889 4.00065 12C5.11176 13.1111 6.4451 13.6666 8.00065 13.6666Z"
      />
    </svg>
  </div>
</button>

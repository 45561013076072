import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@box-shared/shared.module';

import * as Components from './components';

@NgModule({
  declarations: [
    Components.RewardGBComponent,
    Components.RewardsBalanceBadgeComponent,
    Components.RewardsHistoryEarningsComponent,
    Components.RewardsPreviewOverlayComponent,
    Components.RewardsPromoBannerComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    Components.RewardGBComponent,
    Components.RewardsBalanceBadgeComponent,
    Components.RewardsHistoryEarningsComponent,
    Components.RewardsPreviewOverlayComponent,
    Components.RewardsPromoBannerComponent
  ]
})
export class RewardsWidgetModule {}

import { Component, OnInit, Inject, Renderer2, ChangeDetectionStrategy } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BankPointsRedeemVerificationAlertResponse } from '@box-checkout/components/bank-points-redeem-verification-alert/bank-points-redeem-verification-alert-response.interface';
import { BankLoyaltyVerificationAlert } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'bank-points-redeem-verification-alert',
  templateUrl: './bank-points-redeem-verification-alert.component.html',
  styleUrls: ['./bank-points-redeem-verification-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankPointsRedeemVerificationAlertComponent extends BoxDialogWrapperComponent implements OnInit {
  public contentColor: string;
  public title: string;
  public description: string;
  public backgroundColor: string;
  public imageUrl: string;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<BankPointsRedeemVerificationAlertComponent>,
    @Inject(MAT_DIALOG_DATA) private data: BankLoyaltyVerificationAlert
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.contentColor = this.data.contentColor;
    this.title = this.data.title;
    this.description = this.data.description;
    this.backgroundColor = this.data.backgroundColor;
    this.imageUrl = generateImageSrc(this.data.image);
  }

  onVerifySelect(): void {
    this.dialogRef.close({ action: 'VERIFY' } as BankPointsRedeemVerificationAlertResponse);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

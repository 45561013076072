import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnBoardingDialogData } from '@box-types';

@Component({
  selector: 'on-boarding-dialog',
  templateUrl: './on-boarding-dialog.component.html',
  styleUrls: ['./on-boarding-dialog.component.scss']
})
export class OnBoardingDialogComponent implements OnInit {
  public campaignPoints: number;

  constructor(
    private dialogRef: MatDialogRef<OnBoardingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: OnBoardingDialogData
  ) {}

  ngOnInit(): void {
    this.campaignPoints = this.data.eventPoints;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}

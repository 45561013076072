<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody class="box-dialog-content">
    <section class="suggestion-banner-info-dialog-section">
      <div class="suggestion-banner-info-dialog-description" [innerHTML]="description | safeHtml"></div>
    </section>
  </main>
</div>

<div class="shop-menu-nav-wrapper">
  <ul *ngIf="menuNav?.items" class="shop-menu-nav-list">
    <li *ngFor="let item of menuNav.items; trackBy: trackByActive">
      <shop-menu-nav-item
        [item]="item"
        [disableToggle]="!menuNav.hasSubItems"
        (itemClick)="onItemClick($event)"
      ></shop-menu-nav-item>
    </li>
  </ul>
</div>

import { Product, ProductAvailabilityOptions } from '@box-types';
import { transferOptionsSelectedToChoices } from './product-selection.utils';

export { decorateProductWithPseudoId, getProductAvailabilityOptions, copyProductPropertiesAfterAvailabilityCheck };

function decorateProductWithPseudoId(product: Product, index: number): Product {
  return { ...product, pseudoProductId: `${product._id}_${index}` };
}

function getProductAvailabilityOptions(product: Product): ProductAvailabilityOptions {
  return {
    productId: product._id,
    name: product.name,
    selections: product.selections ?? [],
    ...(product.pseudoProductId && { pseudoProductId: product.pseudoProductId }),
    ...(product.quantity && { quantity: product.quantity }),
    ...(product.comments && { comments: product.comments })
  };
}

function copyProductPropertiesAfterAvailabilityCheck(product: Product, options: ProductAvailabilityOptions): Product {
  if (!options) return product;
  const selections = transferOptionsSelectedToChoices(product);
  return {
    ...product,
    ...(selections.length && { selections }),
    ...(options.quantity && { quantity: options.quantity }),
    ...(options.comments && { comments: options.comments })
  };
}

import { Injectable } from '@angular/core';
import { NotificationAlert } from '@box-types';
import { InfoNotificationWidgetData } from '@box-shared/components/info-notification-wrapper/info-notification-wrapper.types';
import { InfoNotificationWrapperComponent } from '@box-shared/components';
import { NotificationsService } from '@box-core/services/notifications.service';
import { NotificationRef } from '@box-core/components';

@Injectable({ providedIn: 'root' })
export class UrgentConditionNotificationService {
  private homePageNotificationAlert: NotificationAlert;
  private shopPageNotificationAlert: NotificationAlert;
  private homePageNotificationRef: NotificationRef;
  private shopPageNotificationRef: NotificationRef;

  constructor(private notificationsService: NotificationsService) {}

  public setHomePageNotificationAlert(alert: NotificationAlert): void {
    if (!this.isNotificationAlertEligible(alert)) return;
    this.homePageNotificationAlert = alert;
  }

  public getHomePageNotificationAlert(): NotificationAlert {
    return this.homePageNotificationAlert;
  }

  public setShopPageNotificationAlert(alert: NotificationAlert): void {
    if (!this.isNotificationAlertEligible(alert)) return;
    this.shopPageNotificationAlert = alert;
  }

  public getShopPageNotificationAlert(): NotificationAlert {
    return this.shopPageNotificationAlert;
  }

  public getHomePageNotificationRef(): NotificationRef {
    return this.homePageNotificationRef;
  }

  public getShopPageNotificationRef(): NotificationRef {
    return this.shopPageNotificationRef;
  }

  private isNotificationAlertEligible(alert: NotificationAlert): boolean {
    if (!alert?.title && !alert?.message) return false;
    return true;
  }

  public removeHomeNotification(): void {
    if (!this.homePageNotificationRef) return;
    this.notificationsService.removeNotification(this.homePageNotificationRef);
  }

  public removeShopNotification(): void {
    if (!this.shopPageNotificationRef) return;
    this.notificationsService.removeNotification(this.shopPageNotificationRef);
  }

  public showUrgentConditionsNotification(type: 'Home' | 'Shop'): NotificationRef {
    const notificationAlert =
      type === 'Home' ? this.getHomePageNotificationAlert() : this.getShopPageNotificationAlert();
    if (!notificationAlert) return;

    const notificationData: InfoNotificationWidgetData = {
      title: notificationAlert.title,
      message: notificationAlert.message
    };
    const notificationRef = this.notificationsService.addNotification(InfoNotificationWrapperComponent, {
      data: notificationData
    });
    if (type === 'Home') {
      this.homePageNotificationRef = notificationRef;
    } else {
      this.shopPageNotificationRef = notificationRef;
    }
    return notificationRef;
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const PopIn = trigger('popIn', [
  state('void', style({ transform: 'scale(0)' })),
  transition('void => *', [
    animate('350ms cubic-bezier(0.4, 0, 1, 1)'),
    style({ transform: 'scale(1.2)' }),
    animate('200ms cubic-bezier(0, 0, 0.2, 1)'),
    style({ transform: 'scale(1)' })
  ])
]);

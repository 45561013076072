import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { SentryService, ShopsService } from '@box-core/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { setPrerenderReady, setRedirectPrerenderMetaElements } from '@box/utils';

const ERROR_REDIRECT_PATH = '/';

@Injectable({ providedIn: 'root' })
export class ShopRedirectGuard implements CanActivate {
  /*
  This is a redirection guard to handle the old Shop Page url
  redirection after the SEO changes.
  The new Shop Page URL is /delivery/:locationKey/:vanityUrl
  */
  constructor(private router: Router, private shopsService: ShopsService, private sentryService: SentryService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    setPrerenderReady(false);
    const oldVanityUrl = route.params.oldVanityUrl as string;
    return this.shopsService.fetchShopByOldVanityUrl(oldVanityUrl).pipe(
      map((shop) => {
        const { locationKey, vanity_url } = shop;
        if (!locationKey || !vanity_url) throw new Error('Missing Shop Redirection Data');

        const redirectCommands = this.getRedirectCommands(route.url, locationKey, vanity_url);
        this.set301RedirectRule(redirectCommands);
        void this.router.navigate(redirectCommands);
        return false;
      }),
      catchError((error: Error): Observable<boolean> => {
        this.sentryService.captureException(error, {
          domain: 'Delivery',
          domainDetails: 'Shop Redirect Guard',
          severity: 'info'
        });
        const redirectCommands = this.getRedirectCommands();
        this.set301RedirectRule(redirectCommands);
        void this.router.navigate(redirectCommands);
        return of(false);
      })
    );
  }

  private getRedirectCommands(segments?: UrlSegment[], locationKey?: string, vanity_url?: string): string[] {
    if (!segments?.length || !locationKey || !vanity_url) return [ERROR_REDIRECT_PATH];
    if (segments.length >= 2) return ['/delivery', locationKey, vanity_url];
    return [ERROR_REDIRECT_PATH];
  }

  private set301RedirectRule(redirectCommands: string[]): void {
    const url = window.location.origin + (redirectCommands?.join() ?? '');
    setRedirectPrerenderMetaElements(url);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { A11y, Mousewheel, Navigation, Pagination } from 'swiper';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'product-myo-slider',
  templateUrl: './product-myo-slider.component.html',
  styleUrls: ['./product-myo-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYOSliderComponent implements OnChanges {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;

  @Input() public images: string[];

  public showSlices: boolean;
  public swiperOptions: SwiperOptions;

  @HostBinding('class') public hostClass = 'product-myo-slider';

  ngOnChanges(changes: SimpleChanges): void {
    this.images = changes.images.currentValue as string[];
    this.setSwiperOptions();
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Pagination, Mousewheel, A11y],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      a11y: {
        prevSlideMessage: 'Next Image',
        nextSlideMessage: 'Previous Image',
        firstSlideMessage: 'First Image',
        lastSlideMessage: 'last Image',
        paginationBulletMessage: 'Go to image {{index}}'
      },
      pagination: { el: '.swiper-pagination', type: 'bullets', clickable: true },
      slidesPerView: 'auto',
      grabCursor: true
    };
  }
}

<div class="coupon-container">
  <div class="coupon-price">
    <div class="coupon-price-cut"></div>
    <span class="ods-typography-button-m">{{ price }}</span>
    <div class="coupon-separator"></div>
  </div>
  <div class="coupon-code">
    <span class="ods-typography-button-m">{{ code }}</span>
    <div class="coupon-code-cut"></div>
  </div>
</div>

import {
  ChainTile,
  Cuisine,
  DiscoverFilter,
  Shop,
  PromoBanner,
  CollapsibleTile,
  GAEcommerceItemConfig,
  GAPromotionConfig
} from '@box-types';
import { HomeSection } from './home.types';

export {
  createCollectionCarouselImpressionItems,
  getCollectionCarouselImpressionGAConfig,
  createCollectionOffersImpressionItems,
  getCollectionOffersImpressionGAConfig,
  createHomeCuisinesImpressionItems,
  getCollectionHomeCuisinesImpressionGAConfig,
  createCollectionHomeShopsImpressionItems,
  getCollectionHomeShopsImpressionGAConfig,
  createCollectionHomeChainsImpressionItems,
  getCollectionHomeChainsImpressionGAConfig
};

function createCollectionCarouselImpressionItems(
  itemListName: string,
  promoBanners: PromoBanner[],
  selectedPromoBanner?: PromoBanner
): GAEcommerceItemConfig[] {
  return promoBanners.reduce((itemsArray: GAEcommerceItemConfig[], promoBanner, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: promoBanner.slug,
      item_name: '',
      index: index + 1,
      item_list_id: '',
      item_list_name: itemListName
    };
    if (item.item_id === selectedPromoBanner.slug) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionCarouselImpressionGAConfig(
  creativeSlot: string,
  itemListName: string,
  promoBanners: PromoBanner[],
  selectedPromoBanner?: PromoBanner
): GAPromotionConfig {
  if (!promoBanners?.length) return;
  const promotionItems = createCollectionCarouselImpressionItems(itemListName, promoBanners, selectedPromoBanner);
  return {
    creative_name: selectedPromoBanner.slug,
    creative_slot: creativeSlot,
    promotion_id: '',
    promotion_name: selectedPromoBanner.title,
    items: promotionItems
  };
}

function createCollectionOffersImpressionItems(
  itemListName: string,
  filters: DiscoverFilter[],
  selectedFilter?: DiscoverFilter
): GAEcommerceItemConfig[] {
  return filters.reduce((itemsArray: GAEcommerceItemConfig[], filter, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: filter.slug,
      item_name: '',
      index: index + 1,
      item_list_id: '',
      item_list_name: itemListName
    };
    if (item.item_id === selectedFilter.slug) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionOffersImpressionGAConfig(
  creativeSlot: string,
  promotionName: string,
  filters: DiscoverFilter[],
  selectedFilter?: DiscoverFilter
): GAPromotionConfig {
  if (!filters?.length) return;
  const promotionItems = createCollectionOffersImpressionItems('offers_for_you', filters, selectedFilter);
  return {
    creative_name: selectedFilter.slug,
    creative_slot: creativeSlot,
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

function createHomeCuisinesImpressionItems(
  itemListName: string,
  cuisines: CollapsibleTile[],
  selectedCuisine?: Cuisine
): GAEcommerceItemConfig[] {
  return cuisines.reduce((itemsArray: GAEcommerceItemConfig[], cuisine, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: cuisine.key,
      item_name: '',
      index: index + 1,
      item_list_id: '',
      item_list_name: itemListName
    };
    if (item.item_id === selectedCuisine.key) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionHomeCuisinesImpressionGAConfig(
  homeSection: HomeSection,
  cuisines: CollapsibleTile[],
  selectedCuisine?: Cuisine
): GAPromotionConfig {
  if (!cuisines?.length) return;
  const promotionName = homeSection.title;
  const itemListName = homeSection.slug;
  const promotionItems = createHomeCuisinesImpressionItems(itemListName, cuisines, selectedCuisine);
  return {
    creative_name: selectedCuisine.name,
    creative_slot: 'discover_home',
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

function createCollectionHomeShopsImpressionItems(
  itemListName: string,
  shops: Shop[],
  selectedShop: Shop
): GAEcommerceItemConfig[] {
  return shops.reduce((itemsArray: GAEcommerceItemConfig[], shop, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: shop._id,
      item_name: shop.name,
      affiliation: shop.promo.firstGroupLongOptions?.text ?? '',
      affiliation2: shop.promo.secondGroupLongOptions?.text ?? '',
      index: index + 1,
      item_category: shop.mainCuisine?.name ?? '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName,
      item_variant: ''
    };
    if (item.item_id === selectedShop._id) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionHomeShopsImpressionGAConfig(
  homeSection: HomeSection,
  shops?: Shop[],
  selectedShop?: Shop
): GAPromotionConfig {
  if (!shops?.length) return;
  const promotionName = homeSection.title;
  const itemListName = homeSection.slug;
  const promotionItems = createCollectionHomeShopsImpressionItems(itemListName, shops, selectedShop);
  return {
    creative_name: '',
    creative_slot: 'discover_home',
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

function createCollectionHomeChainsImpressionItems(
  itemListName: string,
  chains: ChainTile[],
  selectedChain: ChainTile
): GAEcommerceItemConfig[] {
  return chains.reduce((itemsArray: GAEcommerceItemConfig[], chain, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: chain.shop._id,
      item_name: chain.shop.name,
      affiliation: chain.shop.promo.firstGroupLongOptions?.text ?? '',
      affiliation2: chain.shop.promo.secondGroupLongOptions?.text ?? '',
      index: index + 1,
      item_category: chain.shop.mainCuisine?.name ?? '',
      item_category2: chain.shop.secondaryCuisines[0]?.name ?? '',
      item_category3: chain.shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName,
      item_variant: ''
    };
    if (item.item_id === selectedChain.shop._id) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionHomeChainsImpressionGAConfig(
  homeSection: HomeSection,
  chains: ChainTile[],
  selectedChain: ChainTile
): GAPromotionConfig {
  if (!chains?.length) return;
  const promotionName = homeSection.title;
  const itemListName = homeSection.slug;
  const promotionItems = createCollectionHomeChainsImpressionItems(itemListName, chains, selectedChain);
  return {
    creative_name: '',
    creative_slot: 'discover_home',
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

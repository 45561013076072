import { Injectable } from '@angular/core';
import {
  ConfigurationService,
  CartService,
  CosmoteIDService,
  DialogService,
  LoaderService,
  MarketLoyaltyService,
  SEOService,
  ShopService,
  UserService,
  NotificationsService,
  AuthenticationService
} from '@box-core/services';
import {
  Category,
  GenerateCategoriesConfig,
  Order,
  Product,
  Shop,
  ShopItems,
  Offer,
  InfoDialogResponse
} from '@box-types';
import {
  normalizeCategories,
  generateDefaultExtraCategories,
  getDefaultExtraCategory,
  generateImageSrc,
  supermarketHasOffers,
  decorateOfferWithMaxItemsQuantity,
  decorateProductWithMaxItemsQuantity,
  getShopHeroBackgroundColor,
  getShopHeroBackgroundImageUrl,
  getSuggestedProducts,
  sortCategories,
  initJsonLd,
  getAggresiveRatingObject,
  getJsonShopType,
  getCartCollectionFromStorage,
  removeCartCollectionFromStorage,
  isPrerenderBrowser
} from '@box/utils';
import orderBy from 'lodash-es/orderBy';
import { Router } from '@angular/router';
import { ShopMenuAvailabilityService } from '@box-delivery/services';
import { finalize, iif, map, Observable, of, mapTo } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PhoneVerificationDialogComponent, BoxInfoDialogComponent } from '@box-shared/components';
import { PhoneVerificationDialogResponse } from '@box-shared/components/phone-verification-dialog/phone-verification-dialog.types';
import { MatDialogConfig } from '@angular/material/dialog';
import { tap, switchMap, skipWhile } from 'rxjs/operators';
import { InfoNotificationWidgetData } from '@box-shared/components/info-notification-wrapper/info-notification-wrapper.types';
import { InfoNotificationWrapperComponent } from '@box-core/components';
import { LanguageService } from '@box-core/services/language.service';

const MOBILE_BREAKPOINT = '(max-width: 512px)';
// TODO @faropoulos @vasiliadis this Service should be accessible from Shop Page only
// Needs refactor to achieve that
@Injectable()
export class ShopPageService {
  private extraCategories: Category[];

  constructor(
    private configService: ConfigurationService,
    private shopService: ShopService,
    private loaderService: LoaderService,
    private router: Router,
    private seoService: SEOService,
    private shopMenuAvailabilityService: ShopMenuAvailabilityService,
    private breakpointObserver: BreakpointObserver,
    private marketLoyaltyService: MarketLoyaltyService,
    private userService: UserService,
    private dialogService: DialogService,
    private cosmoteIDService: CosmoteIDService,
    private cartService: CartService,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService
  ) {
    this.extraCategories = this.generateDefaultExtraCategories();
  }

  public setMetaTags(shop: Shop): void {
    const t = this.languageService.getTextByKey.bind(this.languageService);
    const { logo, name } = shop;
    const img = generateImageSrc(logo, '@3x');
    const title = `${name} - ${t('online_delivery')}`;
    const url = `/delivery/${shop.locationKey}/${shop.vanity_url}`;
    const options = { title, url, img };
    this.seoService.setTags(options);
  }

  public getShopHeroBackgroundColor(shop: Shop): string {
    return getShopHeroBackgroundColor(shop);
  }

  public getShopHeroBackgroundImageStyle(shop: Shop): string {
    const isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    const url = getShopHeroBackgroundImageUrl(shop, isSmallScreen);
    if (!url) return;
    return `url('${url}')`;
  }

  public getDefaultExtraCategories(): Category[] {
    return this.extraCategories;
  }

  public generateShopMenuOffers(shop: Shop, items: ShopItems): Offer[] {
    if (!items) return [];
    const { offers } = items;
    if (!shop || shop.promo?.hideOffers || !offers?.length) return [];
    const suggestedProducts = getSuggestedProducts(items.products, items.usersFrequentPlates);
    const suggestedProductsIds = suggestedProducts.map((p) => p._id);
    const decoratedOffers = offers.map((o) => {
      const decoratedOffer = this.shopService.offerDecorator(o, suggestedProductsIds);
      return decorateOfferWithMaxItemsQuantity(decoratedOffer, shop);
    });
    return orderBy(decoratedOffers, 'offerIndex', 'asc');
  }

  public generateShopMenuProducts(shop: Shop, items: ShopItems): Product[] {
    if (!shop || !items?.products?.length) return [];
    const suggestedProducts = getSuggestedProducts(items.products, items.usersFrequentPlates);
    const suggestedProductsIds = suggestedProducts.map((product) => product._id);
    const decoratedProducts = items.products.map((p) => {
      const decoratedProduct = this.shopService.decorateProduct(p, suggestedProductsIds);
      return decorateProductWithMaxItemsQuantity(decoratedProduct, shop);
    });
    return orderBy(decoratedProducts, 'productIndex', 'asc');
  }

  private generateCategories(shop: Shop, items: ShopItems, orders: Order[]): Category[] {
    if (!shop || !items) return [];
    const { products, offers, categories } = items;
    const normalizedCategories = normalizeCategories(shop, categories, products);
    const config: GenerateCategoriesConfig = { shop, products, offers, categories: normalizedCategories, orders };
    if (shop.categoryView) return this.generateSuperMarketCategories(config);
    return this.generateShopCategories(config);
  }

  public generateShopPageItems(shop: Shop, items: ShopItems, orders: Order[]): ShopItems {
    const products = this.generateShopMenuProducts(shop, items);
    const offers = this.generateShopMenuOffers(shop, items);
    const updatedItems: ShopItems = {
      products,
      offers,
      categories: items.categories,
      usersFrequentPlates: items.usersFrequentPlates
    };
    const categories = this.generateCategories(shop, updatedItems, orders);
    return { products, offers, categories, usersFrequentPlates: items.usersFrequentPlates, tags: items.tags };
  }

  private generateDefaultExtraCategories(): Category[] {
    const boxConfig = this.configService.getConfiguration();
    const suggestionsCategoryIndex = boxConfig?.forYouShopCategoryPosition;
    return generateDefaultExtraCategories(suggestionsCategoryIndex);
  }

  private generateShopCategories(config: GenerateCategoriesConfig): Category[] {
    const { products, offers, categories, orders } = config;
    const extraCategories = this.extraCategories;
    const additionalCategories: Category[] = [];
    const shouldAddReminders = this.shouldAddRemindersCategory();
    const shouldAddOffers = offers.length > 0;
    const shouldAddOrders = orders.length > 0;
    const shouldAddForYou = products.some((product) => product.suggested);
    if (shouldAddReminders) additionalCategories.push(getDefaultExtraCategory(extraCategories, 'reminder_category'));
    if (shouldAddOrders) additionalCategories.push(getDefaultExtraCategory(extraCategories, 'orders_category'));
    if (shouldAddForYou) additionalCategories.push(getDefaultExtraCategory(extraCategories, 'suggestions_category'));
    if (shouldAddOffers) additionalCategories.push(getDefaultExtraCategory(extraCategories, 'client_offers_category'));
    return orderBy([...additionalCategories, ...categories], 'categoryIndex', 'asc');
  }

  private generateSuperMarketCategories(config: GenerateCategoriesConfig): Category[] {
    const { products, offers, categories } = config;
    const sortedCategories = sortCategories(categories);
    if (!supermarketHasOffers(products, offers)) return sortedCategories;
    const offersCategory = getDefaultExtraCategory(this.extraCategories, 'client_offers_category');
    return [{ ...offersCategory }, ...sortedCategories];
  }

  public generateExtraCategories(orders: Order[]): Category[] {
    const extraCategories: Category[] = [];
    if (!this.shopService.getShop().categoryView) return extraCategories;
    const shouldAddReminders = this.shouldAddRemindersCategory();
    const shouldAddOrders = orders?.length > 0;
    if (shouldAddReminders) extraCategories.push(getDefaultExtraCategory(this.extraCategories, 'reminder_category'));
    if (shouldAddOrders) extraCategories.push(getDefaultExtraCategory(this.extraCategories, 'orders_category'));
    return orderBy(extraCategories, 'categoryIndex', 'asc');
  }

  private shouldAddRemindersCategory(): boolean {
    const remindersLength =
      (this.shopService.getCoupons()?.length ?? 0) + (this.shopService.getShopSuggestionBanners()?.length ?? 0);
    return remindersLength > 0;
  }

  public navigateToCheckout(): void {
    const shop = this.shopService.getShop();
    void this.router.navigate(['/checkout'], { queryParams: { vanityUrl: shop.vanity_url } });
  }

  public initialAggresiveRatingJsonLdShop(shop: Shop): void {
    const shopLogoUrl = generateImageSrc(shop?.logo);
    initJsonLd({
      aggresiveRating: getAggresiveRatingObject(
        shop?.ratingCounter ?? 0,
        shop?.ratingOverall ?? 0,
        getJsonShopType(shop.businessVertical),
        shopLogoUrl,
        shop?.name,
        shop?.mainCuisine?.name,
        `${shop.address?.street} ${shop.address?.streetNo}`,
        shop.address?.region,
        shop.address?.postalCode
      )
    });
  }

  public initializeCart(): void {
    const { products: memoryProducts, offers: memoryOffers } = this.cartService.getCart();
    const hasMemoryItems = Boolean(memoryProducts?.length) || Boolean(memoryOffers?.length);
    const collectionType = this.shopService.getShop().collectionType;
    const collection = getCartCollectionFromStorage(collectionType, window.localStorage);
    if (!hasMemoryItems && !collection) return;

    this.loaderService.setState(true);
    iif(
      () => hasMemoryItems,
      this.shopMenuAvailabilityService.checkCartItemsAvailability(memoryProducts, memoryOffers),
      this.shopMenuAvailabilityService.checkLocalCartCollectionAvailability(collection)
    )
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (response) => {
          this.shopMenuAvailabilityService.handleItemsAvailabilityResponse(response);
        },
        error: () => removeCartCollectionFromStorage(collectionType, window.localStorage)
      });
  }

  public openAuthInfoDialog$(): Observable<BoxInfoDialogComponent | InfoDialogResponse | void> {
    return this.dialogService
      .openInfoDialog({
        title: 'login_to_continue',
        messages: ['login_with_your_mobile_or_email']
      })
      .afterClosed()
      .pipe(tap(() => this.cosmoteIDService.redirectToCosmoteId()));
  }

  public showAddAddressNotification(): void {
    if (isPrerenderBrowser(window)) return;
    const notificationData: InfoNotificationWidgetData = {
      title: 'add_your_location',
      message: 'the_shop_may_not_be_serving_you'
    };
    this.notificationsService.addNotification(InfoNotificationWrapperComponent, { data: notificationData });
  }

  public openUnreachableAddressDelivery(): void {
    this.dialogService
      .openInfoDialog({
        title: 'the_address_is_too_far_away',
        messages: ['the_shop_does_not_serve_this_address'],
        userAction: true,
        btnText: 'discover_'
      })
      .afterClosed()
      .subscribe((response: InfoDialogResponse) => {
        if (response?.clickCta) void this.router.navigate(['discover']);
      });
  }

  public completedMSISDNVerificationFlow$(): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: { verificationReason: 'mobile_phone_initialize' }
    };
    return this.dialogService
      .openDialog<PhoneVerificationDialogComponent, PhoneVerificationDialogResponse>(
        PhoneVerificationDialogComponent,
        dialogConfig
      )
      .afterClosed()
      .pipe(
        map((response) => {
          if (!response?.verifyResponse) return false;
          const user = this.userService.getUser();
          this.userService.setUser({ ...user, ...response.verifyResponse });
          return true;
        })
      );
  }

  public completedCardLoyaltyFlow$(): Observable<boolean> {
    const shop = this.shopService.getShop();
    if (!shop.hasLoyaltyCard) return of(true);
    return this.marketLoyaltyService.checkUserMarketCard(shop);
  }

  private openShopUnavailabilityDialog(): Observable<boolean> {
    return this.dialogService
      .openInfoDialog({ messages: ['delivery_currently_not_possible'], btnText: 'see_other_shops', userAction: true })
      .afterClosed()
      .pipe(
        tap((resposne) => {
          if (resposne?.clickCta) this.router.navigate(['/discover']);
        }),
        mapTo(false)
      );
  }

  public checkCheckoutRedirectionEligibility(): void {
    const user = this.userService.getUser();
    const authenticated = this.authenticationService.isAuthenticated;
    const shopOperatingState = this.shopService.getShop()?.operatingState;
    // the address guard will take care of address checks
    const checkoutRedirectionCheckList = {
      isAuthenticated: authenticated,
      isVerified: user.verifiedMSISDN,
      isLoyaltyCardOk: null,
      isShopOpen: shopOperatingState === 'OPEN'
    };

    /* This logic cannot move to the checkout resolver,
      since we have no way of stopping the box-loader that is setup in box.component
      from triggering while the dialogs are open.
      Additionally this logic cannot go to a guard,
      because of the dependencies it has (user, shop) */
    of(checkoutRedirectionCheckList)
      .pipe(
        switchMap((state) => {
          if (state.isAuthenticated) return of(state);
          // we exit the app at this point thanks to cosmote id
          return this.openAuthInfoDialog$().pipe(map(() => state));
        }),
        skipWhile((state) => !state.isAuthenticated),
        switchMap((state) => {
          if (state.isVerified) return of(state);
          return this.completedMSISDNVerificationFlow$().pipe(map((isVerified) => ({ ...state, isVerified })));
        }),
        skipWhile((state) => !state.isVerified),
        switchMap((state) => {
          if (state.isShopOpen) return of(state);
          return this.openShopUnavailabilityDialog().pipe(map(() => state));
        }),
        skipWhile((state) => !state.isShopOpen),
        switchMap((state) => {
          return this.completedCardLoyaltyFlow$().pipe(map((isLoyaltyCardOk) => ({ ...state, isLoyaltyCardOk })));
        }),
        map((state) => {
          return Object.values(state).every(Boolean);
        })
      )
      .subscribe((canGoToCheckout) => {
        if (canGoToCheckout) this.navigateToCheckout();
      });
  }
}

import { MapCircle, MapPoint, BoxTheme, Coordinates } from '@box-types';
import { environment } from '@box-env/environment';
import { LIGHT_MAPS_THEME, DARK_MAPS_THEME, LIGTHNESS_MAP_RULES, VISIBILITY_DAAS_MAP_RULES } from './maps-rules.types';

export {
  getCircleCenter,
  degreesToRadians,
  getPointsDistance,
  circleContainsPoint,
  getAddressEditMapStyles,
  getDaasMapStyles,
  getStaticMapUrl,
  getStaticMapStylesFromJson,
  getStaticMapMarketQueryParam,
  filterCoordinatesByDistance
};

const STATIC_MAPS_API_KEY = environment.google.STATIC_MAPS_API_KEY;

function getCircleCenter(circle: MapCircle): MapPoint {
  return { lat: circle.lat, lng: circle.lng };
}

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

/*
This uses the Haversine formula to calculate the great-circle distance between two points – that is,
the shortest distance over the earth’s surface – giving an ‘as-the-crow-flies’ distance between the
points (ignoring any hills they fly over, of course!).

Haversine
formula:	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
c = 2 ⋅ atan2( √a, √(1−a) )
d = R ⋅ c
where	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
note that angles need to be in radians to pass to trig functions!
*/

function getPointsDistance(pointA: MapPoint, pointB: MapPoint): number {
  const R = 6378137; // Earths radius in meters
  const latDist = degreesToRadians(pointB.lat - pointA.lat);
  const lngDist = degreesToRadians(pointB.lng - pointA.lng);
  const a =
    Math.sin(latDist / 2) * Math.sin(latDist / 2) +
    Math.cos(degreesToRadians(pointA.lat)) *
      Math.cos(degreesToRadians(pointB.lat)) *
      Math.sin(lngDist / 2) *
      Math.sin(lngDist / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function filterCoordinatesByDistance(
  centerCoordinates: Coordinates,
  coordinatesToFilter: Coordinates[],
  distance: number
): Coordinates[] {
  if (!centerCoordinates) return [];
  const centerMapPoint: MapPoint = { lat: centerCoordinates.latitude, lng: centerCoordinates.longitude };
  const mapPointsToFilter: MapPoint[] = coordinatesToFilter?.map((point) => ({
    lat: point.latitude,
    lng: point.longitude
  }));
  const filteredMapPoints = mapPointsToFilter.filter((point) => {
    const pointsDistance = getPointsDistance(centerMapPoint, point);
    return pointsDistance <= distance;
  });
  return filteredMapPoints.map((point) => ({ latitude: point.lat, longitude: point.lng }));
}

function circleContainsPoint(circle: MapCircle, point: MapPoint): boolean {
  const circleCenter = getCircleCenter(circle);
  const centerDistance = getPointsDistance(circleCenter, point);
  return centerDistance <= circle.radius;
}

function getAddressEditMapStyles(theme: BoxTheme): google.maps.MapTypeStyle[] {
  switch (theme) {
    case 'dark':
      return DARK_MAPS_THEME;
    case 'light':
      return LIGHT_MAPS_THEME;
    default:
      return LIGHT_MAPS_THEME;
  }
}

function getDaasMapStyles(theme: BoxTheme): google.maps.MapTypeStyle[] {
  switch (theme) {
    case 'dark':
      return [...DARK_MAPS_THEME, ...VISIBILITY_DAAS_MAP_RULES];
    case 'light':
      return [...LIGHT_MAPS_THEME, ...LIGTHNESS_MAP_RULES, ...VISIBILITY_DAAS_MAP_RULES];
    default:
      return [...LIGHT_MAPS_THEME, ...LIGTHNESS_MAP_RULES, ...VISIBILITY_DAAS_MAP_RULES];
  }
}

// https://stackoverflow.com/questions/19115223/converting-google-maps-styles-array-to-google-static-maps-styles-string
function getStaticMapStylesFromJson(jsonWithMapStyling): string {
  if (!jsonWithMapStyling?.length) return '';

  return jsonWithMapStyling
    .map((rule) => {
      let ruleText = '';
      if (!rule.stylers?.length) return '';
      // Needs to have a ruleText rule to be valid.
      ruleText += (rule.featureType ? 'feature:' + rule.featureType : 'feature:all') + '|';
      ruleText += (rule.elementType ? 'element:' + rule.elementType : 'element:all') + '|';
      rule.stylers.forEach(function (styleObject) {
        const propertyName = Object.keys(styleObject)[0];
        const propertyValue = styleObject[propertyName].toString().replace('#', '0x');
        ruleText += propertyName + ':' + propertyValue + '|';
      });
      return 'style=' + encodeURIComponent(ruleText);
    })
    .join('&');
}

function getStaticMapUrl(centerPoint: Coordinates, theme: BoxTheme, otherPoints?: Coordinates[]): string {
  if (!centerPoint) return;

  const mapTypeRule = `maptype=roadmap`;
  const stylingRules = theme !== 'dark' ? LIGHT_MAPS_THEME : DARK_MAPS_THEME;
  const staticStylingRules = getStaticMapStylesFromJson(stylingRules);
  const markerColor = '0xf87a1d';
  // %7C is percentage encoding for the | character
  if (!otherPoints?.length) {
    const size = '608x170';
    const zoomRule = 'zoom=16';
    const centerRule = `center=${centerPoint.latitude},${centerPoint.longitude}`;
    const markerSize = 'mid';
    const centerMarkerRule = getStaticMapMarketQueryParam(markerSize, markerColor, centerPoint);
    return `url(https://maps.googleapis.com/maps/api/staticmap?${centerRule}&${zoomRule}&size=${size}&${mapTypeRule}&${centerMarkerRule}&key=${STATIC_MAPS_API_KEY}&${staticStylingRules}`;
  } else {
    const size = '608x304';
    const zoomRule = 'zoom=12';
    const centerRule = `center=${centerPoint.latitude},${centerPoint.longitude}`;
    const centerMarkerSize = 'mid';
    const centerMarkerRule = `markers=size:${centerMarkerSize}|color:${markerColor}|${centerPoint.latitude},${centerPoint.longitude}`;

    const otherMarkersRule = otherPoints
      .reduce((queryParams: string[], point) => {
        const otherMarkerSize = 'small';
        const markerRule = getStaticMapMarketQueryParam(otherMarkerSize, markerColor, point);
        if (markerRule) queryParams.push(markerRule);
        return queryParams;
      }, [])
      .join('|&');
    return `url(https://maps.googleapis.com/maps/api/staticmap?${centerRule}&${zoomRule}&size=${size}&${mapTypeRule}&${centerMarkerRule}&${otherMarkersRule}&key=${STATIC_MAPS_API_KEY}&${staticStylingRules}`;
  }
}

function getStaticMapMarketQueryParam(size: 'mid' | 'small', color: string, coordinates: Coordinates): string {
  if (!coordinates) return;
  return `markers=size:${size}|color:${color}|${coordinates.latitude},${coordinates.longitude}`;
}

<div
  *ngFor="let i of [1, 2, 3, 4, 5]"
  [class.rating-star-active]="isStarActive(i)"
  [class.rating-star-highlighted]="isStarHighlighted(i)"
  (click)="onSelectStar($event, i)"
  (mouseenter)="onMouseenterStar(i)"
  (mouseleave)="onMouseleaveStar(i)"
  class="rating-star"
>
  <svg xmlns="http://www.w3.org/2000/svg" [attr.height]="size" [attr.width]="size" viewBox="0 0 24 24">
    <path d="m12 17 5 3-1-6 4-4h-6l-2-6-2 6H4l4 4-1 6 5-3Z" />
  </svg>
</div>

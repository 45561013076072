export { intersectArrays, groupByBatch };
import chunk from 'lodash-es/chunk';

function intersectArrays<T>(arrays: T[][]): T[] {
  return arrays.reduce((a, b) => a.filter((c) => b.includes(c)));
}

function groupByBatch<T>(array: T[], batchSize: number): T[][] {
  return chunk(array, batchSize);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@box-shared/shared.module';
import * as Components from './components';
import { SkeletonContestComponent } from './components';

@NgModule({
  declarations: [Components.ContestComponent, Components.ContestFlagComponent, Components.SkeletonContestComponent],
  imports: [CommonModule, SharedModule],
  exports: [Components.ContestComponent, SkeletonContestComponent]
})
export class ContestWidgetModule {}

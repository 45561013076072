import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ScrollSpyService } from '@box-core/services';

@Directive({
  selector: '[scrollSpyParent]'
})
export class ScrollSpyParentDirective implements OnInit, OnDestroy {
  constructor(private scrollSpyService: ScrollSpyService, private element: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.scrollSpyService.setParent(this.element);
  }

  ngOnDestroy(): void {
    this.scrollSpyService.clearParent();
  }
}

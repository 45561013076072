export { getPercentageDiscount, applyPercentageDiscount };

function getPercentageDiscount(amount: number, discountPercentage: number): number {
  if (!amount || !discountPercentage) return 0;
  if (discountPercentage < 0 || discountPercentage > 100) return amount;
  return Math.abs(amount) * (discountPercentage / 100);
}

function applyPercentageDiscount(amount: number, discountPercentage: number): number {
  const discount = getPercentageDiscount(amount, discountPercentage);
  return Math.floor(amount - discount);
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import {
  APIResponse,
  PaymentGatewayInfo,
  PaymentCardLoyaltyInfo,
  PaymentCardVerificationData,
  PaymentTokenResponse,
  InitiatePaymentOptions,
  InitiatePaymentResponse,
  BusinessVertical
} from '@box-types';
import { getNbgAppName } from '@box/utils';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';

@Injectable({ providedIn: 'root' })
export class PaymentGatewayService {
  private BOX_API: string = environment.application.API_URL;

  private readonly gatewayInfoSource = new BehaviorSubject<PaymentGatewayInfo>(undefined);
  public readonly gatewayInfo$ = this.gatewayInfoSource.asObservable();

  constructor(private http: HttpClient) {}

  public setPaymentGatewayInfo(info: PaymentGatewayInfo): void {
    this.gatewayInfoSource.next(info);
  }

  public getPaymentGatewayInfo(): PaymentGatewayInfo {
    return this.gatewayInfoSource.getValue();
  }

  public fetchPaymentGatewayInfo(businessVertical?: BusinessVertical): Observable<PaymentGatewayInfo> {
    const params: HttpParams = new HttpParams().set('nbgAppName', getNbgAppName(businessVertical));
    return this.http
      .get(this.BOX_API + '/payment/getCards', { params })
      .pipe(map((response: APIResponse<PaymentGatewayInfo>) => response.payload));
  }

  public getCardLoyaltyPoints(cardId: string): Observable<PaymentCardLoyaltyInfo> {
    return this.http.get(`${this.BOX_API}/cards/${cardId}/loyalty/points`).pipe(
      map((response: APIResponse<PaymentCardLoyaltyInfo>) => {
        const info = response.payload;
        const normalizedPoints = info?.points ?? 0;
        const sortedSteps = orderBy(info?.conversionSteps, 'monetaryValue', 'asc');
        return { points: normalizedPoints, conversionSteps: sortedSteps };
      })
    );
  }

  public verifyPaymentCard(verificationData: PaymentCardVerificationData): Observable<string> {
    const data = { ...verificationData };
    return this.http
      .post(`${this.BOX_API}/cards/loyalty/verify`, data)
      .pipe(map((response: APIResponse<{ boxId: string }>) => response.payload.boxId));
  }

  public getPaymentToken(orderId: string, businessVertical?: BusinessVertical): Observable<PaymentTokenResponse> {
    const params: HttpParams = new HttpParams()
      .set('nbgAppName', getNbgAppName(businessVertical))
      .set('orderId', orderId);
    return this.http
      .get(`${this.BOX_API}/payment/token`, { params })
      .pipe(map((response: APIResponse<PaymentTokenResponse>) => response.payload));
  }

  public initiatePayment(
    data: InitiatePaymentOptions,
    businessVertical?: BusinessVertical
  ): Observable<InitiatePaymentResponse> {
    const params: HttpParams = new HttpParams().set('nbgAppName', getNbgAppName(businessVertical));
    return this.http.post(`${this.BOX_API}/payment/initiate`, data, { params }).pipe(
      map((response: APIResponse<InitiatePaymentResponse>) => response.payload),
      tap(
        (initiatePaymentResponse) =>
          !initiatePaymentResponse.success && throwError(() => new Error('Initiate Payment Failed'))
      )
    );
  }
}

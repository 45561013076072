import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Order } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'order-completion-status',
  templateUrl: './order-completion-status.component.html',
  styleUrls: ['./order-completion-status.component.scss']
})
export class OrderCompletionStatusComponent implements OnChanges {
  @Input() private order: Order;

  public status: string;
  public wrapperClass: string;
  public shopLogo: string;
  public shopName: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    this.status = this.getOrderStatus(this.order);
    this.wrapperClass = this.status + '-status';
    const shop = this.order.shop;
    this.shopLogo = generateImageSrc(shop.logo);
    this.shopName = shop.name;
  }

  private getOrderStatus(order: Order): string {
    if (order.shopResponse.status === 'ACCEPTED') return 'accepted';
    if (order.shopResponse.status === 'COMPLETED') return 'completed';
    if (order.shopResponse.status === 'PENDING') return 'pending';
    return 'declined';
  }
}

import { Shop } from '@box-types';
import { CollectionCampaign } from './promo-campaign.types';
import { shuffle, orderBy, remove, sample } from 'lodash-es';
import { getShopAverageSortingWeight, isShopCampaignRecentlyAdded } from '../shops';

export { sortCollectionCampaignShops };

/**
 * sortCollectionCampaignShops will sort and return a new array of the Shop results by the below rules:
 *
 * The first group contains the 2 Shops that have the highest average weight, shuffled.
 *
 * The second group contains a randomly picked shop from the recently added shops to that campaign.
 *
 * The third group contains the next 3 Shops that have the highest average weight, shuffled.
 *
 * The fourth group contains the remaining recently added shops to that campaign, shuffled.
 *
 * The fifth group contains the remaining Shops sorted by the average weight.
 *
 * @param {Shop[]} shops the shops that needs sort
 * @param {CollectionCampaign} collectionCampaign the collection campaign that is going to be used for the sorting
 * @returns {Shop[]} the sorted shops
 * */
function sortCollectionCampaignShops(shops: Shop[], collectionCampaign: CollectionCampaign): Shop[] {
  if (!shops.length) return [];
  if (!collectionCampaign.campaignName) return shops;
  const shopsBucket = orderBy(shops, (shop) => getShopAverageSortingWeight(shop), 'desc');
  const firstGroup = shuffle(shopsBucket.splice(0, 2));
  const secondGroup: Shop[] = [];
  const recentlyAddedShops = shopsBucket.filter((shop) => isShopCampaignRecentlyAdded(shop, collectionCampaign));
  const randomRecentlyAddedShop = sample(recentlyAddedShops);
  if (randomRecentlyAddedShop) {
    secondGroup.push(randomRecentlyAddedShop);
    remove(shopsBucket, (shop) => shop.collectionType === randomRecentlyAddedShop.collectionType);
  }
  const thirdGroup = shuffle(shopsBucket.splice(0, 3));
  const fourthGroup = shuffle(remove(shopsBucket, (shop) => isShopCampaignRecentlyAdded(shop, collectionCampaign)));
  const fifthGroup = shopsBucket;
  return [...firstGroup, ...secondGroup, ...thirdGroup, ...fourthGroup, ...fifthGroup];
}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ProductInstance } from '@box-types';
import { getCartProductInstanceDescription } from '@box/utils';

@Component({
  selector: 'cart-product-instance',
  templateUrl: './cart-product-instance.component.html',
  styleUrls: ['./cart-product-instance.component.scss']
})
export class CartProductInstanceComponent implements OnChanges {
  @Input() public instance: ProductInstance;
  @Input() public disabled = false;
  @Input() public showCommentPlaceholder = true;

  @Output() private add: EventEmitter<ProductInstance> = new EventEmitter<ProductInstance>();
  @Output() private remove: EventEmitter<ProductInstance> = new EventEmitter<ProductInstance>();

  public description: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instance) {
      this.instance = changes.instance.currentValue as ProductInstance;
      this.description = getCartProductInstanceDescription(this.instance, this.showCommentPlaceholder);
    }
  }

  public onAdd(): void {
    this.add.emit(this.instance);
  }

  public onRemove(): void {
    this.remove.emit(this.instance);
  }
}

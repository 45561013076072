import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  HostBinding,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { ProgressBarFragment } from '@box-types';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnChanges, AfterViewInit {
  @Input() private fragments: ProgressBarFragment[];
  public visibleFragments: ProgressBarFragment[];

  @HostBinding('class') public hostClass = 'progress-bar';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.fragments = changes.fragments.currentValue as ProgressBarFragment[];
    // prevents animations from running multiple times
    if (isEqual(this.visibleFragments, this.fragments)) return;
    this.visibleFragments = this.fragments;
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  public getProgressBarStyle(fragment: ProgressBarFragment): Record<string, string> {
    return {
      backgroundColor: fragment.color,
      width: fragment.loadedPercentage.toString() + '%'
    };
  }
}

import { storageGet, storageSet, isStorageSupported } from '../storage';
import dayjs from 'dayjs';
import { BafSeenState } from './baf.types';

const BAF_STORAGE_KEY = 'Box:bafSeen';

function wasBAFSeenMoreThanMonthsAgo(months: number): boolean {
  const seen = storageGet<BafSeenState>(BAF_STORAGE_KEY, localStorage);
  if (!seen?.timestamp) return false;
  const currentDate = dayjs();
  const seenDate = dayjs(seen.timestamp);
  const dateDifference = currentDate.diff(seenDate, 'months');
  return dateDifference >= months;
}

function shouldShowBAFOrderAcceptanceBanner(): boolean {
  if (!isStorageSupported()) return false;
  const seen: BafSeenState = storageGet(BAF_STORAGE_KEY, localStorage);
  return !seen?.orderAcceptanceSeen;
}

function saveBAFOrderAcceptanceBannerViewed(): void {
  if (!isStorageSupported()) return;
  const seen: BafSeenState = storageGet(BAF_STORAGE_KEY, localStorage);
  const updatedSeen: BafSeenState = {
    ...seen,
    orderAcceptanceSeen: true
  };
  storageSet(BAF_STORAGE_KEY, updatedSeen, localStorage);
}

function shouldShowHomeViewBAFBanner(): boolean {
  if (!isStorageSupported()) return false;
  const seen: BafSeenState = storageGet(BAF_STORAGE_KEY, localStorage);
  if (!seen?.timestamp) return true;
  if (seen.hide) return false;
  return wasBAFSeenMoreThanMonthsAgo(3);
}

function saveHomeViewBAFBannerSeen(): void {
  if (!isStorageSupported()) return;
  const seen: BafSeenState = storageGet(BAF_STORAGE_KEY, localStorage);
  const seenState: BafSeenState = {
    timestamp: dayjs().valueOf(),
    hide: Boolean(seen?.timestamp),
    orderAcceptanceSeen: seen?.orderAcceptanceSeen ?? false
  };
  storageSet(BAF_STORAGE_KEY, seenState, localStorage);
}

export {
  shouldShowHomeViewBAFBanner,
  saveHomeViewBAFBannerSeen,
  wasBAFSeenMoreThanMonthsAgo,
  saveBAFOrderAcceptanceBannerViewed,
  shouldShowBAFOrderAcceptanceBanner
};

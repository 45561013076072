import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Review } from '@box-types';

@Component({
  selector: 'product-myo-reviews',
  templateUrl: './product-myo-reviews.component.html',
  styleUrls: ['./product-myo-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYOReviewsComponent {
  @Input() public reviews: Review[];
  @HostBinding('class') public hostClass = 'product-myo-reviews';
}

import { Component, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { BodyScrollEvent } from '@box-shared/directives/body-scroll-event.types';

@Component({
  selector: 'box-dialog-wrapper',
  templateUrl: './box-dialog-wrapper.component.html',
  styleUrls: ['./box-dialog-wrapper.component.scss']
})
export class BoxDialogWrapperComponent implements AfterViewInit {
  @ViewChild('dialogHeader') private dialogHeader: ElementRef<HTMLElement>;
  @ViewChild('dialogBody') private dialogBody: ElementRef<HTMLElement>;
  @ViewChild('dialogFooter') private dialogFooter: ElementRef<HTMLElement>;

  private hasHeader = false;
  private hasFooter = false;

  constructor(public renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.hasHeader = !!this.dialogHeader;
    this.hasFooter = !!this.dialogFooter;
    this.hasFooter && this.initialScrollableCheck();
  }

  public onBodyScroll(event: BodyScrollEvent): void {
    this.hasHeader && this.headerScrollCallback(event);
    this.hasFooter && this.footerScrollCallback(event);
  }

  private headerScrollCallback(event: BodyScrollEvent): void {
    if (!this.dialogHeader) return undefined;
    event.scrolled
      ? this.renderer.addClass(this.dialogHeader.nativeElement, 'has-shadow')
      : this.renderer.removeClass(this.dialogHeader.nativeElement, 'has-shadow');
  }

  private footerScrollCallback(event: BodyScrollEvent): void {
    if (!this.dialogFooter) return undefined;
    event.scrolledToBottom
      ? this.renderer.removeClass(this.dialogFooter.nativeElement, 'has-shadow')
      : this.renderer.addClass(this.dialogFooter.nativeElement, 'has-shadow');
  }

  private initialScrollableCheck(): void {
    const scrollable = this.dialogBody.nativeElement.clientHeight < this.dialogBody.nativeElement.scrollHeight;
    scrollable && this.renderer.addClass(this.dialogFooter.nativeElement, 'has-shadow');
  }
}

import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { BoxModule } from './box.module';
import { environment } from '@box-env/environment';

if (environment.production) enableProdMode();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(BoxModule)
    .then((ref) => {
      if (environment.production) return;
      // Ensure Angular destroys itself on hot reloads during Development.
      if (window['ngRef']) (window['ngRef'] as NgModuleRef<BoxModule>).destroy();
      window['ngRef'] = ref;
    })
    .catch((err) => console.error(err));
});

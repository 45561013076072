import { CollapsibleTile, Brand, Shop, PromoCampaign } from '@box-types';
import { generateImageSrc } from '../media';
import { filterShopsByBrands } from '../shops';
import { sortSuggestionElements } from '../core';
import { removeAccents } from '../string.utils';

export { brandToCollapsibleTile, filterSortAndSliceBrands, decorateBrandWithBenefits };

function filterSortAndSliceBrands(brands: Brand[], shops: Shop[], maxBrands?: number): Brand[] {
  if (!brands?.length || !shops?.length) return [];
  const brandsWithShops = brands.filter((brand) => Boolean(filterShopsByBrands(shops, [brand]).length));
  const promotedBrands = brandsWithShops.filter((brand) => brand.promoted);
  const notPromotedBrands = brandsWithShops.filter((brand) => !brand.promoted);
  const notPromotedBrandsWithBenefits = notPromotedBrands.filter((brand) => brand.points || brand.multiplier);
  const notPromotedBrandsWithoutBenefits = notPromotedBrands.filter((brand) => !brand.points && !brand.multiplier);

  const sortedPromotedBrands = promotedBrands.sort((a, b) => b.numberOfRecentOrders - a.numberOfRecentOrders); // Descending
  const sortedNotPromotedBrandsWithBenefits = sortSuggestionElements(notPromotedBrandsWithBenefits);
  const sortedNotPromotedBrandsWithoutBenefits = notPromotedBrandsWithoutBenefits.sort(
    (a, b) => b.numberOfRecentOrders - a.numberOfRecentOrders
  ); // Descending

  const sortedBrands = [
    ...sortedPromotedBrands,
    ...sortedNotPromotedBrandsWithBenefits,
    ...sortedNotPromotedBrandsWithoutBenefits
  ];
  return sortedBrands.slice(0, maxBrands ?? sortedBrands?.length);
}

function decorateBrandWithBenefits(brand: Brand, promoCampaigns: PromoCampaign[]): Brand {
  if (!brand?.promoCampaignId?.length || !promoCampaigns?.length) return brand;
  const promo = promoCampaigns.find((campaign) => campaign._id === brand.promoCampaignId);
  if (!promo) return brand;
  return {
    ...brand,
    points: promo.points ?? 0,
    multiplier: promo.multiplier ?? 0
  };
}

function brandToCollapsibleTile(brand: Brand): CollapsibleTile<Brand> {
  return {
    key: brand.name,
    collapsedTitle: removeAccents(brand.name),
    collapsedImage: generateImageSrc(brand.collapsedImageWeb),
    backGroundColor: brand.backGroundColor,
    textColor: brand.textColor,
    image: generateImageSrc(brand.tileImage),
    item: brand,
    ...(brand?.points && { points: brand.points }),
    ...(brand?.multiplier && { multiplier: brand.multiplier })
  };
}

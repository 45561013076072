import { Injectable } from '@angular/core';
import { CoreService, translate, PromoCampaignsService } from '@box-core/services';
import { Shop, Coupon } from '@box-types';
import {
  getCouponDetailsDescription,
  getCouponDetailsExpirationDateText,
  getCouponDetailsRedemptionDateText,
  getCouponDetailsRemainingValueText,
  getCouponDetailsShops,
  getCouponDetailsCtaTitle
} from '@box/utils';
import { currencyCode } from '@box-core/services/currency.service';

@Injectable()
export class CouponDetailsDialogService {
  constructor(private coreService: CoreService, private promoCampaignsService: PromoCampaignsService) {}

  public getCouponDetailsDescription(coupon: Coupon): string {
    return getCouponDetailsDescription(coupon);
  }

  public getCouponDetailsExpirationDateText(coupon: Coupon): string {
    return getCouponDetailsExpirationDateText(coupon, translate);
  }

  public getCouponDetailsRedemptionDateText(coupon: Coupon): string {
    return getCouponDetailsRedemptionDateText(coupon);
  }

  public getCouponDetailsRemainingValueText(coupon: Coupon): string {
    return getCouponDetailsRemainingValueText(coupon, currencyCode);
  }

  public getCouponDetailsShops(coupon: Coupon, shops: Shop[]): Shop[] {
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const couponDetailsShops = getCouponDetailsShops(coupon, shops, cuisines, chains);
    return this.promoCampaignsService.redecorateShopsWithSpecificPromoCouponSynergies(
      [coupon.synergy],
      couponDetailsShops
    );
  }

  public getCouponDetailsCtaTitle(coupon: Coupon): string {
    return getCouponDetailsCtaTitle(coupon);
  }
}

import { Address } from '@box-types';
import { getPlaceResultAddressComponent } from '../maps';

export { placeResultToAddress };

function placeResultToAddress(placeResult: google.maps.places.PlaceResult): Address {
  const longitude = placeResult?.geometry?.location?.lng();
  const latitude = placeResult?.geometry?.location?.lat();
  const city = getPlaceResultAddressComponent(placeResult, 'locality')?.long_name;
  const street = getPlaceResultAddressComponent(placeResult, 'route')?.long_name;
  const streetNo = getPlaceResultAddressComponent(placeResult, 'street_number')?.long_name;
  const postalCodeText = getPlaceResultAddressComponent(placeResult, 'postal_code')?.long_name;
  const postalCode = postalCodeText && postalCodeText.replace(/ /g, '');
  return { longitude, latitude, city, street, streetNo, postalCode };
}

import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { OrderStatusLogoService } from './order-status-logo.service';
import { Order } from '@box-types';
import { OrderStatusLogoState } from './order-status-logo.types';
import { isOrderDaas } from '@box/utils';
import { OrderStatusLogoAnimation } from './order-status-logo.animations';

@Component({
  selector: 'order-status-logo',
  templateUrl: './order-status-logo.component.html',
  styleUrls: ['./order-status-logo.component.scss'],
  providers: [OrderStatusLogoService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [OrderStatusLogoAnimation]
})
export class OrderStatusLogoComponent implements OnChanges {
  @Input() public order: Order;

  public orderStatusLogoState: OrderStatusLogoState;
  public shopImageSrc: string;
  public shopImageAlt: string;

  constructor(private orderStatusLogoService: OrderStatusLogoService) {}

  @HostBinding('class') public hostClass = 'order-status-logo';
  @HostBinding('class.order-status-logo-daas') public isDaasOrder: boolean;
  @HostBinding('class.order-status-logo-accepted') public isAccepted: boolean;
  @HostBinding('class.order-status-logo-rejected') public isRejected: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    this.shopImageSrc = this.orderStatusLogoService.generateShopImageSrc(this.order);
    this.shopImageAlt = this.order.shop.name;
    this.isDaasOrder = isOrderDaas(this.order);
    this.orderStatusLogoState = this.orderStatusLogoService.generateOrderStatusLogoState(this.order);
    this.isAccepted = this.orderStatusLogoState === 'ACCEPTED';
    this.isRejected = this.orderStatusLogoState === 'REJECTED';
  }
}

import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SEOService } from '@box-core/services';

@Component({
  selector: 'four-oh-four-page',
  templateUrl: './four-oh-four.page.html',
  styleUrls: ['./four-oh-four.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FourOhFourPage implements OnInit, OnDestroy {
  constructor(private seoService: SEOService) {}

  ngOnInit(): void {
    this.seoService.setPrerenderStatusCode('404');
  }

  ngOnDestroy(): void {
    this.seoService.removePrerenderStatusCode();
  }
}

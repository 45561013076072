import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticationService, AddressesService, DialogService } from '@box-core/services';
import { Address, Timeslot, User } from '@box-types';
import { TimeslotDialogComponent } from '@box-shared/components';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { TimeslotDialogResponse } from '@box-shared/components/timeslot-dialog/timeslot-dialog-response.interface';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from '@box-core/services/user.service';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'box-header',
  templateUrl: './box-header.component.html',
  styleUrls: ['./box-header.component.scss']
})
export class BoxHeaderComponent implements OnInit, OnDestroy {
  private disabledRoutes = ['/checkout', '/login', '/logout', '/payment'];
  private userSubscription: Subscription;
  private addressSubscription: Subscription;
  private addressesSubscription: Subscription;
  private timeslotSubscription: Subscription;
  private routerSubscription: Subscription;
  private breakPointObserverSubscription: Subscription;

  public user: User;
  public address: Address;
  public addresses: Address[];
  public timeslot: Timeslot;
  public showDeliveryDetails = false;
  public authenticated: boolean;
  public isGuest: boolean;
  public showBalanceBadge: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private addressesService: AddressesService,
    private dialogService: DialogService,
    private timeslotsService: TimeslotsService,
    private breakpointObserver: BreakpointObserver,
    private userServices: UserService
  ) {
    // TODO Find a clean way to detect the activation of the delivery details
    this.setRouterSubscription();
    this.setAddressSubscription();
    this.setAddressesSubscription();
    this.setTimeslotSubscription();
    this.setBreakPointObserverSubscription();
  }

  ngOnInit(): void {
    this.authenticated = this.authenticationService.isAuthenticated;
    this.isGuest = this.userServices.isGuest;
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private setAddressesSubscription(): void {
    this.addressesSubscription = this.addressesService.addresses$.subscribe(
      (addresses) => (this.addresses = addresses)
    );
  }

  private setAddressSubscription(): void {
    this.addressSubscription = this.addressesService.address$.subscribe((address) => (this.address = address));
  }

  private setTimeslotSubscription(): void {
    this.timeslotSubscription = this.timeslotsService.timeslot$.subscribe((timeslot) => (this.timeslot = timeslot));
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      const isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
      this.showBalanceBadge = !isSmallScreen;
    });
  }

  private setRouterSubscription(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        const routerUrl: string = this.router.url.split('?')[0].split('#')[0];
        this.showDeliveryDetails = !this.disabledRoutes.includes(routerUrl);
      });
  }

  private clearSubscriptions(): void {
    this.userSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.addressSubscription?.unsubscribe();
    this.timeslotSubscription?.unsubscribe();
    this.addressesSubscription?.unsubscribe();
    this.breakPointObserverSubscription?.unsubscribe();
  }

  private openTimeslotsDialog(): void {
    this.dialogService
      .openDialog(TimeslotDialogComponent, {
        panelClass: 'box-dialog'
      })
      .afterClosed()
      .subscribe((data: TimeslotDialogResponse) => {
        if (!data) return;
        if (data.reset) this.timeslotsService.clearTimeslot();
        if (data.timeslot) this.timeslotsService.setTimeslot(data.timeslot);
      });
  }

  public onAddressChange(): void {
    if (this.authenticated) {
      return void this.addressesService.initiateAddressSelectDialogFlow$().subscribe();
    }
    if (this.address) {
      return void this.addressesService.initiateEditAddressDialogFlow$(false, this.address).subscribe();
    }
    return void this.addressesService.initiateAddSmallAddressDialogFlow$().subscribe();
  }

  public onTimeslotChange(): void {
    this.openTimeslotsDialog();
  }
}

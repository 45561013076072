import { User, Shop, PromoCampaign } from '@box-types';
import dayjs from 'dayjs';
import { isShopRegisteredToMerchantSponsoredCampaign } from './promo-campaign-shops.utils';
import { isEnabledByTimeRanges } from '../core';
import { sortPromoCampaigns } from './promo-campaigns.utils';

function isLowOrderProbabilityUser(user: User): boolean {
  return user.segments.includes('low_order_probability');
}

function isLowOrderProbabilityUserWithCoupon(user: User): boolean {
  return user.segments?.includes('low_order_probability_active_coupon');
}

function merchantSponsoredCampaignExceededThreshold(shop: Shop, promoCampaign: PromoCampaign): boolean {
  if (!shop.lastUserOrder) return true;
  const daysThreshold = promoCampaign.numberOfDaysThreshold;
  const currentDate = dayjs();
  const orderThresholdDate = dayjs(shop.lastUserOrder).add(daysThreshold, 'day');
  return currentDate.isAfter(orderThresholdDate);
}

function merchantSponsoredCampaignWithinLimit(shop: Shop, promoCampaign: PromoCampaign): boolean {
  if (!shop.lastUserOrder) return false;
  const daysLimit = promoCampaign.numberOfDaysLimit;
  const currentDate = dayjs();
  const orderLimitDate = dayjs(shop.lastUserOrder).add(daysLimit, 'day');
  return currentDate.isBefore(orderLimitDate);
}

function checkMerchantSponsoredCampaignEligibility(shop: Shop, campaign: PromoCampaign): boolean {
  if (campaign?.type !== 'merchant_sponsored') return false;
  if (!shop?.registeredCampaigns?.length) return false;
  const isRegistered = isShopRegisteredToMerchantSponsoredCampaign(shop, campaign.name);
  if (!isRegistered) return false;
  if (campaign.numberOfDaysThreshold) return merchantSponsoredCampaignExceededThreshold(shop, campaign);
  if (campaign.numberOfDaysLimit) return merchantSponsoredCampaignWithinLimit(shop, campaign);
  return true;
}

/**
 * getShopBestMerchantSponsoredCampaign desides the best merchant sponsored promo campaign.
 * x4 (happy_hour) + x6 (new_users) returns x6
 * x4 (happy_hour) + low_order returns x6
 * x4 (happy_hour) + coupon specific returns x6
 * @returns {PromoCampaign}
 */
function getShopBestMerchantSponsoredCampaign(shop: Shop, promoCampaigns: PromoCampaign[] = []): PromoCampaign {
  const merchantSponsoredPromoCampaigns = promoCampaigns.filter(
    (c) => isEnabledByTimeRanges(c.enabledOnTimeRanges) && checkMerchantSponsoredCampaignEligibility(shop, c)
  );
  const sortedMerchantSponsoredPromoCampaigns = sortPromoCampaigns(merchantSponsoredPromoCampaigns);
  if (!sortedMerchantSponsoredPromoCampaigns.length) return;
  return sortedMerchantSponsoredPromoCampaigns[0];
}

export {
  getShopBestMerchantSponsoredCampaign,
  merchantSponsoredCampaignWithinLimit,
  merchantSponsoredCampaignExceededThreshold,
  checkMerchantSponsoredCampaignEligibility,
  isLowOrderProbabilityUser,
  isLowOrderProbabilityUserWithCoupon
};

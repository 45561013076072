import { Injectable } from '@angular/core';
import { CheckoutSuggestionGroup, Product, Offer } from '@box-types';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CheckoutSuggestionDialogService {
  public checkoutSuggestionGroups = new BehaviorSubject<CheckoutSuggestionGroup[]>([]);

  public normalizeGroupItems(suggestionGroups: CheckoutSuggestionGroup[]): void {
    const decoratedGroups = suggestionGroups.map((checkoutSuggestionGroup) => ({
      ...checkoutSuggestionGroup,
      products: checkoutSuggestionGroup.products.map((p) => ({ ...p, quantity: 0, cartQuantity: 0 })),
      offers: checkoutSuggestionGroup.offers.map((o) => ({ ...o, quantity: 0, cartQuantity: 0 }))
    }));
    this.checkoutSuggestionGroups.next(decoratedGroups);
  }

  public addProduct(checkoutSuggestionGroup: CheckoutSuggestionGroup, product: Product): void {
    const currentGroups = this.checkoutSuggestionGroups.value;
    const currentGroup = currentGroups.find((group) => group.campaignName === checkoutSuggestionGroup.campaignName);
    if (!currentGroup) return undefined;
    const currentProduct = currentGroup.products.find((p) => p._id === product._id);
    if (!currentProduct) return undefined;
    currentProduct.quantity++;
    // This line will be removed when we refactor the cart
    currentProduct.cartQuantity++;
  }

  public removeProduct(checkoutSuggestionGroup: CheckoutSuggestionGroup, product: Product): void {
    const currentGroups = this.checkoutSuggestionGroups.value;
    const currentGroup = currentGroups.find((group) => group.campaignName === checkoutSuggestionGroup.campaignName);
    if (!currentGroup) return undefined;
    const currentProduct = currentGroup.products.find((p) => p._id === product._id);
    if (!currentProduct) return undefined;
    currentProduct.quantity--;
    // This line will be removed when we refactor the cart
    currentProduct.cartQuantity--;
  }

  public addOffer(checkoutSuggestionGroup: CheckoutSuggestionGroup, offer: Offer): void {
    const currentGroups = this.checkoutSuggestionGroups.value;
    const currentGroup = currentGroups.find((group) => group.campaignName === checkoutSuggestionGroup.campaignName);
    if (!currentGroup) return undefined;
    const currentProduct = currentGroup.offers.find((p) => p._id === offer._id);
    if (!currentProduct) return undefined;
    currentProduct.quantity++;
    // This line will be removed when we refactor the cart
    currentProduct.cartQuantity++;
  }

  public removeOffer(checkoutSuggestionGroup: CheckoutSuggestionGroup, offer: Offer): void {
    const currentGroups = this.checkoutSuggestionGroups.value;
    const currentGroup = currentGroups.find((group) => group.campaignName === checkoutSuggestionGroup.campaignName);
    if (!currentGroup) return undefined;
    const currentProduct = currentGroup.offers.find((p) => p._id === offer._id);
    if (!currentProduct) return undefined;
    currentProduct.quantity--;
    // This line will be removed when we refactor the cart
    currentProduct.cartQuantity--;
  }

  public getProductsToAdd(): Product[] {
    const currentGroups = this.checkoutSuggestionGroups.value;
    const currentProducts: Product[] = currentGroups.flatMap((group) => group.products);
    return currentProducts.filter((product) => product.quantity > 0);
  }

  public getOffersToAdd(): Offer[] {
    const currentGroups = this.checkoutSuggestionGroups.value;
    const currentProducts: Offer[] = currentGroups.flatMap((group) => group.offers);
    return currentProducts.filter((offer) => offer.quantity > 0);
  }

  public canSubmit(): boolean {
    const productsToAdd = this.getProductsToAdd();
    const offersToAdd = this.getOffersToAdd();
    return productsToAdd.length + offersToAdd.length > 0;
  }
}

import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/el';

import { GetTextByKeyType, Shop, Timeslot, TimetableDay, TimetableDayDetails, TimetableWorkingHours } from '@box-types';
import { diffDays } from '../core';
import { timeslotToDate } from '../timeslots.utils';
import { shopHasRevertSwitchBackOn, shopRevertSwitchBackOnIsOnTheFuture } from './shop.utils';

dayjs.extend(isBetween);

export {
  isShopBanned,
  isShopInWorkingHours,
  getTimetableDetails,
  hasShopAtLeastOneDayOpen,
  getNextWorkingHours,
  getShopCardFutureOpenText,
  clearTimeTable,
  isDateInWorkingHours,
  getTimetableDay
};

function isShopBanned(shop: Shop, date: Date): boolean {
  const currentDate = dayjs(date);
  const currentTimestamp = currentDate.unix();
  const hourToCheck = currentDate.hour();
  const hasNoBannedDates = shop.beginBannedDate === 0 && shop.endBannedDate === 0;
  const isDateInBannedRange = currentTimestamp >= shop.beginBannedDate && currentTimestamp <= shop.endBannedDate;
  if (hasNoBannedDates || !isDateInBannedRange) return false;
  const hasNoBannedHours = shop.beginBannedHour === 0 && shop.endBannedHour === 0;
  const isHourInBannedRange = hourToCheck >= shop.beginBannedHour && hourToCheck <= shop.endBannedHour;
  if (hasNoBannedHours || !isHourInBannedRange) return true;
}

function hasShopAtLeastOneDayOpen(shop: Shop): boolean {
  const timetable: TimetableDay[] = shop.timetableV2;
  return timetable.some((day: TimetableDay) => day.isOpen);
}

function isDateInWorkingHours(date: dayjs.Dayjs, workingHours: TimetableWorkingHours): boolean {
  if (!date || !workingHours) return false;
  const openingHour = workingHours.openingDeliveryHour;
  const openingMinute = workingHours.openingDeliveryMinute;
  const closingHour = workingHours.closingDeliveryHour;
  const closingMinute = workingHours.closingDeliveryMinute;
  const isAllDayOpen = openingHour === 0 && openingMinute === 0 && closingHour === 24 && closingMinute === 0;
  if (isAllDayOpen) return true;
  const currentHour = date.hour();
  const currentMinute = date.minute();
  if (currentHour === openingHour && currentMinute >= openingMinute) {
    if (currentHour !== closingHour) return true;
    if (currentMinute <= closingMinute) return true;
  }
  if (currentHour === closingHour && currentMinute <= closingMinute) return true;
  if (currentHour > openingHour && currentHour < closingHour) return true;
  return false;
}

function getTimetableDay(shop: Shop, dateToCheck: dayjs.Dayjs): TimetableDay {
  const isBanned: boolean = isShopBanned(shop, dateToCheck.toDate());
  const timetable: TimetableDay[] = shop.timetableV2;
  const hasTimetable: boolean = timetable && timetable.length > 0;
  if (isBanned || !hasTimetable) return;
  const dayToCheck: number = dateToCheck.day();
  return timetable[dayToCheck];
}

function isShopInWorkingHours(shop: Shop, timeslot: Timeslot): boolean {
  const dateToCheck: dayjs.Dayjs = dayjs(timeslot ? timeslotToDate(timeslot) : undefined);
  const timetableDay = getTimetableDay(shop, dateToCheck);
  if (!timetableDay || !timetableDay.isOpen) return false;
  return timetableDay.workingHoursArray.some((workingHours) => isDateInWorkingHours(dateToCheck, workingHours));
}

function sortTimeTable(timetable: TimetableDay[]): TimetableDay[] {
  return timetable.map((timetableDay) => ({
    day: timetableDay.day,
    isOpen: timetableDay.isOpen,
    workingHoursArray: timetableDay.workingHoursArray.sort((a, b) => {
      if (a.openingDeliveryHour < b.openingDeliveryHour) {
        return -1;
      } else if (a.openingDeliveryHour === b.openingDeliveryHour) {
        return a.openingDeliveryMinute < b.openingDeliveryMinute ? -1 : 1;
      } else {
        return 1;
      }
    })
  }));
}

// removes working hours that have no length
function clearTimeTable(timetable: TimetableDay[]): TimetableDay[] {
  return timetable.map((timetableDay) => ({
    day: timetableDay.day,
    isOpen: timetableDay.isOpen,
    workingHoursArray: timetableDay.workingHoursArray.filter((workingHours) => {
      if (workingHours?.openingDeliveryHour < workingHours?.closingDeliveryHour) return true;
      if (workingHours?.openingDeliveryMinute < workingHours?.closingDeliveryMinute) return true;
      return false;
    })
  }));
}

function getTimetableDetails(timetable: TimetableDay[]): TimetableDayDetails[] {
  const timetableSorted = sortTimeTable(timetable);
  const clearedTimeTable = clearTimeTable(timetableSorted);
  return clearedTimeTable
    .map((day) => day.workingHoursArray)
    .map((currentDayWorkingHours, currentDayIndex) => {
      const timetableDate = dayjs().startOf('day').day(currentDayIndex);
      const weekDay = timetableDate.format('dddd');
      if (currentDayWorkingHours.length === 0 || !clearedTimeTable[currentDayIndex].isOpen) {
        return { weekDay, workingHours: 'closed_' };
      }

      // Sunday => Saturday
      const previousDayIndex = currentDayIndex - 1 >= 0 ? currentDayIndex - 1 : 6;
      // Saturday => Sunday
      const nextDayIndex = currentDayIndex + 1 <= 6 ? currentDayIndex + 1 : 0;

      const previousDayLastTimetableWorkingHours = clearedTimeTable[previousDayIndex].workingHoursArray.slice(-1)[0];
      const nextDayFirstTimetableWorkingHours = clearedTimeTable[nextDayIndex].workingHoursArray[0];

      const workingHours = currentDayWorkingHours
        .map((workingHour) => {
          const startsAtMidnight = workingHour.openingDeliveryHour === 0 && workingHour.openingDeliveryMinute === 0;
          const endsAtMidnight = workingHour.closingDeliveryHour === 24 && workingHour.closingDeliveryMinute === 0;
          if (startsAtMidnight && endsAtMidnight) {
            return '00:00 - 24:00';
          }

          const previousDayIsOpen = clearedTimeTable[previousDayIndex].isOpen;
          const nextDayIsOpen = clearedTimeTable[nextDayIndex].isOpen;
          const previousDayEndsAtMidnight =
            previousDayLastTimetableWorkingHours &&
            previousDayLastTimetableWorkingHours.closingDeliveryHour === 24 &&
            previousDayLastTimetableWorkingHours.closingDeliveryMinute === 0 &&
            previousDayIsOpen;
          const nextDayStartsAtMidnight =
            nextDayFirstTimetableWorkingHours &&
            nextDayFirstTimetableWorkingHours.openingDeliveryHour === 0 &&
            nextDayFirstTimetableWorkingHours.openingDeliveryMinute === 0 &&
            nextDayIsOpen;

          if (startsAtMidnight && previousDayEndsAtMidnight) {
            // This slot is already sticked to the previous days last hours
            // if there is no other slot shop is closed today
            // otherwise skip the sticked slot and only show the next ones
            const todaysWorkSlots = clearedTimeTable[currentDayIndex].workingHoursArray.length;
            return todaysWorkSlots === 1 ? 'closed_' : undefined;
          } else {
            // Concat with the next
            const validWorkingHours =
              endsAtMidnight && nextDayStartsAtMidnight && nextDayFirstTimetableWorkingHours
                ? {
                    openingDeliveryHour: workingHour.openingDeliveryHour,
                    openingDeliveryMinute: workingHour.openingDeliveryMinute,
                    closingDeliveryHour: nextDayFirstTimetableWorkingHours.closingDeliveryHour,
                    closingDeliveryMinute: nextDayFirstTimetableWorkingHours.closingDeliveryMinute
                  }
                : workingHour;

            const { openingDeliveryHour, openingDeliveryMinute, closingDeliveryHour, closingDeliveryMinute } =
              validWorkingHours;
            const opening = timetableDate.hour(openingDeliveryHour).minute(openingDeliveryMinute).format('HH:mm');
            const closing = timetableDate.hour(closingDeliveryHour).minute(closingDeliveryMinute).format('HH:mm');
            const openingTime = opening === '24:00' ? '00:00' : opening;
            const closingTime = closing === '00:00' ? '24:00' : closing;
            return openingTime + ' - ' + closingTime;
          }
        })
        .filter(Boolean)
        .join(', ');
      return { weekDay, workingHours };
    });
}

function getNextWorkingHours(
  shop: Shop,
  timeslot?: Timeslot
): { nextFutureWorkingHours: TimetableWorkingHours; futureDay: Dayjs } {
  if (!shop) return;
  let dateToCheck: dayjs.Dayjs = dayjs(timeslot ? timeslotToDate(timeslot) : undefined);
  for (let weekdayCount = 0; weekdayCount < 7; weekdayCount++) {
    const timetableDay = getTimetableDay(shop, dateToCheck);
    if (!timetableDay) return;
    const hour = dateToCheck.hour();
    const minute = dateToCheck.minute();
    if (timetableDay.isOpen && timetableDay.workingHoursArray?.length) {
      const nextFutureWorkingHours = timetableDay.workingHoursArray.find((workingHours) => {
        const previousHour = hour < workingHours.openingDeliveryHour;
        const sameHour = hour === workingHours.openingDeliveryHour;
        const previousMinute = minute <= workingHours.openingDeliveryMinute;
        return previousHour || (sameHour && previousMinute);
      });
      if (nextFutureWorkingHours) return { nextFutureWorkingHours, futureDay: dateToCheck };
    }
    // at this point we are looking for the next day
    dateToCheck = dateToCheck.add(1, 'day').clone();
    dateToCheck = dateToCheck.set('hour', 0).clone();
    dateToCheck = dateToCheck.set('minutes', 0).clone();
  }
}

function getTimeslotToCheck(shop: Shop): Timeslot {
  const shouldTakeRevertSwitchBackOnAsTimeslot =
    shopRevertSwitchBackOnIsOnTheFuture(shop) && shopHasRevertSwitchBackOn(shop);
  if (shouldTakeRevertSwitchBackOnAsTimeslot)
    return {
      timeSlotStart: shop.monitoringInfo.revertSwitchBackOn
    } as Timeslot;
}

function getShopCardFutureOpenText(
  shop: Shop,
  translateFn: GetTextByKeyType,
  timeslot?: Timeslot,
  lastUserTimeslot?: Timeslot
): string {
  if (!shop) return;
  const timeslotToCheck = shop.available ? timeslot : getTimeslotToCheck(shop) ?? timeslot;
  const nextWorkingHours = getNextWorkingHours(shop, timeslotToCheck);
  if (!nextWorkingHours) return;

  const { nextFutureWorkingHours, futureDay } = nextWorkingHours;
  if (!nextFutureWorkingHours || !futureDay) return;

  const { openingDeliveryHour, openingDeliveryMinute } = nextFutureWorkingHours;
  if (openingDeliveryHour === null || openingDeliveryMinute === null) return;

  const futureOpenHour = openingDeliveryHour.toString().padStart(2, '0');
  const futureOpenMinute = openingDeliveryMinute.toString().padStart(2, '0');
  const futureOpenTimeString = `${futureOpenHour}:${futureOpenMinute}`;

  const timeslotDate = timeslotToDate(timeslotToCheck);
  const dateToCheck: dayjs.Dayjs = dayjs(timeslotToCheck ? timeslotDate : undefined);

  const sameDay = futureDay.format('dddd') === dateToCheck.format('dddd');
  const tomorrow = diffDays(futureDay.toDate(), dateToCheck.toDate()) === 1;

  // Off Hours with Back Date after user’s available timeslots
  if (lastUserTimeslot) {
    const lastUserTimeslotDate = dayjs(timeslotToDate(lastUserTimeslot));
    if (lastUserTimeslotDate && futureDay.isAfter(lastUserTimeslotDate)) {
      const dateDifference = futureDay.diff(dayjs(), 'days');
      return translateFn('reopens_date_at', {
        _DATE: futureDay.format(dateDifference > 6 ? 'DD/MM' : 'dddd'),
        _FUTURE_OPEN_TIME: futureOpenTimeString
      });
    }
  }

  // Off Hours with Same Back Date - User's selected delivery date is the same day as next available shop timeslot
  if (sameDay) return translateFn('reopens_at', { _FUTURE_OPEN_TIME: futureOpenTimeString });

  // Off Hours with Back Date
  if (tomorrow) return translateFn('reopens_tomorrow_at', { _FUTURE_OPEN_TIME: futureOpenTimeString });
  return translateFn('reopens_date_at', { _DATE: futureDay.format('dddd'), _FUTURE_OPEN_TIME: futureOpenTimeString });
}

<div class="shop-box-hour-banner-wrapper" (click)="onClick(suggestionBanner)">
  <figure boxSuggestionImage class="shop-box-hour-banner-image">
    <img [src]="suggestionBanner.image" [alt]="suggestionBanner.title" />
  </figure>
  <div [ngClass]="showDetails ? 'shop-box-hour-banner-full-content' : 'shop-box-hour-banner-content'">
    <mat-icon *ngIf="showDetails" class="shop-box-hour-banner-info-button" fontSet="material-icons-round"
      >info_outline</mat-icon
    >
    <div class="shop-box-hour-banner-content-wrapper">
      <span *ngIf="!showCountDown" class="shop-box-hour-banner-description">
        {{ suggestionBanner.suggestionDescription }}
      </span>
      <ng-container *ngIf="showCountDown">
        <span class="shop-box-hour-banner-title">{{ suggestionBanner.timerTitle }}</span>
        <campaign-countdown [campaignName]="'happy_hour'" class="shop-box-hour-banner-countdown"></campaign-countdown>
      </ng-container>
    </div>
  </div>
</div>

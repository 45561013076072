import { ThemePreference, BoxTheme } from '@box-types';
import { storageGet, storageSet } from '../storage';
import { isPrerenderBrowser } from '../prerender';

const THEME_STORAGE_KEY = 'Box:themePreference';
const THEME_COLOR_META_ELEMENT_NAME = 'theme-color';
const COLOR_SCHEME_META_ELEMENT_NAME = 'color-scheme';

function getThemePreference(): ThemePreference {
  return storageGet<ThemePreference>(THEME_STORAGE_KEY, window.localStorage);
}

function storeThemePreference(themePreference: ThemePreference): void {
  if (!themePreference) return;
  const currentPreference = getThemePreference();
  if (themePreference === currentPreference) return;
  storageSet(THEME_STORAGE_KEY, themePreference, window.localStorage);
}

function getSystemPreferredTheme(): BoxTheme {
  if (isPrerenderBrowser(window)) return 'light';
  if (!window?.matchMedia) return 'light';
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')?.matches;
  return prefersDark ? 'dark' : 'light';
}

function createMetaElement(name: string, content: string): HTMLMetaElement {
  const metaElement = document.createElement('meta');
  metaElement.setAttribute('name', name);
  metaElement.setAttribute('content', content);
  return metaElement;
}

function getExistingThemeColorMetaElement(): HTMLMetaElement {
  return document.head.querySelector(`meta[name="${THEME_COLOR_META_ELEMENT_NAME}"]`);
}

function getExistingColorSchemeMetaElement(): HTMLMetaElement {
  return document.head.querySelector(`meta[name="${COLOR_SCHEME_META_ELEMENT_NAME}"]`);
}

function updateDataModeAttribute(theme: BoxTheme): void {
  if (!theme || !document?.body) return;
  document.body.setAttribute('data-mode', theme);
}

function updateThemeColorMetaElement(theme: BoxTheme): void {
  /** theme-color: This meta tag specifies the default theme color for a website.
   * It affects the color of the browser toolbar,
   * address bar, and other browser-specific elements when the webpage is viewed on a mobile device. */
  if (!theme) return;
  const themeColorContent = theme === 'dark' ? '#151a20' : '#ffffff';
  const existingThemeColorMetaElement = getExistingThemeColorMetaElement();
  if (existingThemeColorMetaElement) {
    existingThemeColorMetaElement.setAttribute('content', themeColorContent);
    return;
  }
  const newMetaThemeColorElement = createMetaElement(THEME_COLOR_META_ELEMENT_NAME, themeColorContent);
  document.head.appendChild(newMetaThemeColorElement);
}

function updateColorSchemeMetaElement(theme: BoxTheme): void {
  /** color-scheme: This meta tag specifies the preferred color scheme for the webpage.
   * It allows the author to indicate whether the page should be presented in light mode, dark mode, or both.*/
  if (!theme) return;
  const colorSchemeContent = theme === 'dark' ? 'only dark' : 'only light';
  const existingColorSchemeMetaElement = getExistingColorSchemeMetaElement();
  if (existingColorSchemeMetaElement) {
    existingColorSchemeMetaElement.setAttribute('content', colorSchemeContent);
    return;
  }
  const newMetaColorShcemeElement = createMetaElement(COLOR_SCHEME_META_ELEMENT_NAME, colorSchemeContent);
  document.head.appendChild(newMetaColorShcemeElement);
}

export {
  getThemePreference,
  updateDataModeAttribute,
  getSystemPreferredTheme,
  updateColorSchemeMetaElement,
  getExistingThemeColorMetaElement,
  getExistingColorSchemeMetaElement,
  storeThemePreference,
  updateThemeColorMetaElement,
  createMetaElement,
  THEME_STORAGE_KEY,
  THEME_COLOR_META_ELEMENT_NAME,
  COLOR_SCHEME_META_ELEMENT_NAME
};

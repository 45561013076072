import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { translate } from '@box-core/services/language.service';
import { Brand, Product, ProductInstance, ProductPromo, Tag, PromoVisualOptions } from '@box-types';
import {
  displayPointsTag,
  getProductBaseEnvFee,
  getProductBeginPrice,
  getProductBestPromo,
  getProductDescription,
  getProductInstancePrice,
  getProductLimitsText
} from '@box/utils';

@Component({
  selector: 'product-myo-details',
  templateUrl: './product-myo-details.component.html',
  styleUrls: ['./product-myo-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYODetailsComponent implements OnChanges {
  @Input() public showQuantity = true;
  @Input() public showPrice = true;
  @Input() public isSuperMarket: boolean;
  @Input() private product: Product;
  @Input() private productInstance: ProductInstance;
  @Input() public discount: number;
  @Input() public extraIngredientDiscount: number;

  @Output() private productAdd = new EventEmitter();
  @Output() private productRemove = new EventEmitter();

  public productPromo: ProductPromo;
  public showProductPromoDetails: boolean;
  public productName: string;
  public productDescription: string;
  public productLimits: string;
  public productBrand: Brand;
  public productTags: Tag[];
  public productBeginPrice: number;
  public productInstanceQuantity: number;
  public productInstancePrice: number;
  public envFee: number;
  public stepToUomDescription: string;
  public badgeOptions: PromoVisualOptions;
  public displayPointsTag: boolean;

  constructor() {}

  @HostBinding('class') public hostClass = 'product-myo-details';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showQuantity) this.showQuantity = changes.showQuantity.currentValue as boolean;
    if (changes.showPrice) this.showPrice = changes.showPrice.currentValue as boolean;
    if (changes.isSuperMarket) this.isSuperMarket = changes.isSuperMarket.currentValue as boolean;
    if (changes.product) {
      this.product = changes.product.currentValue as Product;
      this.productPromo = getProductBestPromo(this.product);
      this.showProductPromoDetails = Boolean(this.productPromo?.detailsImage && this.productPromo?.detailsText);
      this.productName = this.product.name;
      this.productDescription = getProductDescription(this.product, this.isSuperMarket);
      this.productLimits = getProductLimitsText(this.product, translate);
      this.productBrand = this.product.brand;
      this.productTags = this.product.tags;
      this.badgeOptions = this.product.badgeOptions;
      this.displayPointsTag = this.setDisplayPointsTag();
      this.productBeginPrice = getProductBeginPrice(this.product);
      this.stepToUomDescription = this.product.stepToUomDescription;
    }

    if (changes.productInstance) {
      this.productInstance = changes.productInstance.currentValue as ProductInstance;
      this.productInstanceQuantity = this.productInstance.quantity;
      this.productInstancePrice = this.productInstance.basePrice;
      this.productInstancePrice = getProductInstancePrice(
        this.productInstance,
        this.discount,
        Boolean(this.extraIngredientDiscount)
      );
      this.envFee = getProductBaseEnvFee(this.productInstance);
    }

    if (changes.discount) this.discount = changes.discount.currentValue as number;
    if (changes.extraIngredientDiscount) {
      this.extraIngredientDiscount = changes.extraIngredientDiscount.currentValue as number;
    }
  }

  public onProductAdd(): void {
    this.productAdd.emit();
  }

  public onProductRemove(): void {
    this.productRemove.emit();
  }

  private setDisplayPointsTag(): boolean {
    const { points, multiplier } = this.productPromo ?? {};
    return displayPointsTag(points, multiplier);
  }
}

import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import DeviceIds from '@box-data/user-agent-device-id-prefixes.json';
import { DeviceType } from '@box-types';

@Injectable({ providedIn: 'root' })
export class DeviceDetectorService {
  constructor(private platform: Platform) {}

  public isAndroid(): boolean {
    return this.platform.ANDROID;
  }

  public isIOS(): boolean {
    return this.platform.IOS;
  }

  public isHuawei(): boolean {
    if (!this.platform.isBrowser) return false;
    const userAgent = window?.navigator?.userAgent;
    if (!userAgent) return false;

    const huaweiPhonePrefixes = DeviceIds.huaweiPhones;
    const isHuaweiPhone = huaweiPhonePrefixes.some((prefix) => userAgent.includes(prefix));
    if (isHuaweiPhone) return true;

    const huaweiTabletPrefixes = DeviceIds.huaweiTablets;
    const isHuaweiTablet = huaweiTabletPrefixes.some((prefix) => userAgent.includes(prefix));
    if (isHuaweiTablet) return true;

    return userAgent.includes('HUAWEI');
  }

  public isMobile(): boolean {
    return this.isAndroid() || this.isIOS() || this.isHuawei();
  }

  public getDeviceType(): DeviceType {
    if (this.isHuawei()) return 'HUAWEI';
    if (this.isAndroid()) return 'ANDROID';
    if (this.isIOS()) return 'IOS';
    return 'DESKTOP';
  }

  public getDeviceTypeAnalytics(): string {
    if (this.isHuawei()) return 'Huawei';
    if (this.isAndroid()) return 'Android';
    if (this.isIOS()) return 'iOS';
    return 'Desktop';
  }
}

import { Injectable } from '@angular/core';
import * as CryptoJs from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class CryptoService {
  private readonly KEY: CryptoJS.lib.WordArray;
  private readonly IV: CryptoJS.lib.WordArray;

  constructor() {
    this.KEY = this.generateKey();
    this.IV = this.generateIV();
  }

  private generateKey(): CryptoJS.lib.WordArray {
    return CryptoJs.enc.Hex.parse('0123456789abcdef0123456789abcdef');
  }

  private generateIV(): CryptoJS.lib.WordArray {
    return CryptoJs.enc.Hex.parse('abcdef9876543210abcdef9876543210');
  }

  public encrypt(data: unknown): string {
    if (!data) return;
    const stringifiedData = JSON.stringify(data);
    const encrypted = CryptoJs.AES.encrypt(stringifiedData, this.KEY, {
      iv: this.IV
    });
    return encrypted.toString();
  }

  public decrypt(ciphertext: string): unknown {
    if (!ciphertext) return;
    const decrypted = CryptoJs.AES.decrypt(ciphertext, this.KEY, {
      iv: this.IV
    });
    const stringifiedData = CryptoJs.enc.Utf8.stringify(decrypted);
    if (!stringifiedData?.length) return;
    return JSON.parse(stringifiedData);
  }

  public hash(data: unknown): string {
    if (!data) return;
    const stringifiedData = JSON.stringify(data);
    return CryptoJs.SHA3(stringifiedData, { outputLength: 224 }).toString();
  }
}

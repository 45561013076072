import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { Tag, Brand } from '@box-types';
import { ProductDescriptionBadge } from './product-description-badges.types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'product-description-badges',
  templateUrl: './product-description-badges.component.html',
  styleUrls: ['./product-description-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDescriptionBadgesComponent implements OnInit {
  @Input() public tags: Tag[];
  @Input() public brand: Brand;

  public descriptionBadges: ProductDescriptionBadge[];

  @HostBinding('class') public hostClass = 'product-description-badges';

  ngOnInit(): void {
    this.descriptionBadges = this.generateDescriptionBadges();
  }

  private generateDescriptionBadges(): ProductDescriptionBadge[] {
    const brandBadge = this.brandToDescriptionBadge(this.brand);
    const tagBadges = this.tags?.map((taste) => this.tasteToDescriptionBadge(taste)) ?? [];
    return [brandBadge, ...tagBadges]
      .filter(Boolean)
      .filter((b) => b.label)
      .slice(0, 2);
  }

  private tasteToDescriptionBadge(item: Tag): ProductDescriptionBadge {
    if (!item) return;
    const imageUrl = generateImageSrc(item.image);
    const label = item.name;
    return { imageUrl, label };
  }

  private brandToDescriptionBadge(item: Brand): ProductDescriptionBadge {
    if (!item) return;
    const imageUrl = generateImageSrc(item.tagImage);
    const label = item.name;
    return { imageUrl, label };
  }
}

import { Injectable } from '@angular/core';
import {
  Language,
  LanguageCode,
  APIError,
  User,
  ConfigurationTranslations,
  ConfigurationMultilingualReplacement,
  Configuration,
  ConfigurationToBeHydrated,
  APIResponse,
  GetTextByKeyType
} from '@box-types';
import { BehaviorSubject, Observable, mapTo, of, catchError } from 'rxjs';
import { ConfigurationService } from '@box-core/services/configuration.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { isPrerenderBrowser, storageSet, storageGet, getObjectKeysRecursively } from '@box/utils';
import { environment } from '@box-env/environment';
import { UserService } from '@box-core/services/user.service';
import { set, get } from 'lodash-es';
import { tap, map } from 'rxjs/operators';
import localTranslations from '@box-assets/translations';
import { Platform } from '@angular/cdk/platform';
import dayjs from 'dayjs';
import { SentryService } from '@box-core/services/sentry.service';

import 'dayjs/locale/el';
import 'dayjs/locale/en';
import cloneDeep from 'lodash-es/cloneDeep';
import { CurrencyService } from '@box-core/services/currency.service';
import { QueryParamsService } from '@box-core/services/query-params.service';

const LANGUAGE_STORAGE_KEY = 'Box:languageCode';
const BOT_LANGUAGE_CODE = 'el';
const DEFAULT_LANGUAGE_CODE = 'el';

export let translate: GetTextByKeyType;

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private BOX_API: string = environment.application.API_URL;
  private readonly language = new BehaviorSubject<Language>(null);
  public readonly translations = new BehaviorSubject<Record<string, string>>(null);

  constructor(
    private configurationService: ConfigurationService,
    private userService: UserService,
    private httpClient: HttpClient,
    private queryParamsService: QueryParamsService,
    private platform: Platform,
    private sentryService: SentryService,
    private currencyService: CurrencyService // todo refactor does not belong here
  ) {
    translate = this.getTextByKey.bind(this);
  }

  public initializeLanguagePreference(activatedRouteSnapshot: ActivatedRouteSnapshot): void {
    if (isPrerenderBrowser(window) || !this.platform.isBrowser) {
      const language = this.findLanguageByLanguageCode(BOT_LANGUAGE_CODE);
      return this.setLanguage(language);
    }

    const langParam = activatedRouteSnapshot?.queryParams?.lang as string;
    if (langParam) {
      const language = this.findLanguageByLanguageCode(langParam);
      this.setLanguage(language);
      this.saveLanguageCodeToStorage(language.languageCode);
      this.setUserLanguage(language.languageCode).subscribe();
      this.queryParamsService.clearQueryParam(activatedRouteSnapshot, 'lang');
      return;
    }

    const userPreferredLanguage = this.userService.getUser().preferredLanguage;
    if (userPreferredLanguage) {
      const language = this.findLanguageByLanguageCode(userPreferredLanguage);
      this.setLanguage(language);
      this.saveLanguageCodeToStorage(language.languageCode);
      return;
    }

    const localStorageLanguageCode = storageGet(LANGUAGE_STORAGE_KEY, window.localStorage) as LanguageCode;
    if (localStorageLanguageCode) {
      const language = this.findLanguageByLanguageCode(localStorageLanguageCode);
      this.setLanguage(language);
      this.setUserLanguage(language.languageCode).subscribe();
      return;
    }

    const browserLanguage = this.getBrowserLanguagePreference();
    if (this.userService.isNewUser() && browserLanguage) {
      const language = this.findLanguageByLanguageCode(browserLanguage);
      this.setLanguage(language);
      this.setUserLanguage(language.languageCode).subscribe();
      return;
    }

    const language = this.findLanguageByLanguageCode(DEFAULT_LANGUAGE_CODE);
    this.setLanguage(language);
    this.setUserLanguage(language.languageCode).subscribe();
  }

  public setLanguage(language: Language): void {
    dayjs.locale(language.languageCode);
    this.language.next(language);
  }

  private findLanguageByLanguageCode(languageCode: LanguageCode | string): Language {
    const languages = this.configurationService.getConfiguration()?.languages;
    if (!languages?.length) return;
    if (!languageCode) return languages[0];

    const foundLanguage = languages.find((language) => {
      const configLanguageCode = language.languageCode.toLowerCase();
      const languageCodeToSearch = languageCode.toLowerCase();
      return languageCodeToSearch.includes(configLanguageCode);
    });
    return foundLanguage ?? languages[0];
  }

  public initializeConfiguration$(): Observable<boolean> {
    return this.getConfigTranslations().pipe(
      tap((translations) => {
        const configuration = this.configurationService.getConfiguration() as unknown as ConfigurationToBeHydrated;
        const hydratedConfiguration = this.hydrateConfigurationWithTranslations(configuration, translations);
        if (hydratedConfiguration) this.configurationService.setConfiguration(hydratedConfiguration);
      }),
      mapTo(true)
    );
  }

  private hydrateConfigurationWithTranslations(
    configuration: ConfigurationToBeHydrated,
    translations: ConfigurationTranslations
  ): Configuration {
    if (!configuration) return;
    if (!translations) return configuration as unknown as Configuration;

    const configurationKeys = getObjectKeysRecursively(configuration);
    const hydratedConfiguration = cloneDeep(configuration);

    configurationKeys.map((key) => {
      const configurationValue = get(configuration, key);
      if (!configurationValue) return;
      if (!this.isObjectConfigurationMultilingualReplacement(configurationValue)) return;

      const replacementType = (configurationValue as ConfigurationMultilingualReplacement).type;
      const replacementKey = (configurationValue as ConfigurationMultilingualReplacement).key;
      const translatedValue = translations?.[replacementType]?.[replacementKey];

      if (translatedValue) set(hydratedConfiguration, key, translatedValue);

      /**
       * if the translation is not present keep the original configuration value (which is the replacement object)
       * If the replacement type is strings then set the configuration value as the replacement key as a failsafe mechanism
       */
      if (!translatedValue && replacementType === 'strings') set(hydratedConfiguration, key, replacementKey);
    });

    return hydratedConfiguration as unknown as Configuration;
  }

  public initializeLocalClientTranslations(): void {
    const languageCode = this.getLanguageCode();
    const translationsObject = localTranslations[languageCode].clientTranslations;
    this.translations.next(translationsObject);
  }

  public getLanguage(): Language {
    return this.language.getValue();
  }

  public getLanguageCode(): LanguageCode {
    const language = this.getLanguage();
    return language?.languageCode;
  }

  private getTranslations(): Record<string, string> {
    return this.translations.getValue();
  }

  public setUserLanguage(languageCode: LanguageCode): Observable<null> {
    const user = this.userService.getUser();
    if (this.userService.isGuest) return of(null);
    const currentUserPreferredLanguage = user?.preferredLanguage;
    if (currentUserPreferredLanguage === languageCode) return of(null);
    return this.putRemoteUserLanguage(languageCode).pipe(
      tap(() => {
        const updatedUser = { ...user, preferredLanguage: languageCode } as User;
        this.userService.setUser(updatedUser);
      })
    );
  }

  private putRemoteUserLanguage(languageCode: LanguageCode): Observable<null> {
    return this.httpClient.put(this.BOX_API + '/users/preferred-language', { preferredLanguage: languageCode }).pipe(
      mapTo(null),
      // this.delayedRetryService.delayedRetry(),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'User',
          domainDetails: 'Preferred language',
          severity: 'info'
        });
        return of(null);
      })
    );
  }

  public saveLanguageCodeToStorage(languageCode: LanguageCode): void {
    storageSet(LANGUAGE_STORAGE_KEY, languageCode, window.localStorage);
  }

  private getBrowserLanguagePreference(): string {
    return navigator.language || navigator['userLanguage'];
  }

  private getConfigTranslations(): Observable<ConfigurationTranslations> {
    const language = this.getLanguage();
    const remoteConfigTimestamp = dayjs(language?.cacheTimestamp ?? 0);
    const localConfigTimestamp = dayjs(localTranslations.configTranslationsTimestamp);
    if (remoteConfigTimestamp.isAfter(localConfigTimestamp)) {
      // in that case the remote config transaltions have been updated, and we need to fetch them
      return this.httpClient
        .get(language.fileUrl)
        .pipe(map((response: APIResponse<ConfigurationTranslations>) => response.payload));
    } else {
      return of(localTranslations[language.languageCode].configTranslations);
    }
  }

  // todo refactor when currencies implementation is released
  public getTextByKey(key: string, replacementDictionary?: Record<string, string>): string {
    let translatedValue = this.getTranslations()?.[key];
    if (!translatedValue) return this.currencyService.currencyTextReplace(key);

    if (!replacementDictionary) return this.currencyService.currencyTextReplace(translatedValue);

    Object.entries(replacementDictionary).map((keyValuePair) => {
      const regex = new RegExp(keyValuePair[0], 'g');
      translatedValue = translatedValue.replace(regex, keyValuePair[1]);
    });
    return this.currencyService.currencyTextReplace(translatedValue);
  }

  private isObjectConfigurationMultilingualReplacement(obj: unknown): boolean {
    if (!obj) return false;
    if (typeof obj !== 'object') return false;

    const keys = Object.keys(obj);
    if (keys?.length !== 2) return false;

    if (!keys.includes('key') || !keys.includes('type')) return false;

    const valueOfKeyProp = obj['key'];
    const valueOfTypeProp = obj['type'];
    if (typeof valueOfKeyProp !== 'string') return false;

    if (!['strings', 'links', 'images'].includes(valueOfTypeProp)) return false;

    return true;
  }

  public areThereMultipleLanguages(): boolean {
    const config = this.configurationService.getConfiguration();
    const languages = config?.languages?.length;
    if (!languages) return false;
    return languages >= 2;
  }
}

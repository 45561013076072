import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { getLegalDocumentBody } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class BoxTermsDialogService {
  constructor(private http: HttpClient) {}

  public fetchTerms(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }
}

import { Cuisine, DiscoverSortingOption, Shop, ShopSortingInfoKey } from '@box-types';
import { removePromotedTag } from '../shops';

export {
  getSortingKeyFromOptions,
  defaultSortingIsNotEnabled,
  sortShopsBasedOnOption,
  getSortedShopsBasedOnRating,
  getFilterSortingOptionKey
};

function getFilterSortingOptionKey(sortingOptions: DiscoverSortingOption[]): ShopSortingInfoKey {
  if (!sortingOptions?.length) return;
  const checkedOption = sortingOptions?.find((o) => o.checked);
  if (!checkedOption?.slug) return;
  switch (checkedOption.slug) {
    case 'ratingOverall':
      return 'ratings';
    case 'deliveryEstimationTime':
      return 'deliveryTime';
    case 'minimumAllowedDeliveryOrderTotalPrice':
      return 'minimumOrder';
  }
}

function defaultSortingIsNotEnabled(sortingOptions: DiscoverSortingOption[]): boolean {
  const checkedOption = sortingOptions?.find((o) => o.checked);
  if (!checkedOption?.slug) return true;
  return checkedOption.slug !== 'recommended';
}

function getSortingKeyFromOptions(
  searchTerm: string,
  checkedFoodCuisine: Cuisine,
  sortingOptions: DiscoverSortingOption[]
): ShopSortingInfoKey {
  const filterSortingOptionKey = getFilterSortingOptionKey(sortingOptions);
  if (filterSortingOptionKey) return filterSortingOptionKey;
  const hasSearchQuery = Boolean(searchTerm?.length);
  if (hasSearchQuery) return 'search';
  if (checkedFoodCuisine) return 'defaultForCuisine';
  return 'default';
}

function getSortedShopsBasedOnRating(shops: Shop[]): Shop[] {
  return shops.sort((a, b) => {
    if (a.ratingCounter >= 5 && b.ratingCounter < 5) return -1; // a before b
    if (b.ratingCounter >= 5 && a.ratingCounter < 5) return 1; // b before a
    return b.ratingOverall - a.ratingOverall; // Descending
  });
}

function sortShopsBasedOnOption(shops: Shop[], sortingOption: DiscoverSortingOption): Shop[] {
  if (!shops || shops.length === 0) return [];
  if (!sortingOption || !sortingOption.slug) return [];
  if (sortingOption.slug === 'recommended') return shops;
  const shopsWithoutPromoTag = removePromotedTag(shops);
  if (sortingOption.slug === 'ratingOverall') return getSortedShopsBasedOnRating(shopsWithoutPromoTag);
  return shopsWithoutPromoTag.sort((a, b) => a[sortingOption.slug] - b[sortingOption.slug]); // Ascending
}

function storageGet<T>(key: string, storage: Storage): T {
  try {
    if (!storage) return;
    const item = storage.getItem(key);
    if (!item) return;
    return JSON.parse(item) as T;
  } catch {
    return;
  }
}

function storageSet<T>(key: string, value: T, storage: Storage): void {
  try {
    if (!storage) return;
    return storage.setItem(key, JSON.stringify(value));
  } catch {
    return;
  }
}

function storageRemove(key: string, storage: Storage): void {
  try {
    if (!storage) return;
    return storage.removeItem(key);
  } catch {
    return;
  }
}

function isStorageSupported(webStorageType: 'localStorage' | 'sessionStorage' = 'localStorage'): boolean {
  try {
    const storage: Storage = window[webStorageType];
    if (!storage) return false;
    const test = `STORAGE_TEST`;
    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch (err: unknown) {
    return false;
  }
}

export { storageGet, storageSet, storageRemove, isStorageSupported };

import { Injectable } from '@angular/core';
import { CouponsService, PromoCampaignsService } from '@box-core/services';
import { DiscoverFilter, PromoVisualOptions } from '@box-types';
import { couponSpecificPromoOptions, getSpecificPromoCampaignCoupons } from '@box/utils';

@Injectable()
export class HomeDiscoverFilterService {
  constructor(private couponsService: CouponsService, private promoCampaignsService: PromoCampaignsService) {}

  public getDiscoverFilterCouponSpecificVisualOptions(filter: DiscoverFilter): PromoVisualOptions[] {
    const filterEligibleCouponSynergies = filter?.eligibleCouponSynergies;
    const filterHasNoEligibleCouponSynergies = !filterEligibleCouponSynergies?.length;
    if (filterHasNoEligibleCouponSynergies) return;

    const availableCoupons = this.couponsService.getAvailableCoupons();
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();

    const couponsPromos = getSpecificPromoCampaignCoupons(availableCoupons, promoCampaigns);
    if (!couponsPromos?.length) return;

    const couponsFilterPromo = couponsPromos.filter((coupon) => filterEligibleCouponSynergies.includes(coupon.synergy));

    const couponVisualOptions = couponSpecificPromoOptions(couponsFilterPromo, 'shortTag');
    return [couponVisualOptions];
  }
}

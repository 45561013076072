import { Injectable } from '@angular/core';
import { Review } from '@box-types';
import { getInitials } from '@box/utils';
import dayjs from 'dayjs';

@Injectable()
export class ReviewService {
  public getReviewFormattedDate(review: Review): string {
    return dayjs(review.createdStamp).format('DD/MM/YY');
  }

  public getReviewInitials(review: Review): string {
    const firstName = review.firstName ?? '';
    const lastName = review.lastName ?? '';
    return getInitials(`${firstName} ${lastName}`);
  }

  public getReviewRecommendation(review: Review): string {
    if (!review.products?.length) return '';
    return review.products.map((v) => v.name).join(', ');
  }

  public getReviewName(review: Review): string {
    const firstName = review.firstName ?? '';
    const lastName = review.lastName ?? '';
    const lastNameInitial = getInitials(lastName);
    return `${firstName} ${lastNameInitial}`;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { BoxSession, CosmoteIDTokenConfig, APIError } from '@box-types';
import { AnalyticsService, AuthenticationService, SentryService } from '@box-core/services';

@Injectable({ providedIn: 'root' })
export class LoginResolver implements Resolve<BoxSession> {
  private ROOT_URL: string = environment.application.ROOT_URL;

  constructor(
    private router: Router,
    private platform: Platform,
    private sentryService: SentryService,
    private analyticsService: AnalyticsService,
    private authenticationService: AuthenticationService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BoxSession> {
    if (!this.platform.isBrowser) return of(undefined as BoxSession);

    const code: string = route.queryParams.code as string;
    const tokenConfig: CosmoteIDTokenConfig = {
      grantType: 'AUTHORIZATION_CODE',
      code,
      redirectUri: this.ROOT_URL + '/login'
    };

    return this.authenticationService.getCosmoteToken(tokenConfig).pipe(
      tap((authData) => this.authenticationService.setCosmoteTokens(authData)),
      switchMap((authData) => this.authenticationService.initiateBoxSession(authData.access_token)),
      tap((session: BoxSession) => {
        this.analyticsService.addGAUserIdEvent(session.cosmoteIdUser.extguid);
        this.authenticationService.setBoxToken(session);
        void this.router.navigate(['/login', 'redirect']);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Authentication',
          domainDetails: 'Login Resolver',
          severity: 'error'
        });
        this.authenticationService.clearCosmoteTokens();
        this.authenticationService.clearBoxToken();
        void this.router.navigate(['/']);
        return throwError(() => error);
      })
    );
  }
}

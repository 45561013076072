import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { Coordinates } from '@box-types';
import { coordinatesToLatLng } from '@box/utils';
import cloneDeep from 'lodash-es/cloneDeep';
import { DriverLocation } from '@box-checkout/components/live-tracking-map/live-tracking-map.types';
import { ConfigurationService } from './configuration.service';

@Injectable({ providedIn: 'any' })
export class MarkerAnimationService {
  private readonly FREQUENCY = 15; // in milliseconds ~60fps

  private stepLatitude: number;
  private stepLongitude: number;

  constructor(private configService: ConfigurationService) {}

  public getAnimatedLocationPoints(
    currentCoordinates: Readonly<DriverLocation>,
    nextCoordinates: Readonly<DriverLocation>
  ): Observable<google.maps.LatLng> {
    if (!currentCoordinates || !nextCoordinates) return;
    this.initializeStepDeltas(currentCoordinates, nextCoordinates);

    return new Observable<google.maps.LatLng>((subscriber) => {
      if (currentCoordinates === nextCoordinates) {
        subscriber.next(coordinatesToLatLng(currentCoordinates));
        return subscriber.complete();
      }

      let i = 0;
      const newCoordinates = cloneDeep(currentCoordinates) as Coordinates;
      const animationFrames = this.getTotalAnimationFrames();
      const intervalSubscription = timer(0, this.FREQUENCY).subscribe(() => {
        if (i >= animationFrames) {
          intervalSubscription.unsubscribe();
          subscriber.complete();
          return;
        }

        newCoordinates.latitude = newCoordinates.latitude + this.stepLatitude;
        newCoordinates.longitude = newCoordinates.longitude + this.stepLongitude;
        subscriber.next(coordinatesToLatLng(newCoordinates));
        i++;
      });
    });
  }

  private initializeStepDeltas(currentCoordinates: Coordinates, nextCoordinates: Coordinates): void {
    this.stepLatitude = this.calculateStepLength(nextCoordinates.latitude - currentCoordinates.latitude);
    this.stepLongitude = this.calculateStepLength(nextCoordinates.longitude - currentCoordinates.longitude);
  }

  private calculateStepLength(distance: number): number {
    return distance / this.getTotalAnimationFrames();
  }

  private getTotalAnimationFrames(): number {
    return this.getPollingFrequency() / this.FREQUENCY;
  }

  private getPollingFrequency(): number {
    return this.configService.getConfiguration().liveTrackingTimeInterval ?? 10 * 1000; // in ms
  }
}

import { Component, OnDestroy, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { CosmoteIDService, AnalyticsService } from '@box-core/services';
import { BreakpointObserver } from '@angular/cdk/layout';

// /delivery includes the shop page
const routesWithMobileNavFooter = [
  '/home',
  '/discover',
  '/account',
  '/cookies',
  '/terms',
  '/privacy',
  '/gdpr',
  '/faqs',
  '/rewards',
  '/contest',
  '/checkout/order-status'
];

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'box-shell',
  templateUrl: './box-shell.component.html',
  styleUrls: ['./box-shell.component.scss']
})
export class BoxShellComponent implements OnDestroy {
  @ViewChild('boxBody', { static: true }) boxBody: ElementRef<HTMLDivElement>;

  private gaPageViewSubscription: Subscription;
  private routerSubscription: Subscription;
  private mobileNavSubscription: Subscription;

  public showMobileNav: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cosmoteIDService: CosmoteIDService,
    private analyticsService: AnalyticsService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.setRoutingEventsSubscriptions();
    this.setMobileNavSubscription();
  }

  @HostBinding('class.show-mobile-footer-menu') public showMobileNavHostClass: boolean;

  ngOnDestroy(): void {
    if (this.gaPageViewSubscription) this.gaPageViewSubscription.unsubscribe();
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
    if (this.mobileNavSubscription) this.mobileNavSubscription.unsubscribe();
    this.cosmoteIDService.setReturnURL('');
  }

  private setRoutingEventsSubscriptions(): void {
    this.gaPageViewSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: Data) => this.pageViewGAEvent((event as { name: string }).name, this.router.url));

    this.routerSubscription = this.router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.setCosmoteIDUrls();
        this.boxBody.nativeElement.scrollTop = 0;
      }
    });
  }

  private setMobileNavSubscription(): void {
    this.mobileNavSubscription = combineLatest([
      this.router.events,
      this.breakpointObserver.observe([MOBILE_BREAKPOINT])
    ]).subscribe(([routerEvent]) => {
      if (!(routerEvent instanceof NavigationEnd)) return undefined;
      const nextRoute = (routerEvent as RouterEvent).url;
      const isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
      const routeNeedsMobileNav = !!routesWithMobileNavFooter.find((route) => nextRoute.startsWith(route));
      const showMobileNav = routeNeedsMobileNav && isSmallScreen;
      this.showMobileNavHostClass = showMobileNav;
      this.showMobileNav = showMobileNav;
    });
  }

  private setCosmoteIDUrls(): void {
    this.cosmoteIDService.setReturnURL(this.router.url);
  }

  private pageViewGAEvent(name: string, url: string): void {
    this.analyticsService.addGACustomEvent('page_view', { name, url });
  }
}

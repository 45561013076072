import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  PhoneVerificationDialogData,
  PhoneVerificationDialogResponse,
  PhoneVerificationDialogState
} from './phone-verification-dialog.types';
import { PhoneVerificationDialogService } from './phone-verification-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { VerificationReason, APIError } from '@box-types';
import { VerificationResendCodeComponent, BoxDialogWrapperComponent } from '@box-shared/components';
import { mobilePhoneValidator } from '@box-shared/validators';

@Component({
  selector: 'phone-verification-dialog',
  templateUrl: './phone-verification-dialog.component.html',
  styleUrls: ['./phone-verification-dialog.component.scss'],
  providers: [PhoneVerificationDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneVerificationDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  @ViewChild('verificationResendCodeComponent') public resendCodeComponent: VerificationResendCodeComponent;

  public loading: boolean;
  public mobilePhone: string;
  public verificationReason: VerificationReason;
  public state: PhoneVerificationDialogState;
  public description: string;
  public supportPhoneNumber: string;
  public phoneControl: FormControl;
  public verificationControl: FormControl;

  constructor(
    public renderer: Renderer2,
    private phoneVerificationDialogService: PhoneVerificationDialogService,
    @Inject(MAT_DIALOG_DATA) private data: PhoneVerificationDialogData,
    private dialogRef: MatDialogRef<PhoneVerificationDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.mobilePhone = this.data.mobilePhone ?? '';
    this.verificationReason = this.data.verificationReason;
    this.state = 'PHONE';
    this.description = this.phoneVerificationDialogService.getDescription(this.state, this.mobilePhone);
    this.supportPhoneNumber = this.phoneVerificationDialogService.getSupportPhoneNumber();
    this.phoneControl = new FormControl(
      this.mobilePhone,
      Validators.compose([Validators.required, mobilePhoneValidator()]) // eslint-disable-line @typescript-eslint/unbound-method
    );
    this.verificationControl = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)]) // eslint-disable-line @typescript-eslint/unbound-method
    );
    if (this.data.autoGenerate && this.phoneControl.valid) this.onSendCode();
  }

  public closeDialog(response?: PhoneVerificationDialogResponse): void {
    this.dialogRef.close(response);
  }

  public onSendCode(): void {
    if (this.phoneControl.invalid) return;
    const msisdn = this.phoneControl.value as string;
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.phoneVerificationDialogService
      .generateOTP({ msisdn, reason: this.verificationReason })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.state = 'PASSWORD';
          this.description = this.phoneVerificationDialogService.getDescription(this.state, msisdn);
          // We have to do this due to the ngIf and the change Detection getting triggered by state change
          // That way be push the startDelay to the end of the stack
          setTimeout(() => {
            this.resendCodeComponent.startDelay();
            this.verificationControl.setValue('');
            this.verificationControl.markAsUntouched();
          });
        },
        error: (error: APIError) => this.phoneVerificationDialogService.openErrorDialog(error)
      });
  }

  public onVerification(): void {
    if (this.verificationControl.invalid) return;
    const msisdn = this.phoneControl.value as string;
    const otp = this.verificationControl.value as string;
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.phoneVerificationDialogService
      .verifyPhone({ msisdn, otp })
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (verifyResponse) => this.closeDialog({ success: true, verifyResponse }),
        error: (error: APIError) => this.phoneVerificationDialogService.openErrorDialog(error)
      });
  }
}

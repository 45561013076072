<offer-wizard
  [@offerWizardAnimation]
  *ngIf="state === 'OFFER'"
  [options]="offerWizardOptions"
  (wizardClose)="closeDialog()"
  (productMYO)="onProductMYO($event)"
  (wizardSubmit)="onSubmit($event)"
  (offerInstanceChange)="onOfferInstanceChange($event)"
></offer-wizard>
<product-myo
  [@productMYOAnimation]
  *ngIf="state === 'PRODUCT'"
  [options]="productMYOOptions"
  (myoBack)="onMYOBack()"
  (myoSubmit)="onProductMYOSubmit($event)"
></product-myo>

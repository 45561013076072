import { Shop, Order, Chain, ShopGroupType } from '@box-types';
import { uniq } from 'lodash-es';
import { shopMatchesTagId } from '../shops/shop-tag.utils';

export {
  filterShopsByChainKeys,
  filterShopsByCollectionTypes,
  filterShopsByOfferKinds,
  filterFrequentShops,
  filterShopsByChains,
  filterShopsByGroups,
  filterShopsByTagIds,
  filterShopsByGroupings
};

function filterShopsByChainKeys(shops: Shop[], chainKeys: string[]): Shop[] {
  if (!shops?.length || !chainKeys?.length) return [];
  return shops.filter((shop) => shop?.chain && chainKeys.includes(shop.chain));
}

function filterShopsByChains(shops: Shop[], chains: Chain[]): Shop[] {
  const chainKeys = chains.map((p) => p.chainKey);
  return filterShopsByChainKeys(shops, chainKeys);
}

function filterShopsByCollectionTypes(shops: Shop[], collectionTypes: number[]): Shop[] {
  if (!shops?.length || !collectionTypes?.length) return [];
  return shops.filter((shop) => collectionTypes.includes(shop.collectionType));
}

function filterShopsByOfferKinds(shops: Shop[], offerKinds: string[]): Shop[] {
  if (!shops?.length) return [];
  if (!offerKinds?.length) return shops;
  return shops.filter((shop) => offerKinds.some((type) => shop.offerTypes?.includes(type)));
}

function filterFrequentShops(shops: Shop[], orders: Order[]): Shop[] {
  const openShops = shops.filter((s) => s.operatingState === 'OPEN');
  const shopsIdsFromOrders = uniq(orders.map((o) => o.shop._id));
  return shopsIdsFromOrders.map((id) => openShops.find((shop) => shop._id === id)).filter(Boolean);
}

function filterShopsByGroups(shops: Shop[], groups: ShopGroupType[]): Shop[] {
  if (!shops?.length) return [];
  if (!groups?.length) return shops;
  return shops.filter((shop) => shop.groups && shop.groups.some((group) => groups.includes(group)));
}

function filterShopsByTagIds(shops: Shop[], tagIds: string[]): Shop[] {
  if (!shops?.length) return [];
  if (!tagIds?.length) return shops;
  return shops.filter((shop) => tagIds.some((id) => shopMatchesTagId(shop, id)));
}

function filterShopsByGroupings(shops: Shop[], groupings: string[]): Shop[] {
  if (!shops?.length) return [];
  if (!groupings?.length) return shops;
  return shops.filter((shop) => groupings.some((id) => shop.groupings?.includes(id)));
}

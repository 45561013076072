import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DownloadService } from './download.service';

@Component({
  selector: 'download-page',
  templateUrl: './download.page.html',
  styleUrls: ['./download.page.scss'],
  providers: [DownloadService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadPage implements OnInit {
  public androidUrl: string;
  public iosUrl: string;
  public huaweiUrl: string;

  constructor(private downloadService: DownloadService) {}

  ngOnInit(): void {
    this.downloadService.setMetaTags();
    this.androidUrl = this.downloadService.getAndroidDownloadLink();
    this.iosUrl = this.downloadService.getIOSDownloadLink();
    this.huaweiUrl = this.downloadService.getHuaweiDownloadLink();
  }

  public onDownloadAppClick(platform: string): void {
    this.downloadService.setDownloadPageAnalayticsEvent(platform);
  }
}

import { OfferInstance, Offer, ProductPromo } from '@box-types';
import { getBestProductPromo } from '../shops/shop.utils';

export {
  isOfferWizardComplete,
  getOfferInstanceProductsTotalQuantity,
  getOfferInstanceGroupIndexToExpand,
  getOfferBestPromo
};

function isOfferWizardComplete(offer: Offer | OfferInstance): boolean {
  if (!offer.groups?.length) return false;
  return offer.groups.every((group) => Boolean(group.selectedProduct));
}

function getOfferInstanceProductsTotalQuantity(offerInstance: OfferInstance): number {
  if (!offerInstance.groups?.length) return 0;
  const quantity = offerInstance.quantity ?? 0;
  return offerInstance.groups.filter((group) => Boolean(group.selectedProduct)).length * quantity;
}

function getOfferInstanceGroupIndexToExpand(offerInstance: OfferInstance): number {
  if (!offerInstance.groups?.length) return -1;
  return offerInstance.groups.findIndex((group) => !group.selectedProduct);
}

function getOfferBestPromo(offer: Offer): ProductPromo {
  if (!offer.promos?.length) return;
  return getBestProductPromo(offer.promos);
}

<div class="promo-coupon-clipboard-container">
  <promo-coupon [promoCoupon]="coupon"></promo-coupon>
  <button
    mat-icon-button
    [matTooltip]="'copy_to_clipboard' | translate"
    matTooltipShowDelay="600"
    [attr.aria-label]="'copy_code_to_clipboard' | translate"
    ngxClipboard
    (cbOnSuccess)="onCouponCopy()"
    [cbContent]="coupon.code"
  >
    <mat-icon class="promo-coupon-clipboard-button-icon">content_copy</mat-icon>
  </button>
</div>

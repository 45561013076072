import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { Cuisine, Chain, CoreItems, Drink, Brand, Tag, APIResponse } from '@box-types';
import sortBy from 'lodash-es/sortBy';

@Injectable({ providedIn: 'root' })
export class CoreService {
  private BOX_API: string = environment.application.API_URL;
  public coreItems: BehaviorSubject<CoreItems> = new BehaviorSubject<CoreItems>(undefined);
  public cuisines: BehaviorSubject<Cuisine[]> = new BehaviorSubject<Cuisine[]>(undefined);
  public foodCuisines: BehaviorSubject<Cuisine[]> = new BehaviorSubject<Cuisine[]>(undefined);
  public groceriesCuisines: BehaviorSubject<Cuisine[]> = new BehaviorSubject<Cuisine[]>(undefined);
  public chains: BehaviorSubject<Chain[]> = new BehaviorSubject<Chain[]>(undefined);
  public drinks: BehaviorSubject<Drink[]> = new BehaviorSubject<Drink[]>(undefined);
  public brands: BehaviorSubject<Brand[]> = new BehaviorSubject<Brand[]>(undefined);
  public tags: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>(undefined);

  constructor(private http: HttpClient) {}

  public fetchItems(): Observable<CoreItems> {
    return this.http
      .get(this.BOX_API + '/box/discoverItems')
      .pipe(map((response: APIResponse<CoreItems>) => response.payload));
  }

  public filterEnabledItems(items: CoreItems): CoreItems {
    const cuisines = items.cuisines.filter((c) => c.enabled);
    const foodCuisines = cuisines.filter((c) => c.businessVertical === 'food');
    const groceriesCuisines = cuisines.filter((c) => c.businessVertical === 'groceries');
    const chains = items.chains.filter((c) => c.enabled);
    const drinks = items.drinks.filter((d) => d.enabled);
    const tags = items.tags.filter((t) => t.enabled);
    return { cuisines, foodCuisines, groceriesCuisines, chains, drinks, tags, brands: items.brands };
  }

  public setItems(items: CoreItems): void {
    this.coreItems.next(items);
    this.cuisines.next(sortBy(items.cuisines, 'categoryIndex'));
    this.foodCuisines.next(sortBy(items.foodCuisines, 'categoryIndex'));
    this.groceriesCuisines.next(sortBy(items.groceriesCuisines, 'categoryIndex'));
    this.chains.next(sortBy(items.chains, 'chainIndex'));
    this.drinks.next(sortBy(items.drinks, 'drinkIndex'));
    this.tags.next(sortBy(items.tags, 'tagIndex'));
    this.brands.next(sortBy(items.brands, 'index', 'desc'));
  }
}

<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      [title]="('get_a_discount_up_to' | translate) + ' ' + claimableValueInEuro / 100 + '€!'"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-points-dialog-main-wrapper">
      <p class="checkout-points-dialog-instructions ods-typography-body-s-regular">
        {{
          'for_every_points_you_redeem_you_get_one_euro_discount'
            | translate
              : {
                  _POINTS: stepValue | number,
                  _EURO_REDEMPTION_RATE: euroRedemptionRate.toString()
                }
        }}
      </p>
      <div class="checkout-points-dialog-slider-status ods-typography-body-s-bold">
        {{ 'redeeming_' | translate }}
        <points-tag [points]="selectedValue" [simplifiedText]="true"></points-tag>
        {{ 'the_points' | translate }}, {{ 'you_earn_lowercase' | translate }}
        <span class="checkout-points-dialog-slider-status-amount ods-typography-subtitle-s">
          {{ selectedValueInEuro / 100 }}{{ 'currency' | translate }}</span
        >
      </div>
      <checkout-points-slider-data
        class="checkout-points-dialog-slider-data"
        [activeIndex]="activeIndex"
        [steps]="steps"
        [alternativeSteps]="alternativeSteps"
      >
        <box-slider
          [step]="stepValue"
          [value]="selectedValue"
          [claimableValue]="claimableValue"
          [availableValue]="availableValue"
          [maxValue]="maximumValue"
          (valueChange)="onValueChange($event)"
          (inputChange)="onInputChange($event)"
        ></box-slider>
      </checkout-points-slider-data>
      <div class="checkout-points-dialog-total">
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">{{
            'total_' | translate
          }}</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            cartPrice | currencyPipe
          }}</span>
        </div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">{{
            'win_from_the_discount' | translate
          }}</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s"
            >- {{ totalDiscount | currencyPipe }}</span
          >
        </div>
        <div class="box-divider"></div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">{{
            'final_products_price' | translate
          }}</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            cartPrice - totalDiscount | currencyPipe
          }}</span>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer checkout-points-dialog-footer">
    <button box-button theme="primary" class="checkout-points-dialog-footer-button" (click)="onSubmit()">
      {{ 'apply_' | translate }}
    </button>
  </footer>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShopTileCompactLayoutType } from '@box/utils';

@Component({
  selector: 'skeleton-shop-tile-compact',
  templateUrl: './skeleton-shop-tile-compact.component.html',
  styleUrls: ['./skeleton-shop-tile-compact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonShopTileCompactComponent implements OnChanges {
  @Input() public layout: ShopTileCompactLayoutType = 'HORIZONTAL';
  @HostBinding('class') public hostClass = 'skeleton-shop-tile-compact';
  @HostBinding('class.skeleton-shop-tile-compact-vertical') public isVertical = this.layout === 'VERTICAL';
  @HostBinding('class.skeleton-shop-tile-compact-horizontal') public isHorizontal = this.layout === 'HORIZONTAL';

  ngOnChanges(changes: SimpleChanges): void {
    this.layout = changes.layout.currentValue as ShopTileCompactLayoutType;
    this.isVertical = this.layout === 'VERTICAL';
    this.isHorizontal = this.layout === 'HORIZONTAL';
  }
}

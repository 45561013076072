import { Injectable } from '@angular/core';
import { CouponsService, UserService } from '@box-core/services';
import { rewardsNavItems } from './rewards-nav-items';
import { RewardsService } from '@box-rewards/services';
import { BOXNavItem } from '@box-types';
import { map, Observable } from 'rxjs';
import { RewardsNavItem } from './rewards-nav.types';

@Injectable()
export class RewardsNavService {
  constructor(
    private userService: UserService,
    private couponsService: CouponsService,
    private rewardsService: RewardsService
  ) {}

  public generateNavItems$(): Observable<RewardsNavItem[]> {
    return this.couponsService.availableCoupons$.pipe(map(() => this.generateNavItems()));
  }

  private generateNavItems(): RewardsNavItem[] {
    const rewardsCardsNavItem = rewardsNavItems.points;
    const rewardsCouponsNavItem = this.generateCouponsNavItem();
    const navItems = [rewardsCardsNavItem, rewardsCouponsNavItem];
    const rewardsGBNavItem = this.generateRewardsGBNavItem();
    if (rewardsGBNavItem) navItems.push(rewardsGBNavItem);
    return navItems;
  }

  private generateCouponsNavItem(): RewardsNavItem {
    const rewardsCouponsNavItem = rewardsNavItems.coupons;
    const couponsNumber = this.couponsService.getAvailableCoupons().length;
    if (!couponsNumber) return rewardsCouponsNavItem;
    return { ...rewardsCouponsNavItem, badge: couponsNumber };
  }

  private generateRewardsGBNavItem(): BOXNavItem {
    const userCanClaimGBRewards = Boolean(this.userService.getUserMainSegment());
    if (!userCanClaimGBRewards) return;
    const gbRewards = this.rewardsService.getGBRewards().filter((reward) => reward.triggeredByClient);
    if (!gbRewards.length) return;
    return rewardsNavItems.gb;
  }
}

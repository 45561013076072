import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'skeleton-home-shops-section',
  templateUrl: './skeleton-home-shops-section.component.html',
  styleUrls: ['./skeleton-home-shops-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonHomeShopsSectionComponent {
  @HostBinding('class') public hostClass = 'skeleton-home-shops-section';
}

import {
  Component,
  HostBinding,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { Shop, TimetableDayDetails, ChainShopListItem, DeliveryAnchor } from '@box-types';
import { ShopPageDetailsService } from './shop-page-details.service';
import { ThemingService } from '@box-core/services/theming.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopPageDetailsDialogComponent } from '@box-delivery/components';

@Component({
  selector: 'shop-page-details',
  templateUrl: './shop-page-details.component.html',
  styleUrls: ['./shop-page-details.component.scss'],
  providers: [ShopPageDetailsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopPageDetailsComponent implements OnInit, OnDestroy {
  @Input() public shopsWithChain: ChainShopListItem[];
  public name: string;
  public logoUrl: string;
  public cuisineText: string;
  public paymentIcons: string[];
  public minimumOrderPriceText: string;
  public deliveryFeeText: string;
  public mapBackgroundImage: string;
  public addressText: string;
  public addressAnchor: string;
  public phoneNumber: string;
  public timetableDetails: TimetableDayDetails[];
  public taxNumber: string;
  public brandName: string;
  public companyHeadquarters: string;
  public commercialRegister: string;
  public shopsWithChainDeliveryAnchors: DeliveryAnchor[];
  public mainShopDeliveryAnchor: DeliveryAnchor;
  public storeListSelectIcon: 'keyboard_arrow_down' | 'keyboard_arrow_up' = 'keyboard_arrow_down';
  public shakeAnimationEnabled: boolean = false;

  private shop: Shop;
  private themeSubscription: Subscription;

  constructor(
    private shopPageDetailsService: ShopPageDetailsService,
    private themingService: ThemingService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialogRef: MatDialogRef<ShopPageDetailsDialogComponent>
  ) {}

  @HostBinding('class') public hostClass = 'shop-page-details';

  ngOnInit(): void {
    this.shop = this.shopPageDetailsService.getShop();
    this.name = this.shop.name;
    this.taxNumber = this.shop.taxNumber;
    this.brandName = this.shop.legalInfo?.brandName;
    this.companyHeadquarters = this.shop.legalInfo?.companyHeadquarters;
    this.commercialRegister = this.shop.legalInfo?.commercialRegister;
    this.logoUrl = this.shopPageDetailsService.getShopLogoUrl();
    this.cuisineText = this.shop.cuisinesText;
    this.paymentIcons = this.shopPageDetailsService.generatePaymentIcons();
    this.minimumOrderPriceText = this.shopPageDetailsService.getShopMinimumOrderPriceText();
    this.deliveryFeeText = this.shopPageDetailsService.getShopDeliveryFeeText();
    this.addressText = this.shopPageDetailsService.getAddressText();
    this.addressAnchor = this.shopPageDetailsService.getAddressAnchor();
    this.phoneNumber = this.shopPageDetailsService.getPhoneNumber();
    this.timetableDetails = this.shopPageDetailsService.getTimetableDayDetails();
    this.shopsWithChainDeliveryAnchors = this.shopPageDetailsService.shopsWithChainToDeliveryAnchors(
      this.shopsWithChain
    );
    this.mainShopDeliveryAnchor = this.shopPageDetailsService.findMainChainShopDeliveryAnchor(
      this.shopsWithChainDeliveryAnchors
    );
    this.setThemeSubscription();
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.subscribe((theme) => {
      this.mapBackgroundImage = this.shopPageDetailsService.generateMapBackgroundImage(theme, this.shopsWithChain);
      this.changeDetectorRef.detectChanges();
    });
  }

  public onStoreChange(anchor: DeliveryAnchor): void {
    this.router.navigateByUrl(anchor.link);
    this.closeDialog();
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }

  public onSelectToggle(): void {
    this.storeListSelectIcon =
      this.storeListSelectIcon === 'keyboard_arrow_down' ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    this.changeDetectorRef.detectChanges();
  }

  public onMapClick(): void {
    this.shakeAnimationEnabled = true;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.shakeAnimationEnabled = false;
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }
}

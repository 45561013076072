import { copyProductPropertiesAfterAvailabilityCheck, getProductAvailabilityOptions } from '../products';
import { cloneDeep } from 'lodash-es';
import { Offer, OfferAvailabilityOptions, OfferGroup } from '@box-types';

export {
  decorateOfferWithPseudoId,
  getOfferAvailabilityOptions,
  transferOfferAvailabilityOptionsToOfferGroups,
  copyOfferPropertiesAfterAvailabilityCheck
};

function decorateOfferWithPseudoId(offer: Offer, index: number): Offer {
  return { ...offer, pseudoOfferId: `${offer._id}_${index}` };
}

function getOfferAvailabilityOptions(offer: Offer): OfferAvailabilityOptions {
  return {
    offerId: offer._id,
    name: offer.name,
    products: offer.groups.map((group) => getProductAvailabilityOptions(group.products[0])),
    quantity: offer.quantity,
    ...(offer.pseudoOfferId && { pseudoOfferId: offer.pseudoOfferId })
  };
}

function transferOfferAvailabilityOptionsToOfferGroups(
  offer: Offer,
  offerAvailabilityOptions: OfferAvailabilityOptions
): OfferGroup[] {
  const { groups: offerGroups } = offer;
  if (!offerGroups?.length) return [];
  if (!offerAvailabilityOptions?.products?.length) return offerGroups;

  return cloneDeep(offerGroups).map((offerGroup, index) => {
    const offerAvailabilityProduct = offerAvailabilityOptions.products[index];
    if (!offerAvailabilityProduct) return offerGroup;

    const mergedGroupProducts = offerGroup.products.map((groupProduct) => {
      const checked = offerAvailabilityProduct.productId === groupProduct.productId;
      /** We want to transfer the user selections only for the selected Group products, not for all the products.
       * Otherwise, by calling the `copyProductPropertiesAfterAvailabilityCheck` for each non selected product
       * we are "resetting" the selection choices, causing an issue. */
      const mergedProduct = checked
        ? copyProductPropertiesAfterAvailabilityCheck(groupProduct, offerAvailabilityProduct)
        : groupProduct;
      return { ...mergedProduct, checked };
    });
    return { ...offerGroup, products: mergedGroupProducts };
  });
}

function copyOfferPropertiesAfterAvailabilityCheck(offer: Offer, options: OfferAvailabilityOptions): Offer {
  if (!options) return offer;
  const groups = transferOfferAvailabilityOptionsToOfferGroups(offer, options);

  return {
    ...offer,
    ...(options.quantity && { quantity: options.quantity }),
    ...(groups.length && { groups })
  };
}

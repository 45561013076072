import { Component, OnInit } from '@angular/core';
import { appConfig } from '@box-configs/app.config';
import { AnalyticsService } from '@box-core/services';

@Component({
  selector: 'box-app-links',
  templateUrl: './box-app-links.component.html',
  styleUrls: ['./box-app-links.component.scss']
})
export class BoxAppLinksComponent implements OnInit {
  public androidUrl: string;
  public iosUrl: string;
  public huaweiUrl: string;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.androidUrl = appConfig.mobile.ANDROID_APP;
    this.iosUrl = appConfig.mobile.IOS_APP;
    this.huaweiUrl = appConfig.mobile.HUAWEI_APP;
  }

  public onDownloadAppClick(event: Event, platform: string): void {
    const source = 'Footer';
    this.analyticsService.addGACustomEvent('click_app_download', { platform, source });
  }
}

import { Cuisine, DeliveryAnchor, Location, Shop } from '@box-types';
import orderBy from 'lodash-es/orderBy';
import groupBy from 'lodash-es/groupBy';
import { removeAccents } from '../string.utils';
import { filterLocationsWithCuisine, filterCuisinesUnderLocation } from './locations-filter.utils';

const DEFAULT_LOCATION_PRIORITY = 999;
const MAXIMUM_LOCATIONS_DELIVERY_ANCHORS = 20;
const MAXIMUM_SHOPS_DELIVERY_ANCHORS = 20;

function normalizeLocation(location: Location): Location {
  if (!location) return;
  return {
    ...location,
    priority: location.priority ?? DEFAULT_LOCATION_PRIORITY
  };
}

function groupLocations(locations: Location[]): { [key: string]: Location[] } {
  if (!locations?.length) return;
  const filteredLocations = locations.filter((l) => l && l.group);
  if (!filteredLocations?.length) return;
  const sortedLocations = orderBy(filteredLocations, (l: Location) => removeAccents(l.name), 'asc');
  return groupBy(sortedLocations, (l: Location) => l.group) as { [key: string]: Location[] };
}

function locationCuisineToDeliveryAnchor(location: Location, cuisine: Cuisine): DeliveryAnchor {
  const cuisineDirectory = cuisine.businessVertical === 'food' ? 'kouzines' : 'psonia';
  return {
    text: `${cuisine?.name} ${location?.name}`,
    link: `/delivery/${location?.key}/${cuisineDirectory}/${cuisine?.key}`
  };
}

function generateLocationCuisinesDeliveryAnchors(location: Location, cuisines: Cuisine[]): DeliveryAnchor[] {
  if (!cuisines?.length || !location?.shops) return [];
  const cuisinesInLocation = filterCuisinesUnderLocation(cuisines, location);
  return cuisinesInLocation.map((cuisine) => locationCuisineToDeliveryAnchor(location, cuisine));
}

function generateCuisineLocationsDeliveryAnchors(cuisine: Cuisine, locations: Location[]): DeliveryAnchor[] {
  if (!locations?.length || !cuisine) return [];
  const locationsWithCuisine = filterLocationsWithCuisine(locations, cuisine);
  return locationsWithCuisine
    .slice(0, MAXIMUM_LOCATIONS_DELIVERY_ANCHORS)
    .map((location) => locationCuisineToDeliveryAnchor(location, cuisine));
}

function shopToDeliveryAnchor(shop: Shop): DeliveryAnchor {
  return {
    text: `${shop?.name}`,
    link: `/delivery/${shop?.locationKey}/${shop?.vanity_url}`
  };
}

function generateShopsDeliveryAnchors(shops: Shop[]): DeliveryAnchor[] {
  if (!shops?.length) return [];
  return shops.slice(0, 20).map((shop) => shopToDeliveryAnchor(shop));
}

function locationShopToDeliveryAnchor(location: Location, shop: Shop): DeliveryAnchor {
  return {
    text: `${shop?.name ?? ''} ${location?.name ?? ''}`,
    link: `/delivery/${shop?.locationKey}/${shop?.vanity_url}`
  };
}

function generateLocationShopsDeliveryAnchors(location: Location, shops: Shop[]): DeliveryAnchor[] {
  if (!shops?.length || !location) return [];
  return shops.map((shop) => locationShopToDeliveryAnchor(location, shop)).slice(0, MAXIMUM_SHOPS_DELIVERY_ANCHORS);
}

export {
  normalizeLocation,
  groupLocations,
  locationCuisineToDeliveryAnchor,
  generateLocationCuisinesDeliveryAnchors,
  generateCuisineLocationsDeliveryAnchors,
  shopToDeliveryAnchor,
  locationShopToDeliveryAnchor,
  generateShopsDeliveryAnchors,
  generateLocationShopsDeliveryAnchors
};

<div class="progress-bar-fragment" *ngFor="let fragment of visibleFragments">
  <div
    class="progress-bar-fragment-progress"
    data-mode="light"
    [class.progress-bar-fragment-progress-slide-animation]="fragment.slideAnimation"
    [class.progress-bar-fragment-progress-infinite-slide-animation]="fragment.infiniteSlideAnimation"
    [class.progress-bar-fragment-progress-shimmer-animation]="fragment.shimmerAnimation"
    [ngStyle]="getProgressBarStyle(fragment)"
  ></div>
</div>

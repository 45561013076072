import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserEvent, APIResponse, APIError } from '@box-types';
import dayjs from 'dayjs';
import { environment } from '@box-env/environment';
import { map, catchError } from 'rxjs/operators';
import { SentryService } from '@box-core/services/sentry.service';

const BOX_API = environment.application.API_URL;

@Injectable({ providedIn: 'root' })
export class UserEventsService {
  private readonly userEventHistorySource: BehaviorSubject<UserEvent[]> = new BehaviorSubject<UserEvent[]>([]);
  public readonly userEventHistory$ = this.userEventHistorySource.asObservable();

  constructor(private http: HttpClient, private sentryService: SentryService) {}

  public fetchUserEventHistory(): Observable<UserEvent[]> {
    const url = `${BOX_API}/user/event-history`;
    return this.http.get(url).pipe(
      map((response: APIResponse<{ eventHistory: UserEvent[] }>) => response.payload.eventHistory),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'User Events',
          domainDetails: 'User Events get error',
          severity: 'error'
        });
        // returning an empty array would result in spamming the user with all sorts of popups
        return of(null);
      })
    );
  }

  private updateRemoteEventHistory(key: string): Observable<void> {
    const url = `${BOX_API}/user/event-history/${key}`;
    return this.http.post<void>(url, { key }).pipe(
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'User Events',
          domainDetails: 'User Events post error',
          severity: 'error'
        });
        return of(null);
      })
    );
  }

  public appendToEventHistory(key: string): void {
    const timestamp = dayjs().unix();
    const event: UserEvent = { timestamp, key };
    const updatedEventHistory = [...this.getUserEventHistory(), event];
    this.setUserEventHistory(updatedEventHistory);
    this.updateRemoteEventHistory(key).subscribe();
  }

  public getUserEventHistory(): UserEvent[] {
    return this.userEventHistorySource.getValue();
  }

  public setUserEventHistory(history: UserEvent[]): void {
    this.userEventHistorySource.next(history);
  }
}

const DOMAIN_NAMES = [
  'http://box.gr',
  'https://box.gr',
  'http://stage.box.gr',
  'https://stage.box.gr',
  'http://www.box.gr',
  'https://www.box.gr',
  'http://www.stage.box.gr',
  'https://www.stage.box.gr'
];

function normalizeWebAction(webAction: string): string {
  const domain = DOMAIN_NAMES.find((domain) => webAction.includes(domain));
  return webAction.replace(domain, '');
}

function webActionIsDynamicLink(webAction: string): boolean {
  const domain = DOMAIN_NAMES.find((domain) => webAction.includes(domain));
  return domain?.length > 0 ? false : true;
}

export { normalizeWebAction, webActionIsDynamicLink };

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class PromoCouponClipboardService {
  constructor(private snackBar: MatSnackBar, private languageService: LanguageService) {}

  public showCopySnackBar(): void {
    const snackConfig: MatSnackBarConfig = { duration: 3000 };
    const messageText = this.languageService.getTextByKey('coupon_code_has_been_copied_to_the_clipboard');
    const actionText = this.languageService.getTextByKey('ok_');
    this.snackBar.open(messageText, actionText, snackConfig);
  }
}

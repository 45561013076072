import { ValidatorFn } from '@angular/forms';
import { regExToValidatorFunction } from '@box-shared/validators/validator.utils';

/* Only for Greek numbers, 10 Digits, with or without the +30 prefix */

const EXPRESSIONS: string[] = ['(^69\\d{8}$)', '(^2\\d{9}$)', '(^\\+3069\\d{8}$)', '(^\\+302\\d{9}$)'];

const REG_EXP = new RegExp(EXPRESSIONS.join('|'));

export function phoneValidator(): ValidatorFn {
  return regExToValidatorFunction(REG_EXP);
}

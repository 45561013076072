import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIResponse } from '@box-types';
import pickBy from 'lodash-es/pickBy';
import { of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class RateAppService {
  private readonly BOX_API: string = environment.application.API_URL;

  constructor(private http: HttpClient) {}

  public rateApp(rating: number, comment?: string): Observable<void> {
    const data = pickBy({ rating, comment }) as { rating: number; comment?: string };
    return this.http.post<APIResponse>(`${this.BOX_API}/in-app-ratings/`, data).pipe(switchMap(() => of(undefined)));
  }
}

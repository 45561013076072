import { Injectable } from '@angular/core';
import { Product, Shop, Offer, APIResponse } from '@box-types';
import { asapScheduler, BehaviorSubject, combineLatest, map, Observable, observeOn, Subscriber, zip } from 'rxjs';
import {
  filterOffersBySearchTerms,
  normalizeOffer,
  filterProductsBySearchTerms,
  normalizeProduct,
  syncOfferCartQuantityWithCart,
  syncProductCartQuantityWithCart,
  decorateOfferWithMaxItemsQuantity,
  decorateProductWithMaxItemsQuantity
} from '@box/utils';
import { getGreeklish } from 'greek-utilities';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ShopMenuSearchOptions, ShopMenuSearchResponse } from '@box-delivery/delivery.types';
import { environment } from '@box-env/environment';
import { pickBy } from 'lodash-es';
import { CartService, ShopService } from '@box-core/services';
import { OffersService, ProductsService } from '@box-delivery/services';

@Injectable()
export class ShopMenuSearchService {
  private readonly BOX_API = environment.application.API_URL;
  private readonly searchTermSource = new BehaviorSubject<string>('');
  private readonly filteredOffersSource = new BehaviorSubject<Offer[]>([]);
  private readonly filteredProductsSource = new BehaviorSubject<Product[]>([]);

  public readonly searchTerm$ = this.searchTermSource.asObservable();
  public readonly filteredOffers$ = this.filteredOffersSource.asObservable();
  public readonly filteredProducts$ = this.filteredProductsSource.asObservable();

  constructor(
    private http: HttpClient,
    private shopService: ShopService,
    private productsService: ProductsService,
    private offersService: OffersService,
    private cartService: CartService
  ) {}

  public getShop(): Shop {
    return this.shopService.getShop();
  }

  public fetchProduct(productId: string): Observable<Product> {
    const shop = this.shopService.getShop();
    const { collectionType, supermarketGroup } = shop;
    return this.productsService
      .fetchProduct(productId, { collectionType, supermarketGroup })
      .pipe(map((product) => this.shopService.decorateProduct(product)));
  }

  public fetchOffer(offerId: string): Observable<Offer> {
    const shop = this.shopService.getShop();
    const { collectionType, supermarketGroup } = shop;
    return this.offersService
      .fetchOffer(offerId, { collectionType, supermarketGroup })
      .pipe(map((offer) => this.shopService.offerDecorator(offer)));
  }

  public searchResultChanges$(): Observable<{ products: Product[]; offers: Offer[] }> {
    const resultChanges$ = zip(this.filteredOffers$, this.filteredProducts$).pipe(
      map(() => ({
        offers: this.filteredOffersSource.getValue(),
        products: this.filteredProductsSource.getValue()
      }))
    );

    return combineLatest({ cart: this.cartService.cart$, resultChanges: resultChanges$ }).pipe(
      map(({ cart, resultChanges }) => {
        const { offers: resultsOffers, products: resultsProducts } = resultChanges;
        const offers = resultsOffers.map((offer) => syncOfferCartQuantityWithCart(offer, cart));
        const products = resultsProducts.map((product) => syncProductCartQuantityWithCart(product, cart));
        return { products, offers };
      })
    );
  }

  public search(term: string): Observable<ShopMenuSearchResponse> {
    const shop = this.shopService.getShop();
    if (shop.categoryView) {
      const options = { term, supermarketGroup: shop.supermarketGroup };
      return this.fetchSearchResults(shop.collectionType, options);
    }

    return new Observable((subscriber: Subscriber<ShopMenuSearchResponse>) => {
      const offers = this.shopService.menuOffers.getValue();
      const products = this.shopService.menuProducts.getValue();
      const greeklishTerm = getGreeklish(term) as string; // eslint-disable-line
      subscriber.next({
        offers: filterOffersBySearchTerms(offers, greeklishTerm),
        products: filterProductsBySearchTerms(products, greeklishTerm)
      });
      subscriber.complete();
    }).pipe(observeOn(asapScheduler));
  }

  public fetchSearchResults(
    collectionType: number,
    options: ShopMenuSearchOptions
  ): Observable<ShopMenuSearchResponse> {
    const params = new HttpParams({ fromObject: pickBy(options) });
    return this.http.get(`${this.BOX_API}/items/grocery/${collectionType}`, { params }).pipe(
      map((response: APIResponse<ShopMenuSearchResponse>) => {
        const shop = this.shopService.getShop();
        const items = response.payload;

        const products = items.products.map((product) => {
          const normalizedProduct = normalizeProduct(product);
          const decoratedProduct = this.shopService.decorateProduct(normalizedProduct);
          return decorateProductWithMaxItemsQuantity(decoratedProduct, shop);
        });

        const offers = items.offers.map((offer) => {
          const normalizedOffer = normalizeOffer(offer);
          const decoratedOffer = this.shopService.offerDecorator(normalizedOffer);
          return decorateOfferWithMaxItemsQuantity(decoratedOffer, shop);
        });

        return { products, offers };
      })
    );
  }

  public setSearchTerm(term: string): void {
    this.searchTermSource.next(term);
  }

  public getSearchTerm(): string {
    return this.searchTermSource.getValue();
  }

  public clearSearchTerm(): void {
    this.searchTermSource.next('');
  }

  public setFilteredOffers(offers: Offer[]): void {
    this.filteredOffersSource.next(offers);
  }

  public getFilteredOffers(): Offer[] {
    return this.filteredOffersSource.getValue();
  }

  public setFilteredProducts(products: Product[]): void {
    this.filteredProductsSource.next(products);
  }

  public getFilteredProducts(): Product[] {
    return this.filteredProductsSource.getValue();
  }

  public clearFilteredItems(): void {
    this.filteredOffersSource.next([]);
    this.filteredProductsSource.next([]);
  }

  public getResultsContainerMaxHeight(element: HTMLElement): number {
    const elementDistance = element.getBoundingClientRect().top;
    const elementHeight = element.offsetHeight;
    const elementOffset = 14;
    return window.innerHeight - elementDistance - elementHeight - elementOffset;
  }
}

import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { ShopSuggestionBanner } from '@box-types';

@Component({
  selector: 'shop-suggestion-banner-info-dialog',
  templateUrl: './shop-suggestion-banner-info-dialog.component.html',
  styleUrls: ['./shop-suggestion-banner-info-dialog.component.scss']
})
export class ShopSuggestionBannerInfoDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public title: string;
  public description: string;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<ShopSuggestionBannerInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { suggestionBanner: ShopSuggestionBanner }
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    const suggestionBanner = this.data.suggestionBanner;
    this.title = suggestionBanner.shopModalTitle;
    this.description = suggestionBanner.shopModalDescription;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

import { Product, Offer, OfferAvailabilityOptions, ProductAvailabilityOptions } from '@box-types';
import { getOfferFromInstance } from '../offers';
import { getProductFromInstance } from '../products';

export {
  getOrderProductAvailabilityOptions,
  getOrderOfferAvailabilityOptions,
  getPreviousOrderProductsAvailabilityOptions,
  getPreviousOrderOffersAvailabilityOptions,
  getProductsForOrder,
  getOffersForOrder
};

function getOrderProductAvailabilityOptions(product: Product, index?: number): ProductAvailabilityOptions {
  /* We can't use the getProductAvailabilityOptions due to the fact that order products
  have _id and productId on them. _id is the document MongoID and the productId is the
  original product document ID */
  const pseudoProductId = typeof index === 'number' && `${product.productId}_${index}`;
  return {
    productId: product.productId,
    name: product.name,
    selections: product.selections ?? [],
    ...(pseudoProductId && { pseudoProductId }),
    ...(product.quantity && { quantity: product.quantity }),
    ...(product.comments && { comments: product.comments })
  };
}

function getOrderOfferAvailabilityOptions(offer: Offer, index?: number): OfferAvailabilityOptions {
  /* We can't use the getOfferAvailabilityOptions due to the fact that order offers
  have _id and offerId on them. _id is the document MongoID and the offerId is the
  original offer document ID. Also, Order Offers hav eno groups, but only the selected
  products on order.products array */
  const pseudoOfferId = typeof index === 'number' && `${offer.offerId}_${index}`;
  return {
    offerId: offer.offerId,
    name: offer.name,
    products: offer.products?.map((product) => getOrderProductAvailabilityOptions(product)) ?? [],
    quantity: offer.quantity,
    ...(pseudoOfferId && { pseudoOfferId })
  };
}

function getPreviousOrderProductsAvailabilityOptions(products: Product[]): ProductAvailabilityOptions[] {
  if (!products?.length) return [];
  return products.map((product, index) => getOrderProductAvailabilityOptions(product, index));
}

function getPreviousOrderOffersAvailabilityOptions(offers: Offer[]): OfferAvailabilityOptions[] {
  if (!offers?.length) return [];
  return offers.map((offer, index) => getOrderOfferAvailabilityOptions(offer, index));
}

function getProductsForOrder(products: Product[]): Product[] {
  if (!products) return [];
  return products.flatMap((product) => {
    return product.cartInstances.map((instance) => {
      const newProduct = getProductFromInstance(product, instance);
      newProduct.selections?.map((s) => (s.choices = []));
      return newProduct;
    });
  });
}

function getOffersForOrder(offers: Offer[]): Offer[] {
  if (!offers) return [];
  return offers.flatMap((offer) => {
    return offer.cartInstances.flatMap((instance) => {
      const newOffer = getOfferFromInstance(offer, instance);
      newOffer.groups?.forEach((group) =>
        group.products.forEach((product) => product.selections?.map((s) => (s.choices = [])))
      );
      return newOffer;
    });
  });
}

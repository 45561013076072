import { Directive, ElementRef, OnDestroy, Output, EventEmitter, OnInit, HostListener, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { BodyScrollEvent } from '@box-shared/directives/body-scroll-event.types';

@Directive({ selector: '[bodyScroll]' })
export class BodyScrollDirective implements OnInit, OnDestroy {
  @Input() public delayTime = 16;
  @Output() public bodyScroll: EventEmitter<BodyScrollEvent> = new EventEmitter<BodyScrollEvent>();

  private scrollEvent = new Subject();
  private subscription: Subscription;
  private scrolled = false;
  private scrolledToBottom = false;

  @HostListener('scroll', ['$event']) _scrollEvent(event) {
    this.scrollEvent.next(event);
  }

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.subscription = this.scrollEvent.pipe(auditTime(this.delayTime)).subscribe(() => this.onScroll());
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  private onScroll(): void {
    /*
    We are adding 1px to the scrollHeight due to some issues with percentage height on browsers.
    The scrollTop property will always return a Math.floor() version, losing the subpixel precision
    */
    const body: HTMLElement = this.element.nativeElement;
    const scrollTop: number = body.scrollTop;
    const height: number = body.offsetHeight + 1;
    const scrollHeight: number = body.scrollHeight;
    this.scrolled = scrollTop > 0;
    this.scrolledToBottom = scrollTop >= scrollHeight - height;
    this.bodyScroll.emit({ scrolled: this.scrolled, scrolledToBottom: this.scrolledToBottom });
  }
}

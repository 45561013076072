import { Review } from '@box-types';
import floor from 'lodash-es/floor';
import dayjs from 'dayjs';

export { SHOP_REVIEWS_THRESHOLD, getLatestReview, shouldShowShopReviews, formatReviewsCounter };

const SHOP_REVIEWS_THRESHOLD = 5;

function getLatestReview(reviews: Review[]): Review {
  if (!reviews?.length) return;
  const sortedUserReviews = [...reviews].sort(
    (reviewA, reviewB) => dayjs(reviewB.createdStamp).valueOf() - dayjs(reviewA.createdStamp).valueOf()
  );
  return sortedUserReviews[0];
}

function shouldShowShopReviews(reviews: Review[]): boolean {
  return reviews.length >= SHOP_REVIEWS_THRESHOLD;
}

function formatReviewsCounter(count: number): string {
  const thresholdData = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 }
  ];

  const data = thresholdData.find((x) => Math.abs(count) >= x.threshold);
  if (!data) return String(count);
  const { suffix, threshold } = data;
  return `${floor(count / threshold, 1)}${suffix}`;
}

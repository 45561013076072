import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { map, Observable, take, timer } from 'rxjs';

@Injectable()
export class VerificationResendCodeService {
  private readonly RESEND_DELAY_SECONDS = 30;

  public getDelayTimeText(): Observable<string> {
    const resendDelay = this.RESEND_DELAY_SECONDS;
    return timer(0, 1000).pipe(
      map((value) => resendDelay - value),
      take(resendDelay + 1),
      map((value) => this.remainingTimeFormatter(value))
    );
  }

  private remainingTimeFormatter(value: number): string {
    return dayjs(value * 1000).format('mm:ss');
  }
}

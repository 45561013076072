import { Brand, Shop } from '@box-types';

export { filterShopsByBrands };

function filterShopsByBrands(shops: Shop[], brands: Brand[]): Shop[] {
  if (!shops?.length) return [];
  if (!brands?.length) return shops;
  const brandIds = brands.map((brand) => brand._id);
  return shops.filter((shop) => shop.brands?.some((id) => brandIds.includes(id)));
}

<div class="swiper-section box-swiper promo-banners-wrapper">
  <button #prevBtn mat-icon-button class="box-swiper-button-inline-prev promo-banners-btn-inline-prev">
    <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
  </button>
  <button #nextBtn mat-icon-button class="box-swiper-button-inline-next promo-banners-btn-inline-next">
    <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
  </button>
  <div [swiper]="swiperOptions" *ngIf="promoBanners.length > 0">
    <div class="swiper-wrapper">
      <div *ngFor="let promoBanner of promoBanners" class="swiper-slide">
        <promo-banner 
          visibilityObserver 
          [triggerOnce]="true" 
          [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"    
          (enteredViewport)="onPromoBannerEnteredViewport(promoBanner)"
          [promoBanner]="promoBanner" 
          (click)="onPromoBannerClick(promoBanner)">
        </promo-banner>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { DialogService, LoaderService } from '@box-core/services';
import { Order, APIError } from '@box-types';
import { NavigationExtras, Router } from '@angular/router';
import { ShopMenuAvailabilityService } from '@box-delivery/services';
import { finalize } from 'rxjs';

@Injectable()
export class ShopOrdersService {
  constructor(
    private router: Router,
    private shopMenuAvailabilityService: ShopMenuAvailabilityService,
    private loaderService: LoaderService,
    private dialogService: DialogService
  ) {}

  public orderToCart(order: Order): void {
    this.loaderService.setState(true);
    this.shopMenuAvailabilityService
      .checkPreviousOrderItemsAvailability(order)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (response) => this.shopMenuAvailabilityService.handleItemsAvailabilityResponse(response),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public handleAcceptedOrder(order: Order): void {
    const options: NavigationExtras = { queryParams: { friendlyId: order.friendlyId } };
    void this.router.navigate(['/checkout', 'order-status'], options);
  }

  public handleCompletedOrder(order: Order): void {
    this.orderToCart(order);
  }
}

import { ProductAvailabilityOptions, Offer, OfferAvailabilityOptions, Product } from '@box-types';
import { appConfig } from '@box-configs/app.config';
import dayjs from 'dayjs';
import { decorateProductWithPseudoId, getProductAvailabilityOptions, getProductFromInstance } from '../products';
import { decorateOfferWithPseudoId, getOfferAvailabilityOptions, getOfferFromInstance } from '../offers';
import { CartCollection } from './cart.types';

export {
  collectionExpired,
  getCartCollectionsFromStorage,
  setCartCollectionToStorage,
  removeCartCollectionFromStorage,
  getCartCollectionFromStorage,
  getCartOffersAvailabilityOptions,
  getCartProductsAvailabilityOptions
};

function collectionExpired(collection: CartCollection): boolean {
  /* Since we changed the CartCollection interface, we wanna remove all
  the old data from the users browser. That block of code can be removed
  when the Release Date and the appConfig.cart.EXPIRATION_TIME sum is
  after the current date. For good measurment, we can remove this
  on the next release.
  Current Check: if (!collection.timestamp || collection['cartItems']) return true;
  Check after the Data Normalization is Done: if (!collection.timestamp) return true;
  */
  if (!collection.timestamp || collection['cartItems']) return true;
  const currentDate = dayjs();
  const date = dayjs(collection.timestamp);
  const CART_EXPIRATION_MS = appConfig.cart.EXPIRATION_TIME;
  return currentDate.isAfter(date.add(CART_EXPIRATION_MS, 'millisecond'));
}

function getCartCollectionsFromStorage(storage: Storage): Record<number, CartCollection> {
  return JSON.parse(storage.getItem('Box:cartCollections')) as Record<number, CartCollection>;
}

function setCartCollectionToStorage(collectionType: number, collection: CartCollection, storage: Storage): void {
  const cartCollections = getCartCollectionsFromStorage(storage) ?? {};
  cartCollections[collectionType] = collection;
  storage.setItem('Box:cartCollections', JSON.stringify(cartCollections));
}

function removeCartCollectionFromStorage(collectionType: number, storage: Storage): void {
  const cartCollections = getCartCollectionsFromStorage(storage) ?? {};
  const collection = cartCollections[collectionType];
  if (!collection) return;
  delete cartCollections[collectionType];
  if (Object.keys(cartCollections).length === 0) return storage.removeItem('Box:cartCollections');
  storage.setItem('Box:cartCollections', JSON.stringify(cartCollections));
}

function getCartCollectionFromStorage(collectionType: number, storage: Storage): CartCollection {
  const cartCollections = getCartCollectionsFromStorage(storage);
  if (!cartCollections) return;
  const shopCartCollection = cartCollections[collectionType];
  if (!shopCartCollection) return;
  return shopCartCollection;
}

function getCartOffersAvailabilityOptions(offers: Offer[]): OfferAvailabilityOptions[] {
  if (!offers?.length) return [];
  return offers.flatMap((offer) =>
    offer.cartInstances.map((instance, index) => {
      const offerFromInstnace = getOfferFromInstance(offer, instance);
      const decoratedOffer = decorateOfferWithPseudoId(offerFromInstnace, index);
      return getOfferAvailabilityOptions(decoratedOffer);
    })
  );
}

function getCartProductsAvailabilityOptions(products: Product[]): ProductAvailabilityOptions[] {
  if (!products?.length) return [];
  return products.flatMap((product) =>
    product.cartInstances.map((instance, index) => {
      const productFromInstnace = getProductFromInstance(product, instance);
      const decoratedProduct = decorateProductWithPseudoId(productFromInstnace, index);
      return getProductAvailabilityOptions(decoratedProduct);
    })
  );
}

import { Injectable } from '@angular/core';
import { Router, Params, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { getPathWithoutQueryParams } from '@box/utils';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  constructor(private router: Router, private location: Location, private activatedRoute: ActivatedRoute) {}

  /**
   * @Param activatedRouteSnapshot must come from a resolver in order to work
   * @Param key is the key of the query param that we want to remove
   * */
  public clearQueryParam(activatedRouteSnapshot: ActivatedRouteSnapshot, key: string): void {
    if (!activatedRouteSnapshot) return;
    const params = { ...activatedRouteSnapshot.queryParams } as Params;
    delete params[key];
    const currentPathWithoutParams = getPathWithoutQueryParams(this.location.path());
    this.router.navigate([currentPathWithoutParams], { queryParams: params });
  }

  public addQueryParamsAndRefresh(keysAndValues: Record<string, string>) {
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: keysAndValues,
        queryParamsHandling: 'merge'
      })
      .then(() => {
        window.location.reload();
      });
  }
}

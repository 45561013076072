<image-tile class="order-status-logo-shop-image" [imgUrl]="shopImageSrc" [altText]="shopImageAlt"></image-tile>
<div [@orderStatusLogoAnimation] *ngIf="orderStatusLogoState" class="order-status-logo-icon-container">
  <ng-container [ngSwitch]="orderStatusLogoState">
    <mat-icon *ngSwitchCase="'ACCEPTED'" class="order-status-logo-icon">done</mat-icon>
    <mat-icon *ngSwitchCase="'REJECTED'" class="order-status-logo-icon">close</mat-icon>
  </ng-container>
</div>
<div *ngIf="isDaasOrder" class="order-status-logo-bubble">
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="132" fill="none" viewBox="0 0 112 132">
    <ellipse cx="56" cy="127" fill="url(#eclipse-shadow)" rx="32" ry="6" />
    <g filter="url(#bubble-shadow)">
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M8 5C6 5 4 7 4 9v96c0 2 2 4 4 4h34l14 18 14-18h34c2 0 4-2 4-4V9c0-2-2-4-4-4H8Z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <radialGradient
        id="eclipse-shadow"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(0 6 -32 0 56 127)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2A292E" stop-opacity=".3" />
        <stop offset="1" stop-color="#2A292E" stop-opacity="0" />
      </radialGradient>
      <filter
        id="bubble-shadow"
        width="112"
        height="132"
        x="0"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.160784 0 0 0 0 0.180392 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4597_18179" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.160784 0 0 0 0 0.180392 0 0 0 0.1 0" />
        <feBlend in2="effect1_dropShadow_4597_18179" result="effect2_dropShadow_4597_18179" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_4597_18179" result="shape" />
      </filter>
    </defs>
  </svg>
</div>

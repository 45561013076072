import { Component, OnInit, ChangeDetectionStrategy, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutOrderPreviewInfoDialogData } from './checkout-order-preview-info-dialog.types';
import { ConfigurationService } from '@box-core/services';
import { PriceCalculationInfoKey } from '@box-types';

@Component({
  selector: 'checkout-order-preview-info-dialog',
  templateUrl: './checkout-order-preview-info-dialog.component.html',
  styleUrls: ['./checkout-order-preview-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutOrderPreviewInfoDialogComponent implements OnInit {
  public showEnvFeeInfo: boolean;
  public envFeeText: string;
  public showDeliveryFeeInfo: boolean;
  public deliveryFeeText: string;
  public showServiceFeeInfo: boolean;
  public serviceFeeText: string;

  constructor(
    private configurationService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) private data: CheckoutOrderPreviewInfoDialogData,
    private dialogRef: MatDialogRef<CheckoutOrderPreviewInfoDialogComponent>
  ) {}

  @HostBinding('class') public hostClass = 'checkout-order-preview-info-dialog';

  ngOnInit(): void {
    this.showEnvFeeInfo = this.data.showEnvFeeInfo;
    this.envFeeText = this.showEnvFeeInfo ? this.getPriceCalculationText('envFee') : undefined;
    this.showDeliveryFeeInfo = this.data.showDeliveryFeeInfo;
    this.deliveryFeeText = this.showDeliveryFeeInfo ? this.getPriceCalculationText('deliveryFee') : undefined;
    this.showServiceFeeInfo = this.data.showServiceFeeInfo;
    this.serviceFeeText = this.showServiceFeeInfo ? this.getPriceCalculationText('serviceFee') : undefined;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private getPriceCalculationText(key: PriceCalculationInfoKey): string {
    const config = this.configurationService.getConfiguration();
    const info = config?.priceCalculation?.info;
    if (!info) return;
    return info[key]?.text;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigurationService, GDPRService } from '@box-core/services';
import { generateImageSrc } from '@box/utils';
import { finalize } from 'rxjs';
import { GDPRConsent } from '@box-types';

type ConsentBlock = { imageSrc: string; text: string };

@Component({
  selector: 'gdpr-consent-dialog',
  templateUrl: './gdpr-consent-dialog.component.html',
  styleUrls: ['./gdpr-consent-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GDPRConsentDialogComponent implements OnInit {
  public loading: boolean;
  public title: string;
  public description: string;
  public consentBlocks: ConsentBlock[];

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<GDPRConsentDialogComponent>,
    private configurationService: ConfigurationService,
    private gdprService: GDPRService
  ) {}

  @HostBinding('class') public hostClass = 'gdpr-consent-dialog';

  ngOnInit(): void {
    const consentConfiguration = this.configurationService.getConfiguration().gdprConsent;
    this.title = consentConfiguration.titleText;
    this.description = consentConfiguration.descriptionText;
    this.consentBlocks = consentConfiguration.infoBlocks.map((block) => ({
      imageSrc: generateImageSrc(block.image),
      text: block.text
    }));
  }

  public closeDialog(gdprConsent?: GDPRConsent): void {
    this.dialogRef.close(gdprConsent);
  }

  public onGDPRConsent(accepted: boolean): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.gdprService
      .updateGDPRConsent(accepted)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((gdprConsent: GDPRConsent) => this.closeDialog(gdprConsent));
  }

  public onClickReadMore(): void {
    this.closeDialog();
    void this.router.navigate(['/account/terms/personalisation']);
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService, CartService, ShopService } from '@box-core/services';
import { Product, Shop, OfferInstance, OfferGroup } from '@box-types';
import cloneDeep from 'lodash-es/cloneDeep';
import { OfferWizardGroupChangeEvent } from '../offer-wizard-groups/offer-wizard-groups.types';
import { OfferWizardOptions } from '../offer-wizard/offer-wizard.types';
import { ProductMYOOptions } from '../product-myo/product-myo.types';
import { OfferWizardAnimation, ProductMYOAnimation } from './offer-wizard-dialog.animations';
import { OfferWizardDialogData, OfferWizardState } from './offer-wizard-dialog.types';
import { getOfferInstancePrice, CartActionResponse, getShopSelectItemModalGAConfig } from '@box/utils';

@Component({
  selector: 'offer-wizard-dialog',
  templateUrl: './offer-wizard-dialog.component.html',
  styleUrls: ['./offer-wizard-dialog.component.scss'],
  animations: [OfferWizardAnimation, ProductMYOAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferWizardDialogComponent implements OnInit {
  public state: OfferWizardState = 'OFFER';
  public offerWizardOptions: OfferWizardOptions;
  public productMYOOptions: ProductMYOOptions;

  private shop: Shop;
  private offerInstance: OfferInstance;
  private activeGroup: OfferGroup;

  constructor(
    private cartService: CartService,
    private shopService: ShopService,
    private dialogRef: MatDialogRef<OfferWizardDialogComponent>,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) private data: OfferWizardDialogData
  ) {}

  ngOnInit(): void {
    this.shop = this.data.shop;
    this.offerInstance = this.data.offerInstance;
    this.offerWizardOptions = {
      offer: this.data.offer,
      offerInstance: this.data.offerInstance,
      editMode: this.data.editMode,
      offerInstanceToEdit: this.data.offerInstanceToEdit
    };
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onMYOBack(): void {
    this.state = 'OFFER';
  }

  public onStateChange(state: OfferWizardState): void {
    this.state = state;
  }

  public onProductMYO(event: OfferWizardGroupChangeEvent): void {
    this.activeGroup = event.group;
    this.productMYOOptions = {
      product: event.product,
      productInstance: cloneDeep(event.product.cartInstances[0]),
      productInstanceToEdit: event.product.cartInstances[0],
      editMode: true,
      offerInstance: this.offerInstance,
      canBack: true,
      isSuperMarket: this.shop.isSuperMarket,
      showQuantity: false,
      showOnlyAdditionalPrice: this.offerInstance.offerType === 'fixedPrice',
      commentsAllowed: this.shop.isProductCommentsAllowed,
      customPriceCheck: false
    };
    this.state = 'PRODUCT';
    this.triggerAnalyticsSelectProductEvent(event.product);
  }

  public onProductMYOSubmit(options: ProductMYOOptions): void {
    this.activeGroup.products = this.activeGroup.products.map((p) => ({
      ...p,
      checked: p.productId === options.product.productId
    }));
    this.activeGroup.selectedProduct = options.product;
    options.product.cartInstances[0] = options.productInstance;
    this.offerInstance.price = getOfferInstancePrice(this.offerInstance);
    this.state = 'OFFER';
  }

  public onOfferInstanceChange(offerInstance: OfferInstance): void {
    this.offerInstance = offerInstance;
    this.offerWizardOptions.offerInstance = this.offerInstance;
  }

  public onSubmit(data: OfferWizardOptions): void {
    const { editMode, offer, offerInstance, offerInstanceToEdit } = data;
    const response: CartActionResponse = editMode
      ? this.cartService.updateOffer(offer, offerInstanceToEdit, offerInstance)
      : this.cartService.addOffer(offer, offerInstance);
    if (response === 'CART_LIMIT_REACHED') return;
    if (response === 'ITEM_LIMIT_REACHED') return;
    if (response === 'CHOICES_THRESHOLD_NOT_REACHED') return;
    this.shopService.syncCartOfferToMenu(offer);
    this.shopService.addToCartOfferAnalyticsEvent(offer, offerInstance, 'shop');
    this.closeDialog();
  }

  private triggerAnalyticsSelectProductEvent(product: Product): void {
    const itemListName = this.offerInstance.description;
    const shop = this.shopService.getShop();
    const gaConfig = getShopSelectItemModalGAConfig(product, shop, itemListName);
    this.analyticsService.addGAEcommerceEvent('select_item', gaConfig);
  }
}

import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AuthenticationService, CosmoteIDService } from '@box-core/services';

const ROUTES_THAT_LEAD_TO_LANDING_PAGE_AFTER_LOGOUT = ['/home', '/discover', '/checkout'];

@Injectable({ providedIn: 'root' })
export class LogoutResolver implements Resolve<boolean> {
  constructor(
    private router: Router,
    private cosmoteIDService: CosmoteIDService,
    private authenticationService: AuthenticationService
  ) {}

  resolve(): Promise<boolean> {
    const returnURL = this.getReturnUrlAfterLogout();
    this.authenticationService.signOut();
    return this.router.navigateByUrl(returnURL);
  }

  private getReturnUrlAfterLogout(): string {
    const returnUrl = this.cosmoteIDService.getReturnUrl();
    if (ROUTES_THAT_LEAD_TO_LANDING_PAGE_AFTER_LOGOUT.some((route) => returnUrl.includes(route))) return '';
    return returnUrl;
  }
}

<div class="box-dialog-wrapper">
  <box-dialog-header
    [title]="title"
    class="box-dialog-header promo-banner-details-dialog-header"
    (closeDialog)="onCloseClick()"
  ></box-dialog-header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <section class="promo-banner-details-dialog-section">
      <figure class="promo-banner-details-dialog-image">
        <img [src]="imageUrl" [alt]="title" />
      </figure>
    </section>
    <section *ngIf="description" class="promo-banner-details-dialog-section">
      <div class="promo-banner-details-dialog-description" [innerHTML]="description"></div>
    </section>
    <section *ngIf="coupon" class="promo-banner-details-dialog-section promo-banner-details-dialog-coupon-clipboard">
      <promo-coupon-clipboard [coupon]="coupon"></promo-coupon-clipboard>
    </section>
    <section *ngIf="relatedShops?.length" class="promo-banner-details-dialog-section">
      <shop-tile-compact
        *ngFor="let shop of relatedShops"
        [shop]="shop"
        class="hoverable"
        visibilityObserver
        [triggerOnce]="true"
        [intersectionRoot]="dialogBody"
        [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
        [intersectionRootMargin]="DEFAULT_INTERSECTION_ROOT_MARGIN"
        (enteredViewport)="onShopEnteredViewport(shop)"
        (click)="onShopCardClick(shop)"
      ></shop-tile-compact>
    </section>
  </main>
  <footer #dialogFooter *ngIf="buttonText" class="box-dialog-footer">
    <section class="promo-banner-details-dialog-footer-section">
      <button box-button theme="primary" (click)="onAction()">{{ buttonText | translate }}</button>
    </section>
  </footer>
</div>

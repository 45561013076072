import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { AnalyticsService, DeviceDetectorService, DownloadHeaderService } from '@box-core/services';
import { appConfig } from '@box-configs/app.config';

@Component({
  selector: 'download-header',
  templateUrl: './download-header.component.html',
  styleUrls: ['./download-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadHeaderComponent implements OnInit {
  public show: boolean;
  public link: string;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private analyticsService: AnalyticsService,
    private downloadHeaderService: DownloadHeaderService
  ) {}

  @HostBinding('class') public hostClass = 'download-header';

  ngOnInit(): void {
    this.show = this.downloadHeaderService.getDownloadHeaderVisibility();
    if (this.show) this.downloadHeaderService.setDownloadHeaderSeen(false);
    this.link = this.getLink();
  }

  public onClickCta(): void {
    const source = 'Landing Page Header';
    const platform = this.deviceDetectorService.getDeviceTypeAnalytics();
    this.analyticsService.addGACustomEvent('click_app_download', { platform, source });
  }

  public onClose(): void {
    this.show = false;
    this.downloadHeaderService.setDownloadHeaderSeen(true);
  }

  private getLink(): string {
    const deviceType = this.deviceDetectorService.getDeviceType();
    if (deviceType === 'HUAWEI') return appConfig.mobile.HUAWEI_APP;
    return appConfig.mobile.dynamicLink;
  }
}

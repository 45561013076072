import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnChanges {
  @Input() public rating: number;
  @Input() public size = 14;
  @Input() public disabled = false;
  @Output() private changeRating: EventEmitter<number> = new EventEmitter<number>();
  public starOnHover: number;

  @HostBinding('class') public hostClass = 'rating';
  @HostBinding('attr.disabled') public disabledBinding = this.disabled;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) this.size = changes.size.currentValue as number;
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue as boolean;
      this.disabledBinding = this.disabled;
    }
  }

  public onSelectStar(event: Event, rating: number): void {
    event.stopPropagation(); // is required for rating stars to work in /account/orders page
    this.changeRating.emit(rating);
  }

  public onMouseenterStar(starOnHover: number): void {
    if (this.disabled) return;
    this.starOnHover = starOnHover;
  }

  public onMouseleaveStar(starLostHover: number): void {
    if (this.starOnHover !== starLostHover) return;
    this.starOnHover = null;
  }

  public isStarActive(i: number): boolean {
    return this.rating && this.rating + 0.5 > i;
  }

  public isStarHighlighted(i: number): boolean {
    return this.starOnHover && i <= this.starOnHover;
  }
}

import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Cuisine } from '@box-types';
import { getCuisineHeroBackgroundColor, getCuisineHeroBackgroundImageUrl } from '@box/utils';

const MOBILE_BREAKPOINT = '(max-width: 512px)';

@Injectable()
export class DeliveryCuisineHeroService {
  constructor(private breakpointObserver: BreakpointObserver) {}
  public getCuisineColor(cuisine: Cuisine): string {
    return getCuisineHeroBackgroundColor(cuisine);
  }

  public getCuisineImage(cuisine: Cuisine): string {
    const isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    const url = getCuisineHeroBackgroundImageUrl(cuisine, isSmallScreen);
    if (!url) return;
    return `url('${url}')`;
  }
}

import {
  Directive,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ScrollSpyService } from '@box-core/services';
import { Subject, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';

interface SectionChangeEvent {
  event: Event;
  parent: HTMLElement;
  children: HTMLCollection;
}

@Directive({ selector: '[scrollSpy]' })
export class ScrollSpyDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() public spiedTags = [];
  @Input() public scrollSpyOffset = 0;
  @Input() public delayTime = 16;
  @Output() public sectionChange = new EventEmitter<string>();

  private currentSection: string;
  private scrollListener: () => void;
  private sectionChangeEvent = new Subject<SectionChangeEvent>();
  private subscription: Subscription;

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer2: Renderer2,
    private scrollSpyService: ScrollSpyService
  ) {}

  ngOnInit(): void {
    this.subscription = this.sectionChangeEvent
      .pipe(auditTime(this.delayTime))
      .subscribe((event: SectionChangeEvent) => this.scrollSpyCallback(event));
  }

  ngOnDestroy(): void {
    if (this.scrollListener) this.scrollListener();
    if (this.subscription) this.subscription.unsubscribe();
    const children = this.element.nativeElement.children;
    this.scrollSpyService.removeScrollSpyElements(children);
  }

  ngAfterViewInit(): void {
    const parent = this.scrollSpyService.parent.nativeElement;
    const children = this.element.nativeElement.children;
    this.scrollSpyService.addScrollSpyElements(children);
    this.scrollListener = this.renderer2.listen(parent, 'scroll', (event: Event) =>
      this.sectionChangeEvent.next({ event, parent, children })
    );
  }

  private scrollSpyCallback(event: SectionChangeEvent) {
    let currentSection: string;
    const { parent, children } = event;
    const scrollTop = parent.scrollTop;
    const parentOffset = parent.offsetTop - this.scrollSpyOffset;
    for (const element of Array.from(children) as HTMLElement[]) {
      // TODO: declarative
      if (this.spiedTags.some((spiedTag) => spiedTag === element.tagName)) {
        if (element.offsetTop - parentOffset <= scrollTop) {
          currentSection = element.id;
        }
      }
    }
    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }
  }
}

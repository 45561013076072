import {
  Component,
  HostBinding,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { CampaignTimerService } from '@box-core/services';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'campaign-countdown',
  templateUrl: './campaign-countdown.component.html',
  styleUrls: ['./campaign-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignCountdownComponent implements OnChanges, OnDestroy {
  @Input() public campaignName: string;
  @Output() private expired = new EventEmitter<void>();

  public seconds: string;
  public minutes: string;
  public hours: string;

  private countDownSubscription: Subscription;
  private expirationSubscription: Subscription;

  constructor(private campaignTimerService: CampaignTimerService, private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class') public hostClass = 'campaign-countdown';
  @HostBinding('class.expires-soon') public expiresSoon: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.campaignName) {
      this.campaignName = changes.campaignName.currentValue as string;
      this.countDownSubscription?.unsubscribe();
      this.expirationSubscription?.unsubscribe();
      this.setCountDownSubscription();
      this.setExpirationSubscription();
    }
  }

  ngOnDestroy(): void {
    this.countDownSubscription?.unsubscribe();
    this.expirationSubscription?.unsubscribe();
  }

  private setCountDownSubscription(): void {
    this.countDownSubscription = this.campaignTimerService
      .getCampaignCountDown$(this.campaignName)
      .subscribe((countDown) => {
        this.expiresSoon = countDown <= 1800;
        const countDownISOString = dayjs(countDown * 1000).toISOString();
        this.seconds = countDownISOString.substr(17, 2);
        this.minutes = countDownISOString.substr(14, 2);
        this.hours = countDownISOString.substr(11, 2);
        this.changeDetectorRef.detectChanges();
      });
  }

  private setExpirationSubscription(): void {
    this.expirationSubscription = this.campaignTimerService.whenCampaignIsExpired$(this.campaignName).subscribe(() => {
      this.expired.emit();
      this.seconds = '00';
      this.minutes = '00';
      this.hours = '00';
      this.changeDetectorRef.detectChanges();
    });
  }
}

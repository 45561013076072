import { PromoVisualOptions, CollapsibleTileSize, CollapsibleTile } from '@box-types';
import { getPointsPromoVisuals } from '../promo-campaigns';

function getHostClasses(size: CollapsibleTileSize): string {
  if (!size) return '';
  return `expanded-tile-${size}`;
}

function generateTitleSizeClass(title: string): string {
  if (!title?.length) return '';
  const longestWord = title.split(' ').sort((a, b) => b.length - a.length)[0];
  if (!longestWord) return '';
  if (longestWord.length > 10 && longestWord.length <= 12) return 'expanded-tile-title-medium';
  if (longestWord.length > 12) return 'expanded-tile-title-small';
  return '';
}

function generateTagOptions(tile: CollapsibleTile): PromoVisualOptions {
  const text = tile.multiplier > 0 ? `x${String(tile.multiplier)}` : tile.points > 0 ? `+${String(tile.points)}` : null;
  if (!text) return;
  return {
    ...getPointsPromoVisuals(),
    text
  };
}

function generateBenefitClass(tile: CollapsibleTile): string {
  if (!tile) return '';
  if (tile.multiplier > 0) return 'collapsed-tile-benefit-multiplier';
  if (tile.points > 0) return 'collapsed-tile-benefit-points';
}

export { generateBenefitClass, generateTagOptions, getHostClasses, generateTitleSizeClass, getPointsPromoVisuals };

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Contest } from '@box-types';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContestsService } from '@box-core/services';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { generateImageSrc } from '@box/utils';

const MOBILE_BREAKPOINT = '(max-width: 512px)';

@Component({
  selector: 'contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.scss']
})
export class ContestComponent implements OnInit, OnDestroy {
  @Input() public contest: Contest;
  @Input() public smallVersionOnly = false;

  public title: string;
  public imageUrl: string;
  public hasParticipated = false;
  public hasWon = false;
  public participations: number;
  public remainingTime: string;
  private remainingTimeSubscription: Subscription;

  constructor(
    private router: Router,
    private contestsService: ContestsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.setContestData();
    this.setRemainingTimeSubscription();
  }

  ngOnDestroy(): void {
    if (this.remainingTimeSubscription) this.remainingTimeSubscription.unsubscribe();
  }

  private setRemainingTimeSubscription(): void {
    this.remainingTimeSubscription = this.contestsService
      .triggerCountDown(this.contest.expirationDate)
      .pipe(
        map((remainingMillis) =>
          this.contestsService.convertMillisToReadableText(remainingMillis, this.contest.expirationDate)
        )
      )
      .subscribe((remainingTime) => {
        this.remainingTime = remainingTime;
      });
  }

  private setContestData(): void {
    this.title = this.contest.title;
    this.setContestImage();
    if (this.contest.participation) this.setContestParticipationData();
  }

  private setContestImage(): void {
    const isSmallScreen: boolean = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    if (isSmallScreen || this.smallVersionOnly) {
      this.imageUrl = generateImageSrc(this.contest.mobileImage);
      return;
    }
    this.imageUrl = generateImageSrc(this.contest.webImage);
  }

  private setContestParticipationData(): void {
    this.participations = this.contest.participation?.participations;
    this.hasParticipated = this.participations >= 0;
    this.hasWon = this.contest.won;
  }

  public goToContestPage(): void {
    const state = {
      state: this.contest
    };
    void this.router.navigate(['/contests', this.contest.slug], state);
  }
}

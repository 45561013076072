import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Timeslot } from '@box-types';
import { generateTimeslots } from '@box/utils';
import { ConfigurationService } from './configuration.service';

@Injectable({ providedIn: 'root' })
export class TimeslotsService {
  private readonly timeslotSource = new BehaviorSubject<Timeslot>(undefined);
  public readonly timeslot$ = this.timeslotSource.asObservable();

  private readonly timeslotsSource = new BehaviorSubject<Timeslot[]>(undefined);
  public readonly timeslots$ = this.timeslotsSource.asObservable();

  constructor(private configService: ConfigurationService) {}

  public getTimeslot(): Timeslot {
    return this.timeslotSource.getValue();
  }

  public setTimeslot(timeslot: Timeslot): void {
    this.timeslotSource.next(timeslot);
  }

  public clearTimeslot(): void {
    this.timeslotSource.next(undefined);
  }

  public getTimeslots(): Timeslot[] {
    return this.timeslotsSource.getValue();
  }

  public setTimeslots(timeslots: Timeslot[]): void {
    this.timeslotsSource.next(timeslots);
  }

  public clearTimeslots(): void {
    this.timeslotsSource.next(undefined);
  }

  public generateDefaultTimeslots(): Timeslot[] {
    const config = this.configService.getConfiguration();
    if (!config?.scheduledOrdersLimit) return generateTimeslots(30, 5, 60);
    const timeslotDaysLimit: number = config.scheduledOrdersLimit;
    return generateTimeslots(30, timeslotDaysLimit, 60);
  }
}

import { getCardFromPaymentType } from './payment-types.utils';
import { PaymentTokenResponse, Payment, InitiatePaymentOptions, BusinessVertical } from '@box-types';

function getInitiatePaymentOptions(
  paymentTokenResponse: PaymentTokenResponse,
  payment: Payment,
  orderId: string
): InitiatePaymentOptions {
  const selectedCard = getCardFromPaymentType(payment, paymentTokenResponse.cardList);
  const useSavedCard = Boolean(selectedCard);
  const saveCard = Boolean(payment?.saveCard);
  return {
    merchantRef: paymentTokenResponse.merchantRef,
    secToken: paymentTokenResponse.secToken,
    orderId,
    savedCard: selectedCard ? selectedCard.datacacheReference : '',
    saveCard,
    useSavedCard
  };
}

function getNbgAppName(businessVertical?: BusinessVertical): string {
  if (!businessVertical) return 'efoodnew';
  const isFood = businessVertical === 'food';
  return isFood ? 'efoodnew' : 'boxmarketsweb';
}

export { getInitiatePaymentOptions, getNbgAppName };

import { Order } from '@box-types';
import { isOrderAccepted } from './order-status.utils';
import dayjs from 'dayjs';
import { isOrderDaas, orderMatchesDaasInfoStatus } from './order-daas.utils';

export { isOrderReviewable };

function isOrderReviewable(order: Order): boolean {
  if (order.rating) return false;

  const currentDate = dayjs();
  const isDaas = isOrderDaas(order);

  if (isDaas) {
    return orderMatchesDaasInfoStatus(order, 'SHIPPED');
  } else {
    const { timeSlotEnd, createdAt, shopResponse } = order;
    if (timeSlotEnd) {
      const deliveredDate = dayjs.unix(timeSlotEnd).add(shopResponse.minutesEstimate, 'minutes');
      return currentDate.isAfter(deliveredDate);
    }

    if (isOrderAccepted(order)) {
      const deliveredDate = dayjs(createdAt).add(shopResponse.minutesEstimate, 'minutes');
      return currentDate.isAfter(deliveredDate);
    }

    return false;
  }
}

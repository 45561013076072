import { orderBy } from 'lodash-es';
import { isCouponExpired, isCouponUsed } from './coupon.utils';
import { Coupon } from '@box-types';

function sortAvailableCoupons(coupons: Coupon[]): Coupon[] {
  if (!coupons?.length) return [];
  return orderBy(
    coupons,
    [
      (coupon) => (coupon.disabledText ? 0 : 1),
      (coupon) => coupon.info?.priority ?? 0,
      (coupon) => {
        /* if a coupon has no expiration date we want it to lose priority.
        since we have an ascending order of epochs,
        we need to use a large number to achieve this behavior */
        if (!coupon.expirationDate) return 9999999999999;
        return new Date(coupon.expirationDate).getTime();
      },
      (coupon) => coupon.amount ?? 0,
      (coupon) => coupon.percentage ?? 0,
      (coupon) => coupon.loyaltyPoints ?? 0
    ],
    ['desc', 'desc', 'asc', 'desc', 'desc', 'desc']
  );
}

function sortUnavailableCoupons(coupons: Coupon[]): Coupon[] {
  const usedCoupons = coupons.filter((coupon) => isCouponUsed(coupon));
  const expiredCoupons = coupons.filter((coupon) => !isCouponUsed(coupon) && isCouponExpired(coupon));
  const sortedUsedCoupon = orderBy(usedCoupons, 'redemptionDate', 'desc');
  const sortedExpiredCoupon = orderBy(expiredCoupons, 'expirationDate', 'desc');
  return [...sortedUsedCoupon, ...sortedExpiredCoupon];
}

export { sortAvailableCoupons, sortUnavailableCoupons };

import { Component, ChangeDetectionStrategy, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'rewards-preview-overlay',
  templateUrl: './rewards-preview-overlay.component.html',
  styleUrls: ['./rewards-preview-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsPreviewOverlayComponent {
  @Output() private ctaClick = new EventEmitter<null>();
  @Output() private closeClick = new EventEmitter<null>();
  @HostBinding('class') public hostClass = 'rewards-preview-overlay';

  public onClickCta(): void {
    this.ctaClick.emit();
  }

  public onClose(): void {
    this.closeClick.emit();
  }
}

import { Injectable } from '@angular/core';
import { storageGet, storageSet } from '@box/utils';
import { DeviceDetectorService } from './device-detector.service';
import { UserService } from './user.service';

const DOWNLOAD_HEADER_SEEN = 'Box:downloadHeaderSeen';

@Injectable({ providedIn: 'root' })
export class DownloadHeaderService {
  constructor(private userService: UserService, private deviceDetectorService: DeviceDetectorService) {}

  public setDownloadHeaderSeen(seen: boolean): void {
    storageSet(DOWNLOAD_HEADER_SEEN, seen, window.sessionStorage);
  }

  public getDownloadHeaderSeen(): boolean {
    return storageGet(DOWNLOAD_HEADER_SEEN, window.sessionStorage);
  }

  public getDownloadHeaderVisibility(): boolean {
    if (!this.userService.isGuest) return false;
    const isMobile = this.deviceDetectorService.isMobile();
    if (!isMobile) return false;
    const seen = this.getDownloadHeaderSeen();
    if (seen) return false;
    return true;
  }
}

import { Injectable } from '@angular/core';
import { UserService } from '@box-core/services/user.service';
import { SentryService } from '@box-core/services/sentry.service';
import { LoyaltyService } from '@box-core/services/loyalty.service';
import { OnBoardingDialogComponent } from '@box-core/components';
import { DialogService } from '@box-core/services/dialog.service';
import { OnBoardingDialogData, APIError } from '@box-types';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OnBoardingService {
  constructor(
    private userService: UserService,
    private sentryService: SentryService,
    private loyaltyService: LoyaltyService,
    private dialogService: DialogService
  ) {}

  public initiateOnBoarding$(): Observable<null> {
    const seenFirstPoints: boolean = this.userService.hasUserLoyaltyEvent('first_referral_view');
    if (seenFirstPoints) return of(null);

    const appDownloadEventPoints: number = this.loyaltyService.getClaimablePoints('app_download');
    this.setPointsAndLoyaltyEvent(appDownloadEventPoints);
    if (appDownloadEventPoints > 0) return this.openOnBoardingDialog(appDownloadEventPoints);
    return of(null);
  }

  private openOnBoardingDialog(eventPoints: number): Observable<null> {
    return this.dialogService
      .openDialog(OnBoardingDialogComponent, {
        closeOnNavigation: true,
        panelClass: ['box-dialog-fit-content', 'no-background', 'borderless'],
        data: {
          eventPoints: eventPoints
        } as OnBoardingDialogData
      })
      .afterClosed()
      .pipe(switchMap(() => of(null)));
  }

  private setPointsAndLoyaltyEvent(campaignPoints: number): void {
    if (this.userService.isGuest) {
      this.userService.addPoints(campaignPoints);
      this.userService.setGuestLoyaltyEvent('first_referral_view');
    } else {
      this.loyaltyService.setEvent('first_referral_view').subscribe({
        next: () => this.userService.addUserLoyaltyEvent('first_referral_view'),
        error: (error: APIError) => this.sentryService.captureException(error)
      });
    }
  }
}

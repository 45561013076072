import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DiscoverFilter, PromoVisualOptions } from '@box-types';
import { getDiscoverFilterMultiplierTag, generateImageSrc } from '@box/utils';
import { HomeDiscoverFilterService } from './home-discover-filter.service';

@Component({
  selector: 'home-discover-filter',
  templateUrl: 'home-discover-filter.component.html',
  styleUrls: ['home-discover-filter.component.scss'],
  providers: [HomeDiscoverFilterService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeDiscoverFilterComponent implements OnChanges {
  @Input() public discoverFilter: DiscoverFilter;

  public tags: PromoVisualOptions[];
  public imageUrl: string;
  public altText: string;

  constructor(private homeDiscoverFilterService: HomeDiscoverFilterService) {}

  @HostBinding('class') public hostClass = 'home-discover-filter';

  ngOnChanges(changes: SimpleChanges): void {
    this.discoverFilter = changes.discoverFilter.currentValue as DiscoverFilter;
    this.imageUrl = this.discoverFilter.mainViewWebImage
      ? generateImageSrc(this.discoverFilter.mainViewWebImage)
      : generateImageSrc(this.discoverFilter.webImage);
    this.altText = this.discoverFilter.title;

    const fileterVisualsRelatedToCouponSynergy =
      this.homeDiscoverFilterService.getDiscoverFilterCouponSpecificVisualOptions(this.discoverFilter);
    this.tags = fileterVisualsRelatedToCouponSynergy
      ? fileterVisualsRelatedToCouponSynergy
      : getDiscoverFilterMultiplierTag(this.discoverFilter);
  }
}

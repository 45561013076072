<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content unavailable-items-dialog">
    <div class="unavailable-items-dialog-wrapper">
      <food-product *ngFor="let product of products; let i = index" [product]="product"> </food-product>
    </div>
  </main>
</div>

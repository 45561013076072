import { Shop, GAEcommerceItemConfig, GAPromotionConfig } from '@box-types';
import { CollectionCampaign } from '../promo-campaigns';

export { getCollectionCampaignImpressionGAConfig, createCollectionCampaignImpressionItems };

function createCollectionCampaignImpressionItems(
  creativeSlot: string,
  shops: Shop[],
  selectedShop: Shop
): GAEcommerceItemConfig[] {
  return shops.reduce((itemsArray: GAEcommerceItemConfig[], shop, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: shop._id,
      item_name: shop.name,
      affiliation: shop.promo.firstGroupLongOptions?.text ?? '',
      affiliation2: shop.promo.secondGroupLongOptions?.text ?? '',
      index: index + 1,
      item_category: shop.mainCuisine?.name ?? '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: creativeSlot,
      item_variant: ''
    };
    if (item.item_id === selectedShop._id) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionCampaignImpressionGAConfig(
  creativeName: string,
  collectionCampaign: CollectionCampaign,
  shops: Shop[],
  selectedShop: Shop
): GAPromotionConfig {
  if (!shops?.length) return;
  const promotionItems = createCollectionCampaignImpressionItems('campaign bottomsheet', shops, selectedShop);
  return {
    creative_name: creativeName,
    creative_slot: 'campaign_bottomsheet',
    promotion_id: '',
    promotion_name: collectionCampaign.shortTabTitle,
    items: promotionItems
  };
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';
import { ProductInstance, OfferInstance } from '@box-types';
import { getOfferInstanceFreeGroupIndex, getCartProductInstanceDescription } from '@box/utils';

@Component({
  selector: 'cart-offer-instance',
  templateUrl: './cart-offer-instance.component.html',
  styleUrls: ['./cart-offer-instance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartOfferInstanceComponent implements OnChanges {
  /* The disabled property is used for the Order Revie won Checkout. This needs
  to be removed in the future with a Checkout specific component.
  The inactive property is used for the Cart Offers that do not meet the
  minimumOrderItemsPrice requirement. */
  @Input() public instance: OfferInstance;
  @Input() public disabled = false;
  @Input() public inactive = false;

  @Output() private add: EventEmitter<OfferInstance> = new EventEmitter<OfferInstance>();
  @Output() private remove: EventEmitter<OfferInstance> = new EventEmitter<OfferInstance>();

  public freeGroupIndex: number;

  @HostBinding('class.inactive') public isInactive: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instance) {
      this.instance = changes.instance.currentValue as OfferInstance;
      this.freeGroupIndex = getOfferInstanceFreeGroupIndex(this.instance);
    }

    if (changes.disabled) this.disabled = changes.disabled.currentValue as boolean;
    if (changes.inactive) {
      this.inactive = changes.inactive.currentValue as boolean;
      this.isInactive = this.inactive;
    }
  }

  public onAdd(): void {
    this.add.emit(this.instance);
  }

  public onRemove(): void {
    this.remove.emit(this.instance);
  }

  public productInstanceDescription(instance: ProductInstance): string {
    return getCartProductInstanceDescription(instance, false);
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CartService, ShopService } from '@box-core/services';
import { Shop } from '@box-types';
import { ProductMYOOptions } from '../product-myo/product-myo.types';
import { ProductMYODialogData } from './product-myo-dialog.types';
import { CartActionResponse } from '@box/utils';

@Component({
  selector: 'product-myo-dialog',
  templateUrl: './product-myo-dialog.component.html',
  styleUrls: ['./product-myo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYODialogComponent implements OnInit {
  public productMYOOptions: ProductMYOOptions;
  private shop: Shop;

  constructor(
    private cartService: CartService,
    private shopService: ShopService,
    private dialogRef: MatDialogRef<ProductMYODialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ProductMYODialogData
  ) {}

  ngOnInit(): void {
    this.shop = this.data.shop;
    this.productMYOOptions = {
      editMode: this.data.editMode,
      product: this.data.product,
      productInstance: this.data.productInstance,
      productInstanceToEdit: this.data.productInstanceToEdit,
      canClose: true,
      isSuperMarket: this.shop.isSuperMarket,
      showQuantity: true,
      commentsAllowed: this.shop.isProductCommentsAllowed,
      customPriceCheck: true
    };
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public onSubmit(data: ProductMYOOptions) {
    const { editMode, product, productInstance, productInstanceToEdit } = data;
    const response: CartActionResponse = editMode
      ? this.cartService.updateProduct(product, productInstanceToEdit, productInstance)
      : this.cartService.addProduct(product, productInstance);
    if (response === 'CART_LIMIT_REACHED') return;
    if (response === 'ITEM_LIMIT_REACHED') return;
    if (response === 'CHOICES_THRESHOLD_NOT_REACHED') return;
    this.shopService.syncCartProductToMenu(product);
    this.shopService.addToCartProductAnalyticsEvent(product, productInstance, 'shop');
    this.closeDialog();
  }
}

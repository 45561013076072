import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'box-digital-card',
  templateUrl: './box-digital-card.component.html',
  styleUrls: ['./box-digital-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxDigitalCardComponent implements OnChanges {
  @Input() public disabled = false;
  @Input() public flippable = false;
  @Output() private flip: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class') public hostClass = 'box-digital-card';
  @HostBinding('class.box-digital-card-disabled') public isDisabled: boolean;
  @HostBinding('class.box-digital-card-flipped') public isFlipped = false;
  @HostBinding('class.box-digital-card-flippable') public isFlippable = false;

  @HostListener('click', ['$event']) onClick(): void {
    this.onFlip();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue as boolean;
      this.isDisabled = this.disabled;
    }
    if (changes.flippable) {
      this.flippable = changes.flippable.currentValue as boolean;
      this.isFlippable = this.flippable;
    }
  }

  public onFlip(): void {
    if (!this.flippable || this.disabled) return undefined;
    this.isFlipped = !this.isFlipped;
    this.flip.emit(this.isFlipped);
  }
}

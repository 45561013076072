<div class="box-swiper">
  <div [swiper]="swiperOptions" class="suggestions-swiper-wrapper" *ngIf="checkoutSuggestionBanners">
    <div class="swiper-wrapper">
      <ng-container *ngIf="alternativeView; else normalView">
        <div
          class="swiper-slide checkout-suggestion-banner-card-slide"
          *ngFor="let banner of checkoutSuggestionBanners; let i = index"
        >
          <checkout-suggestion-banner-card
            [style.--animation-order]="i"
            [checkoutSuggestionBanner]="banner"
            (bannerSelect)="onBannerSelect($event)"
            (click)="onBannerClick(banner)"
            visibilityObserver 
            [triggerOnce]="true" 
            [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"    
            (enteredViewport)="onBannerEnteredViewport(banner)"
          ></checkout-suggestion-banner-card>
        </div>
      </ng-container>
      <ng-template #normalView>
        <div class="swiper-slide" *ngFor="let banner of checkoutSuggestionBanners; let i = index">
          <checkout-suggestion-banner
            [checkoutSuggestionBanner]="banner"
            [active]="activeIndex === i"
            (bannerSelect)="onBannerSelect($event)"
            (click)="onBannerClick(banner)"
            visibilityObserver 
            [triggerOnce]="true" 
            [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"    
            (enteredViewport)="onBannerEnteredViewport(banner)"
          ></checkout-suggestion-banner>
        </div>
      </ng-template>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</div>

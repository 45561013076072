export { matchesSearchTerm, filterItemsBySearchTerms };

function matchesSearchTerm<T>(item: T & { searchTerms?: string }, term: string): boolean {
  if (!item?.searchTerms) return false;
  return item.searchTerms.indexOf(term) !== -1;
}

function filterItemsBySearchTerms<T>(items: (T & { searchTerms?: string })[], term: string): T[] {
  return items.filter((item) => matchesSearchTerm(item, term));
}

<mat-accordion displayMode="flat">
  <ng-container *ngFor="let group of offerInstance.groups; let i = index">
    <mat-expansion-panel
      class="offer-wizard-group"
      (afterCollapse)="onAfterCollapse()"
      (afterExpand)="onAfterExpand()"
      [expanded]="i === groupIndexToBeExpanded"
    >
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
        <mat-panel-title>
          <div class="offer-wizard-group-header-wrapper">
            <div class="offer-wizard-group-header">
              <mat-icon
                *ngIf="group.selectedProduct"
                class="offer-wizard-group-check ods-typography-subtitle-s"
                fontSet="material-icons-round"
                >check</mat-icon
              >
              <span class="ods-typography-body-m-bold">{{ group.title }}</span>
            </div>
            <div
              *ngIf="group.selectedProduct"
              class="offer-wizard-group-selected-product ods-typography-body-s-regular"
            >
              <span class="ods-typography-body-s-bold">{{ group.selectedProduct.name }}</span>
              <span
                *ngIf="group.selectedProduct.cartInstances[0].description || group.selectedProduct.info?.el"
                class="offer-wizard-group-selected-product-description"
                >: {{ group.selectedProduct.cartInstances[0].description ?? group.selectedProduct.info?.el }}</span
              >
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-radio-group class="offer-wizard-group-products">
          <div *ngFor="let product of group.products">
            <mat-radio-button
              *ngIf="product.displayable"
              color="primary"
              [value]="product"
              [checked]="product.checked"
              [disabled]="!product.available"
              (change)="onProductChange(group, product)"
            >
              <offer-wizard-product
                [product]="product"
                [offerInstance]="offerInstance"
                (productMYO)="onProductMYO(group, product)"
              ></offer-wizard-product>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </ng-template>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>

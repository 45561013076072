import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { HomeSearchService } from '@box-core/services';
import { BOXEmptyStateOptions, Shop, SearchStatusType } from '@box-types';
import { Subscription, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { HomeSearchResultsService } from './home-search-results.service';

@Component({
  selector: 'home-search-results',
  templateUrl: './home-search-results.component.html',
  styleUrls: ['./home-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HomeSearchResultsService]
})
export class HomeSearchResultsComponent implements OnInit, OnDestroy {
  public hasResults: boolean;
  public pendingResults: boolean;
  public searchTerm: string;
  public shops: Shop[];
  public searchStatus: SearchStatusType;
  public emptyStateOptions: BOXEmptyStateOptions;
  public ctaText: string;

  private searchResultsSubscription: Subscription;
  private searchTermSubscription: Subscription;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private homeSearchService: HomeSearchService,
    private homeSearchResultsService: HomeSearchResultsService
  ) {}

  ngOnInit(): void {
    this.setSearchTermSubscription();
    this.setSearchSubscription();
  }

  ngOnDestroy(): void {
    if (this.searchResultsSubscription) this.searchResultsSubscription.unsubscribe();
    if (this.searchTermSubscription) this.searchTermSubscription.unsubscribe();
  }

  private setSearchTermSubscription(): void {
    this.searchTermSubscription = this.homeSearchService.searchTerm.subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  private setSearchSubscription(): void {
    this.searchResultsSubscription = combineLatest([
      this.homeSearchService.searchResults,
      this.homeSearchService.searchStatus
    ]).subscribe(([shops, status]) => {
      this.hasResults = !!shops?.length && status === 'COMPLETED';
      this.pendingResults = status === 'PENDING';
      this.shops = shops;
      this.searchStatus = status;
      this.ctaText = this.homeSearchResultsService.generateCTAText(status, this.hasResults);
      this.emptyStateOptions = this.homeSearchResultsService.generateEmptyStateOptions(this.searchTerm);
      this.changeDetectorRef.detectChanges();
    });
  }

  public onShowMoreClick(): void {
    if (this.searchStatus !== 'COMPLETED' || !this.hasResults) return void this.router.navigate(['/discover']);
    const queryParams = { vertical: 'food', query: this.searchTerm };
    void this.router.navigate(['/discover/search'], { queryParams });
  }
}

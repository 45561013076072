import {
  Product,
  Shop,
  DeliveryFee,
  Offer,
  OfferAvailabilityOptions,
  DeliveryMethod,
  ShopItemsAvailabilityApiOptions,
  ProductAvailabilityOptions
} from '@box-types';
import { Cart, CreateCartOptions } from './cart.types';
import { getCartProductBeginPrice, getCartProductFinalPrice } from './cart-product.utils';
import { getCartOfferBeginPrice, getCartOfferFinalPrice } from './cart-offer.utils';
import { orderBy, omitBy, isNil } from 'lodash-es';
import { getOfferInstanceTotalEnvFee, offerRequiresMinOrderItemsPrice } from '../offers';
import { getProductTotalEnvFee } from '../products';
import { getShopMinimumPrice } from '../shops';

export {
  createCart,
  updateCart,
  isCartEmpty,
  cartSupportsVolume,
  emptyCart,
  getShopCartMaxVolume,
  maxCartVolumeWillBeReached,
  maxCartQuantityForItemWillBeReached,
  calculateCartVolume,
  calculateCartEnvFee,
  calculateCartItemsQuantity,
  calculateCartItemsBeginPrice,
  calculateCartItemsFinalPrice,
  itemsPriceIsOverMinimum,
  cartHasInactiveOffers,
  calculateCartValues,
  getFreeDeliveryFeeThreshold,
  getCorrespondingDeliveryFeeTier,
  getNextDeliveryFeeTier,
  shouldShowFee,
  generateShopItemsAvailabilityApiOptions
};

function createCart(options: CreateCartOptions): Readonly<Cart> {
  const { products, offers, maxVolume, shop } = options;

  const cart: Cart = {
    products: products ?? [],
    offers: offers ?? [],
    shop,
    itemsStartingPrice: 0,
    itemsFinalPrice: 0,
    itemsDiscount: 0,
    itemsQuantity: 0,
    envFee: 0
  };

  if (maxVolume) {
    cart.volume = 0;
    cart.maxVolume = maxVolume;
  }

  return Object.freeze<Cart>(cart);
}

function updateCart(cart: Cart, cartProperties: Cart): Readonly<Cart> {
  return Object.freeze<Cart>({ ...cart, ...cartProperties });
}

function isCartEmpty(cart: Cart): boolean {
  return cart.itemsQuantity === 0;
}

function cartSupportsVolume(cart: Cart): boolean {
  return cart.maxVolume > 0;
}

function emptyCart(cart: Cart): Readonly<Cart> {
  const updatedCart: Cart = {
    ...cart,
    products: [],
    offers: [],
    itemsStartingPrice: 0,
    itemsFinalPrice: 0,
    itemsDiscount: 0,
    itemsQuantity: 0,
    envFee: 0
  };

  if (cartSupportsVolume(cart)) updatedCart.volume = 0;
  return Object.freeze<Cart>(updatedCart);
}

function getShopCartMaxVolume(shop: Shop): number {
  const { hasVolumeMeasurement, volumeMeasurementLimit } = shop;
  if (!hasVolumeMeasurement) return 0;
  return volumeMeasurementLimit ?? 0;
}

function maxCartVolumeWillBeReached(cart: Cart, volume: number): boolean {
  if (!cartSupportsVolume(cart)) return false;
  const maxVolume = cart.maxVolume;
  const cartVolume = cart.volume;
  return cartVolume + volume > maxVolume;
}

function maxCartQuantityForItemWillBeReached(quantity: number, item: Product | Offer): boolean {
  if (!item.maxItemsQuantity) return false;
  return quantity > item.maxItemsQuantity;
}

function calculateCartVolume(cart: Cart): number {
  const { products, offers } = cart;
  const items = [...offers, ...products];
  return items.reduce((cartVolume, item) => cartVolume + (item.volume ?? 0) * item.cartQuantity, 0);
}

function calculateCartEnvFee(cart: Cart): number {
  const { offers, products } = cart;
  const offersEnvFee = offers
    .flatMap((offer) => offer.cartInstances ?? [])
    .reduce((envFee, instance) => envFee + getOfferInstanceTotalEnvFee(instance), 0);
  const productsEnvFee = products
    .flatMap((product) => product.cartInstances ?? [])
    .reduce((acc, instance) => acc + getProductTotalEnvFee(instance), 0);
  return offersEnvFee + productsEnvFee;
}

function calculateCartItemsQuantity(cart: Cart): number {
  const { products, offers } = cart;
  return [...products, ...offers].reduce((quantity, item) => quantity + item.cartQuantity, 0);
}

function calculateCartItemsBeginPrice(cart: Cart): number {
  const { offers, products } = cart;
  const offersPrice = offers.reduce((price, offer) => price + getCartOfferBeginPrice(offer), 0);
  const productsPrice = products.reduce((price, product) => price + getCartProductBeginPrice(product), 0);
  return offersPrice + productsPrice;
}

function calculateCartItemsFinalPrice(cart: Cart): number {
  const { offers, products } = cart;
  const offersPrice = offers.reduce((price, offer) => price + getCartOfferFinalPrice(offer), 0);
  const productsPrice = products.reduce((price, product) => price + getCartProductFinalPrice(product), 0);
  return offersPrice + productsPrice;
}

function itemsPriceIsOverMinimum(shop: Shop, deliveryMethod: DeliveryMethod, itemsPrice: number): boolean {
  const minimumPrice = getShopMinimumPrice(shop, deliveryMethod);
  return itemsPrice >= minimumPrice;
}

function cartHasInactiveOffers(cart: Cart): boolean {
  const { itemsQuantity, products, offers } = cart;
  const productsQuantity = products.reduce((cartQuantity, product) => cartQuantity + product.cartQuantity, 0);
  const offersQuantity = offers.reduce((cartQuantity, offer) => cartQuantity + offer.cartQuantity, 0);
  return productsQuantity + offersQuantity > itemsQuantity;
}

function calculateCartValues(cart: Cart): Cart {
  const { offers, products } = cart;
  const offersWithMinOrderPrice = offers.filter((offer) => offerRequiresMinOrderItemsPrice(offer));
  const offersWithoutMinOrderPrice = offers.filter((offer) => !offerRequiresMinOrderItemsPrice(offer));

  let itemsStartingPrice = 0;
  let itemsFinalPrice = 0;
  let itemsQuantity = 0;
  let envFee = 0;

  for (const product of products) {
    const productBeginPrice = getCartProductBeginPrice(product);
    const productFinalPrice = getCartProductFinalPrice(product);
    // this should be changes to productInstanceEnvFee
    const productEnvFee = product.cartInstances.reduce((acc, instance) => acc + getProductTotalEnvFee(instance), 0);
    itemsStartingPrice += productBeginPrice;
    itemsFinalPrice += productFinalPrice;
    itemsQuantity += product.cartQuantity;
    envFee += productEnvFee;
  }

  for (const offer of offersWithoutMinOrderPrice) {
    const offerBeginPrice = getCartOfferBeginPrice(offer);
    const offerFinalPrice = getCartOfferFinalPrice(offer);
    const offerEnvFee = offer.cartInstances.reduce((acc, instance) => acc + getOfferInstanceTotalEnvFee(instance), 0);
    itemsStartingPrice += offerBeginPrice;
    itemsFinalPrice += offerFinalPrice;
    itemsQuantity += offer.cartQuantity;
    envFee += offerEnvFee;
  }

  const sortedOffersWithMinOrderPrice = orderBy(offersWithMinOrderPrice, 'minOrderItemsPrice', 'asc');

  for (const offer of sortedOffersWithMinOrderPrice) {
    if (offer.minOrderItemsPrice > itemsFinalPrice) break;
    const offerBeginPrice = getCartOfferBeginPrice(offer);
    const offerFinalPrice = getCartOfferFinalPrice(offer);
    const offerEnvFee = offer.cartInstances.reduce((acc, instance) => acc + getOfferInstanceTotalEnvFee(instance), 0);
    itemsStartingPrice += offerBeginPrice;
    itemsFinalPrice += offerFinalPrice;
    itemsQuantity += offer.cartQuantity;
    envFee += offerEnvFee;
  }

  return {
    ...cart,
    itemsStartingPrice,
    itemsFinalPrice,
    itemsDiscount: itemsStartingPrice - itemsFinalPrice,
    itemsQuantity,
    envFee
  };
}

function shouldShowFee(fees: DeliveryFee[]): boolean {
  if (!fees?.length) return false;
  if (fees.length > 1) return true;
  const noFee = fees[0].fee === 0;
  const noThreshold = fees[0].minCartPrice === 0;
  if (noFee && noThreshold) return false;
  return true;
}

function getFreeDeliveryFeeThreshold(fees: DeliveryFee[]): number {
  if (!shouldShowFee(fees)) return 0;
  return fees.find((fee) => fee.fee === 0)?.minCartPrice;
}

function getCorrespondingDeliveryFeeTier(fees: DeliveryFee[], itemsPrice: number): DeliveryFee {
  if (!shouldShowFee(fees)) return;
  const normalizedPrice = itemsPrice > 0 ? itemsPrice : 0;
  const orderedFees = orderBy(fees, 'minCartPrice', 'desc');
  return orderedFees.find((feeTier) => normalizedPrice >= feeTier.minCartPrice);
}

function getNextDeliveryFeeTier(fees: DeliveryFee[], itemsPrice: number): DeliveryFee {
  if (!shouldShowFee(fees)) return;
  const normalizedPrice = itemsPrice > 0 ? itemsPrice : 0;
  const orderedFees = orderBy(fees, 'minCartPrice', 'desc');
  const currentTier = orderedFees.findIndex((feeTier) => normalizedPrice >= feeTier.minCartPrice);
  if (currentTier === 0 || currentTier === -1) return;
  return orderedFees[currentTier - 1];
}

function generateShopItemsAvailabilityApiOptions(
  shop: Shop,
  productsAvailabilityOptions: ProductAvailabilityOptions[],
  offersAvailabilityOptions: OfferAvailabilityOptions[]
): ShopItemsAvailabilityApiOptions {
  const { collectionType, supermarketGroup, promo } = shop;
  const params = omitBy(
    {
      offers: promo ? !promo.hideOffers : true,
      supermarketGroup
    },
    isNil
  );
  const body = {
    products: productsAvailabilityOptions,
    offers: offersAvailabilityOptions
  };
  return { collectionType, body, params };
}

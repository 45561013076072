function isValidGreekPhone(phone: string): boolean {
  const regExp = new RegExp('(^69\\d{8}$)|(^2\\d{9}$)');
  return regExp.test(phone);
}

function formatPhoneToITU(phone: string): string {
  if (isValidGreekPhone(phone)) return `+30${phone}`;
  return phone;
}

export { isValidGreekPhone, formatPhoneToITU };

import { Component, ChangeDetectionStrategy, OnChanges, Input, HostBinding, SimpleChanges } from '@angular/core';
import { OrderReceiptOffer, OrderReceiptOfferProduct } from '@box-types';

@Component({
  selector: 'order-receipt-offer',
  templateUrl: './order-receipt-offer.component.html',
  styleUrls: ['./order-receipt-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReceiptOfferComponent implements OnChanges {
  @Input() private offer: OrderReceiptOffer;

  public quantity: number;
  public price: string;
  public products: OrderReceiptOfferProduct[];

  @HostBinding('class') public hostClass = 'order-receipt-offer';

  ngOnChanges(changes: SimpleChanges): void {
    this.offer = changes.offer.currentValue as OrderReceiptOffer;
    this.quantity = this.offer.quantity;
    this.price = this.offer.price;
    this.products = this.offer.products;
  }
}

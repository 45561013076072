import { BOXTimeRange, GBRewardSkin, Reward, RewardsCard, RewardsCardStatus, Configuration } from '@box-types';
import dayjs from 'dayjs';
import orderBy from 'lodash-es/orderBy';
import { storageSet } from '../storage';

function normalizeReward(reward: Reward): Reward {
  return {
    ...reward,
    segments: reward.segments ?? []
  };
}

function filterGBRewardsBySegment(rewards: Reward[], segment: string): Reward[] {
  if (!rewards?.length) return [];
  const gbRewards = rewards.filter((reward) => reward?.megabytesReward > 0);
  if (!gbRewards?.length) return [];
  const noSegmentRewards = gbRewards.filter((reward) => !reward.segments?.length);
  if (!segment) return noSegmentRewards;
  // filterItemsBySegments does not apply here
  const segmentedRewards = gbRewards.filter((reward) => reward.segments?.includes(segment));
  return segmentedRewards.length > 0 ? segmentedRewards : noSegmentRewards;
}

function getGBSegmentSkin(config: Configuration, segment: string): GBRewardSkin {
  if (!config?.rewards?.mb?.skins?.length) return;
  const defaultSkin = config.rewards.mb.skins.find((skin) => !skin.segment);
  if (!segment) return defaultSkin;
  const segmentSkin = config.rewards.mb.skins.find((skin) => skin.segment === segment);
  return segmentSkin ?? defaultSkin;
}

function getRewardCardStatus(card: RewardsCard): RewardsCardStatus {
  if (!card.counter) return 'NO_PROGRESS';
  if (card.counter.current >= card.counter.max) return 'COMPLETED';
  return 'IN_PROGRESS';
}

function isRewardsCardClickable(card: RewardsCard): boolean {
  if (card.type === 'exploreOrders') return card.clickable;
  const cardStatus = getRewardCardStatus(card);
  return cardStatus !== 'COMPLETED' && Boolean(card.action);
}

function timeRangesFormat(timeRanges: BOXTimeRange[]): string {
  const allTimeRanges = timeRanges.map((time) => {
    const start = `${time.startHour.toString().padStart(2, '0')}:${time.startMinute.toString().padStart(2, '0')}`;
    const end = `${time.endHour.toString().padStart(2, '0')}:${time.endMinute.toString().padStart(2, '0')}`;
    return `${start}-${end}`;
  });

  return allTimeRanges.join(', ');
}

function sortRewardsCards(cards: RewardsCard[]): RewardsCard[] {
  if (!cards?.length) return [];
  return orderBy(
    cards,
    [
      (card) => {
        switch (card.status) {
          case 'IN_PROGRESS':
            return 0;
          case 'NO_PROGRESS':
            return 1;
          case 'COMPLETED':
            return 2;
        }
      }
    ],
    ['asc']
  );
}

function saveRewardsPointsNotificationTimestamp(): void {
  const currentDate = dayjs();
  const currentTimestamp = currentDate.unix();
  storageSet('Box:rewardsPointsNotification', currentTimestamp, window.localStorage);
}

export {
  normalizeReward,
  getRewardCardStatus,
  filterGBRewardsBySegment,
  getGBSegmentSkin,
  isRewardsCardClickable,
  timeRangesFormat,
  sortRewardsCards,
  saveRewardsPointsNotificationTimestamp
};

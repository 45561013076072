import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

type BadgeSize = 'small' | 'medium' | 'large';

@Directive({ selector: '[badge]' })
export class BadgeDirective implements OnInit, OnChanges {
  @Input('badge') public content: number;
  @Input('badgeSize') public size: BadgeSize = 'medium';
  @Input('badgeHidden') public hidden: boolean;

  private readonly contentElement: HTMLSpanElement;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    this.contentElement = this.renderer.createElement('span') as HTMLSpanElement;
  }

  @HostBinding('class') public hostClass = 'badge-container';

  ngOnInit(): void {
    this.renderer.addClass(this.contentElement, 'badge');
    this.renderer.appendChild(this.element.nativeElement, this.contentElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content) {
      this.content = changes.content.currentValue as number;
      this.renderer.setProperty(this.contentElement, 'innerText', this.content);
    }

    if (changes.size) {
      const previousValue = changes.size.previousValue as BadgeSize;
      this.size = changes.size.currentValue as BadgeSize;
      this.renderer.addClass(this.contentElement, `badge-${this.size}`);
      if (previousValue) this.renderer.removeClass(this.contentElement, `badge-${previousValue}`);
    }

    if (changes.hidden) {
      this.hidden = changes.hidden.currentValue as boolean;
      this.renderer.setProperty(this.contentElement, 'hidden', this.hidden);
    }
  }
}

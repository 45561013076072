<div class="promo-badge-wrapper">
  <ng-container *ngIf="image; else textBadge">
    <div class="promo-badge-image">
      <img [src]="image" />
    </div>
  </ng-container>
  <ng-template #textBadge>
    <div [ngStyle]="textBadgeStyles" class="promo-badge-text ods-typography-microcopy-bold">
      {{ text }}
    </div>
  </ng-template>
</div>

import { ValidatorFn } from '@angular/forms';
import { regExToValidatorFunction } from '@box-shared/validators/validator.utils';

/* Only numbers */

const REG_EXP = new RegExp('^[0-9]*$');

export function numbersOnlyValidator(): ValidatorFn {
  return regExToValidatorFunction(REG_EXP);
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Reward } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'reward-gb',
  templateUrl: './reward-gb.component.html',
  styleUrls: ['./reward-gb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardGBComponent implements OnChanges {
  @Input() private reward: Reward;
  @Input() private segment: string;
  /** `alternativeView` is used to swap between the two different description on the reward. */
  @Input() public alternativeView: boolean;
  /** `backgroundView` is used to swap between the two different UI on the reward container. */
  @Input() public backgroundView: boolean;

  public imageSrc: string;
  public description: string;

  @HostBinding('class') public hostClass = 'reward-gb';
  @HostBinding('class.reward-gb-disabled') public disabled: boolean;
  @HostBinding('class.reward-gb-backgrounded') public backgrounded: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reward) this.reward = changes.reward.currentValue as Reward;
    if (changes.segment) this.segment = changes.segment.currentValue as string;
    if (changes.alternativeView) this.alternativeView = changes.alternativeView.currentValue as boolean;
    this.disabled = !this.reward.available;
    this.backgrounded = this.backgroundView;
    this.imageSrc = this.getImageSrc();
    this.description = this.alternativeView ? this.reward.longDescription : this.reward.shortDescription;
  }

  private getImageSrc(): string {
    if (!this.reward?.skins?.length) return generateImageSrc(this.reward?.image);
    const skin = this.reward.skins.find((skin) => skin.segment === this.segment);
    if (!skin) return generateImageSrc(this.reward.image);
    return generateImageSrc(skin.image);
  }
}

import capitalize from 'lodash-es/capitalize';

export { capitalizeSentence, removeAccents, getInitials, separate, numbersOnly };

function capitalizeSentence(value: string, delimeter = ' '): string {
  const str = value ?? '';
  return str.split(' ').map(capitalize).join(delimeter);
}

function removeAccents(str: string): string {
  if (!str) return '';
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function getInitials(value: string): string {
  const str = value ?? '';
  return str
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toLocaleUpperCase();
}

function separate(input: string, maxSeparations: number, distance: number, separator: string): string {
  if (!input?.length || !maxSeparations || !distance || maxSeparations <= 0 || distance <= 0 || !separator?.length) {
    return input;
  }
  const characters: string[] = [...input];
  const indexes: number[] = [];
  const maximumSeparations = Math.min(Math.floor(characters.length / distance), maxSeparations);
  for (
    let sliceCount = 0, sliceIndex = distance;
    sliceCount < maximumSeparations;
    sliceCount++, sliceIndex += distance
  ) {
    indexes.push(sliceIndex);
  }
  indexes.forEach((value, index) => {
    characters.splice(value + index, 0, separator);
  });
  return characters.join('');
}

function numbersOnly(value: string): string {
  if (!value?.length) return '';
  return value.replace(/[^0-9]/g, '');
}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ShopMenuNavItem, ShopMenuNavItemChange } from '@box-types';

@Component({
  selector: 'shop-menu-nav-bottom-sheet-item',
  templateUrl: './shop-menu-nav-bottom-sheet-item.component.html',
  styleUrls: ['./shop-menu-nav-bottom-sheet-item.component.scss']
})
export class ShopMenuNavBottomSheetItemComponent implements OnChanges {
  @Output() private itemClick: EventEmitter<ShopMenuNavItemChange> = new EventEmitter();
  @Output() private afterExpanded: EventEmitter<any> = new EventEmitter();
  @Output() private afterCollapsed: EventEmitter<any> = new EventEmitter();

  @Input() public item: ShopMenuNavItem;
  @Input() public expanded: boolean;
  @Input() public disableToggle = false;

  public hasSubItems: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableToggle) this.disableToggle = changes.disableToggle.currentValue;
    if (changes.item) {
      this.item = changes.item.currentValue;
      this.hasSubItems = this.item.hasOwnProperty('subItems') && this.item.subItems.length > 0;
    }
  }

  public onSelectItem(event: MouseEvent, subItem?: ShopMenuNavItem): void {
    this.itemClick.emit({ item: this.item, subItem });
  }

  public onChevronClick(event: MouseEvent): void {
    this.toggle();
  }

  private toggle(): void {
    if (!this.expanded) return this.expand();
    if (this.expanded) return this.collapse();
  }

  private expand(): void {
    this.expanded = true;
    this.afterExpanded.emit();
  }

  private collapse(): void {
    this.expanded = false;
    this.afterCollapsed.emit();
  }
}

import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { phoneValidator, specialCharacterExclusionValidator, numbersOnlyValidator } from '@box-shared/validators';
import { InvoiceDialogResponseData, InvoiceDialogData, Invoice } from '@box-types';

const DEFAULT_INVOICE_FORM = {
  companyName: undefined,
  profession: undefined,
  taxId: undefined,
  taxOffice: undefined,
  companyAddress: undefined,
  companyAddressNumber: undefined,
  companyArea: undefined,
  companyCity: undefined,
  complanyPostalCode: undefined,
  companyPhone: undefined
};

@Component({
  selector: 'checkout-invoice-dialog',
  templateUrl: './checkout-invoice-dialog.component.html',
  styleUrls: ['./checkout-invoice-dialog.component.scss']
})
export class CheckoutInvoiceDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  private invoice: Invoice;
  public invoiceForm: FormGroup;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<CheckoutInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: InvoiceDialogData
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.invoice = { ...DEFAULT_INVOICE_FORM, ...this.data.invoice };
    this.initForm();
  }

  public closeDialog(data?: InvoiceDialogResponseData) {
    this.dialogRef.close(data);
  }

  public onSubmit(): void {
    const invoice = { ...(this.invoiceForm.value as Invoice) };
    this.closeDialog({ save: true, invoice });
  }

  private initForm(): void {
    this.invoiceForm = new FormGroup({
      companyName: new FormControl(this.invoice.companyName, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(1),
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]),
      profession: new FormControl(this.invoice.profession, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(1),
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]),
      taxId: new FormControl(this.invoice.taxId, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(9),
        Validators.maxLength(9),
        numbersOnlyValidator()
      ]),
      taxOffice: new FormControl(this.invoice.taxOffice, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(1),
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]),
      companyAddress: new FormControl(this.invoice.companyAddress, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(3),
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]),
      companyAddressNumber: new FormControl(this.invoice.companyAddressNumber, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]),
      companyArea: new FormControl(this.invoice.companyArea, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(3),
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]),
      companyCity: new FormControl(this.invoice.companyCity, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(3),
        Validators.maxLength(500),
        specialCharacterExclusionValidator()
      ]), // eslint-disable-line @typescript-eslint/unbound-method
      companyPostalCode: new FormControl(this.invoice.companyPostalCode, [
        Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
        Validators.minLength(5),
        Validators.maxLength(5),
        numbersOnlyValidator()
      ]),
      companyPhone: new FormControl(this.invoice.companyPhone, [Validators.required, phoneValidator()]) // eslint-disable-line @typescript-eslint/unbound-method
    });
  }
}

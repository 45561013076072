import { Component, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

declare type BoxBottomSheetHeaderAlignment = 'center' | 'left' | 'right';

@Component({
  selector: 'box-bottom-sheet-header',
  templateUrl: './box-bottom-sheet-header.component.html',
  styleUrls: ['./box-bottom-sheet-header.component.scss']
})
export class BoxBottomSheetHeaderComponent implements OnChanges {
  @Input() public title: string;
  @Input() public canClose = true;
  @Input() public alignment: BoxBottomSheetHeaderAlignment = 'center';

  @Output() private dismiss: EventEmitter<Event> = new EventEmitter<Event>();

  public headerClasses: string[];
  public alignmentClass: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setClasses();
  }

  private setClasses(): void {
    this.alignmentClass = this.alignment + '-alignment';
  }

  public onDismiss(event: Event): void {
    this.dismiss.emit(event);
  }
}

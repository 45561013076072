export { clamp, toInteger, formatPoints };

function clamp(number: number, min = 0, max = 1): number {
  return Math.max(min, Math.min(number, max));
}

function toInteger(float: number): number {
  return parseFloat(float.toFixed());
}

function formatPoints(points: number, locale: string = 'el-GR'): string {
  return new Intl.NumberFormat(locale, { style: 'decimal' }).format(points);
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CouponsService, CouponTimerService, SentryService } from '@box-core/services';
import { APIError, Coupon } from '@box-types';

@Injectable({ providedIn: 'root' })
export class CouponsResolver implements Resolve<Coupon[]> {
  constructor(
    private couponsService: CouponsService,
    private sentryService: SentryService,
    private couponTimerService: CouponTimerService
  ) {}

  resolve(): Observable<Coupon[]> {
    return this.couponsService.fetchAvailableCoupons$().pipe(
      tap((coupons) => {
        this.couponsService.setAvailableCoupons(coupons);
        this.couponTimerService.initialize(coupons);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Rewards Balance Badge Coupons',
          severity: 'error'
        });
        return of([]);
      })
    );
  }
}

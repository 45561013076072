import { Shop, PromoCampaign, ShopRegisteredCampaign } from '@box-types';

export {
  shouldHideShopOffers,
  isShopRegisteredToMerchantSponsoredCampaign,
  isShopEligibleForPromoCampaign,
  filterShopsByPromoCampaigns
};

function isShopRegisteredToMerchantSponsoredCampaign(shop: Shop, promoCampaignName: string): boolean {
  if (!shop.registeredCampaigns?.length) return false;
  const registeredCampaignData = shop.registeredCampaigns.find((c) => c.campaignName === promoCampaignName);
  return Boolean(registeredCampaignData?.isActive);
}

function isShopEligibleForPromoCampaign(shop: Shop, campaignName: string): boolean {
  return shop.promo?.activePromoCampaignsNames?.includes(campaignName) ?? false;
}

function filterShopsByPromoCampaigns(shops: Shop[], campaignNames: string[]): Shop[] {
  if (!campaignNames?.length) return shops;
  return shops.filter((shop) => campaignNames.some((cn) => isShopEligibleForPromoCampaign(shop, cn)));
}

function shouldHideShopOffers(shop: Shop, campaign: PromoCampaign): boolean {
  if (!campaign) return false;
  if (!shop.registeredCampaigns?.length) return false;
  const merchantSponsoredCampaign: ShopRegisteredCampaign = shop.registeredCampaigns.find(
    (rc) => rc.campaignName === campaign.name
  );
  if (!merchantSponsoredCampaign) return false;
  return merchantSponsoredCampaign.hideShopOffers;
}

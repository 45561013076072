import {
  Order,
  OrderStatusHistoryRecord,
  OrdersDayGroup,
  OrderDeliveryStatus,
  OrderShopResponseStatusType
} from '@box-types';
import dayjs from 'dayjs';

export {
  isOrderValid,
  isOrderDelivery,
  isOrderTakeAway,
  groupOrdersByDay,
  getOrdersDayGroups,
  getOrdersDayGroupsByStatus,
  isReasonDeclinedDaas,
  getOrderShopStatus,
  getOrderTotalDiscountPrice,
  getOrdersTotalDiscountPrice
};

/**
 * isOrderValid is being used to filter invalid orders from any Orders endpoint.
 * For now we are using only the order.isHidden property but in the future we might
 * add a more complicated logic, and this will help us change the functionality once
 * @param {Order} order the order to be checked
 * @returns {boolean}
 * */
function isOrderValid(order: Order): boolean {
  if (order.isHidden) return false;
  return true;
}

function isOrderDelivery(order: Order): boolean {
  return order.orderShippingType === 'delivery';
}

function isOrderTakeAway(order: Order): boolean {
  return order.orderShippingType === 'takeAway';
}

function groupOrdersByDay(orders: Order[]): { [key: string]: Order[] } {
  return orders.reduce((groupedOrders: { [key: string]: Order[] }, order: Order) => {
    const key = dayjs(order.createdAt).startOf('day').toString();
    return { ...groupedOrders, [key]: [...(groupedOrders[key] ?? []), order] };
  }, {});
}

function getOrdersDayGroups(orders: Order[]): OrdersDayGroup[] {
  if (!orders?.length) return [];
  const ordersGroupedByDay = groupOrdersByDay(orders);
  return Object.keys(ordersGroupedByDay)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((key) => ({ day: dayjs(key).format('dddd, DD/MM/YYYY'), orders: ordersGroupedByDay[key] }));
}

function getOrdersDayGroupsByStatus(orders: Order[], status: OrderDeliveryStatus): OrdersDayGroup[] {
  if (!orders?.length) return [];
  return getOrdersDayGroups(orders.filter((order) => order.status === status));
}

function isReasonDeclinedDaas(order: Order): boolean {
  const reasonDeclinedKey = order?.shopResponse?.reasonDeclinedKey;
  if (!reasonDeclinedKey) return false;
  return reasonDeclinedKey === 'daas_driver_not_available' || reasonDeclinedKey === 'daas_shop_closed';
}

function getOrderShopStatus(order: Order, status: OrderShopResponseStatusType): OrderStatusHistoryRecord {
  const statusHistory = order.shopResponse?.statusHistory;
  if (!statusHistory?.length) return;
  return statusHistory.find((entry) => entry.status === status);
}

function getOrderTotalDiscountPrice(order: Order): number {
  return order.totalDiscountPrice ?? 0;
}

function getOrdersTotalDiscountPrice(orders: Order[]): number {
  if (!orders?.length) return 0;
  return orders.reduce((acc, order) => acc + getOrderTotalDiscountPrice(order), 0);
}

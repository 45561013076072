import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
  ViewChild
} from '@angular/core';
import { MapsService, MarkerAnimationService } from '@box-core/services';
import { tap, skipWhile, switchMap } from 'rxjs/operators';
import { LiveTrackingMapService } from '@box-checkout/components/live-tracking-map/live-tracking-map.service';
import { Observable, Subscription, BehaviorSubject, skip } from 'rxjs';
import { Address, Coordinates, BoxTheme } from '@box-types';
import { DriverLocation } from '@box-checkout/components/live-tracking-map/live-tracking-map.types';
import {
  getShopMarkerOptions,
  getDaasMapOptions,
  getAddressMarkerOptions,
  getDeliveryMarkerOptions,
  getCoordinatesFromMarkerOptions,
  isMarkerInsideMapBounds,
  coordinatesToLatLng,
  getDaasMapStyles
} from '@box/utils';
import { OrderStatusService } from '@box-checkout/pages/order-status/order-status.service';
import { GoogleMap } from '@angular/google-maps';
import { ThemingService } from '@box-core/services/theming.service';

@Component({
  selector: 'live-tracking-map',
  templateUrl: './live-tracking-map.component.html',
  styleUrls: ['./live-tracking-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiveTrackingMapService]
})
export class LiveTrackingMapComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: false }) private map: GoogleMap;

  public mapOptions: google.maps.MapOptions;
  public shopMarkerOptions: google.maps.MarkerOptions;
  public addressMarkerOptions: google.maps.MarkerOptions;
  public deliveryMarkerOptions: google.maps.MarkerOptions;
  public mapsAPILoaded: boolean;
  public emptyStateMapUrl: string;

  private orderSubscription: Subscription;
  private driverLocationsToBeShown = new BehaviorSubject<DriverLocation[]>([]);
  private latestDriverLocationShown: DriverLocation;
  private locationsToBeShownSubscription: Subscription;
  private animationSubscription: Subscription;
  private pollingSubscription: Subscription;
  private themeSubscription: Subscription;

  constructor(
    private orderStatusService: OrderStatusService,
    private mapsService: MapsService,
    private liveTrackingMapService: LiveTrackingMapService,
    private markerAnimationService: MarkerAnimationService,
    private changeDetectorRef: ChangeDetectorRef,
    private themingService: ThemingService
  ) {}

  @HostBinding('class') public hostClass = 'live-tracking-map';

  ngOnInit(): void {
    this.setLocationsToBeShownSubscription();
    this.setPollingSubscription();
    this.setThemeSubscription();
  }

  ngOnDestroy(): void {
    this.liveTrackingMapService.stopPolling();
    this.orderSubscription?.unsubscribe();
    this.locationsToBeShownSubscription?.unsubscribe();
    this.pollingSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  private setLocationsToBeShownSubscription(): void {
    this.locationsToBeShownSubscription = this.driverLocationsToBeShown.pipe(skip(1)).subscribe((locations) => {
      this.updateDeliveryMarker(locations);
    });
  }

  private setPollingSubscription(): void {
    this.pollingSubscription = this.loadMapsApi()
      .pipe(
        skipWhile((loaded) => !loaded),
        tap(() => this.initializeMap()),
        switchMap(() => this.liveTrackingMapService.waitForOrderToBeDeliveryReady()),
        switchMap(() => this.liveTrackingMapService.liveTrackingPolling())
      )
      .subscribe((response) => {
        this.setLocationsToBeShown(response?.driverLocation);
      });
  }

  private loadMapsApi(): Observable<boolean> {
    return this.mapsService.loadAPI().pipe(
      tap((mapsAPILoaded) => {
        this.mapsAPILoaded = mapsAPILoaded;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.pipe(skip(1)).subscribe((theme) => {
      // if this.mapOptions.styles is updated directly, the change detector doesn't pick it up
      this.mapOptions = { ...this.mapOptions, styles: getDaasMapStyles(theme) };
      this.setEmptyStateMapUrl(theme);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setEmptyStateMapUrl(theme: BoxTheme): void {
    const imageFile =
      theme !== 'dark' ? 'order-status-header-background-light.jpg' : 'order-status-header-background-dark.png';
    this.emptyStateMapUrl = `url(/assets/images/checkout/order-tracking/@1x${imageFile})`;
  }

  private initializeMap(): void {
    const order = this.orderStatusService.getOrder();
    const theme = this.themingService.getTheme();
    const shopAddress: Address = order.shop.address;
    const userAddress: Address = order.address;
    const shopCoordinates: Coordinates = { latitude: shopAddress.latitude, longitude: shopAddress.longitude };
    const userAddressCoordinates: Coordinates = { latitude: userAddress.latitude, longitude: userAddress.longitude };
    this.mapOptions = getDaasMapOptions(this.map, [shopCoordinates, userAddressCoordinates], theme);
    this.setEmptyStateMapUrl(theme);
    this.shopMarkerOptions = getShopMarkerOptions(shopCoordinates);
    this.addressMarkerOptions = getAddressMarkerOptions(userAddress);
    this.changeDetectorRef.detectChanges();
  }

  private updateDeliveryMarker(locations: DriverLocation[]): void {
    if (this.isAnimationActive()) return;
    if (locations.length < 1) return;

    const locationA = this.latestDriverLocationShown ?? locations[0];
    const locationB = locations[locations?.length - 1];

    // making sure the delivery marker is always visible
    const initialDeliveryMarkerOptions = getDeliveryMarkerOptions(coordinatesToLatLng(locationA));
    const finalDeliveryMarkerOptions = getDeliveryMarkerOptions(coordinatesToLatLng(locationB));
    const isNewDeliveryMarkerInBounds = isMarkerInsideMapBounds(finalDeliveryMarkerOptions, this.map);

    // the map is expanded before the animation begins
    if (!isNewDeliveryMarkerInBounds) this.updateMapOptions(finalDeliveryMarkerOptions, initialDeliveryMarkerOptions);

    this.animationSubscription = this.markerAnimationService.getAnimatedLocationPoints(locationA, locationB).subscribe({
      next: (position) => {
        this.deliveryMarkerOptions = getDeliveryMarkerOptions(position);
        this.changeDetectorRef.detectChanges();
      },
      complete: () => {
        // the map is shrinked after the animation completes
        if (isNewDeliveryMarkerInBounds) this.updateMapOptions(finalDeliveryMarkerOptions);
        this.animationSubscription?.unsubscribe();
        this.latestDriverLocationShown = locationB;
        this.setLocationsToBeShown();
      }
    });
  }

  private setLocationsToBeShown(latestLocation?: DriverLocation): void {
    const previousLocations = this.driverLocationsToBeShown.getValue();
    const allLocations = latestLocation ? [...previousLocations, latestLocation] : previousLocations;
    const filteredLocations = this.liveTrackingMapService.filterLocationsToBeShown(
      allLocations,
      this.latestDriverLocationShown?.capturedAt
    );
    this.driverLocationsToBeShown.next(filteredLocations);
  }

  private updateMapOptions(
    finalDeliveryMarkerOptions: google.maps.MarkerOptions,
    initialDeliveryMarkerOptions?: google.maps.MarkerOptions
  ): void {
    if (!finalDeliveryMarkerOptions) return;
    const coordinates: Coordinates[] = [];

    if (initialDeliveryMarkerOptions) {
      const deliveryCoordinates: Coordinates = getCoordinatesFromMarkerOptions(initialDeliveryMarkerOptions);
      coordinates.push(deliveryCoordinates);
    }

    if (!this.liveTrackingMapService.canFocusOnUserAndDelivery()) {
      const shopCoordinates: Coordinates = getCoordinatesFromMarkerOptions(this.shopMarkerOptions);
      coordinates.push(shopCoordinates);
    }

    const deliveryCoordinates: Coordinates = getCoordinatesFromMarkerOptions(finalDeliveryMarkerOptions);
    const userAddressCoordinates: Coordinates = getCoordinatesFromMarkerOptions(this.addressMarkerOptions);
    coordinates.push(userAddressCoordinates, deliveryCoordinates);
    const theme = this.themingService.getTheme();
    this.mapOptions = getDaasMapOptions(this.map, coordinates, theme);
    this.setEmptyStateMapUrl(theme);
    this.changeDetectorRef.detectChanges();
  }

  private isAnimationActive(): boolean {
    if (!this.animationSubscription) return false;
    return !this.animationSubscription.closed;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  public readonly loading = new BehaviorSubject<boolean>(false);
  public text: string;

  public setState(loading: boolean, text?: string): void {
    this.text = text ? text : null;
    this.loading.next(loading);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetInfo } from '@box-types';

@Component({
  selector: 'select-msisdn-dialog',
  templateUrl: './select-msisdn-dialog.component.html',
  styleUrls: ['./select-msisdn-dialog.component.scss']
})
export class SelectMsisdnDialogComponent implements OnInit {
  public assetsInfo: AssetInfo[];
  public selectedAssetInfo: AssetInfo;
  public suggestionIndicator: string;
  public suggestionRewardSegment: string;

  constructor(
    private dialogRef: MatDialogRef<SelectMsisdnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.setDialogContent(this.data);
  }

  public onConfirm() {
    this.closeDialog({ msisdn: this.selectedAssetInfo });
  }

  public closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  private setDialogContent(data: any): void {
    this.assetsInfo = data.assetsInfo;
    const selectedMSISDN = data.selectedAssetInfo.msisdn;
    this.selectedAssetInfo = this.assetsInfo.find((assetInfo) => assetInfo.msisdn === selectedMSISDN);
    this.suggestionRewardSegment = this.data.suggestionRewardSegment;
    this.suggestionIndicator = data.suggestionIndicator;
  }
}

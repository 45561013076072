<img [src]="imgUrl" [alt]="altText" loading="lazy" />
<div *ngIf="overlayText" class="image-tile image-tile-overlay">
  <span class="image-tile-overlay-text ods-typography-body-s-bold">{{ overlayText | translate }}</span>
</div>
<div *ngIf="bottomOverlayText" class="image-tile-bottom-overlay">
  <span class="image-tile-bottom-overlay-text ods-typography-microcopy-bold">{{ bottomOverlayText | translate }}</span>
</div>
<div *ngIf="bottomRightOverlayText" class="image-tile-bottom-right-overlay">
  <span class="image-tile-bottom-right-overlay-text ods-typography-microcopy-bold">{{
    bottomRightOverlayText | translate
  }}</span>
</div>
<div *ngIf="checked" class="image-tile image-tile-checked-overlay"></div>

<ng-container *ngIf="showCustomActiveState">
  <svg width="76" height="64" class="checkout-coupon-image-svg">
    <defs>
      <path
        id="a"
        d="m22.12 10.107-8.787 8.786-4.786-4.773L6.667 16l6.666 6.667L24 12l-1.88-1.893ZM16 2.667C8.64 2.667 2.667 8.64 2.667 16S8.64 29.333 16 29.333 29.333 23.36 29.333 16 23.36 2.667 16 2.667Zm0 24c-5.893 0-10.667-4.774-10.667-10.667S10.107 5.333 16 5.333 26.667 10.107 26.667 16 21.893 26.667 16 26.667Z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g [attr.fill]="coupon.info.backgroundColor">
        <path d="M0 0h76L64 64H0z" />
        <path d="M0 64h76L64 0H0z" opacity=".6" />
      </g>
      <g transform="translate(20 16)">
        <path d="M0 0h32v32H0V0Zm0 0h32v32H0V0Z" />
        <mask id="b" [attr.fill]="coupon.info.primaryTextColor"><use xlink:href="#a" /></mask>
        <g [attr.fill]="coupon.info.primaryTextColor" mask="url(#b)"><path d="M0 0h32v32H0z" /></g>
      </g>
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="showDefaultCouponState">
  <svg
    class="checkout-coupon-image-default-stage-svg"
    width="76"
    height="64"
    viewBox="0 0 76 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.2" d="M0 4C0 1.79086 1.79086 0 4 0H61L76 64H4C1.79086 64 0 62.2091 0 60V4Z" fill="#AEADB4" />
    <path
      class="checkout-coupon-image-default-stage-svg-bg"
      d="M0 4C0 1.79086 1.79086 0 4 0H76L61 64H4C1.79086 64 0 62.2091 0 60V4Z"
    />
    <path
      class="checkout-coupon-image-default-stage-svg-ticket"
      d="M47.7038 29.0512C48.2349 29.0512 48.6654 28.6207 48.6654 28.0897V22.9615C48.6654 22.4305 48.2348 22 47.7038 22H26.55V26.8077C26.55 27.3387 26.1195 27.7692 25.5885 27.7692C25.0575 27.7692 24.627 27.3387 24.627 26.8077V22H16.2936C15.7626 22 15.332 22.4305 15.332 22.9615V28.0897C15.332 28.6208 15.7626 29.0512 16.2936 29.0512C17.8841 29.0512 19.1782 30.3452 19.1782 31.9359C19.1782 33.5265 17.8842 34.8205 16.2936 34.8205C15.7626 34.8205 15.332 35.251 15.332 35.782V40.9102C15.332 41.4413 15.7626 41.8717 16.2936 41.8717H24.6269V37.0641C24.6269 36.533 25.0574 36.1025 25.5884 36.1025C26.1194 36.1025 26.5499 36.5331 26.5499 37.0641V41.8717H47.7038C48.2348 41.8717 48.6653 41.4412 48.6653 40.9102V35.782C48.6653 35.251 48.2348 34.8205 47.7038 34.8205C46.1132 34.8205 44.8191 33.5265 44.8191 31.9359C44.8191 30.3452 46.1132 29.0512 47.7038 29.0512ZM26.55 33.2179C26.55 33.749 26.1195 34.1794 25.5885 34.1794C25.0575 34.1794 24.627 33.7489 24.627 33.2179V30.6538C24.627 30.1227 25.0575 29.6923 25.5885 29.6923C26.1195 29.6923 26.55 30.1228 26.55 30.6538V33.2179Z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="imageSrc">
  <figure class="checkout-coupon-image">
    <img [src]="imageSrc" alt="Checkout Coupon" />
  </figure>
</ng-container>

import { Injectable, OnDestroy } from '@angular/core';
import { PromoCampaign, CampaignTimerStateType, CampaignState } from '@box-types';
import dayjs from 'dayjs';
import { BehaviorSubject, Subscription, timer, Observable, distinctUntilChanged, skipWhile } from 'rxjs';
import { map, pairwise, filter } from 'rxjs/operators';
import { generateCampaignCountDown, findCampaignEnabledTimeRange, isPrerenderBrowser } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class CampaignTimerService implements OnDestroy {
  private timerSubscription: Subscription;

  private campaignTimer = new BehaviorSubject<Record<string, CampaignTimerStateType>>({});
  public readonly campaignTimer$ = this.campaignTimer.asObservable();

  ngOnDestroy(): void {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }

  private setState(campaignName: string, state: CampaignState): void {
    const currentState = this.campaignTimer.getValue();
    currentState[campaignName] = { state, countDown: this.getCampaignCountDown(campaignName) };
    this.campaignTimer.next(currentState);
  }

  private setCountDown(campaignName: string, countDown: number): void {
    const currentState = this.campaignTimer.getValue();
    currentState[campaignName] = { countDown, state: this.getCampaignTimerState(campaignName) };
    this.campaignTimer.next(currentState);
  }

  public getCampaignTimerState(campaignName: string): CampaignState {
    return this.campaignTimer.getValue()[campaignName]?.state;
  }

  public getCampaignTimerState$(campaignName: string): Observable<CampaignState> {
    return this.campaignTimer$.pipe(
      map((globalState: Record<string, CampaignTimerStateType>) => {
        return globalState[campaignName]?.state;
      }),
      distinctUntilChanged()
    );
  }

  public whenCampaignIsEnabled$(campaignName: string): Observable<[CampaignState, CampaignState]> {
    return this.getCampaignTimerState$(campaignName).pipe(
      pairwise(),
      filter(([prev, curr]) => prev === 'PROMO_INACTIVE' && curr === 'PROMO_ACTIVE')
    );
  }

  public whenCampaignIsExpired$(campaignName: string): Observable<[CampaignState, CampaignState]> {
    return this.getCampaignTimerState$(campaignName).pipe(
      pairwise(),
      filter(([prev, curr]) => prev === 'PROMO_ACTIVE' && curr === 'PROMO_INACTIVE')
    );
  }

  public getCampaignCountDown(campaignName: string): number {
    return this.campaignTimer.getValue()[campaignName]?.countDown;
  }

  public getCampaignCountDown$(campaignName: string): Observable<number> {
    return this.campaignTimer$.pipe(
      skipWhile(() => this.getCampaignTimerState(campaignName) !== 'PROMO_ACTIVE'),
      map((globalState: Record<string, CampaignTimerStateType>) => {
        return globalState[campaignName]?.countDown;
      })
    );
  }

  public initialize(campaigns: PromoCampaign[]): void {
    if (isPrerenderBrowser(window)) return;
    const campaignsWithTimeRanges = campaigns.filter((p) => p.enabledOnTimeRanges?.length);
    if (!campaignsWithTimeRanges?.length) return;
    this.updateState(campaignsWithTimeRanges);
    const currentDate = dayjs();
    const preDelayMilliseconds = 1000 - currentDate.millisecond();
    this.timerSubscription = timer(preDelayMilliseconds, 1000).subscribe(() =>
      this.updateState(campaignsWithTimeRanges)
    );
  }

  private updateState(campaigns: PromoCampaign[]): void {
    campaigns.map((campaign) => {
      const currentTimeRange = findCampaignEnabledTimeRange(campaign.enabledOnTimeRanges);
      if (!currentTimeRange) this.setState(campaign.name, 'PROMO_INACTIVE');
      if (currentTimeRange) {
        const countDown = generateCampaignCountDown(currentTimeRange);
        this.setCountDown(campaign.name, countDown);
        this.setState(campaign.name, 'PROMO_ACTIVE');
      }
    });
  }
}

<div class="whats-new-dialog-wrapper">
  <button [style.color]="buttonColor" class="close-button" mat-icon-button (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <section *ngIf="imageUrl" class="whats-new-dialog-top-section">
    <figure class="image contain-image whats-new-image">
      <img [src]="imageUrl" [alt]="title" />
    </figure>
  </section>
  <section class="whats-new-dialog-bottom-section">
    <div class="whats-new-dialog-container">
      <span class="whats-new-dialog-text ods-typography-body-m-bold">{{ title }}</span>
      <span class="whats-new-dialog-text whats-new-dialog-text-description">{{ description }}</span>
    </div>
  </section>
</div>

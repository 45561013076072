<div boxNav class="delivery-method-nav">
  <div
    boxNavItem
    [class.box-nav-item-active]="deliveryMethod === 'delivery'"
    [class.box-nav-item-disabled]="!shop.delivery"
    class="box-nav-item-text"
    (click)="onDeliveryMethodClick('delivery')"
  >
    <span class="delivery-method-nav-item-text">{{ deliveryTitle }}</span>
  </div>
  <div
    boxNavItem
    [class.box-nav-item-active]="deliveryMethod === 'takeAway'"
    [class.box-nav-item-disabled]="!shop.takeAway"
    class="box-nav-item-text"
    (click)="onDeliveryMethodClick('takeAway')"
  >
    <span class="delivery-method-nav-item-text">{{ takeawayTitle }}</span>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AnalyticsService, DeviceDetectorService } from '@box-core/services';
import { appConfig } from '@box-configs/app.config';
import { DeviceType } from '@box-types';

@Component({
  selector: 'box-download',
  templateUrl: './box-download.component.html',
  styleUrls: ['./box-download.component.scss']
})
export class BOXDownloadComponent implements OnInit {
  public iosUrl: string;
  public androidUrl: string;
  public huaweiUrl: string;
  public deviceType: DeviceType;

  constructor(private deviceDetectorService: DeviceDetectorService, private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.deviceType = this.deviceDetectorService.getDeviceType();
    this.iosUrl = appConfig.mobile.IOS_APP;
    this.androidUrl = appConfig.mobile.ANDROID_APP;
    this.huaweiUrl = appConfig.mobile.HUAWEI_APP;
  }

  public onDownload(platform: string): void {
    const source = 'Landing Page';
    this.analyticsService.addGACustomEvent('click_app_download', { platform, source });
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product, ProductInstance, CartGroupChange, PromoVisualOptions } from '@box-types';
import { getProductBadgeOptions } from '@box/utils';

@Component({
  selector: 'cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss']
})
export class CartProductComponent implements OnInit {
  @Input() public product: Product;
  @Input() public disabled = false;
  @Input() public showCommentPlaceholder = true;

  @Output() private add = new EventEmitter<CartGroupChange<Product, ProductInstance>>();
  @Output() private remove = new EventEmitter<CartGroupChange<Product, ProductInstance>>();
  @Output() private edit = new EventEmitter<CartGroupChange<Product, ProductInstance>>();

  public badgeOptions: PromoVisualOptions;

  ngOnInit(): void {
    this.badgeOptions = getProductBadgeOptions(this.product);
  }

  public onAdd(itemInstance: ProductInstance): void {
    this.add.emit({ item: this.product, instance: itemInstance });
  }

  public onRemove(itemInstance: ProductInstance): void {
    this.remove.emit({ item: this.product, instance: itemInstance });
  }

  public onEdit(event: Event, itemInstance: ProductInstance): void {
    if (!this.disabled) this.edit.emit({ item: this.product, instance: itemInstance });
  }
}

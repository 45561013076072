import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  Input
} from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { VerificationResendCodeService } from './verification-resend-code.service';
import { VerificationResendCodeState } from './verification-resend-code.types';
import { OTPMethod } from '@box-types';

@Component({
  selector: 'verification-resend-code',
  templateUrl: './verification-resend-code.component.html',
  styleUrls: ['./verification-resend-code.component.scss'],
  providers: [VerificationResendCodeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationResendCodeComponent implements OnInit, OnDestroy {
  @Input() public otpMethod: OTPMethod;
  @Output() private send = new EventEmitter<void>();

  public state: VerificationResendCodeState;
  public remainingTime: string;
  private subscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private phoneVerificationResendCodeService: VerificationResendCodeService
  ) {}

  @HostBinding('class') public hostClass = 'verification-resend-code';

  ngOnInit(): void {
    this.state = 'AVAILABLE';
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public onSend(): void {
    this.send.emit();
  }

  public startDelay(): void {
    this.state = 'IN_DELAY';
    this.subscription = this.phoneVerificationResendCodeService
      .getDelayTimeText()
      .pipe(
        finalize(() => {
          this.state = 'AVAILABLE';
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((remainingTime) => {
        this.remainingTime = remainingTime;
        this.changeDetectorRef.detectChanges();
      });
  }

  public resetDelay(): void {
    this.state = 'AVAILABLE';
    if (this.subscription) this.subscription.unsubscribe();
  }
}

<div class="checkout-payment-badge-wrapper">
  <div class="checkout-payment-badge-icons">
    <figure *ngFor="let image of images" class="checkout-payment-badge-icon">
      <img [src]="image" alt="partner" />
    </figure>
  </div>
  <div class="checkout-payment-badge-benefits">
    <span class="checkout-payment-badge-benefits-text">{{ this.multiplierView + ' ' + this.pointsView }}</span>
  </div>
</div>

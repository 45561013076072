import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EmbeddedViewRef,
  HostBinding,
  OnDestroy,
  Renderer2,
  ComponentRef
} from '@angular/core';
import { NotificationsService } from '@box-core/services';
import { Subscription } from 'rxjs';
import { NotificationComponent } from '@box-core/components';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements AfterViewInit, OnDestroy {
  private notificationsSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private notificationsService: NotificationsService
  ) {}

  @HostBinding('class') public hostClass = 'notifications';

  ngAfterViewInit(): void {
    this.notificationsSubscription = this.notificationsService.notifications$.subscribe(
      (notifications: ComponentRef<NotificationComponent>[]) => {
        for (const notification of notifications) {
          const domElem = (notification.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
          this.renderer.appendChild(this.element.nativeElement, domElem);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.notificationsSubscription?.unsubscribe();
  }
}

import { CircularProgressConfig } from './circular-progress.types';
import { toInteger } from '../core';

function getProgressRatioDegrees(config: CircularProgressConfig): number {
  const isDataCorrect = config.max > 0 && config.current >= 0 && config.max >= config.current;
  if (!isDataCorrect) return 0;
  return toInteger((config.current / config.max) * 360);
}

function getProgressText(config: CircularProgressConfig): string {
  return `'${config.current > 0 ? config.current : 0}/${config.max > 0 ? config.max : 0}'`;
}

export { getProgressRatioDegrees, getProgressText };

import { Brand, Product } from '@box-types';

export { decorateProductWithBrand };

function decorateProductWithBrand(product: Product, brands: Brand[]): Product {
  if (!product?.brand?._id && !product?.brandId) return product;
  if (!brands?.length) return product;
  const brand = brands.find((br) => br._id === product.brand?._id || br._id === product.brandId);
  if (!brand) return product;
  return { ...product, brand };
}

import { Cuisine, GetTextByKeyType } from '@box-types';
import { generateImageSrc } from '../media';
export { getCuisineHeroBackgroundColor, getCuisineHeroBackgroundImageUrl, generateShopsTitleDescription };

function getCuisineHeroBackgroundColor(cuisine: Cuisine): string {
  if (cuisine?.backGroundColor) return cuisine.backGroundColor;
  return '#ffffff';
}

function getCuisineHeroBackgroundImageUrl(cuisine: Cuisine, isSmallScreen: boolean): string {
  const { webImage, mobileImage } = cuisine ?? {};
  if (mobileImage && isSmallScreen) return generateImageSrc(mobileImage);
  if (webImage) return generateImageSrc(webImage, '@3x');
  return;
}

function generateShopsTitleDescription(shopsLength: number, translateFn: GetTextByKeyType): string {
  if (!shopsLength) return;
  return shopsLength > 1
    ? translateFn('found_x_shops', { _SHOPS_COUNT: shopsLength.toString() })
    : translateFn('found_one_shop');
}

import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { DIRECTION_HORIZONTAL } from 'hammerjs';
import { CoreModule } from '@box-core/core.module';
import { BoxRoutingModule } from './box-routing.module';
import { BoxComponent } from './box.component';
import { TraceService } from '@sentry/angular-ivy';
import { Router } from '@angular/router';

@Injectable()
class HammerConfig extends HammerGestureConfig {
  overrides = { swipe: { direction: DIRECTION_HORIZONTAL } };
}

@NgModule({
  declarations: [BoxComponent],
  imports: [BrowserModule, BoxRoutingModule, CoreModule, BrowserAnimationsModule, HammerModule],
  bootstrap: [BoxComponent],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      /** We disabled eslint for the next line due to the fact that the Official Sentry documentation
       * demands that we use this as an empty arrow function. */
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    }
  ]
})
export class BoxModule {}

import { ValidatorFn } from '@angular/forms';
import { regExToValidatorFunction } from '@box-shared/validators/validator.utils';

/* set of characters @ set of characters . set of characters */

const REG_EXP = new RegExp('^\\S+@\\S+\\.\\S+$');

export function emailValidator(): ValidatorFn {
  return regExToValidatorFunction(REG_EXP);
}

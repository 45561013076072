<div class="contest-flag-wrapper">
  <figure class="contest-flag-figure" [class.contest-flag-figure-congrats]="flagType === 'WIN'" [class.]>
    <img [class.small-version-only]="smallVersionOnly" [src]="imageUrl" alt="flag" />
  </figure>
  <span
    class="contest-flag-content"
    [class.contest-flag-content-congrats]="flagType === 'WIN'"
    [class.small-version-only]="smallVersionOnly"
  >
    <ng-content> </ng-content>
  </span>
</div>

import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { RewardsHistoryEarningsService } from './rewards-history-earnings.service';
import { RewardsHistoryEarning } from '@box-types';

@Component({
  selector: 'rewards-history-earnings',
  templateUrl: './rewards-history-earnings.component.html',
  styleUrls: ['./rewards-history-earnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RewardsHistoryEarningsService]
})
export class RewardsHistoryEarningsComponent implements OnInit {
  public gbEarningsImageClass: string;
  public historyEarningsEuro: RewardsHistoryEarning;
  public historyEarningsGB: RewardsHistoryEarning;

  constructor(private rewardsHistoryEarningsService: RewardsHistoryEarningsService) {}

  @HostBinding('class') public hostClass = 'rewards-history-earnings';

  ngOnInit(): void {
    this.gbEarningsImageClass = this.rewardsHistoryEarningsService.getGBEarningsImageClass();
    this.historyEarningsEuro = this.rewardsHistoryEarningsService.getHistoryEarningsEuro();
    this.historyEarningsGB = this.rewardsHistoryEarningsService.getHistoryEarningsGB();
  }
}

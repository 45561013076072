<div class="box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title }}</h3>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div [swiper]="swiperOptions" class="contests-wrapper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let contest of contests">
        <contest [contest]="contest"></contest>
      </div>
    </div>
  </div>
</div>

import { Input, HostBinding, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { getProgressRatioDegrees, CircularProgressConfig, getProgressText } from '@box/utils';

@Directive({ selector: '[circular-progress]' })
export class CircularProgressDirective implements OnChanges {
  @Input('circular-progress') public config: CircularProgressConfig;
  @HostBinding('class') public hostClass = 'circular-progress';

  constructor(private element: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.config = changes.config.currentValue as CircularProgressConfig;
      const elementStyle = (this.element.nativeElement as HTMLElement).style;
      if (!elementStyle) return;
      this.setColors(elementStyle);
      this.setData(elementStyle);
      this.changeDetectorRef.detectChanges();
    }
  }

  private setColors(style: CSSStyleDeclaration): void {
    if (this.config.filledPerimeterColor) {
      style.setProperty('--filled-perimeter-color', this.config.filledPerimeterColor);
    }
    if (this.config.emptyPerimeterColor) {
      style.setProperty('--empty-perimeter-color', this.config.emptyPerimeterColor);
    }
    if (this.config.contentColor) {
      style.setProperty('--content-color', this.config.contentColor);
    }
  }

  private setData(style: CSSStyleDeclaration): void {
    style.setProperty('--content', getProgressText(this.config));
    style.setProperty('--degrees', `${getProgressRatioDegrees(this.config)}deg`);
  }
}

import { Component, Input, OnInit } from '@angular/core';

const TIME_FLAG_IMAGE_URL = '/assets/images/contest-flag/contests_date.png';
const PARTICIPATIONS_FLAG_IMAGE_URL = '/assets/images/contest-flag/contests_participations.png';
const WIN_FLAG_IMAGE_URL = '/assets/images/contest-flag/contests_winner.png';

@Component({
  selector: 'contest-flag',
  templateUrl: './contest-flag.component.html',
  styleUrls: ['./contest-flag.component.scss']
})
export class ContestFlagComponent implements OnInit {
  @Input() public flagType: 'DATE' | 'PARTICIPATIONS' | 'WIN';
  @Input() public smallVersionOnly = false;
  public imageUrl: string;

  constructor() {}

  ngOnInit(): void {
    switch (this.flagType) {
      case 'DATE':
        this.imageUrl = TIME_FLAG_IMAGE_URL;
        break;
      case 'PARTICIPATIONS':
        this.imageUrl = PARTICIPATIONS_FLAG_IMAGE_URL;
        break;
      case 'WIN':
        this.imageUrl = WIN_FLAG_IMAGE_URL;
    }
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coupon } from '@box-types';
import { generateImageSrc } from '@box/utils';
import { CouponRedemptionDialogData } from './coupon-redemption-dialog.types';

@Component({
  selector: 'coupon-redemption-dialog',
  templateUrl: './coupon-redemption-dialog.component.html',
  styleUrls: ['./coupon-redemption-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponRedemptionDialogComponent implements OnInit {
  private coupon: Coupon;

  public imageSrc: string;
  public points: number;
  public benefitReason: string;

  constructor(
    private dialogRef: MatDialogRef<CouponRedemptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CouponRedemptionDialogData
  ) {}

  ngOnInit(): void {
    this.coupon = this.data.coupon;
    this.imageSrc = generateImageSrc(this.coupon.webImage);
    this.points = this.coupon.loyaltyPoints;
    this.benefitReason = this.coupon.benefitReason;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIResponse, Reward } from '@box-types';
import { filterGBRewardsBySegment, normalizeReward } from '@box/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RewardsPromoBannerService {
  private readonly BOX_API: string = environment.application.API_URL;

  constructor(private http: HttpClient) {}

  public fetchRewardsGB(segment: string): Observable<Reward[]> {
    const params: HttpParams = new HttpParams().set('includeSegments', 'true');
    return this.http.get(`${this.BOX_API}/rewards`, { params }).pipe(
      map((response: APIResponse<{ rewards: Reward[] }>) => {
        const rewards = response.payload.rewards;
        const normalizedRewards = rewards.map((reward) => normalizeReward(reward));
        return filterGBRewardsBySegment(normalizedRewards, segment).sort(
          (a, b) => a.megabytesReward - b.megabytesReward
        );
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { PromoCampaignBanner, Shop } from '@box-types';
import { sortSuggestionElements, generateImageSrc } from '@box/utils';

@Injectable()
export class CheckoutSynergyDialogService {
  public initializeSynergyBanners(promoCampaignBanners: PromoCampaignBanner[], shop: Shop): PromoCampaignBanner[] {
    const shopLogo = generateImageSrc(shop.logo);
    return sortSuggestionElements(
      promoCampaignBanners.map((promoCampaignBanner) => {
        const isBoxaki = ['new_users', 'happy_hour', 'returning_users'].includes(promoCampaignBanner.name);
        if (!isBoxaki) return promoCampaignBanner;
        return { ...promoCampaignBanner, checkoutBannerLogo: shopLogo, name: shop.name };
      })
    );
  }

  public getSynergyDialogImage(promoCampaignBanners: PromoCampaignBanner[]): string {
    if (promoCampaignBanners.length === 1) return promoCampaignBanners[0].checkoutBannerLogo;
    return 'assets/images/logo/box-logo.svg';
  }

  public getSynergyDialogImageAlt(promoCampaignBanners: PromoCampaignBanner[]): string {
    if (promoCampaignBanners.length === 1) return promoCampaignBanners[0].name;
    return 'box-logo';
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CampaignTimerService, DialogService } from '@box-core/services';
import { ShopSuggestionBanner } from '@box-types';
import { Subscription } from 'rxjs';
import { ShopSuggestionBannerInfoDialogComponent } from '../shop-suggestion-banner-info-dialog/shop-suggestion-banner-info-dialog.component';

@Component({
  selector: 'shop-box-hour-banner',
  templateUrl: './shop-box-hour-banner.component.html',
  styleUrls: ['./shop-box-hour-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopBoxHourBannerComponent implements OnInit, OnDestroy {
  @Input() public suggestionBanner: ShopSuggestionBanner;

  public showCountDown: boolean;
  public showDetails: boolean;
  private subscription: Subscription;

  constructor(
    private campaignTimerService: CampaignTimerService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService
  ) {}

  @HostBinding('class') public hostClass = 'shop-box-hour-banner';

  ngOnInit(): void {
    this.setSubscription();
    this.showDetails = this.showDetailsVisibility();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public onClick(suggestionBanner: ShopSuggestionBanner): void {
    if (!this.showDetails) return;
    const dialogConfig = { data: { suggestionBanner: suggestionBanner } };
    this.dialogService.openDialog(ShopSuggestionBannerInfoDialogComponent, dialogConfig);
  }

  private setSubscription(): void {
    this.subscription = this.campaignTimerService.getCampaignTimerState$('happy_hour').subscribe((state) => {
      this.showCountDown = state === 'PROMO_ACTIVE';
      this.changeDetectorRef.detectChanges();
    });
  }

  private showDetailsVisibility(): boolean {
    if (!this.suggestionBanner?.shopModalTitle) return false;
    if (!this.suggestionBanner?.shopModalDescription) return false;
    return true;
  }
}

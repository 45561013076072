import { Injectable } from '@angular/core';
import { PromoCampaignsService, ShopService, PaymentTypesService } from '@box-core/services';
import { PromoCampaign } from '@box-types';
import { doesPaymentTypeMatchCampaign, getCampaignsCompatibleWithPaymentType } from '@box/utils';

@Injectable()
export class PaymentSynergyBannerService {
  constructor(
    private shopService: ShopService,
    private promoCampaignsService: PromoCampaignsService,
    private paymentTypesService: PaymentTypesService
  ) {}

  public getTopPaymentCampaigns(): PromoCampaign[] {
    const shopBusinessVertical = this.shopService.getShop()?.businessVertical;
    const topPaymentCampaigns = this.promoCampaignsService.getTopPaymentCampaigns(shopBusinessVertical);
    return this.filterPaymentCampaignsBasedOnSavedPaymentMethods(topPaymentCampaigns);
  }

  private filterPaymentCampaignsBasedOnSavedPaymentMethods(topPaymentCampaigns: PromoCampaign[]): PromoCampaign[] {
    const savedPaymentTypes = this.paymentTypesService.getPaymentTypes();
    return topPaymentCampaigns.filter((campaign) => {
      if (campaign.visibleOnlyWhenCardFound) {
        return savedPaymentTypes.some((savedPaymentType) => doesPaymentTypeMatchCampaign(savedPaymentType, campaign));
      }
      return true;
    });
  }

  public isSelectedPaymentTypeCompatible(topPaymentCampaigns: PromoCampaign[]): boolean {
    const selectedPaymentType = this.paymentTypesService.getPaymentType();
    // If user has selected a payment type that is compatible with at least one campaign, we don't show anything
    const campaignsCompatibleWithSelectedPaymentType = getCampaignsCompatibleWithPaymentType(
      selectedPaymentType,
      topPaymentCampaigns
    );
    return Boolean(campaignsCompatibleWithSelectedPaymentType?.length);
  }
}

<div class="box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title }}</h3>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div [swiper]="swiperOptions" *ngIf="chainsTiles?.length">
    <div class="swiper-wrapper home-brands-swiper-wrapper">
      <div class="swiper-slide home-brands-slide" *ngFor="let chainTile of chainsTiles">
        <image-tile
          visibilityObserver
          [triggerOnce]="true"
          [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
          (enteredViewport)="onChainEnteredViewport(chainTile)"
          class="hover-opacity"
          [imgUrl]="getChainTileImageUrl(chainTile)"
          [altText]="chainTile.chain.chainKey + ' logo'"
          (click)="onChainSelection(chainTile)"
        >
        </image-tile>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'box-chip',
  templateUrl: './box-chip.component.html',
  styleUrls: ['./box-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BOXChipComponent {
  @HostBinding('class') public hostClass = 'box-chip';

  /*
  This component needs improvement. We need to change the [boxChipRemove] selector in css
  with a class name.
  We also need to work with Output events for the action of the button
  */
}

import { Component, Input, OnChanges, ChangeDetectionStrategy, HostBinding, SimpleChanges } from '@angular/core';
import { OrderReceiptItemType, OrderReceiptItemsGroup, PromoVisualOptions } from '@box-types';

@Component({
  selector: 'order-receipt-items-group',
  templateUrl: './order-receipt-items-group.component.html',
  styleUrls: ['./order-receipt-items-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReceiptItemsGroupComponent<T> implements OnChanges {
  @Input() private group: OrderReceiptItemsGroup<T>;
  @Input() public type: OrderReceiptItemType;

  public name: string;
  public items: T[];
  public badgeOptions: PromoVisualOptions;

  @HostBinding('class') public hostClass = 'order-receipt-items-group';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.group = changes.group.currentValue as OrderReceiptItemsGroup<T>;
      this.name = this.group.name;
      this.items = this.group.items;
      this.badgeOptions = this.group.badgeOptions;
    }

    if (changes.type) this.type = changes.type.currentValue as OrderReceiptItemType;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as Components from './components';

@NgModule({
  declarations: [
    Components.SkeletonCuisinesComponent,
    Components.SkeletonShopTileCompactComponent,
    Components.SkeletonShopsComponent,
    Components.SkeletonDiscoverFiltersComponent,
    Components.SkeletonPromoBannersComponent,
    Components.SkeletonHomeShopsSectionComponent,
    Components.SkeletonLegalDocumentComponent,
    Components.SkeletonShopCardWithItemsComponent,
    Components.SkeletonShopPageReviewsComponent
  ],
  imports: [CommonModule],
  exports: [
    Components.SkeletonCuisinesComponent,
    Components.SkeletonShopTileCompactComponent,
    Components.SkeletonShopsComponent,
    Components.SkeletonDiscoverFiltersComponent,
    Components.SkeletonPromoBannersComponent,
    Components.SkeletonHomeShopsSectionComponent,
    Components.SkeletonLegalDocumentComponent,
    Components.SkeletonShopCardWithItemsComponent,
    Components.SkeletonShopPageReviewsComponent
  ]
})
export class SkeletonWidgetModule {}

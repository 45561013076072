import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Address, APIError } from '@box-types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddressesService, AuthenticationService } from '@box-core/services';
import { storageRemove } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class AddressesResolver implements Resolve<Address[]> {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private addressesService: AddressesService
  ) {}

  resolve(): Observable<Address[]> {
    const authenticated = this.authenticationService.isAuthenticated;
    if (!authenticated) return; // guest users don't have addresses
    return new Observable<Address[]>((subscriber) => {
      this.addressesService
        .getAddresses$()
        .pipe(
          tap((addresses) => {
            this.addressesService.setAddresses(addresses);
            this.handleAddressInStorageAfterLogin();
          })
        )
        .subscribe({
          next: (addresses) => {
            subscriber.next(addresses);
            subscriber.complete();
          },
          error: (error: APIError) => {
            this.addressesService.clearAddress();
            this.addressesService.clearAddresses();
            subscriber.error(error);
            storageRemove('BOX:address', localStorage);
            void this.router.navigate(['/']);
          }
        });
    });
  }

  /* some routes are address guard protected,
  in that case, the guard will run first and the address will be set. */
  private handleAddressInStorageAfterLogin(): void {
    /* in the scope this resolver is executed,
    if an address exists,
    it means it was stored in the browser's local storage */
    const localStorageAddress: Address = this.addressesService.getAddress();

    /* if the user has entered an address as a guest,
    that does not exist in his account,
    we want to add it, in the list of addresses. */
    if (localStorageAddress && !this.addressesService.addressAlreadyExists(localStorageAddress)) {
      this.addressesService.addAddress(localStorageAddress);
    }

    /* if no address is found we attempt to set one of the user's available addresses */
    if (!localStorageAddress) {
      const addresses = this.addressesService.getAddresses();
      if (addresses?.length === 1) return this.addressesService.setAddress(addresses[0]);
    }
  }
}

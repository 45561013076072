import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Shop, Coupon } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { isCouponDisabled } from '@box/utils';
import { CouponDetailsDialogService } from './coupon-details-dialog.service';
import { CouponDetailsDialogData, CouponDetailsDialogResponse } from './coupon-details-dialog.types';

@Component({
  selector: 'coupon-details-dialog',
  templateUrl: './coupon-details-dialog.component.html',
  styleUrls: ['./coupon-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CouponDetailsDialogService]
})
export class CouponDetailsDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  private coupon: Coupon;
  private shops: Shop[];

  public detailsDescription: string;
  public expirationDateText: string;
  public redemptionDateText: string;
  public remainingValueText: string;
  public filteredShops: Shop[];
  public showCta: boolean;
  public buttonText: string;
  public code: string;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<CouponDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CouponDetailsDialogData,
    private couponDetailsDialogService: CouponDetailsDialogService
  ) {
    super(renderer);
  }

  @HostBinding('class') public hostClass = 'coupon-details-dialog';

  ngOnInit(): void {
    this.coupon = this.data.coupon;
    this.shops = this.data.shops;
    this.detailsDescription = this.couponDetailsDialogService.getCouponDetailsDescription(this.coupon);
    this.expirationDateText = this.couponDetailsDialogService.getCouponDetailsExpirationDateText(this.coupon);
    this.redemptionDateText = this.couponDetailsDialogService.getCouponDetailsRedemptionDateText(this.coupon);
    this.remainingValueText = this.couponDetailsDialogService.getCouponDetailsRemainingValueText(this.coupon);
    this.filteredShops = this.couponDetailsDialogService.getCouponDetailsShops(this.coupon, this.shops);
    this.buttonText = this.couponDetailsDialogService.getCouponDetailsCtaTitle(this.coupon);
    this.showCta = this.shouldShowCTA();
    this.code = this.coupon.code;
  }

  public onShopCardClick(): void {
    this.closeDialog();
  }

  public onCouponAction(): void {
    this.closeDialog({ triggerAction: true });
  }

  public closeDialog(data?: CouponDetailsDialogResponse): void {
    this.dialogRef.close(data);
  }

  private shouldShowCTA(): boolean {
    if (this.data.showCta === false) return false;
    if (isCouponDisabled(this.coupon)) return false;
    if (!this.buttonText) return false;
    if (this.filteredShops?.length > 0) return false;
    return true;
  }
}

<figure class="image checkout-suggestion-banner-image">
  <img [src]="checkoutSuggestionBanner.image" [alt]="checkoutSuggestionBanner.campaignName" />
</figure>
<div class="checkout-suggestion-banner-content">
  <points-tag
    [points]="checkoutSuggestionBanner?.points"
    [multiplier]="checkoutSuggestionBanner?.multiplier"
  ></points-tag>
  <span class="checkout-suggestion-banner-subtitle ods-typography-body-s-bold">{{
    checkoutSuggestionBanner.subtitle
  }}</span>
</div>
<div class="checkout-suggestion-banner-action">
  <button
    box-button
    size="small"
    theme="primary"
    class="checkout-suggestion-banner-btn"
    (click)="onBannerSelect($event)"
  >
    {{ 'i_want_it' | translate }}
  </button>
</div>

import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';

@Component({
  selector: 'checkout-order-tracking-dialog',
  templateUrl: './checkout-order-tracking-dialog.component.html',
  styleUrls: ['./checkout-order-tracking-dialog.component.scss']
})
export class CheckoutOrderTrackingDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public frameURL: string;

  constructor(
    public renderer2: Renderer2,
    private dialogRef: MatDialogRef<CheckoutOrderTrackingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { url: string }
  ) {
    super(renderer2);
  }

  ngOnInit(): void {
    this.frameURL = this.data.url;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

<div *ngIf="pendingResults || hasResults; else noResults" class="home-search-results-container">
  <ng-container *ngIf="hasResults; else loading">
    <ng-container *ngFor="let shop of shops">
      <shop-tile-compact *ngIf="shop" [shop]="shop" class="hoverable"></shop-tile-compact>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <skeleton-shop-tile-compact></skeleton-shop-tile-compact>
    <skeleton-shop-tile-compact></skeleton-shop-tile-compact>
    <skeleton-shop-tile-compact></skeleton-shop-tile-compact>
    <skeleton-shop-tile-compact></skeleton-shop-tile-compact>
  </ng-template>
</div>
<ng-template #noResults>
  <box-empty-state [options]="emptyStateOptions"></box-empty-state>
</ng-template>
<div class="home-search-results-cta-wrapper">
  <button box-button theme="insignificant" size="small" (click)="onShowMoreClick()">
    {{ ctaText }}
  </button>
</div>

import { DiscoverSearchShop, Product } from '@box-types';

function getDiscoverSearchShopProductOffers(searchShop: DiscoverSearchShop): Product[] {
  if (!searchShop?.products?.length) return [];
  return searchShop.products.filter((p) => p.isOffer);
}

type ShopSearchResultScoreKeys = keyof Pick<DiscoverSearchShop, 'shopScore' | 'productMaxScore' | 'offerMaxScore'>;

function sortSearchShopsByScore(shops: DiscoverSearchShop[], key: ShopSearchResultScoreKeys): DiscoverSearchShop[] {
  if (!shops?.length || !key) return [];
  return shops.sort((a, b) => b[key] - a[key]);
}

export { getDiscoverSearchShopProductOffers, sortSearchShopsByScore };

<div class="cart-product-wrapper">
  <div class="cart-product-header">
    <promo-badge class="cart-product-promo-badge" *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
    <div class="cart-product-name">{{ product.name }}</div>
  </div>
  <div class="cart-product-instances">
    <cart-product-instance
      *ngFor="let instance of product.cartInstances"
      [instance]="instance"
      [disabled]="disabled"
      [showCommentPlaceholder]="showCommentPlaceholder"
      [class.hoverable]="!disabled"
      (add)="onAdd($event)"
      (remove)="onRemove($event)"
      (click)="onEdit($event, instance)"
    ></cart-product-instance>
  </div>
</div>

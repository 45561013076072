import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ElementRef,
  HostBinding
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { generateImageSrc, normalizeWebAction } from '@box/utils';
import { Router } from '@angular/router';
import { SingleBannerConfiguration } from '@box-types';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'single-banner',
  templateUrl: './single-banner.component.html',
  styleUrls: ['./single-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleBannerComponent implements OnInit, OnDestroy {
  @Output() private removeSection = new EventEmitter<null>();
  @Output() private ctaButtonClick = new EventEmitter<null>();
  @Input() public config: SingleBannerConfiguration;

  public imageUrl: string;
  public desktopView: boolean;
  public showCloseButton: boolean;
  public showCta: boolean;

  private breakPointObserverSubscription: Subscription;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private element: ElementRef
  ) {}

  @HostBinding('class') public hostClass = 'single-banner';

  ngOnInit(): void {
    this.imageUrl = generateImageSrc(this.config.webImage);
    this.setBreakPointObserverSubscription();
    this.setColors();
    this.showCta = Boolean(this.config.cta && this.config.webAction);
    this.showCloseButton = this.config.closeButton;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.breakPointObserverSubscription?.unsubscribe();
  }

  public onClose(): void {
    this.removeSection.emit();
  }

  public onClickCta(): void {
    const webAction = this.config?.webAction;
    const navigationUrl = normalizeWebAction(webAction);
    this.ctaButtonClick.emit();
    return void this.router.navigateByUrl(navigationUrl);
  }

  private setColors(): void {
    const style = (this.element.nativeElement as HTMLElement).style;
    if (!style) return;
    style.setProperty('--cta-color', this.config.ctaColor);
    style.setProperty('--title-color', this.config.titleColor);
    style.setProperty('--description-color', this.config.descriptionColor);
    style.setProperty('--background-color', this.config.backgroundColor);
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      const isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
      this.desktopView = !isSmallScreen;
      this.changeDetectorRef.detectChanges();
    });
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Order } from '@box-types';
import { generateImageSrc } from '@box/utils';
import dayjs from 'dayjs';

@Component({
  selector: 'order-completion-ratings-order',
  templateUrl: './order-completion-ratings-order.component.html',
  styleUrls: ['./order-completion-ratings-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderCompletionRatingsOrderComponent implements OnChanges {
  @Output() private rateOrder = new EventEmitter<number>();
  @Input() private order: Order;

  public imageSrc: string;
  public shopName: string;
  public dateText: string;

  @HostBinding('class') public hostClass = 'order-completion-ratings-order';

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    const shop = this.order.shop;
    this.imageSrc = generateImageSrc(shop.logo);
    this.shopName = shop.name;
    this.dateText = dayjs(this.order.createdAt).format('DD/MM/YYYY');
  }

  public onRate(rating: number): void {
    this.rateOrder.emit(rating);
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { generateBenefitClass, generateTagOptions, generateTitleSizeClass, getHostClasses } from '@box/utils';
import { PromoVisualOptions, CollapsibleTileSize, CollapsibleTile } from '@box-types';

@Component({
  selector: 'collapsible-tile',
  templateUrl: './collapsible-tile.component.html',
  styleUrls: ['./collapsible-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('textFadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void => *', [animate('100ms cubic-bezier(0.4, 0, 0.2, 1)'), style({ opacity: 1 })])
    ])
  ]
})
export class CollapsibleTileComponent implements OnInit, OnChanges {
  @Input() public collapsed: boolean;
  @Input() private size: CollapsibleTileSize;
  @Input() private tile: CollapsibleTile;

  public collapsedTitle: string;
  public title: string;
  public subtitle: string;
  public backgroundImage: string;
  public collapsedBackgroundImage: string;
  public titleSizeClass: string;
  public tagOptions: PromoVisualOptions;
  public textColor: string;
  public backGroundColor: string;
  public disabledAttribute: boolean;
  public expandedTileSizeClass: string;
  public collapsedTileBenefitClass: string;
  public points: number;
  public multiplier: number;

  ngOnInit(): void {
    this.expandedTileSizeClass = getHostClasses(this.size);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this.size = changes.size.currentValue as CollapsibleTileSize;
      this.expandedTileSizeClass = getHostClasses(this.size);
    }

    if (changes.tile) {
      this.tile = changes.tile.currentValue as CollapsibleTile;
      this.collapsedTitle = this.tile.collapsedTitle;
      this.title = this.tile.title;
      this.backGroundColor = this.tile.backGroundColor ?? '#5a5a5a';
      this.textColor = this.tile.textColor ?? '#ffffff';
      this.disabledAttribute = this.tile.disabled;
      this.backgroundImage = `url('${this.tile.image}')`;
      this.collapsedBackgroundImage = `url('${this.tile.collapsedImage}')`;
      this.titleSizeClass = generateTitleSizeClass(this.title);
      this.tagOptions = generateTagOptions(this.tile);
      this.collapsedTileBenefitClass = generateBenefitClass(this.tile);
      this.points = this.tile?.points;
      this.multiplier = this.tile?.multiplier;
    }

    if (changes.collapsed) {
      this.collapsed = changes.collapsed.currentValue as boolean;
    }
  }
}

import { Component, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { AnalyticsService } from '@box-core/services';
import { BOXNavOptions, GAPromotionConfig } from '@box-types';

@Component({
  selector: 'box-navigation',
  templateUrl: './box-navigation.component.html',
  styleUrls: ['./box-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxNavigationComponent {
  public navOptions: BOXNavOptions = { separators: false };
  @HostBinding('class') public hostClass = 'box-navigation';

  constructor(private analyticsService: AnalyticsService) {}

  public onBoxNavItemClick(tabName: string, creativeSlot: string): void {
    const gaConfig = {
      creative_name: tabName,
      creative_slot: creativeSlot,
      promotion_id: '',
      promotion_name: 'tabs'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
  }
}

import { Directive, Input, OnInit, HostBinding } from '@angular/core';
import { BOXNavOptions } from '@box-types';

@Directive({ selector: '[boxNav]' })
export class BOXNavDirective implements OnInit {
  @Input() private options: BOXNavOptions;

  @HostBinding('class') public hostClass = 'box-nav';
  @HostBinding('class.box-nav-separated') public hasSeparators: boolean;
  @HostBinding('class.box-nav-elevated') public isElevated: boolean;

  ngOnInit() {
    this.hasSeparators = this.options?.separators ?? true;
    this.isElevated = this.options?.elevated ?? true;
  }
}

import { SitemapLocation, Cuisine, Location } from '@box-types';

function isLocationValid(location: SitemapLocation): boolean {
  return location.key && location.shops && location.shops?.total > 0;
}

function filterValidLocations(locations: SitemapLocation[]): SitemapLocation[] {
  if (!locations?.length) return [];
  return locations.filter((location) => isLocationValid(location));
}

function filterCuisinesUnderLocation(cuisines: Cuisine[], location: Location): Cuisine[] {
  if (!cuisines?.length || !location?.shops) return [];
  return cuisines.filter((cuisine: Cuisine) => location.shops && location.shops[cuisine.key] > 0);
}

function filterLocationsWithCuisine(locations: Location[], cuisine: Cuisine): Location[] {
  if (!locations?.length || !cuisine.key) return [];
  return locations.filter((location: Location) => location.shops && location.shops[cuisine.key] > 0);
}

export { isLocationValid, filterValidLocations, filterLocationsWithCuisine, filterCuisinesUnderLocation };

<div class="info-notification-wrapper" (click)="onClick()">
  <mat-icon fontSet="material-icons-round" class="info-notification-icon">{{ matIcon }}</mat-icon>
  <div class="info-notification-content">
    <header *ngIf="title" class="info-notification-content-header">
      <span class="info-notification-content-header-title ods-typography-body-m-bold">{{ title | translate }}</span>
      <button *ngIf="closeButton" class="info-notification-content-header-close" mat-icon-button (click)="onClose()">
        <mat-icon fontSet="material-icons-round">close</mat-icon>
      </button>
    </header>
    <main class="info-notification-content-body ods-typography-body-m-regular">
      {{ message | translate }}
    </main>
  </div>
</div>

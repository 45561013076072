import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@box-env/environment';
import { Observable, map, of, switchMap } from 'rxjs';
import { APIResponse, GDPRConsentReminder, GDPRConsent, User } from '@box-types';
import { DialogService } from './dialog.service';
import { GDPRConsentDialogComponent } from '@box-core/components';
import { UserEventsService } from './user-events.service';
import { OrdersService } from './orders.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { getLatestGDPRConsentReminder, getNextGDPRConsentReminder, isGDPRConsentReminderActive } from '@box/utils';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class GDPRService {
  private readonly BOX_API = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private configService: ConfigurationService,
    private authService: AuthenticationService,
    private userService: UserService,
    private dialogService: DialogService,
    private userEventsService: UserEventsService,
    private ordersService: OrdersService
  ) {}

  public updateGDPRConsent(accept: boolean): Observable<GDPRConsent> {
    return this.http
      .post(`${this.BOX_API}/users/change/gdprConsent`, { accept })
      .pipe(map((response: APIResponse<{ user: User }>) => response.payload.user?.gdprConsent));
  }

  public checkGDPRConsent$(): Observable<null> {
    /** For the GDPR Consent Check, we require the resolution of the Order History endpoint. */
    const currentActiveReminder = this.getCurrentActiveReminder();
    if (currentActiveReminder) return this.openGDPRConsentDialog$(currentActiveReminder);
    return of(null);
  }

  private getCurrentActiveReminder(): GDPRConsentReminder {
    const isAuthenticated = this.authService.isAuthenticated;
    if (!isAuthenticated) return;
    const user = this.userService.getUser();
    const gdprConsentAccepted = user.gdprConsent?.accepted;
    if (gdprConsentAccepted === false || gdprConsentAccepted === true) return;
    const configurationConsentReminders = this.configService.getConfiguration()?.gdprConsent?.reminders;
    if (!configurationConsentReminders?.length) return;
    const userEventHistory = this.userEventsService.getUserEventHistory();
    const latestReminder = getLatestGDPRConsentReminder(configurationConsentReminders, userEventHistory);
    const nextReminder = getNextGDPRConsentReminder(latestReminder, configurationConsentReminders);
    if (!latestReminder) return nextReminder;
    if (!nextReminder) return;
    const latestOrder = this.ordersService.getOrderHistory()[0];
    if (!latestOrder) return;
    const latestEvent = userEventHistory.find((event) => event.key === latestReminder.key);
    const shouldShowConsent = isGDPRConsentReminderActive(latestOrder, nextReminder, latestEvent);
    if (shouldShowConsent) return nextReminder;
  }

  private openGDPRConsentDialog$(reminder: GDPRConsentReminder): Observable<null> {
    const dialogConfig = { disableClose: true, closeOnNavigation: true } as MatDialogConfig;
    return this.dialogService
      .openDialog(GDPRConsentDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        switchMap((gdprConsent: GDPRConsent) => {
          if (!gdprConsent) {
            this.userEventsService.appendToEventHistory(reminder.key);
            return of(null);
          }

          const currentUser = this.userService.getUser();
          this.userService.setUser({ ...currentUser, gdprConsent });
          return of(null);
        })
      );
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BOXNavOptions, DeliveryMethod } from '@box-types';
import { Subscription } from 'rxjs';
import { ShopPageDeliveryMethodService } from './shop-page-delivery-method.service';
import { ShopPageDeliveryMethodBlock } from './shop-page-delivery-method.types';

@Component({
  selector: 'shop-page-delivery-method',
  templateUrl: './shop-page-delivery-method.component.html',
  styleUrls: ['./shop-page-delivery-method.component.scss'],
  providers: [ShopPageDeliveryMethodService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopPageDeliveryMethodComponent implements OnInit, OnDestroy {
  public readonly navOptions: BOXNavOptions = { elevated: true, separators: true };
  public blocks: ShopPageDeliveryMethodBlock[];
  public deliveryMethod: DeliveryMethod;

  private blocksSubscription: Subscription;
  private deliveryMethodSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private shopPageDeliveryMethodService: ShopPageDeliveryMethodService
  ) {}

  @HostBinding('class') public hostClass = 'shop-page-delivery-method';

  ngOnInit(): void {
    this.setBlocksSubscription();
    this.setDeliveryMethodSubscription();
  }

  ngOnDestroy(): void {
    this.blocksSubscription?.unsubscribe();
    this.deliveryMethodSubscription?.unsubscribe();
  }

  public onDeliveryMethodClick(deliveryMethod: DeliveryMethod): void {
    this.shopPageDeliveryMethodService.setDeliveryMethod(deliveryMethod);
  }

  private setBlocksSubscription(): void {
    this.blocksSubscription = this.shopPageDeliveryMethodService.blocks$().subscribe((blocks) => {
      this.blocks = blocks;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setDeliveryMethodSubscription(): void {
    this.deliveryMethodSubscription = this.shopPageDeliveryMethodService
      .deliveryMethod$()
      .subscribe((deliveryMethod) => {
        this.deliveryMethod = deliveryMethod;
        this.changeDetectorRef.detectChanges();
      });
  }
}

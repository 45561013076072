import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService, CartService, DialogService, ShopService } from '@box-core/services';
import { CheckoutSuggestionBanner, Product, Offer } from '@box-types';
import { CheckoutSuggestionDialogComponent } from '../checkout-suggestion-dialog/checkout-suggestion-dialog.component';
import { CheckoutSuggestionDialogResponse } from '../checkout-suggestion-dialog/checkout-suggestion-dialog.types';
import { createOfferInstance, createProductInstance } from '@box/utils';

@Injectable()
export class CheckoutSuggestionBannersService {
  constructor(
    private configService: ConfigurationService,
    private shopService: ShopService,
    private cartService: CartService,
    private dialogService: DialogService
  ) {}

  public exceededThreshold(banners: CheckoutSuggestionBanner[]): boolean {
    const threshold = this.getSuggestionBannersThreshold();
    const bannersLength = banners?.length ?? 0;
    return bannersLength > threshold;
  }

  public addOffersToCart(offers: Offer[]): void {
    if (!offers) return;
    offers.map((offer) => {
      const offerInstance = createOfferInstance(offer);
      const decoratedOfferInstance = { ...offerInstance, addedFromCheckout: true };
      const cartResponse = this.cartService.addOffer(offer, decoratedOfferInstance);
      if (cartResponse === 'OFFER_ADDED') {
        this.shopService.syncCartOfferToMenu(offer);
        this.shopService.addToCartOfferAnalyticsEvent(offer, offerInstance, 'cart_promo_modal');
      }
    });
  }

  public addProductsToCart(products: Product[]): void {
    if (!products) return;
    products.map((product) => {
      const productInstance = createProductInstance(product);
      const decoratedProductInstance = { ...productInstance, addedFromCheckout: true };
      const cartResponse = this.cartService.addProduct(product, decoratedProductInstance);
      if (cartResponse === 'PRODUCT_ADDED') {
        this.shopService.syncCartProductToMenu(product);
        this.shopService.addToCartProductAnalyticsEvent(product, productInstance, 'cart_promo_modal');
      }
    });
  }

  public openCheckoutSuggestionDialog(
    banner: CheckoutSuggestionBanner
  ): MatDialogRef<CheckoutSuggestionDialogComponent, CheckoutSuggestionDialogResponse> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      restoreFocus: false,
      autoFocus: false,
      data: { suggestionGroups: banner.groups }
    };
    return this.dialogService.openDialog(CheckoutSuggestionDialogComponent, dialogConfig);
  }

  private getSuggestionBannersThreshold(): number {
    return this.configService.getConfiguration()?.cartSuggestionThreshold ?? 3;
  }
}

import { Shop } from '@box-types';
import { generateImageSrc } from '../media';

export { DEFAULT_SHOP_HERO_BACKGROUND_COLOR, getShopHeroBackgroundColor, getShopHeroBackgroundImageUrl };

const DEFAULT_SHOP_HERO_BACKGROUND_COLOR = '#ffffff';

// Requires the use of decorateShopWithCuisines so that we have the appropriate Cuisine Data
function getShopHeroBackgroundColor(shop: Shop): string {
  const { mainCuisine, secondaryCuisines } = shop;
  if (mainCuisine?.backGroundColor) return mainCuisine.backGroundColor;
  const secondaryCuisine = secondaryCuisines?.length > 0 ? secondaryCuisines[0] : undefined;
  if (secondaryCuisine?.backGroundColor) return secondaryCuisine.backGroundColor;
  return DEFAULT_SHOP_HERO_BACKGROUND_COLOR;
}

// We will hardcode the @3x until we fix the webImage sizes on the shops
// Requires the use of decorateShopWithCuisines so that we have the appropriate Cuisine Data
function getShopHeroBackgroundImageUrl(shop: Shop, isSmallScreen: boolean): string {
  const { webImage, mobileImage, mainCuisine, secondaryCuisines } = shop;
  if (mobileImage && isSmallScreen) return generateImageSrc(mobileImage);
  if (webImage) return generateImageSrc(webImage, '@3x');

  if (mainCuisine?.mobileImage && isSmallScreen) return generateImageSrc(mainCuisine.mobileImage);
  if (mainCuisine?.webImage) return generateImageSrc(mainCuisine.webImage, '@3x');

  const secondaryCuisine = secondaryCuisines?.length > 0 ? secondaryCuisines[0] : null;
  if (secondaryCuisine?.mobileImage && isSmallScreen) generateImageSrc(secondaryCuisine.mobileImage);
  if (secondaryCuisine?.webImage) return generateImageSrc(secondaryCuisine.webImage, '@3x');
  return;
}

<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header shop-page-details-dialog-header">
    <box-dialog-header (closeDialog)="closeDialog()"></box-dialog-header>
    <div boxNav [options]="navOptions" class="shop-page-details-dialog-nav">
      <div
        boxNavItem
        [title]="'informations_' | translate"
        [class.box-nav-item-active]="state === 'SHOP_DETAILS'"
        (click)="onStateChange('SHOP_DETAILS')"
      >
        <span class="ods-typography-body-s-bold">{{ 'informations_' | translate }}</span>
      </div>
      <div
        boxNavItem
        [title]="'reviewsTabTitle' | translate"
        [class.box-nav-item-active]="state === 'SHOP_REVIEWS'"
        (click)="onStateChange('SHOP_REVIEWS')"
      >
        <div *ngIf="loading; else reviewsNavTextLoaded" class="shop-page-details-dialog-nav-text-skeleton"></div>
        <ng-template #reviewsNavTextLoaded>
          <span class="ods-typography-body-s-bold">{{ reviewsTabTitle | translate }}</span>
        </ng-template>
      </div>
    </div>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content" [ngSwitch]="state">
    <shop-page-details *ngSwitchCase="'SHOP_DETAILS'" [shopsWithChain]="data.shopsWithChain"></shop-page-details>
    <ng-container *ngSwitchCase="'SHOP_REVIEWS'">
      <skeleton-shop-page-reviews *ngIf="loading; else shopPageReviews"></skeleton-shop-page-reviews>
      <ng-template #shopPageReviews>
        <shop-page-reviews [reviews]="reviews"></shop-page-reviews>
      </ng-template>
    </ng-container>
  </main>
</div>

<div #wrapper class="box-slider-wrapper">
  <div class="box-slider-track">
    <div class="box-slider-bars-wrapper">
      <div class="box-slider-bar box-slider-bar--fill"></div>
      <div #availableBar class="box-slider-bar box-slider-bar--available"></div>
      <div #claimableBar class="box-slider-bar box-slider-bar--claimable"></div>
      <div #activeBar class="box-slider-bar box-slider-bar--active"></div>
    </div>
  </div>

  <div #thumbContainer class="box-slider-thumb-container">
    <div class="box-slider-thumb-ring"></div>
    <div class="box-slider-thumb"></div>
  </div>
</div>

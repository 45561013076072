import { Component, Input, HostBinding, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ShopTagSlide } from '@box-core/animations/shops.animations';
import { LongPromoVisualOptionsService, ShortPromoVisualOptionsService } from '@box-core/services';
import { Shop, PromoVisualOptions } from '@box-types';
import { ShopTileCompactLayoutType, canShowShopRatings, generateImageSrc, isShopOnlyTakeAway } from '@box/utils';

@Component({
  selector: 'shop-tile-compact',
  templateUrl: './shop-tile-compact.component.html',
  styleUrls: ['./shop-tile-compact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ShortPromoVisualOptionsService, LongPromoVisualOptionsService],
  animations: [ShopTagSlide]
})
export class ShopTileCompactComponent implements OnChanges {
  @Input() public shop: Shop;
  @Input() public layout: ShopTileCompactLayoutType = 'HORIZONTAL';
  @Input() public disableAnimation = false;

  public name: string;
  public cuisineText: string;
  public logoUrl: string;
  public isNew: boolean;
  public hasTakeAwayLabel: boolean;
  public routerLink: string;
  public disabledText: string;
  public showRating: boolean;
  public firstGroupTagOptions: PromoVisualOptions;
  public secondGroupTagOptions: PromoVisualOptions;
  public firstGroupBadgeOptions: PromoVisualOptions;
  public secondGroupBadgeOptions: PromoVisualOptions;
  public showTags: boolean;
  public showBadges: boolean;
  public promotedTag: string;
  public showPromotedTag: boolean;

  @HostBinding('class') public hostClass = 'shop-tile-compact';
  @HostBinding('class.shop-tile-compact-vertical') public isVertical = this.layout === 'VERTICAL';
  @HostBinding('class.shop-tile-compact-horizontal') public isHorizontal = this.layout === 'HORIZONTAL';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableAnimation) this.disableAnimation = changes.disableAnimation.currentValue as boolean;
    if (changes.layout) {
      this.layout = changes.layout.currentValue as ShopTileCompactLayoutType;
      this.isVertical = this.layout === 'VERTICAL';
      this.isHorizontal = this.layout === 'HORIZONTAL';
    }

    if (changes.shop) {
      this.shop = changes.shop.currentValue as Shop;
      this.name = this.shop.name;
      this.cuisineText = this.getCuisineText();
      this.logoUrl = generateImageSrc(this.shop.logo);
      this.isNew = this.shop.isNew;
      this.hasTakeAwayLabel = isShopOnlyTakeAway(this.shop);
      this.routerLink = `/delivery/${this.shop.locationKey}/${this.shop.vanity_url}`;
      this.disabledText = this.shop.disabledText;
      this.showRating = canShowShopRatings(this.shop.ratingCounter);
      this.firstGroupTagOptions = this.shop.promo?.firstGroupShortOptions;
      this.secondGroupTagOptions = this.shop.promo?.secondGroupShortOptions;
      this.firstGroupBadgeOptions = this.shop.promo?.firstGroupLongOptions;
      this.secondGroupBadgeOptions = this.shop.promo?.secondGroupLongOptions;
      this.promotedTag = this.shop.promotedTag;
    }

    const hasTagOptions = Boolean(this.firstGroupTagOptions) || Boolean(this.secondGroupTagOptions);
    this.showTags = this.isVertical && hasTagOptions;
    const hasBadgeOptions = Boolean(this.firstGroupBadgeOptions) || Boolean(this.secondGroupBadgeOptions);
    this.showBadges = this.isHorizontal && hasBadgeOptions;
    this.showPromotedTag = this.isHorizontal && Boolean(this.promotedTag);
  }

  private getCuisineText(): string {
    if (this.layout === 'HORIZONTAL') return this.shop.cuisinesText;
    return this.shop.mainCuisineText;
  }
}

import {
  Order,
  Product,
  ProductInstance,
  Shop,
  GAEcommerceItemConfig,
  GAPromotionConfig,
  GARemoveFromCartConfig,
  GASelectItemConfig,
  Offer,
  OfferInstance
} from '@box-types';
import { getPurchaseEventProductDiscount, getPurchaseEventOfferDiscount } from '../checkout';

export {
  createCollectionShopOrderImpressionItems,
  getCollectionShopOrderImpressionGAConfig,
  createCartProductItems,
  getCartProductGAConfig,
  createCartOfferItems,
  getCartOfferGAConfig,
  createShopSelectItemProductItems,
  getShopSelectItemProductGAConfig,
  createShopSelectItemOfferItems,
  getShopSelectItemOfferGAConfig,
  createShopSelectItemModalItems,
  getShopSelectItemModalGAConfig
};

type getCollectionShopOrderImpressionGAConfigOptions = {
  creativeName: string;
  creativeSlot: string;
  promotionName: string;
  itemListName: string;
  orders: Order[];
  order: Order;
  shop: Shop;
};

function createCollectionShopOrderImpressionItems(
  itemListName: string,
  orders: Order[],
  selectedOrder: Order,
  shop: Shop
): GAEcommerceItemConfig[] {
  return orders.reduce((itemsArray: GAEcommerceItemConfig[], order, index) => {
    const item: GAEcommerceItemConfig = {
      item_id: order._id,
      item_name: '',
      index: index + 1,
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName
    };
    if (item.item_id === selectedOrder._id) {
      itemsArray.push(item);
    }
    return itemsArray;
  }, [] as GAEcommerceItemConfig[]);
}

function getCollectionShopOrderImpressionGAConfig(
  options: getCollectionShopOrderImpressionGAConfigOptions
): GAPromotionConfig {
  const creativeName = options.creativeName;
  const creativeSlot = options.creativeSlot;
  const promotionName = options.promotionName;
  const itemListName = options.itemListName;
  const orders = options.orders;
  const order = options.order;
  const shop = options.shop;
  if (!orders?.length) return;
  const promotionItems = createCollectionShopOrderImpressionItems(itemListName, orders, order, shop);
  return {
    creative_name: creativeName,
    creative_slot: creativeSlot,
    promotion_id: '',
    promotion_name: promotionName,
    items: promotionItems
  };
}

function createCartProductItems(
  product: Product,
  productInstance: ProductInstance,
  shop: Shop,
  itemListName: string
): GAEcommerceItemConfig[] {
  return [
    {
      item_id: product._id,
      item_name: product.name,
      affiliation: shop.name,
      discount: getPurchaseEventProductDiscount(product),
      item_category: product.category.name ?? '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName,
      item_variant: '',
      price: productInstance.price / 100,
      quantity: productInstance.quantity
    }
  ] as GAEcommerceItemConfig[];
}

function getCartProductGAConfig(
  product: Product,
  productInstance: ProductInstance,
  shop: Shop,
  itemListName: string
): GARemoveFromCartConfig {
  if (!product) return;
  const items = createCartProductItems(product, productInstance, shop, itemListName);
  return {
    currency: 'EUR',
    value: (productInstance.price / 100) * productInstance.quantity,
    items: items
  };
}

function createCartOfferItems(
  offer: Offer,
  offerInstance: OfferInstance,
  shop: Shop,
  itemListName: string
): GAEcommerceItemConfig[] {
  return [
    {
      item_id: offer._id,
      item_name: offer.name,
      affiliation: shop.name,
      discount: getPurchaseEventOfferDiscount(offer),
      item_category: 'Offers',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: itemListName,
      item_variant: '',
      price: offerInstance.price / 100,
      quantity: offerInstance.quantity
    }
  ] as GAEcommerceItemConfig[];
}

function getCartOfferGAConfig(
  offer: Offer,
  offerInstance: OfferInstance,
  shop: Shop,
  itemListName: string
): GARemoveFromCartConfig {
  if (!offer) return;
  const items = createCartOfferItems(offer, offerInstance, shop, itemListName);
  return {
    currency: 'EUR',
    value: (offerInstance.price / 100) * offerInstance.quantity,
    items: items
  };
}

function createShopSelectItemProductItems(product: Product, shop: Shop): GAEcommerceItemConfig[] {
  return [
    {
      item_id: product._id,
      item_name: product.name,
      affiliation: shop.name,
      discount: getPurchaseEventProductDiscount(product),
      item_category: product.category.name ?? '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_brand: product.brand?.name ?? '',
      item_list_id: product.category._id,
      item_list_name: product.category.name,
      item_variant: '',
      price: product.finalPrice / 100,
      quantity: 1
    }
  ] as GAEcommerceItemConfig[];
}

function getShopSelectItemProductGAConfig(product: Product, shop: Shop): GASelectItemConfig {
  if (!product) return;
  const items = createShopSelectItemProductItems(product, shop);
  return {
    item_list_id: product.category._id,
    item_list_name: product.category.name,
    items: items
  };
}

function createShopSelectItemOfferItems(offer: Offer, shop: Shop): GAEcommerceItemConfig[] {
  return [
    {
      item_id: offer._id,
      item_name: offer.name,
      affiliation: shop.name,
      discount: getPurchaseEventOfferDiscount(offer),
      item_brand: '',
      item_category: 'Offers',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: 'Offers',
      item_variant: '',
      price: offer.price / 100,
      quantity: offer.quantity
    }
  ] as GAEcommerceItemConfig[];
}

function getShopSelectItemOfferGAConfig(offer: Offer, shop: Shop): GASelectItemConfig {
  if (!offer) return;
  const items = createShopSelectItemOfferItems(offer, shop);
  return {
    item_list_id: '',
    item_list_name: 'Offers',
    items: items
  };
}

function createShopSelectItemModalItems(product: Product, shop: Shop): GAEcommerceItemConfig[] {
  return [
    {
      item_id: product.productId,
      item_name: product.name,
      affiliation: shop.name,
      item_category: '',
      item_category2: shop.secondaryCuisines[0]?.name ?? '',
      item_category3: shop.chain ?? '',
      item_list_id: '',
      item_list_name: 'select_item_modal'
    }
  ] as GAEcommerceItemConfig[];
}

function getShopSelectItemModalGAConfig(product: Product, shop: Shop, itemListName: string): GASelectItemConfig {
  if (!product) return;
  const items = createShopSelectItemModalItems(product, shop);
  return {
    item_list_id: product.category._id ?? '',
    item_list_name: itemListName,
    items: items
  };
}

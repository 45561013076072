import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const CartTwist = trigger('cartTwist', [
  state('false', style({ transform: 'rotate(0deg)' })),
  state('true', style({ transform: 'rotate(0deg)' })),
  transition('false <=> true', [
    animate(
      '0.8s ease-out',
      keyframes([
        style({ transform: 'rotate(0deg)' }),
        style({ transform: 'rotate(35deg)' }),
        style({ transform: 'rotate(-35deg)' }),
        style({ transform: 'rotate(35deg)' }),
        style({ transform: 'rotate(0deg)' })
      ])
    )
  ])
]);

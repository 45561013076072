import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MarketCardBanner, MarketCardPointsBanner, Order, SMLoyaltyConfiguration } from '@box-types';
import { CheckoutOrderMarketCardPointsService } from './checkout-order-market-card-points.service';

@Component({
  selector: 'checkout-order-market-card-points',
  templateUrl: './checkout-order-market-card-points.component.html',
  styleUrls: ['./checkout-order-market-card-points.component.scss'],
  providers: [CheckoutOrderMarketCardPointsService]
})
export class CheckoutOrderMarketCardPointsComponent implements OnInit {
  @HostBinding('class') public hostClass = 'checkout-order-market-card-points';
  @Input() private order: Order;

  public marketCardBanner: MarketCardBanner;
  public marketCardPointsBanner: MarketCardPointsBanner;

  public showCardBanner: boolean;
  public showCardPointsBanner: boolean;

  private marketCardCreated: boolean;
  private loyaltyInfo: SMLoyaltyConfiguration;

  constructor(private checkoutOrderMarketCardPointsService: CheckoutOrderMarketCardPointsService) {}

  ngOnInit(): void {
    this.loyaltyInfo = this.checkoutOrderMarketCardPointsService.getMarketLoyaltyInfo(this.order);
    this.marketCardCreated = this.checkoutOrderMarketCardPointsService.marketCardCreated();
    this.marketCardBanner = this.getMarketCardBanner();
    this.marketCardPointsBanner = this.getMarketCardPointsBanner();
    this.showCardBanner = this.marketCardCreated;
    this.showCardPointsBanner = this.marketCardPointsBanner.points > 0;
    if (this.marketCardCreated) this.checkoutOrderMarketCardPointsService.clearMarketCardCreated();
  }

  private getMarketCardBanner(): MarketCardBanner {
    if (!this.marketCardCreated) return undefined;
    return this.checkoutOrderMarketCardPointsService.getMarketCardBanner(this.loyaltyInfo);
  }

  private getMarketCardPointsBanner(): MarketCardPointsBanner {
    return this.checkoutOrderMarketCardPointsService.getMarketCardPointsBanner(this.order, this.loyaltyInfo);
  }
}

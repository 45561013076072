import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'offer-wizard-dfy-dialog',
  templateUrl: './offer-wizard-dfy-dialog.component.html',
  styleUrls: ['./offer-wizard-dfy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferWizardDFYDialogComponent {
  constructor(private dialogRef: MatDialogRef<OfferWizardDFYDialogComponent>) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

<div class="swiper-section box-swiper">
  <div [swiper]="swiperOptions" *ngIf="orders?.length">
    <div class="swiper-wrapper">
      <div
        *ngFor="let order of orders; trackBy: trackByStatus; let i = index"
        class="swiper-slide"
        [class.swiper-slide-full-width]="orders.length === 1"
        [class.swiper-no-swiping]="orders.length === 1"
      >
        <a [routerLink]="['/checkout', 'order-status']" [queryParams]="{ friendlyId: order.friendlyId }">
          <order-status-preview
            [order]="order"
            class="hoverable"
          ></order-status-preview>
        </a>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</div>

import { Component, ChangeDetectionStrategy, HostBinding, SimpleChanges, Input, OnChanges } from '@angular/core';
import { Order, ProgressBarFragment } from '@box-types';
import { generateOrderProgressBarFragments, getOrderAcceptedStatusText, generateImageSrc } from '@box/utils';

@Component({
  selector: 'order-status-preview',
  templateUrl: './order-status-preview.component.html',
  styleUrls: ['./order-status-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusPreviewComponent implements OnChanges {
  @Input() private order: Order;

  public logoSrc: string;
  public logoAlt: string;
  public fragments: ProgressBarFragment[];
  public title: string;

  @HostBinding('class') public hostClass = 'order-status-preview';

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    const shop = this.order.shop;
    this.logoSrc = generateImageSrc(shop.logo);
    this.logoAlt = shop.name;
    this.fragments = generateOrderProgressBarFragments(this.order);
    this.title = getOrderAcceptedStatusText(this.order, 'main');
  }
}

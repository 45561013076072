import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { NotificationConfig, NotificationRef } from '@box-core/components';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'benefits-banner-wrapper',
  templateUrl: './benefits-banner-wrapper.component.html',
  styleUrls: ['./benefits-banner-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitsBannerWrapperComponent implements OnInit {
  public ordersEuroText: string;
  public infoText: string;

  private routeSubscription: Subscription;

  constructor(
    private notificationConfig: NotificationConfig,
    private notificationRef: NotificationRef,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'benefits-banner-wrapper';

  ngOnInit(): void {
    const benefitsBannerData = this.notificationConfig.data as { currencyText: string; infoText: string };
    this.ordersEuroText = benefitsBannerData.currencyText;
    this.infoText = benefitsBannerData.infoText;
    this.changeDetectorRef.detectChanges();
    this.notificationRef.dismiss({ delay: 15000 });
    this.setRouteSubscription();
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  public onClick(): void {
    void this.router.navigate(['/rewards']);
    this.notificationRef.dismiss();
  }

  private setRouteSubscription(): void {
    this.routeSubscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.notificationRef.dismiss();
      });
  }
}

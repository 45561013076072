import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '@box-core/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private authenticated: boolean;

  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    this.authenticated = this.authService.isAuthenticated;
    /*
    We transfer the params for a case that we have encountered
    with the /promos?promo={slug} redirection of the non Authenticated User.
    That functionality might need a revisit in the future, due to the fact
    that this is a Global Guard.
    */
    const queryParams = activatedRouteSnapshot.queryParams;
    if (!this.authenticated) return void this.router.navigate(['/'], { queryParams });
    return this.authenticated;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SentryService, LoaderService } from '@box-core/services';
import { Location, APIError, Shop, DeliveryAnchor } from '@box-types';
import { finalize } from 'rxjs/operators';
import { ShopExtraInfoService } from './shop-extra-info.service';
import { ShopExtraInfoLocationData } from './shop-extra-info.types';
import { ShopResolverData } from '@box-delivery/delivery.types';
import { generateShopsWithChainAnchors } from '@box/utils';

@Component({
  selector: 'shop-extra-info',
  templateUrl: './shop-extra-info.component.html',
  styleUrls: ['./shop-extra-info.component.scss'],
  providers: [ShopExtraInfoService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopExtraInfoComponent implements OnInit {
  @Input() public options: ShopResolverData;

  public templateHtml: string;
  public extraInfoLocationData: ShopExtraInfoLocationData;
  public shopsWithChainAnchors: DeliveryAnchor[];
  public shop: Shop;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private shopExtraInfoService: ShopExtraInfoService,
    private sentryService: SentryService,
    private loaderService: LoaderService
  ) {}

  @HostBinding('class') public hostClass = 'shop-extra-info';

  ngOnInit(): void {
    this.shop = this.options.shop;
    this.templateHtml = this.shopExtraInfoService.getShopDescriptionHTML(this.shop);
    this.shopsWithChainAnchors = generateShopsWithChainAnchors(this.shop, this.options.shopsWithChain);
    this.setLocationDataSubscription();
  }

  private setLocationDataSubscription(): void {
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    const location = { name: this.shop.locationName, key: this.shop.locationKey } as Location;
    this.shopExtraInfoService
      .getShopLocationData(this.shop, location)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (extraInfoLocationData) => {
          this.extraInfoLocationData = extraInfoLocationData;
          this.changeDetectorRef.detectChanges();
        },
        error: (error: APIError) =>
          this.sentryService.captureException(error, {
            domain: 'Delivery',
            domainDetails: 'Shop Extra Info Location Data',
            severity: 'error'
          })
      });
  }
}

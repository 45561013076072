import { Component, OnInit, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { CheckoutCouponsService } from '@box-checkout/services';
import { Coupon } from '@box-types';
import { MatDialogRef } from '@angular/material/dialog';
import { BankPointsRedeemSlideDialogComponent } from '@box-checkout/components';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { CheckoutCouponsDialogResponse } from '@box-checkout/components/checkout-coupons-dialog/checkout-coupons-dialog.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'checkout-coupons-dialog',
  templateUrl: './checkout-coupons-dialog.component.html',
  styleUrls: ['./checkout-coupons-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutCouponsDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public coupons: Coupon[];
  public selectedCoupon: Coupon;

  private couponsSubscription: Subscription;
  private couponSubscription: Subscription;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<BankPointsRedeemSlideDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private checkoutCouponsService: CheckoutCouponsService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setCouponSubscription();
    this.setCouponsSubscription();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.couponSubscription?.unsubscribe();
    this.couponsSubscription?.unsubscribe();
  }

  private setCouponsSubscription(): void {
    this.couponsSubscription = this.checkoutCouponsService.coupons$.subscribe((coupons) => {
      this.coupons = coupons;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCouponSubscription(): void {
    this.couponSubscription = this.checkoutCouponsService.coupon$.subscribe((coupon) => {
      this.selectedCoupon = coupon;
      this.changeDetectorRef.detectChanges();
    });
  }

  public onCouponClick(coupon: Coupon): void {
    this.checkoutCouponsService.selectCoupon(coupon);
    this.closeDialog();
  }

  public onCouponAddClick(): void {
    this.closeDialog({ addCoupon: true } as CheckoutCouponsDialogResponse);
  }

  public closeDialog(data?: CheckoutCouponsDialogResponse): void {
    this.dialogRef.close(data);
  }
}

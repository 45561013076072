import { DaasDeliveryStatusHistoryEntry, Order, OrderDaasInfoStatus } from '@box-types';

export { isOrderDaas, orderMatchesDaasInfoStatus, isOrderDaasBeforeShipping, getOrderDaasStatus };

function isOrderDaas(order: Order): boolean {
  if (order.orderShippingType === 'takeAway') return false;
  return Boolean(order.daasInformation?.deliveryStatus);
}

function orderMatchesDaasInfoStatus(order: Order, status: OrderDaasInfoStatus): boolean {
  if (!isOrderDaas(order)) return false;
  return order.daasInformation.deliveryStatus.status === status;
}

function isOrderDaasBeforeShipping(order: Order): boolean {
  if (!isOrderDaas(order)) return false;
  return ['UNASSIGNED', 'ASSIGNED', 'STARTED', 'ARRIVING_AT_SHOP'].includes(
    order.daasInformation.deliveryStatus.status
  );
}

function getOrderDaasStatus(order: Order, status: OrderDaasInfoStatus): DaasDeliveryStatusHistoryEntry {
  const deliveryStatuses = order?.daasInformation?.deliveryStatus?.statusHistory;
  if (!deliveryStatuses?.length) return;
  return deliveryStatuses.find((deliveryStatus) => deliveryStatus.status === status);
}

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const Shake = trigger('shake', [
  state('shakeStart', style({ transform: 'translate3d(0, 0, 0)' })),
  state('shakeEnd', style({ transform: 'translate3d(0, 0, 0)' })),
  transition('shakeStart => shakeEnd', [
    animate(
      '820ms',
      keyframes([
        style({ transform: 'translate3d(-0.0625rem, 0, 0)', offset: 0.1 }),
        style({ transform: 'translate3d(0.125rem, 0, 0)', offset: 0.2 }),
        style({ transform: 'translate3d(-0.25rem, 0, 0)', offset: 0.3 }),
        style({ transform: 'translate3d(0.25rem, 0, 0)', offset: 0.4 }),
        style({ transform: 'translate3d(-0.25rem, 0, 0)', offset: 0.5 }),
        style({ transform: 'translate3d(0.25rem, 0, 0)', offset: 0.6 }),
        style({ transform: 'translate3d(-0.25rem, 0, 0)', offset: 0.7 }),
        style({ transform: 'translate3d(0.125rem, 0, 0)', offset: 0.8 }),
        style({ transform: 'translate3d(-0.0625rem, 0, 0)', offset: 0.9 })
      ])
    )
  ])
]);

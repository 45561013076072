import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { BoxTheme, ThemePreference } from '@box-types';
import {
  isPrerenderBrowser,
  getThemePreference,
  updateDataModeAttribute,
  storeThemePreference,
  getSystemPreferredTheme,
  updateThemeColorMetaElement,
  updateColorSchemeMetaElement
} from '@box/utils';

@Injectable({ providedIn: 'root' })
export class ThemingService {
  private selectedTheme = new BehaviorSubject<BoxTheme>(null);
  public selectedTheme$ = this.selectedTheme.asObservable();
  private systemThemeSubscription: Subscription;

  public initializeTheme(): void {
    const themePreference = getThemePreference();
    this.setThemePreference(themePreference);
  }

  public setThemePreference(themePreference: ThemePreference): void {
    storeThemePreference(themePreference);
    this.systemThemeSubscription?.unsubscribe();

    switch (themePreference) {
      case 'dark':
        this.setTheme('dark');
        break;
      case 'light':
        this.setTheme('light');
        break;
      default: {
        const systemTheme = getSystemPreferredTheme();
        this.setTheme(systemTheme);
        this.setSystemThemeSubscription();
      }
    }
  }

  public getTheme(): BoxTheme {
    return this.selectedTheme.getValue();
  }

  private setTheme(theme: BoxTheme): void {
    if (!theme || this.getTheme() === theme) return;
    updateDataModeAttribute(theme);
    updateThemeColorMetaElement(theme);
    updateColorSchemeMetaElement(theme);
    this.selectedTheme.next(theme);
  }

  private setSystemThemeSubscription(): void {
    if (isPrerenderBrowser(window) || !window?.matchMedia) return;
    const event = window.matchMedia('(prefers-color-scheme: dark)');
    if (!event) return;
    const systemTheme$ = fromEvent(event, 'change');
    if (!systemTheme$) return;
    this.systemThemeSubscription = systemTheme$.subscribe((event: MediaQueryListEvent) => {
      const theme: BoxTheme = event?.matches ? 'dark' : 'light';
      this.setTheme(theme);
    });
  }
}

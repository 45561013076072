import { Media } from '@box-types';

function isValidMedia(media: Media): boolean {
  return Boolean(media && media.path && media.name && media.ext);
}

function constructMediaSrc(media: Media, prefix = '@3x'): string {
  if (!isValidMedia(media)) return '';
  return `${media.path + prefix + media.name}.${media.ext}`;
}

function getMediaPrefix(window: Window): string {
  if (!window?.devicePixelRatio) return '@1x';
  const pixelRatio: number = Math.min(Math.ceil(window.devicePixelRatio), 3);
  return `@${pixelRatio}x`;
}

export { isValidMedia, constructMediaSrc, getMediaPrefix };

<div class="order-receipt-items-group-header">
  <promo-badge *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
  <div class="order-receipt-items-group-title">{{ name }}</div>
</div>
<div class="order-receipt-items-group-body" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'OFFER'">
    <order-receipt-offer *ngFor="let item of items" [offer]="item"></order-receipt-offer>
  </ng-container>
  <ng-container *ngSwitchCase="'PRODUCT'">
    <order-receipt-product *ngFor="let item of items" [product]="item"></order-receipt-product>
  </ng-container>
</div>

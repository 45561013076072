import { Component, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';
import { Subscription } from 'rxjs';
import {
  BreadcrumbListItem,
  removeJsonLdWithId,
  breadcrumbListId,
  BreadcrumbList,
  breadCrumbsItemDecorator,
  addOrUpdateJsonLd,
  createBreadCrumbListItem,
  isValidJsonLd
} from '@box/utils';
import { translate } from '@box-core/services';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  private snapshot: ActivatedRouteSnapshot;
  private path: string;
  private breadCrumbSubscription: Subscription;
  private routerSubscription: Subscription;

  public breadcrubmData: BreadcrumbListItem[] = [];
  public hasAbsolutePosition: boolean;

  constructor(private router: Router, private breadCrumbService: BreadCrumbService) {
    this.setBreadCrumbSubscription();
    this.setRoutingEventsSubscription();
  }

  ngOnDestroy(): void {
    this.breadCrumbSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  private setBreadCrumbSubscription(): void {
    this.breadCrumbSubscription = this.breadCrumbService.breadcrumbs$.subscribe(() => {
      this.setBreadcrumbData();
    });
  }

  private setRoutingEventsSubscription(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (this.router.routerState.snapshot.url != this.path) {
        removeJsonLdWithId(breadcrumbListId);
        this.breadcrubmData = [];
        this.path = this.router.routerState.snapshot.url;
      }
      if (event instanceof ActivationEnd && event.snapshot.data['breadcrumbName']) {
        this.snapshot = event.snapshot;
        this.setBreadcrumbData();
      }
    });
  }

  private setBreadcrumbData(): void {
    if (!this.snapshot) return;
    const data = this.buildBreadcrumbTrail(this.snapshot);
    const jsonObj: BreadcrumbList = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadCrumbsItemDecorator(data)
    };
    if (!isValidJsonLd(jsonObj)) return;
    this.breadcrubmData = data;
    addOrUpdateJsonLd(breadcrumbListId, jsonObj);
  }

  private buildBreadcrumbTrail(snapshot: ActivatedRouteSnapshot): BreadcrumbListItem[] {
    const breadcrumbItems: BreadcrumbListItem[] = [];

    // navigate to the deepest child route
    while (snapshot?.firstChild) {
      snapshot = snapshot.firstChild;
    }

    this.setBreadcrumbAbsolutePosition(snapshot.data.name);

    const childrenBreadcrumbs = this.breadCrumbService.buildChildrenBreadcrumbTrail(
      snapshot,
      this.path,
      1,
      breadcrumbItems
    );
    breadcrumbItems.push(...childrenBreadcrumbs);
    breadcrumbItems.push(createBreadCrumbListItem(breadcrumbItems.length + 1, translate('home_page'), ''));
    return breadcrumbItems.reverse();
  }

  private setBreadcrumbAbsolutePosition(name: string): void {
    const snapshotDataNames = [
      'Shop Menu Page',
      'Location Food Cuisine Page',
      'Location Groceries Cuisine Page',
      'Food Cuisine Page',
      'Groceries Category Page',
      'Promo Page'
    ];
    this.hasAbsolutePosition = snapshotDataNames.includes(name);
  }
}

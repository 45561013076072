import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIResponse, APIError } from '@box-types';
import { filterEnabledItems, HomeSection, normalizeHomeSection, isTimestampExpired } from '@box/utils';
import { BehaviorSubject, catchError, map, Observable, of, take, tap } from 'rxjs';
import dayjs from 'dayjs';
import defaultHomeSections from '@box-data/default-home-sections.json';
import { UserService } from '@box-core/services/user.service';
import { SentryService } from '@box-core/services/sentry.service';
import { CouponsService } from './coupons.service';

@Injectable({ providedIn: 'root' })
export class HomeSectionsService {
  private readonly BOX_API = environment.application.API_URL;
  private readonly SECTIONS_SESSION_EXPIRATION = 10 * 60 * 1000; // 10 minutes;
  private sectionsTimestamp: number;
  private readonly homeSectionsSource = new BehaviorSubject<HomeSection[]>([]);
  public readonly homeSections$ = this.homeSectionsSource.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private sentryService: SentryService,
    private couponsService: CouponsService
  ) {}

  public fetchHomeSections(): Observable<HomeSection[]> {
    return this.http.get(`${this.BOX_API}/sections`).pipe(
      map((response: APIResponse<{ sections: HomeSection[] }>) => response.payload.sections),
      catchError((response: APIError) => {
        this.sentryService.captureException(response, {
          domain: 'sections',
          domainDetails: 'Loading defaults',
          severity: 'error'
        });
        return of(defaultHomeSections as HomeSection[]);
      })
    );
  }

  public getHomeSections$(): Observable<HomeSection[]> {
    const expired: boolean = isTimestampExpired(this.sectionsTimestamp, this.SECTIONS_SESSION_EXPIRATION);
    if (!expired) return this.homeSectionsSource.pipe(take(1));

    return this.fetchHomeSections().pipe(
      map((sections) => {
        const normalizedSections = sections.map((section) => normalizeHomeSection(section));
        const userSegments = this.userService.getUser()?.segments ?? [];
        const availableCoupons = this.couponsService.getAvailableCoupons();
        const filteredSections = filterEnabledItems(normalizedSections, userSegments, availableCoupons);
        return filteredSections.sort((a, b) => a.position - b.position);
      }),
      tap((homeSections) => {
        this.sectionsTimestamp = dayjs().unix();
        this.setHomeSections(homeSections);
      })
    );
  }

  public setHomeSections(sections: HomeSection[]): void {
    this.homeSectionsSource.next(sections);
  }

  public getHomeSections(): HomeSection[] {
    return this.homeSectionsSource.getValue();
  }
}

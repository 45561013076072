import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Coupon } from '@box-types';
import { CouponService } from './coupon.service';

@Component({
  selector: 'coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  providers: [CouponService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponComponent implements OnChanges {
  @Input() private coupon: Coupon;
  @Input() public active: boolean;
  /* The overrideDisabledState is used to prevent the extra disabled background and replace the
  dateText with the disabledText. That covers a specific design scenario where we wanna
  show disabled context of the coupons in the Shop page, but avoid the disabled background features. */
  @Input() public overrideDisabledState: boolean;

  public code: string;
  public available: boolean;
  public primaryText: string;
  public primaryTextColor: string;
  public primaryTextSize: string;
  public secondaryText: string;
  public secondaryTextColor: string;
  public shortDescription: string;
  public counterfoilText: string;
  public disabledText: string;
  public backgroundColor: string;
  public logo: string;
  public showDisabledBackground: boolean;
  public showTerms: boolean;
  private dateText: string;

  constructor(private couponService: CouponService) {}

  @HostBinding('class') public hostClass = 'coupon';
  @HostBinding('class.coupon-unavailable') public couponUnAvailable: boolean;
  @HostBinding('class.coupon-disabled') public couponDisabled: boolean;
  @HostBinding('class.coupon-used') public couponUsed: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coupon) {
      this.coupon = changes.coupon.currentValue as Coupon;
      this.code = this.coupon.code;
      this.primaryText = this.couponService.getCouponPrimaryText(this.coupon);
      this.primaryTextColor = this.couponService.getCouponPrimaryTextColor(this.coupon);
      this.primaryTextSize = this.couponService.getPrimaryTextSize(this.primaryText);
      this.secondaryText = this.couponService.getCouponSecondaryText(this.coupon);
      this.secondaryTextColor = this.couponService.getCouponSecondaryTextColor(this.coupon);
      this.shortDescription = this.coupon.info.shortDescription;
      this.dateText = this.couponService.getCouponDateText(this.coupon);
      this.disabledText = this.coupon.disabledText;
      this.couponDisabled = Boolean(this.disabledText);
      this.available = this.coupon.available;
      this.couponUnAvailable = !this.coupon.available;
      this.backgroundColor = this.couponService.getCouponBackgroundColor(this.coupon);
      this.logo = this.couponService.getCouponLogo(this.coupon);
    }

    if (changes.overrideDisabledState) {
      this.overrideDisabledState = changes.overrideDisabledState.currentValue as boolean;
    }

    if (changes.active) {
      this.active = changes.active.currentValue as boolean;
    }

    this.couponUsed = this.coupon.available === false && Boolean(this.coupon.redemptionDate);
    this.counterfoilText = this.overrideDisabledState && Boolean(this.disabledText) ? this.disabledText : this.dateText;
    this.showDisabledBackground = Boolean(this.disabledText) && !this.overrideDisabledState;
    this.showTerms = !(this.overrideDisabledState && Boolean(this.disabledText));
  }
}

import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Order, MarketPlacePointsTransactionBanner } from '@box-types';
import { CheckoutOrderPointsService } from './checkout-order-points.service';

@Component({
  selector: 'checkout-order-points',
  templateUrl: './checkout-order-points.component.html',
  styleUrls: ['./checkout-order-points.component.scss'],
  providers: [CheckoutOrderPointsService]
})
export class CheckoutOrderPointsComponent implements OnInit {
  @HostBinding('class') public hostClass = 'checkout-order-points';
  @Input() private order: Order;

  public showDetails: boolean;
  public transactionBanners: MarketPlacePointsTransactionBanner[] = [];

  constructor(private checkoutOrderPointsService: CheckoutOrderPointsService) {}

  ngOnInit(): void {
    this.transactionBanners = this.checkoutOrderPointsService.getMarketPlacePointsTransactionBanners(this.order);
    this.showDetails = this.showDetailsVisibility();
  }

  private showDetailsVisibility(): boolean {
    if (this.transactionBanners?.length >= 1) return true;
    return false;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BOXNavOptions } from '@box-types';
import { Subscription } from 'rxjs';
import { RewardsNavService } from './rewards-nav.service';
import { RewardsNavItem } from './rewards-nav.types';

@Component({
  selector: 'rewards-nav',
  templateUrl: './rewards-nav.component.html',
  styleUrls: ['./rewards-nav.component.scss'],
  providers: [RewardsNavService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsNavComponent implements OnInit, OnDestroy {
  public navItems: RewardsNavItem[] = [];
  public navOptions: BOXNavOptions = { separators: false, elevated: false };

  private navItemsSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef, private rewardsNavService: RewardsNavService) {}

  @HostBinding('class') public hostClass = 'rewards-nav';

  ngOnInit(): void {
    this.rewardsNavItemsSubscription();
  }

  ngOnDestroy(): void {
    this.navItemsSubscription?.unsubscribe();
  }

  private rewardsNavItemsSubscription(): void {
    this.navItemsSubscription = this.rewardsNavService.generateNavItems$().subscribe((navItems) => {
      this.navItems = navItems;
      this.changeDetectorRef.detectChanges();
    });
  }
}

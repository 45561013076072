import { DayOfTheWeek, PromoPageData } from '@box-types';
import { generateImageSrc } from '../media';

export const weekDays: DayOfTheWeek[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export { normalizePromoPage, getPromoPageHeroBackgroundImageUrl };

function normalizePromoPage(promoPage: PromoPageData): PromoPageData {
  return {
    ...promoPage,
    title: promoPage.title ?? null,
    slug: promoPage.slug ?? null,
    webImage: promoPage.webImage ?? null,
    description: promoPage.description ?? null,
    coupon: promoPage.coupon ?? undefined,
    seoTitle: promoPage.seoTitle ?? null,
    seoText: promoPage.seoText ?? undefined,
    faqTitle: promoPage.faqTitle ?? null,
    faq: promoPage.faq ?? undefined,
    buttonTitle: promoPage.buttonTitle ?? null,
    webAction: promoPage.webAction ?? null,
    available: promoPage.available ?? true,
    enabled: promoPage.enabled ?? true,
    enabledOnWeekDays: promoPage.enabledOnWeekDays ?? weekDays,
    segments: promoPage.segments ?? [],
    notEligibleForSegments: promoPage.notEligibleForSegments ?? [],
    shops: promoPage.shops ?? [],
    ineligibleShops: promoPage.ineligibleShops ?? [],
    cuisines: promoPage.cuisines ?? [],
    primaryOrSecondaryCuisines: promoPage.primaryOrSecondaryCuisines ?? [],
    offerKinds: promoPage.offerKinds ?? [],
    chains: promoPage.chains ?? [],
    shopCampaigns: promoPage.shopCampaigns ?? [],
    displayDaasShops: promoPage.displayDaasShops ?? false,
    limitResultsToDaas: promoPage.limitResultsToDaas ?? false,
    isGoogleIndexed: promoPage.isGoogleIndexed ?? true
  };
}

function getPromoPageHeroBackgroundImageUrl(promoPageData: PromoPageData): string {
  const webImage = promoPageData?.webImage;
  if (webImage) return generateImageSrc(webImage, '@3x');
  return;
}

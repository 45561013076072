import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AnalyticsService } from '@box-core/services';
import { OrderCompletionContactInfo, GAClickContactShopConfig } from '@box-types';

@Component({
  selector: 'order-completion-contact-info',
  templateUrl: './order-completion-contact-info.component.html',
  styleUrls: ['./order-completion-contact-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderCompletionContactInfoComponent implements OnChanges {
  @Input() private contactInfo: Partial<OrderCompletionContactInfo>;

  public icon: string;
  public title: string;
  public subtitle: string;
  public anchorHref: string;
  public anchorText: string;

  constructor(private analyticsService: AnalyticsService) {}

  @HostBinding('class') public hostClass = 'order-completion-contact-info';

  ngOnChanges(changes: SimpleChanges): void {
    this.contactInfo = changes.contactInfo.currentValue as OrderCompletionContactInfo;
    this.icon = this.contactInfo.icon;
    this.title = this.contactInfo.title;
    this.subtitle = this.contactInfo.subtitle;
    this.anchorHref = this.contactInfo.anchorHref;
    this.anchorText = this.contactInfo.anchorText;
  }

  public onAnchorTextClick(): void {
    this.triggerAnalyticsEvent();
  }

  private triggerAnalyticsEvent(): void {
    const gaConfig = {
      order_status: 'accepted'
    } as GAClickContactShopConfig;
    this.analyticsService.addGACustomEvent('click_contact_shop', gaConfig);
  }
}

import { GetTextByKeyType } from '@box-types';
import { formatPoints } from './number.utils';

export { generatePointsTagContent, generateSimplifiedPointsTagContent, displayPointsTag };

function generatePointsTagContent(points: number, multiplier: number, translateFn: GetTextByKeyType): string {
  const pointsTranslatedText = translateFn('points_lowercase');
  if (multiplier) return `x${multiplier} ${pointsTranslatedText}`;
  return `${formatPoints(points)} ${pointsTranslatedText}`;
}

function generateSimplifiedPointsTagContent(points: number, multiplier: number): string {
  if (multiplier) return `x${multiplier}`;
  return `${formatPoints(points)}`;
}

function displayPointsTag(points: number, multiplier: number): boolean {
  if (points || multiplier) return true;
  return false;
}

import { Component, ChangeDetectionStrategy, Input, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { Shop } from '@box-types';
import {
  getShopDeliveryFeeText,
  getShopEstimationTimeText,
  getShopMinimumOrderPriceText,
  isShopOnlyTakeAway
} from '@box/utils';
import { currencyCode } from '@box-core/services/currency.service';

@Component({
  selector: 'shop-tile-delivery-info',
  templateUrl: './shop-tile-delivery-info.component.html',
  styleUrls: ['./shop-tile-delivery-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopTileDeliveryInfoComponent implements OnChanges {
  @Input() public shop: Shop;

  @HostBinding('class') public hostClass = 'shop-tile-delivery-info';

  public deliveryEstimationText: string;
  public minimumOrderPriceText: string;
  public deliveryFeeText: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.shop = changes.shop.currentValue as Shop;
    this.deliveryEstimationText = getShopEstimationTimeText(
      this.shop,
      isShopOnlyTakeAway(this.shop) ? 'takeAway' : 'delivery'
    );
    this.minimumOrderPriceText = getShopMinimumOrderPriceText(
      this.shop,
      isShopOnlyTakeAway(this.shop) ? 'takeAway' : 'delivery',
      true,
      currencyCode
    );
    this.deliveryFeeText = getShopDeliveryFeeText(
      this.shop,
      isShopOnlyTakeAway(this.shop) ? 'takeAway' : 'delivery',
      currencyCode,
      true
    );
  }
}

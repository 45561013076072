import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { NotificationRef } from '@box-core/components';

@Component({
  selector: 'rate-app-notification-wrapper',
  templateUrl: './rate-app-notification-wrapper.component.html',
  styleUrls: ['./rate-app-notification-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateAppNotificationWrapperComponent {
  constructor(private notificationRef: NotificationRef) {}

  @HostBinding('class') public hostClass = 'rate-app-notification-wrapper';

  public onRate(): void {
    this.notificationRef.dismiss({ delay: 3000 });
  }

  public onCancel(): void {
    this.notificationRef.dismiss();
  }
}

import { animate, keyframes, sequence, state, style, transition, trigger } from '@angular/animations';

export const BalanceBubbleTwist = trigger('balanceBubbleTwist', [
  state('true', style({})),
  transition('* => true', [
    sequence([
      animate(
        '0.3s ease-in',
        keyframes([
          style({ transform: 'scale(1)' }),
          style({ transform: 'scale(1.3)' }),
          style({ transform: 'scale(1' })
        ])
      ),
      animate(
        '0.3s ease-out',
        keyframes([
          style({ transform: 'rotate(0deg)' }),
          style({ transform: 'rotate(25deg)' }),
          style({ transform: 'rotate(-25deg)' }),
          style({ transform: 'rotate(0deg)' })
        ])
      )
    ])
  ])
]);

<header class="download-page-header">
  <div class="container">
    <div class="download-page-header-wrapper">
      <a routerLink="/" class="download-page-header-logo"></a>
    </div>
  </div>
</header>
<main class="download-page-main">
  <section class="download-page-main-section section">
    <div class="download-page-text-container container">
      <h1 class="download-page-h1 ods-typography-display-m">{{ 'download_box' | translate}}</h1>
      <p
        class="download-page-p ods-typography-display-m"
        [innerHTML]="'the_only_rewarding_delivery' | translate | safeHtml"
      ></p>
    </div>
    <div class="container download-page-links-container">
      <div class="download-page-links">
        <div class="download-page-links-wrapper">
          <a
            [attr.href]="androidUrl"
            (click)="onDownloadAppClick('Android')"
            target="_blank"
            rel="noopener noreferrer"
            class="download-page-link"
          >
            <img src="/assets/images/download/google-play-logo-new.svg" alt="Download BOX to Android" />
          </a>
          <a
            [attr.href]="iosUrl"
            (click)="onDownloadAppClick('iOS')"
            target="_blank"
            rel="noopener noreferrer"
            class="download-page-link"
          >
            <img src="/assets/images/download/app-store-logo-new.svg" alt="Download BOX to iOS" />
          </a>
          <a
            [attr.href]="huaweiUrl"
            (click)="onDownloadAppClick('Huawei')"
            target="_blank"
            rel="noopener noreferrer"
            class="download-page-link"
          >
            <img src="/assets/images/download/huawei-store-logo-new.svg" alt="Download BOX to Huawei" />
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="download-page-image-section section">
    <div class="download-page-image-background">
      <div class="download-page-image-upper-slices">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 1439 102"
          preserveAspectRatio="none"
        >
          <g fill="#FF8500" fill-rule="evenodd">
            <polygon
              points=".635 .963 1440.635 78.676 1440.635 542.963 .635 542.963"
              transform="matrix(-1 0 0 1 1441.27 0)"
            />
            <polygon
              points=".635 102.263 1440.635 .963 1440.635 542.963 .635 542.963"
              opacity=".66"
              transform="matrix(-1 0 0 1 1441.27 0)"
            />
          </g>
        </svg>
      </div>
      <div class="download-page-image-center-block"></div>
      <div class="download-page-image-lower-slices">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1439 128"
          width="100%"
          height="100%"
          preserveAspectRatio="none"
        >
          <g fill="#FF8500" fill-rule="evenodd" transform="matrix(1 0 0 -1 0 128)">
            <polygon points="0 27.037 1441 59.729 1441 255.037 0 255.037" transform="matrix(-1 0 0 1 1441 0)" />
            <polygon
              points="0 129.301 1440.365 0 1441.365 256.037 .365 256.037"
              opacity=".66"
              transform="matrix(-1 0 0 1 1441.365 0)"
            />
          </g>
        </svg>
      </div>
    </div>

    <div class="container">
      <div class="download-page-image-wrapper">
        <picture class="download-page-image">
          <source
            type="image/webp"
            srcset="
              /assets/images/download/@1xbox-download-image.webp 1x,
              /assets/images/download/@2xbox-download-image.webp 2x,
              /assets/images/download/@3xbox-download-image.webp 3x
            "
            alt="BOX Mobile"
          />

          <source
            type="image/png"
            srcset="
              /assets/images/download/@1xbox-download-image.png 1x,
              /assets/images/download/@2xbox-download-image.png 2x,
              /assets/images/download/@3xbox-download-image.png 3x
            "
            alt="BOX Mobile"
          />
          <img
            src="/assets/images/download/@3xbox-download-image.png"
            srcset="
              /assets/images/download/@1xbox-download-image.png 1x,
              /assets/images/download/@2xbox-download-image.png 2x,
              /assets/images/download/@3xbox-download-image.png 3x
            "
            alt="BOX Mobile"
          />
        </picture>
      </div>
    </div>
  </section>
</main>
<box-footer></box-footer>
